import React, {useState, useEffect} from "react"
import { Row, Col, Card, CardBody , Button } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import moment from "moment-timezone";
import { Link } from "react-router-dom"

const Calls = props => {
    
    const [seriesData, setSeriesData] = useState([1, 1, 1, 1, 1]) 
    const [seriesData2, setSeriesData2] = useState([0, 0, 0, 0, 0]) 
    const [emailsSent, setEmailsSent] = useState(0)
    const [receivedCalls, setReceivedCalls] = useState(0)  
    const [callWeeks, setCallWeeks] = useState(["Week 1", "Week 2", "Week 3", "week 4"])
    const [sereiesName, setSeriesName] = useState(`${props.startdate} - ${props.enddate}`)
    const [sereiesName2, setSeriesName2] = useState(`${props.startdate} - ${props.enddate}`)


    useEffect(() => {
        let data = props.data
        let last = props.last

        let series = []
        let series2 = []
        let count = 0
        let count2 = 0
        let week = []
        if(data.length > 0){
            let startDate = moment(data[0].from).tz("America/Chicago").format('MMMM Do')
            let endDate = moment(data[data.length-1].to).tz("America/Chicago").format('MMMM Do')
            for(let i=0 ; i< data.length; i++){
                series.push(data[i].count)
                count = count + data[i].count
                week.push(`Week ${data[i].week}`)
            }
            setSeriesName(`${startDate} - ${endDate}`)
               
        }
        if(last.length >0){
          let startDate = moment(last[0].from).tz("America/Chicago").format('MMMM Do')
          let endDate = moment(last[last.length-1].to).tz("America/Chicago").format('MMMM Do')
          for(let i=0 ; i< last.length; i++){
            series2.push(last[i].count)
            count2 = count2 + last[i].count
        }
        setSeriesName2(`${startDate} - ${endDate}`)

        }
        setEmailsSent(count)
        setSeriesData(series) 
        setSeriesData2(series2) 
        setCallWeeks(week)
        setReceivedCalls(count2)

        
      }, [props.data, props.last]);


    const series = [
      {
        name: sereiesName2,
        data: seriesData2,
      },
      {
        name: sereiesName,
        data: seriesData,
      },
    ]
  
    const options = {
      dataLabels: {
        enabled: !1
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      series: [{
        name: sereiesName2,
        data: seriesData2,
      },{
        name: sereiesName,
        data: seriesData,
      }
      ],
      colors: ['#45cb85','#fbc02d'],
      xaxis: {
        categories: callWeeks,
      },
      grid: {
        borderColor: '#f1f1f1',
      },
    }
  
    return (
        <React.Fragment>
            <Col lg={12}>
            <Card>
                <CardBody>
                    <Row>
                        <Col sm={6}>
                        <div className="mb-4"><Link className="text-dark" to={'/analytics/contractor-received-calls'}><b>Contractors Calls</b></Link></div>
                        </Col>
                        <Col sm={6}>
                        <p className="float-end"><b>{sereiesName2}: {receivedCalls}</b></p>
                        <p className="float-end"><b>{sereiesName}: {emailsSent}</b></p>
                        </Col> 
                        </Row>
                        <Row>
                        <div className="col-12">
                            <div>
                                {/* <ReactEcharts style={{ height: "350px" }} option={series} /> */}
                                <ReactApexChart options={options} series={series} type="area" height={350} className="apex-charts" />
                            </div>
                        </div>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={6}>
                            <p className="mt-2">{props.startdate} - {props.enddate}</p>
                        </Col>
                        <Col sm={6}>
                        <Link to={'/analytics/contractor-received-calls'}><Button color="light" className="btn-outline me-2 float-end btn-sm" >
                                view report <i className="mdi mdi-arrow-right-bold text-dark"></i>
                            </Button></Link>
                        </Col>

                    </Row>

                    
                </CardBody>
            </Card>
        </Col>
        </React.Fragment>
    )
}
export default Calls
