import React, { useState, useEffect,  } from "react"

import {
    Row, Col, Card, CardBody, Label, Button, Modal, NavLink, Container, FormGroup,
    NavItem, Nav, TabPane, TabContent, Spinner, ModalHeader, ModalBody
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import classnames from "classnames"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'moment-timezone';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { BASE_URL } from "../../../helpers/url_helper";
import { HeaderFormatter } from "../../../helpers/methods";
import { toast } from 'react-toastify';
import moment from "moment-timezone";
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import {
    EditorState, convertToRaw, left, right, center, justify, undo, redo, color, bold, italic, underline,
    ordered, fontSize, indent, outdent, link, emoji, unlink, eraser, unordered,
} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { getLastMontDate } from "../../../helpers/methods"

const optionGroup = [
    {
        label: "Filter Date",
        options: [

            { label: "Today", value: "today" },
            { label: "Yesterday", value: "yesterday" },
            { label: "Last week ", value: "last_week" },
            { label: "Last month", value: "last_month" },
            { label: "Last 7 days", value: "last_7_days" },
            { label: "Last 30 days", value: "last_30_days" },
            { label: "Custom", value: "custom" },
        ],
    },
];

const columns = [
    {
        dataField: "week",
        text: "ID",
        hidden: true,

    },
    {
        dataField: "from",
        text: "From Date",
        headerFormatter: HeaderFormatter,
        formatter: (cellContent) => (
            moment(cellContent).format('MM-DD-YYYY')
        ),
    },
    {
        dataField: "to",
        text: "To Date",
        headerFormatter: HeaderFormatter,
        formatter: (cellContent) => (
            moment(cellContent).format('MM-DD-YYYY')
        ),

    },

]

const ContractorDetailData = (props) => {

    const url = props.location.url
    const page_name = props.location.page_name
    const { slug } = props.match.params
    const obj = props.obj

    const [startdate, setStartDate] = useState(moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [seletedDate, setSeletedDate] = useState({ label: "Last 30 days", value: "last_30_days" })
    const [modal_standard, setmodal_standard] = useState(false)
    const [activeTab, setactiveTab] = useState("5")
    // const [print, setPrint] = useState(false)

    const [password_loading, setPasswordLoading] = useState(false)
    const [invoiceloading, setinvoiceloading] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rawMessage, setrawMessage] = useState('');


    const FeedbackColumn = [...columns, {
        dataField: "count",
        text: "No of Customer Feedbacks",
        headerFormatter: HeaderFormatter,
        sort: true,
        align: 'center',
        headerAlign: 'center',
    },]

    const CallsColumn = [...columns, {
        dataField: "count",
        text: "No of Calls Received",
        headerFormatter: HeaderFormatter,
        sort: true,
        align: 'center',
        headerAlign: 'center',
    },]

    const EmailColumn = [...columns, {
        dataField: "count",
        text: "No of Emails Received",
        headerFormatter: HeaderFormatter,
        sort: true,
        align: 'center',
        headerAlign: 'center',
    },]

    const ProfileViewColumn = [...columns, {
        dataField: "count",
        text: "No of Profile Visits",
        headerFormatter: HeaderFormatter,
        sort: true,
        align: 'center',
        headerAlign: 'center',
    },]

    const WebsiteClickColumn = [...columns, {
        dataField: "count",
        text: "No of Website Clicks",
        headerFormatter: HeaderFormatter,
        sort: true,
        align: 'center',
        headerAlign: 'center',
    },]


    const profileShareColumn = [...columns, {
        dataField: "count",
        text: "No of Profile Shares",
        headerFormatter: HeaderFormatter,
        sort: true,
        align: 'center',
        headerAlign: 'center',
    },]

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function toggleCustomJustified(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    function onEditorStateChange(editorState) {
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    useEffect(() => {
            
        const filter =  JSON.parse(localStorage.getItem("analyticsDate"))
        let startDate = startdate
        let endDate = enddate
        if (filter){
            setStartDate(filter.startDate)
            setEnddate(filter.endDate)
            startDate = filter.startDate
            endDate = filter.endDate
            setSeletedDate(filter.type)
        }
        let data = {"campaign_slug":slug, "from_date":startDate, "to_date":endDate}

        props.loadCampaignData(data)
          
            
    }, []); 

    function getChart(data) {

        let count = 0
        let seriesData = []
        for (let i = 0; i < data.length; i++) {
            count = count + data[i].count
            seriesData.push(data[i].count)
        }
        const series = [{
            name: "Series A",
            data: seriesData
        }
        ]

        const options = {
            chart: {
                sparkline: {
                    enabled: !0
                }
                ,
                toolbar: {
                    show: !1
                }
                ,
            }
            ,
            dataLabels: {
                enabled: !1
            }
            ,
            stroke: {
                curve: 'smooth', width: 3
            }
            ,
            colors: ['#3b5de7'],
        }
        return (
            <Row>
                <Col sm={6}>
                    <div>
                        <h4>{count}</h4>
                    </div>
                </Col>
                <Col sm={6}>
                    <div className="mt-3 mt-sm-0">
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="line"
                            height="50"
                            className="apex-charts"
                        />
                    </div>
                </Col>
            </Row>)
    }

    function handleSeletedDateChange(selected_date) {
        setSeletedDate(selected_date)
        if (selected_date.value !== 'custom') {
            if (selected_date.value === 'today') {
                let start = moment(new Date()).format('YYYY-MM-DD')
                let end = moment(new Date()).format('YYYY-MM-DD')
                props.loadCampaignData({ "campaign_slug": slug, "from_date": start, "to_date": end })
            } else if (selected_date.value === 'yesterday') {
                let start = moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD')
                let end = moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD')
                props.loadCampaignData({ "campaign_slug": slug, "from_date": start, "to_date": end })
            } else if (selected_date.value === 'last_week') {
                let start = moment(new Date()).subtract(14, 'd').format('YYYY-MM-DD')
                let end = moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
                props.loadCampaignData({ "campaign_slug": slug, "from_date": start, "to_date": end })
            } else if (selected_date.value === 'last_month') {
                let start = getLastMontDate().firstDay
                let end = getLastMontDate().lastDay
                setStartDate(start)
                setEnddate(end)
                props.loadCampaignData({ "campaign_slug": slug, "from_date": start, "to_date": end })
            } else if (selected_date.value === 'last_7_days') {
                let start = moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD')
                let end = moment(new Date()).format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
                props.loadCampaignData({ "campaign_slug": slug, "from_date": start, "to_date": end })
            } else if (selected_date.value === 'last_30_days') {
                let start = moment(new Date()).subtract(30, 'd').format('YYYY-MM-DD')
                let end = moment(new Date()).format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
                props.loadCampaignData({ "campaign_slug": slug, "from_date": start, "to_date": end })
            }
        }
    }

    function handleFilter(e, values) {

        if ((new Date(values.enddate)) >= (new Date(values.startdate))) {
            localStorage.setItem("analyticsDate", JSON.stringify({'type':seletedDate,'startDate':enddate, 'endDate':enddate}));
            props.loadCampaignData({ "campaign_slug": slug, "from_date": startdate, "to_date": enddate })

        } else {
            alert(`To date must be grater than from Date`)
        }

    }

    function handlePrint() {

        props.setPrint(true)

        setTimeout(() => {
            window.print();
            props.setPrint(false)
        }, 10);
    }

    function handleShare(e, values) {
        setinvoiceloading(true)
        let message = encodeURIComponent(rawMessage)

        let data = {
            "email": values.email,
            "data": message,
            "subject": values.subject,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch(`${BASE_URL}/api/tgcl/dashboard/share-analytics/`, requestOptions)
            .then(res => {
                if (res.status >= 400) {
                    setEditorState(EditorState.createEmpty())
                    setinvoiceloading(false)
                }
                else {
                    toast.success(`Report Has been Send on ${values.email}`)
                    setEditorState(EditorState.createEmpty())
                    setinvoiceloading(false)

                }
            }
            )
    }

    function handleValidateUserSubmit(e, values) {
        setPasswordLoading(true)
        console.log((props.campaign.contact_phone).replaceAll("-", ""))
        if ((props.campaign.contact_phone).replaceAll("-", "") === values.password) {
            setPasswordLoading(false)
            props.setPassword(true)
        } else {
            toast.error("Password Do not match")
            setPasswordLoading(false)
        }
    }
    console.log(props.campaign?.contact_phone)


    return (
        <React.Fragment>

            <div className={obj && !props.print ? "page-content" : "p-4"}>
                {obj && !props.print ?
                    <Breadcrumbs title={page_name ? page_name : 'Analytics Dashbaord'} breadcrumbItem={`${props.campaign ? props.campaign.pg_title : "Campaign "} Report`} link={url ? url : "/analytics"} />

                    : <Row>
                        <Col className="col-12 my-4">
                            {props.password ?

                                <h3 className="" style={{ color: 'black' }}>{props.campaign?.pg_title}</h3> : null}
                            {/* </div> */}
                        </Col>
                    </Row>
                }
                {props.loaddata ?
                    props.password ?
                        <>
                            {!props.print ?
                                <Row>
                                    <Col xs="12">
                                        <Card>
                                            <CardBody>
                                                <AvForm className="needs-validation" onValidSubmit={handleFilter}>
                                                    <Row>
                                                        <Col md="3">
                                                            <div className="text-sm-start mb-2">
                                                                <Select
                                                                    value={seletedDate}
                                                                    onChange={(e) => handleSeletedDateChange(e)}
                                                                    options={optionGroup}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>

                                                        </Col>


                                                        <Col md={obj ? "6" : "7"}>
                                                            {seletedDate.value === 'custom' ?
                                                                <Row>
                                                                    <Col md={8}>
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <Row className="mb-3">
                                                                                    <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                                                                                    <div className="col-md-8">
                                                                                        <AvField
                                                                                            name="startdate"
                                                                                            placeholder="To Date"
                                                                                            type="date"
                                                                                            errorMessage="To Date"
                                                                                            className="form-control"
                                                                                            value={startdate}
                                                                                            onChange={(e) => setStartDate(e.target.value)}
                                                                                            id="to_date"

                                                                                        />
                                                                                    </div>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <Row className="mb-3">
                                                                                    <Label htmlFor="email_bcc" className="col-md-4 col-form-label">To</Label>
                                                                                    <div className="col-md-8">
                                                                                        <AvField
                                                                                            name="enddate"
                                                                                            placeholder="To Date"
                                                                                            type="date"
                                                                                            errorMessage="To Date"
                                                                                            className="form-control"
                                                                                            value={enddate}
                                                                                            onChange={(e) => setEnddate(e.target.value)}
                                                                                            id="to_date"
                                                                                        />
                                                                                    </div>
                                                                                </Row>
                                                                            </Col>

                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={4}>
                                                                        <Button type="submit" color="success" className="btn-rounded me-2 mb-3" >
                                                                            Filter
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                : null}
                                                        </Col>
                                                        {obj && !props.print ?
                                                            <Col md="3">
                                                                <div className="float-end">
                                                                    <Button type="button" color="warning" onClick={() => tog_standard()} className="btn-rounded me-2" >
                                                                        <i className="mdi mdi-share me-1 mb-3" />{" "}Share
                                                                    </Button>{" "}
                                                                    <Button type="button" color="warning" onClick={() => handlePrint()} className="btn-rounded me-2" >
                                                                        <i className="mdi mdi-printer me-1 mb-3" />{" "}Print
                                                                    </Button>


                                                                </div>
                                                            </Col>
                                                            :
                                                            <Col md='2'>
                                                                <Button type="button" color="warning" onClick={() => handlePrint()} className="btn-rounded me-2" >
                                                                    <i className="mdi mdi-printer me-1" />{" "}Print
                                                                </Button>
                                                            </Col>}

                                                    </Row>

                                                </AvForm>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row> : null}
                            <Row>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title mb-4">Email To Contractor</h4>
                                            {getChart(props.contractorEmail)}

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title mb-4">Profile Views </h4>
                                            {getChart(props.profileView)}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title mb-4">Website Clicks</h4>
                                            {getChart(props.websiteClick)}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title mb-4">Calls Received</h4>
                                            {getChart(props.receivedCalls)}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title mb-4">FeedBack Received</h4>
                                            {getChart(props.contractorFeedback)}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={4}>
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title mb-4">Profile Shares</h4>
                                            {getChart(props.profileShare)}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {/* Tables */}
                            <Row>
                                <Col className="col-12">
                                    <h5 className="page-title my-4" style={{ color: 'black' }}>{"Emails Received"}</h5>

                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive">

                                                <BootstrapTable
                                                    responsive
                                                    keyField="week"
                                                    data={props.contractorEmail}
                                                    columns={EmailColumn}
                                                    bordered={false}
                                                    striped={true}
                                                    hover
                                                    classes={
                                                        "table align-middle table-nowrap table-check"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                />

                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-12">
                                    <h5 className="page-title my-4" style={{ color: 'black' }}>{"Profile Views"}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive">

                                                <BootstrapTable
                                                    responsive
                                                    keyField="week"
                                                    data={props.profileView}
                                                    columns={ProfileViewColumn}
                                                    bordered={false}
                                                    striped={true}
                                                    hover
                                                    classes={
                                                        "table align-middle table-nowrap table-check"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                />

                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="pagebreak"> </div>

                            <Row>
                                <Col className="col-12">
                                    <h5 className="page-title my-4" style={{ color: 'black' }}>{"Website Clicks"}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive">

                                                <BootstrapTable
                                                    responsive
                                                    keyField="week"
                                                    data={props.websiteClick}
                                                    columns={WebsiteClickColumn}
                                                    bordered={false}
                                                    striped={true}
                                                    hover
                                                    classes={
                                                        "table align-middle table-nowrap table-check"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                />

                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-12">
                                    <h5 className="page-title my-4 " style={{ color: 'black' }}>{"Calls Received"}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive">

                                                <BootstrapTable
                                                    responsive
                                                    keyField="week"
                                                    data={props.receivedCalls}
                                                    columns={CallsColumn}
                                                    bordered={false}
                                                    striped={true}
                                                    hover
                                                    // defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap table-check"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                // noDataIndication={() => NoDataIndication()}
                                                />

                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="pagebreak"> </div>

                            <Row>
                                <Col className="col-12">
                                    <h5 className="page-title my-4" style={{ color: 'black' }}>{"Customer Feedback"}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive">

                                                <BootstrapTable
                                                    responsive
                                                    keyField="week"
                                                    data={props.contractorFeedback}
                                                    columns={FeedbackColumn}
                                                    bordered={false}
                                                    striped={true}
                                                    hover
                                                    // defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap table-check"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                // noDataIndication={() => NoDataIndication()}
                                                />

                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-12">

                                    <h5 className="page-title my-4" style={{ color: 'black' }}>{"Profile Shares"}</h5>

                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive">

                                                <BootstrapTable
                                                    responsive
                                                    keyField="week"
                                                    data={props.profileShare}
                                                    columns={profileShareColumn}
                                                    bordered={false}
                                                    striped={true}
                                                    hover
                                                    // defaultSorted={defaultSorted}
                                                    classes={
                                                        "table align-middle table-nowrap table-check"
                                                    }
                                                    headerWrapperClasses={"table-light"}
                                                // noDataIndication={() => NoDataIndication()}
                                                />

                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Container fluid={true}>
                            <Col sm={6} md={4} xl={3} className="mt-4">

                                <Modal
                                    isOpen={true}
                                    toggle={() => { props.history.goBack() }}
                                >
                                    <ModalHeader toggle={() => { props.history.goBack() }}>
                                        Enter password
                                    </ModalHeader>
                                    <ModalBody>
                                        <AvForm className="needs-validation" onValidSubmit={handleValidateUserSubmit}>
                                            <Row>
                                                <Col xs={12}>
                                                    <Row className="mb-3">
                                                        <Label className="col-md-2 col-form-label" htmlFor="password">Password</Label>
                                                        <Col md={8}>
                                                            <AvField
                                                                name="password"
                                                                type="text"
                                                                errorMessage="Enter Password"
                                                                className="form-control"
                                                                validate={{ required: { value: true } }}
                                                                id="password"
                                                            />
                                                        </Col>
                                                        <p>your mobile no is your password</p>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <FormGroup className="mb-0">
                                                {password_loading ? (
                                                    <Spinner className="me-2 mt-2" color="primary" />
                                                ) : (
                                                    <div>
                                                        <Button type="submit" color="primary" className="me-1">Submit</Button>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </AvForm>

                                    </ModalBody>
                                </Modal>
                            </Col>
                        </Container>
                    :
                    <div id="preloader">
                        <div id="status">
                            <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                            </div>
                        </div>
                    </div>
                }

            </div>

            <Col sm={6} md={4} xl={3}>
                <Modal
                    isOpen={modal_standard}
                    toggle={tog_standard}
                    centered={true}
                >
                    <ModalHeader toggle={tog_standard}>
                        Share Invoice
                    </ModalHeader>
                    <ModalBody>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab === "5"
                                    })}
                                    onClick={() => {
                                        toggleCustomJustified("5");
                                    }}
                                >
                                    <span><span><i className="ri-mail-send-line"> Via Email</i></span></span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "url" }}
                                    className={classnames({
                                        active: activeTab === "7"
                                    })}
                                    onClick={() => {
                                        toggleCustomJustified("7");
                                    }}
                                >
                                    <span><i className=" far fa-copy"> URL</i></span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab === "6"
                                    })}
                                    onClick={() => {
                                        toggleCustomJustified("6");
                                    }}
                                >
                                    <span><i className="fab fa-whatsapp"> Via WhatsApp</i></span>
                                </NavLink>
                            </NavItem>

                        </Nav>
                        <TabContent activeTab={activeTab}>

                            <TabPane tabId="5" className="p-3">
                                <AvForm className="needs-validation" onValidSubmit={handleShare} >
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="user_email">Email</Label>
                                                <AvField
                                                    name="email"
                                                    value={props.campaign?.email}
                                                    type="email"
                                                    errorMessage="Enter Customer Name"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="user_email"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="subject">Subject</Label>
                                                <AvField
                                                    name="subject"
                                                    type="text"
                                                    errorMessage="Enter Heading Name"
                                                    className="form-control"
                                                    value={'Analytics Report'}
                                                    validate={{ required: { value: true } }}
                                                    id="subject"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="mb-3">
                                            <Editor

                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                                    inline: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['bold', 'italic', 'underline',],
                                                        bold: { icon: bold, className: undefined },
                                                        italic: { icon: italic, className: undefined },
                                                        underline: { icon: underline, className: undefined },
                                                    },
                                                    blockType: {
                                                        inDropdown: true,
                                                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    fontSize: {
                                                        icon: fontSize,
                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    fontFamily: {
                                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'metropolisRegular', 'gothambold'],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    list: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                        unordered: { icon: unordered, className: undefined },
                                                        ordered: { icon: ordered, className: undefined },
                                                        indent: { icon: indent, className: undefined },
                                                        outdent: { icon: outdent, className: undefined },
                                                    },
                                                    textAlign: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['left', 'center', 'right', 'justify'],
                                                        left: { icon: left, className: undefined },
                                                        center: { icon: center, className: undefined },
                                                        right: { icon: right, className: undefined },
                                                        justify: { icon: justify, className: undefined },
                                                    },
                                                    colorPicker: {
                                                        icon: color,
                                                        className: undefined,
                                                        component: undefined,
                                                        popupClassName: undefined,
                                                        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                                    },
                                                    link: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        popupClassName: undefined,
                                                        dropdownClassName: undefined,
                                                        showOpenOptionOnHover: true,
                                                        defaultTargetOption: '_self',
                                                        options: ['link', 'unlink'],
                                                        link: { icon: link, className: undefined },
                                                        unlink: { icon: unlink, className: undefined },
                                                        linkCallback: undefined
                                                    },
                                                    emoji: {
                                                        icon: emoji,
                                                        className: undefined,
                                                        component: undefined,
                                                        popupClassName: undefined,
                                                        emojis: [
                                                            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                            '✅', '❎', '💯',
                                                        ],
                                                    },

                                                    remove: { icon: eraser, className: undefined, component: undefined },
                                                    history: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['undo', 'redo'],
                                                        undo: { icon: undo, className: undefined },
                                                        redo: { icon: redo, className: undefined },
                                                    },
                                                }}
                                                editorStyle={{ height: '200px', border: 'inset 2px', padding: '10px' }}
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md="6">
                                            {invoiceloading ?
                                                <Spinner className="mt-3" color="primary" />
                                                :
                                                <div className="mt-3">
                                                    <Button type="submit" color="primary" className="">
                                                        Share
                                                    </Button>
                                                </div>

                                            }
                                        </Col>
                                    </Row>

                                </AvForm>
                            </TabPane>
                            <TabPane tabId="6" className="p-3">
                                <div className='d-flex align-items-center justify-content-center'>
                                    <WhatsappShareButton
                                        windowWidth="1024"
                                        windowHeight={"800"}
                                        from=""
                                        url={`${BASE_URL}/analytics/contractor-detail/${slug}`}
                                        separator="::"
                                        className="Demo__some-network__share-button"
                                    >
                                        <WhatsappIcon size={50} round={true} />
                                    </WhatsappShareButton>
                                </div>
                            </TabPane>
                            <TabPane tabId="7" className="p-3">
                                <Row>
                                    <Col md={12}>
                                        <AvForm>
                                            <div className="mb-3">
                                                <AvField
                                                    name="url_link"
                                                    type="text"
                                                    errorMessage="Enter Subject Name"
                                                    className="form-control"
                                                    value={`${BASE_URL}/analytics/contractor-detail/${slug}`}
                                                    disabled
                                                    id="url_link"
                                                />
                                            </div>
                                        </AvForm>
                                    </Col>
                                </Row>
                                <Row>
                                    <Link to="#" onClick={() => { navigator.clipboard.writeText(`${BASE_URL}/analytics/contractor-detail/${slug}`); toast.success("URL Copied on Clip Board") }} className="text-dark"><i className=" far fa-copy font-size-30 float-end"> Copy URL</i></Link>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                </Modal>
            </Col>


        </React.Fragment>
    )
}

export default ContractorDetailData
