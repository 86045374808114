import React, {useEffect, useState} from "react"
import { Row, Col, CardBody, Card, CardTitle, Button, Label, Spinner } from "reactstrap"
import { post } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import moment from "moment-timezone";
import { AvForm, AvField } from "availity-reactstrap-validation"

import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select";

const optionGroup = [
    {
      label: "Email Type",
      options: [

        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Last week ", value: "last_week" },
        { label: "Last month", value: "last_month" },
        { label: "Last 7 days", value: "last_7_days" },
        { label: "Last 30 days", value: "last_30_days" },
        { label: "Custom", value: "custom" },
      ],
    },
  ];


const CategoryDetailAnalytics = (props) => {

    const categoryName = props.location.categoryName
    const { id } = props.match.params;

    const [zicodesData, setZicodesData] = useState([]) 
    const [count, setcount] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [startdate, setStartDate] = useState(moment(new Date()).subtract(30,'d').tz("America/Chicago").format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')) 
    const [seletedDate, setSeletedDate] = useState({ label: "Last 30 days", value: "last_30_days" })
    const [loaddata, setLoaddata] = useState(false)  
    
    function loadData(start_date, end_date){
        let data = {"category_id":id, "from_date": start_date, "to_date": end_date}
        post(`${BASE_URL}/api/tgcl/dashboard/category-detail-analytics/`,data, { headers: { 'Content-Type': 'application/json', }} )
        .then(response => {
            
        let data = response.data.category_data
        let series = []
        let counts = []
        if(data.length > 0){
            let length =  data.length>15? 15 : data.length
            for(let i=0 ; i< length; i++){
                series.push(data[i].date)
                counts.push(data[i].count)
            }
             
        }
        // setCategoryData(response.data.grouped_data)
            let catData = response.data.grouped_data
            let nonExpandable = []
            let totalCount = 0
            let categoryData = []
            Object.keys(catData).map((key, index) => {
                
                let category = {}
                category['date'] = key

                let zipData =  catData[key]
                category['id'] = key
                if (zipData.length < 2){
                    nonExpandable.push(key)
                    category['zipcode_range'] = zipData[0].zip
                    category['count'] = zipData[0].count
                    category['zipdata'] = []
                    totalCount = totalCount+zipData[0].count

                }else{
                    let Zipcodes = []
                    let count = 0
                    for(let i=0; i<zipData.length; i++){
                        Zipcodes.push(parseInt(zipData[i].zip))
                        count = count + zipData[i].count
                    }
                    Zipcodes.sort()
                    category['zipcode_range'] = `${Zipcodes[0]} - ${Zipcodes[Zipcodes.length -1]}`
                    category['count'] = count
                    totalCount = totalCount + count
                    category['zipdata'] = zipData

                }
                if (!zipData[0].category__id_num){
                    console.log(category)
                }
                categoryData.push(category)
                
            })
            // setNonExpandable(nonExpandable)
            setTotalCount(totalCount)
            setCategoryData(response.data.zip_data)
            setLoaddata(true)
            setZicodesData(series)
            setcount(counts) 

        })
    }

    useEffect(() => {
        if (categoryName){
            loadData(startdate, enddate)
        }else{
            props.history.push('/analytics/category-search')
        }
            
        
        
    }, []);

    function handleFilter(e, values){
        if ((new Date(values.enddate)) > (new Date(values.startdate))){
        loadData(startdate, enddate)
        setLoaddata(true)
        
        }else{
        alert(`To date must be grater than from Date`)
        }
  
        
      }

      function handleSeletedDateChange(selected_date){
        setSeletedDate(selected_date)
        if(selected_date.value !== 'custom'){
            let start = startdate
            let end = enddate
            if(selected_date.value === 'today'){
                start = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
            }else if(selected_date.value === 'yesterday'){
                start = moment(new Date()).subtract(1,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).subtract(1,'d').tz("America/Chicago").format('YYYY-MM-DD')
            }else if(selected_date.value === 'last_week'){
                start = moment(new Date()).subtract(14,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).subtract(7,'d').tz("America/Chicago").format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_month'){
                start = moment(new Date()).subtract(60,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).subtract(30,'d').tz("America/Chicago").format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_7_days'){
                start = moment(new Date()).subtract(7,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_30_days'){
                start = moment(new Date()).subtract(30,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }
            loadData(start, end)
            setLoaddata(false)
        }
    }
    
    function NoDataIndication(){
        if (loaddata && categoryData.length === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )
    
        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
      }


    const series = [
        {
          name: "Search Count",
          data: count,
        },
      ]
    
      const options = {
        dataLabels: {
          enabled: !1
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        series: [{
          name: 'Count',
          data: count
        }],
        colors: ['#45cb85'],
        xaxis: {
          type: 'datetime',
          categories: zicodesData,
        },
        grid: {
          borderColor: '#f1f1f1',
        },
        tooltip: {
          x: {
            format: 'MM/dd/yy'
          },
        }
      }

      const columns = [
        {
          dataField: "id",
          text: "ID",
          hidden: true,
        },
        {
            dataField: "zip",
            text: "Zipcode Range",
            sort: true,
            formatter: (cellContent) => (
              cellContent    
            ),
            title: (cell) => `${cell}`
        },
        {
        dataField: "count",
        text: "Search Count",
        sort: true,
        formatter: (cellContent) => (
            cellContent    
        ),
        title: (cell) => `${cell}`
        },
      ]

    const defaultSorted = [{
    dataField: 'date',
    order: 'asc'
    }];

    //pagination customization
    const pageOptions = {
        sizePerPage: 20,
        totalSize: categoryData.length,
        custom: true,
    }

    return (
        <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Category Analytics" breadcrumbItem={categoryName?`${categoryName} Searches`:'Category Analytics'} link="/analytics/category-search" />
            <Row>
                <Col xs="12">
                <Card>
                    <CardBody>
                    <AvForm className="needs-validation" onValidSubmit={handleFilter}>
                        <Row>
                            <Col sm="3">
                                <div className="text-sm-start mb-2">
                                    <Select
                                    value={seletedDate}
                                    onChange={(e) => handleSeletedDateChange(e)}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                                
                            </Col>
                            {seletedDate.value==='custom'?
                            <Col sm="9">
                                <Row>
                                
                                    <Col md={8}>
                                    <Row>
                                        <Col md={6}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                                            <div className="col-md-8">
                                            <AvField
                                            name="startdate"
                                            placeholder="To Date"
                                            type="date"
                                            errorMessage="To Date"
                                            className="form-control"
                                            value={startdate}
                                            onChange={(e)=> setStartDate(e.target.value)}
                                            id="to_date"
                                            
                                            />
                                        </div>
                                        </Row>
                                        </Col>
                                        <Col md={6}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_bcc" className="col-md-4 col-form-label">To</Label>
                                            <div className="col-md-8">
                                            <AvField
                                            name="enddate"
                                            placeholder="To Date"
                                            type="date"
                                            errorMessage="To Date"
                                            className="form-control"
                                            value={enddate}
                                            onChange={(e)=> setEnddate(e.target.value)}
                                            id="to_date"
                                            />
                                        </div>
                                        </Row>
                                        </Col>
                                        
                                    </Row>
                                    </Col>
                                
                                    <Col md={4}>
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                            Filter
                                        </Button>
                                        {" "}
                                        {/* <Button
                                            type="button"
                                            color="primary"
                                            className="btn-rounded mb-2 me-2"
                                            // onClick={() => handleDownloadExcel()}
                                            >
                                            <i className="mdi mdi-file-excel-outline me-1" />{" "}
                                            Download
                                        </Button> */}
                                    </Col>
                                </Row>
                            </Col>
                            :null}
                        
                        </Row>
                    
                    </AvForm>
                    </CardBody>
                </Card>
                </Col>
            </Row>
    
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <CardTitle className="h4 mb-4">
                            {categoryName} Searches
                            </CardTitle>
                            {loaddata?
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="area"
                                    height="350"
                                    className="apex-charts"
                                />
                                : 
                                <Col sm="12">
                                    <div className="d-flex align-items-center justify-content-center" style={{height:"250px"}}>
                                    <Spinner className="my-3" color="dark" />
                                    </div>
                                </Col>
                                }
                        </CardBody>
                    </Card>

                </Col>
            
            
            </Row>
            <Row>
                <Col xs="12">
                    <Card>
                    <CardBody>
                        <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        data={categoryData}
                        >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                            keyField="id"
                            data={categoryData}
                            columns={columns}
                            bootstrap5
                            search
                            >
                            
                            {toolkitProps => (
                                <React.Fragment>
                                
                                <Row className="mb-2">
                                    <Col sm="4">
                                    </Col>
                                    <Col sm="8">
                                    <div className="text-sm-end">
                                        <p><b className="mt-2">Searches Count: {totalCount}</b></p>
                                        
                                    </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive">

                                    <BootstrapTable
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                            "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        noDataIndication={() => NoDataIndication()}
                                    />
                                    
                                </div>
                                <Row>
                                        <Col sm={6}>
                                        <div className="pagination  mb-2">
                                            <PaginationTotalStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        
                                        </Col>
                                        <Col sm={6}>
                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                            <PaginationListStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                            </ToolkitProvider>
                        )}
                        </PaginationProvider>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            
        </div>
        </React.Fragment>
    )
}

export default CategoryDetailAnalytics