import React, {useState, useEffect} from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"

const ReceivedEmails = props => {
    
    const [seriesData, setSeriesData] = useState([1, 1, 1, 1, 1]) 
    const [emailsSent, setEmailsSent] = useState(0) 
    useEffect(() => {
        let data = props.data
        let series = []
        let count = 0
        if(data.length > 0){
            for(let i=0 ; i< data.length; i++){
                series.push(data[i].email_sent)
                count = count + data[i].email_sent
            }
            setEmailsSent(count)
            setSeriesData(series)  
        }
        
      }, [props.data]);



    const series = [{
        name: "Series A",
        data: seriesData
    }
    ]
    
    const options = {
        chart: {
            sparkline: {
                enabled: !0
            }
            ,
            toolbar: {
                show: !1
            }
            ,
        }
        ,
        dataLabels: {
            enabled: !1
        }
        ,
        stroke: {
            curve: 'smooth', width: 3
        }
        ,
        colors: ['#3b5de7'],
    }
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Received Emails</h4>
                    <Row>
                        <Col sm={6}>
                            <div>
                                <h4>{emailsSent}</h4>  
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="mt-3 mt-sm-0">
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="line"
                                    height="50"
                                    className="apex-charts"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={6}>
                            <p className="mt-2">{props.startdate} - {props.enddate}</p>
                        </Col>
                        <Col sm={6}>
                        <Link to={'/analytics'}><Button color="light" className="btn-outline me-2 float-end btn-sm" >
                                view report <i className="mdi mdi-arrow-right-bold text-dark"></i>
                            </Button></Link>
                        </Col>

                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
export default ReceivedEmails
