import React, { useState,useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { toast } from "react-toastify";
import { BASE_URL } from "../../../helpers/url_helper";
import { get, post } from "../../../helpers/api_helper";
import { ValidateUser } from "../../../helpers/validate_user";

const AddNewZipCode = (props) => {

    const [zipcode, setZipCode] = useState(false)
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null);
    const [didMount, setDidMount] = useState(false);

    ValidateUser(props)


    function handleChange(e) {
        setFile(e.target.files[0]);
    }

    function loadZipcodes(search){
        get(`${BASE_URL}/api/tgcl/ziprad/?zipcode=${search}`, { headers: { 'Content-Type': 'application/json', },  validateStatus: false})
        .then(response => {
            if(response.data.results.length >0){
                setZipCode(response.data.results[0])
            }
            else{
                setZipCode(false)
            }
            
        })
    }

    function handleSubmit(e, values){
        
        if(zipcode){
            setLoading(true)
            let data = { "zipcode": values.zip, "is_active": document.getElementById('is_active').checked, "zip": zipcode.id }
            console.log(data)
            get(`${BASE_URL}/api/tgcl/zipcode/?zipcode=${values.zip}`, { headers: { 'Content-Type': 'application/json', },  validateStatus: false})
            .then(response => {
                if(response.data.results.length >0){
                    toast.info(`${values.zip} Zipcode Already Added`)
                    setZipCode(false)
                    setLoading(false)
                }
                else{
                    post(`${BASE_URL}/api/tgcl/zipcode/`, data, {  validateStatus: false})
                    .then(response => {
                        if (response.status>= 400){
                            console.log(response)
                            toast.error("Somthing Went Wrong")
                            setLoading(false)
                        }else{
                            props.history.push(`/zipcodes`)
                        
                        }
                    })
                }
            })
        }else{
            toast.error("Invalid Zipcode")
            setLoading(false)
        }
        
    }
  
    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="ZIP Codes" breadcrumbItem={`Add New Zip Code`} link="/zipcodes" />
         
            <Row>
            <Col className="col-6">
                <Card>
                <CardBody>
                    <CardTitle>ZIP Detail</CardTitle>                  
                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                        <Row>
                            <Col md="12">
                            <div className="mb-3">
                            <Label htmlFor="Zip">Zip Code</Label>
                            <AvField
                                name="zip"
                                placeholder="Zip Code"
                                type="text"
                                errorMessage=" Please provide a valid zip."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="Zip"
                                onChange={(e)=> e.target.value.length === 5?loadZipcodes(e.target.value):setZipCode(false)}
                            />
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                            <div className="form-check mb-3">
                                <input className="form-check-input" defaultChecked={true} type="checkbox" id="is_active"
                                    required />{" "}
                                <label className="form-check-label" htmlFor="is_active">
                                Active</label>

                            </div>
                            </Col>
                        </Row>
                        {zipcode?
                        <>
                        <Row>
                            
                            <Col sm={6}>
                              <p><b>City Name :</b> {zipcode.cityname}</p>
                            </Col>
                        
                            <Col sm={6}>
                            <p><b>State :</b> {zipcode.statename}</p>
                            </Col>
                            
                        </Row>
                        {loading?
                        <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        >
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                        Submitting
                        </button>
                        :
                        <Button color="primary" type="submit">
                        Submit
                        </Button>
                        }
                        </>
                        :<Button color="primary" type="button" disabled={true}>
                            Submit
                        </Button>}
                        
         
                    </AvForm>
                
                </CardBody>
                </Card>
            </Col>
            </Row>
          
        </div>

        </React.Fragment>
    )
}

export default AddNewZipCode
