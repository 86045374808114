import React, { useState,useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { slugify } from "../../helpers/helper_methods";
import { toast } from "react-toastify";
import { BASE_URL } from "../../helpers/url_helper";
import { get } from "../../helpers/api_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { toTitleCase } from "../../helpers/methods";
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"

const CreateHeading = (props) => {

  const [selectedCategory, setselectedCategory] = useState({ label: "Home Services", value: 1 });
  const [file, setFile] = useState(null);
  const [categories, setCategories] = useState([])
  const [all_categories, setAllCategories] = useState([])
  const [didMount, setDidMount] = useState(false);
  const [slug, setSlug] = useState("");

  const Categories = [
    {
      label: "Categoties",
      options: categories
    },
  ];
  ValidateUser(props)

  useEffect(() => {
    
    setDidMount(true);
    loadParentCategory()
    return () => setDidMount(false);
      
  },[]);

  if(!didMount) {
      return null;
    }

  function handleSelectGroup(selectedCategory) {
      setselectedCategory(selectedCategory);
  }

  function handleChange(e) {
      setFile(e.target.files[0]);
  }


  function loadParentCategory(){
    let data = get(`${BASE_URL}/api/tgcl/all-parents/?page_size=200`,
    { headers: { 'Content-Type': 'application/json', },validateStatus: false} )
    data.then(response => {
      let options = []
        let cat_data = response.data.results
        for(let i=0; i<cat_data.length; i++){
            options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
        }
        setCategories(options)
        setAllCategories(cat_data)
        },
        error => {
            props.history.push('/categories')
            toast.error("Somthing Went wrong")

        })
  }
  

  function handleSubmit(e, values){
    const formData = new FormData();
    let keywords = toTitleCase(values.heading)
    let pg_title = `${toTitleCase(values.heading)} Contractors & Companies Near (dynamic_location)`
    let pg_desc = `(dynamic_location) ${toTitleCase(values.heading)} Company chosen for operating their ${toTitleCase(values.heading)} business with the highest integrity and honesty by The Good Contractors List.`
    let desc = `(dynamic_location) ${toTitleCase(values.heading)} Company chosen for operating their ${toTitleCase(values.heading)} business with the highest integrity and honesty. The Good Contractors Lists backs all the ${toTitleCase(values.heading)} Companies on our list with a $10,000 guarantee to the customer. Every ${toTitleCase(values.heading)} Contractor on our list has passed a rigorous background investigation and were met face-to-face.`
    if (file){
      formData.append('image', file, file.name) 
    }
    if(selectedCategory.value){
      for (let i=0; i< all_categories.length ; i++){
        if (selectedCategory.value === all_categories[i].id_num){
          keywords = keywords + ", "+ all_categories[i].keyword
        }
      }
        formData.append("parent_id",selectedCategory.value)
    }
    formData.append("heading",toTitleCase(values.heading))
    formData.append("pg_title",pg_title)
    formData.append("pg_desc",pg_desc)
    formData.append("desc",desc)
    formData.append("keyword",keywords)
    formData.append("slug",slug)
    formData.append("active",0)

    const options = {
        method: 'POST',
        body: formData,
        headers: {'Authorization': getAccessToken() }
    };
    fetch(`${BASE_URL}/api/tgcl/headings/`, options)
          .then(res => 
              {if(res.status >= 400){
                  res.json()
                  .then(
                      (data) =>{  
                        if(data['slug']){
                          toast.error("Category with this slug already exists.")
                      }else{
                          toast.error("Somthing Went Wrong")
                      }
                      console.log(data, "---data")
                      }
                        
                  )
              }
              else{
                  props.history.push("/categories")
              }    
          }
      ) 

  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Categories" breadcrumbItem="Create Category" link="/categories" />
        <Row>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle>Category Detail</CardTitle>                  
                  <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                  <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label htmlFor="heading">Name</Label>
                          <AvField
                            name="heading"
                            placeholder="Name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            onChange={(e)=> setSlug(slugify(e.target.value))}
                            validate={{ required: { value: true } }}
                            id="heading"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label>Parent</Label>
                                <Select
                                value={selectedCategory}
                                onChange={(e) => {
                                    handleSelectGroup(e);
                                }}
                                options={Categories}
                                classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                          <div className="mb-3">
                          <Label htmlFor="name">Slug</Label>
                          <AvField
                              name="slug"
                              placeholder="Category"
                              type="text"
                              errorMessage="Enter Category"
                              className="form-control"
                              onChange={(e)=> setSlug(slugify(e.target.value))}
                              value = {slug}
                              validate={{ required: { value: true } }}
                              id="slug"
                          />
                          </div>
                      </Col>
                    </Row>
                    <Row>
                      <Label htmlFor="icon">Category Icon</Label>
                      <Col sm={6}>
                            <input id="icon" className="mb-3" type="file" onChange={handleChange} />
                        </Col>
                      {file?
                        <Col sm={6}>
                          <img src={URL.createObjectURL(file)} className="rounded avatar-lg m-2" alt="" />
                        </Col>: null}
                        
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </AvForm>
             
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default CreateHeading
