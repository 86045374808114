import React, { useState, useEffect } from "react";
import { Row, Col, NavLink, NavItem, Nav, TabPane,TabContent } from "reactstrap"
// import Select from "react-select";
// import { AvForm, AvField } from "availity-reactstrap-validation"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ContractorDetail from './ContractorDetail'
import ContractorCampaign from './ContractorCampaign'
// import accessToken from "../../helpers/jwt-token-access/accessToken";

// import { BASE_URL } from "../../helpers/url_helper";
// import { get } from "../../helpers/api_helper";
// import { slugify } from "../../helpers/helper_methods";


  toastr.options = {
    positionClass: "toast-top-right",
    closeButton: "true",
    progressBar: "true",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
    }

const EditContractor = (props) => {

    const info = props.location.info
    const [activeTab, setactiveTab] = useState("1")
    const [didMount, setDidMount] = useState(false); 


    function toggle(tab) {
        if (activeTab !== tab) {
          setactiveTab(tab)
        }
      }

    useEffect(() => {
        setDidMount(true);
        if(info){
          setactiveTab(info)
        }
        
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
    }

    

  return (

    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Contractors" breadcrumbItem="Contractor Detail" link="/contractors" />

        <Row>
          <Col xs={12}>
            {/* <Card>
              <CardBody> */}
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1")
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                      <span className="d-none d-sm-block">Details</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2")
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                      <span className="d-none d-sm-block"> Campaigns</span>
                    </NavLink>
                  </NavItem>
                  
                </Nav>

                <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                        <ContractorDetail 
                            history = {props.history}
                            location= {props.location}
                            match= {props.match}
                            staticContext = {props.staticContext}
                            />

                    </TabPane>
                    <TabPane tabId="2" >
                        <ContractorCampaign
                            history = {props.history}
                            location= {props.location}
                            match= {props.match}
                            staticContext = {props.staticContext}
                            />


                    </TabPane>
                </TabContent>
              {/* </CardBody>
            </Card> */}
          </Col>
        </Row>        
      </div>

    </React.Fragment>
  )
}

export default EditContractor
