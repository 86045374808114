import React, { useState,useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { slugify } from "../../../helpers/helper_methods";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../helpers/url_helper";
import { get } from "../../../helpers/api_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken"

const AddNewLocation = (props) => {

  const [selectedState, setselectedState] = useState({ label: "Select State", value: null });
  const [selectedCounty, setselectedCounty] = useState({ label: "Select County", value: null });

  const [file, setFile] = useState(null);
 
  const [stateOptions, setStateOptions] = useState([])
  const [countyOptions, setCountyOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [didMount, setDidMount] = useState(false);
  const [slug, setSlug] = useState("");


  const StateOptions = [
    {
      label: "State",
      options: stateOptions
    },
  ];

  const CountyOptions = [
    {
      label: "County",
      options: countyOptions
    },
  ];


  ValidateUser(props)

  useEffect(() => {
    
    setDidMount(true);
    loadStateData()
    return () => setDidMount(false);
      
  },[]);

  if(!didMount) {
      return null;
    }

  function handleChange(e) {
      setFile(e.target.files[0]);
  }



  function loadStateData(){

    get(`${BASE_URL}/api/tgcl/state/?page_size=200`, { headers: { 'Content-Type': 'application/json', },validateStatus: false} )
    .then(response => {
      let options = []
        let states = response.data.results
        for(let i=0; i<states.length; i++){
            options.push({ label: `${states[i].state}`, value: states[i] })
            if (states[i].abbr ===  'TX'){
              setselectedState({ label: `${states[i].state}`, value: states[i] })
              loadCountyData(states[i].id_num)
            }
        }
        setStateOptions(options)
        })

  }

  async function loadCountyData(state){

    let url = `${BASE_URL}/api/tgcl/county/?state=${state}&page_size=500`
    await get(url,  { headers: { 'Content-Type': 'application/json', },validateStatus: false} )
    .then(response => {
      let options = []
        let county = response.data.results
        for(let i=0; i<county.length; i++){
            options.push({ label: `${county[i].name}`, value: county[i].id })
            if ((county[i].name).toLowerCase() ===  'dallas'){
              setselectedCounty({ label: `${county[i].name}`, value: county[i].id })
            }
        }
        setCountyOptions(options)
        })

  }



  function handleSubmit(e, values){
    
    // setLoading(true)
    let data ={
        "name": values.city,
        "slug": "",
        "county": selectedCounty.value
    }

  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Locations" breadcrumbItem="Add New location" link="/locations" />
        <Row>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle>Location Detail</CardTitle>                  
                  <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                  
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label>State</Label>
                                <Select
                                value={selectedState}
                                onChange={(e) => {
                                    setselectedState(e)
                                    loadCountyData(e.value.id_num);
                                    setselectedCounty({ label: "Select County", value: null })

                                }}
                                options={StateOptions}
                                classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label>County</Label>
                                <Select
                                value={selectedCounty}
                                onChange={(e) => {
                                    setselectedCounty(e);
                                }}
                                options={CountyOptions}
                                classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    <Col md="12">
                          <div className="mb-3">
                          <Label htmlFor="city">Location Name</Label>
                          <AvField
                              name="city"
                              placeholder="Location Name"
                              type="text"
                              errorMessage="Enter Location Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="city"
                              onChange={(e) => setSlug(slugify(`${e.target.value} ${selectedCounty.label} ${selectedState.value.abbr}`))}
                          />
                          </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                          <div className="mb-3">
                          <Label htmlFor="name">Slug</Label>
                          <AvField
                              name="slug"
                              placeholder="Location Slug"
                              type="text"
                              errorMessage="Enter Slug"
                              className="form-control"
                              value = {slug}
                              onChange={(e)=> setSlug(e.target.value)}
                              validate={{ required: { value: true } }}
                              id="slug"
                          />
                          </div>
                      </Col>
                    </Row>
                    
                    {loading?
                    <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                      Submitting
                    </button>
                    :
                    <Button color="primary" type="submit">
                      Submit
                    </Button>}
                   
                  </AvForm>
             
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default AddNewLocation
