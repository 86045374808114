import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner, Label, Button } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'moment-timezone';
import { AvForm, AvField } from "availity-reactstrap-validation"
import ReactApexChart from "react-apexcharts"
import ReactEcharts from "echarts-for-react"

import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { downloadExcel } from "react-export-table-to-excel";
import { HeaderFormatter } from "../../helpers/methods";
import moment from "moment-timezone";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"

const series = [70]
const bglocation = ['rgba(30, 136, 229, 0.25)', ['#1e88e5']]
const bgzip = ['rgba(251, 192, 45, 0.25)', ['#fbc02d']]
const bgsearch = ['rgba(59, 93, 231, .25)', ['#3b5de7']]
const bgprofile =['rgba(69, 203, 133, .25)', ['#45CB85']]

const series1 = [81]



const PagesAnalytics = (props) => {

    const [reports, setreports] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [loaddata, setLoadData] = useState(false)
    const [startdate, setStartDate] = useState(moment(new Date()).subtract(30,'d').tz("America/Chicago").format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD'))

    ValidateUser(props)

    const columns = [
        {
            dataField: "week",
            text: "ID",
            hidden:true,
        
        },
        {
            dataField: "from",
            text: "From Date",
            headerFormatter:HeaderFormatter,
            sort: true,
            formatter: (cellContent) => (
              moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY')
            ),
        },
        {
            dataField: "to",
            text: "To Date",
            headerFormatter:HeaderFormatter,
            sort: true,
            formatter: (cellContent) => (
              moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY')
            ),

        },
        {
            dataField: "new_contractor",
            text: "No of New Contractors",
            headerFormatter:HeaderFormatter,
            sort: true,
        },
    ]

    const header = ["From Date", "To Date", "No of New Contractors"];



    useEffect(() => {
        
        loadData(`${BASE_URL}/api/tgcl/dashboard/contractor-reports/?startdate=${startdate}&enddate=${enddate}`)
        
    }, []);
    
    function Option(color){

      return {
        plotOptions: {
            radialBar: {
                offsetY: -12,
                hollow: {
                    margin: 5, size: '60%', background: color[0],
                }
                ,
                dataLabels: {
                    name: {
                        show: false,
                    }
                    ,
                    value: {
                        show: true, fontSize: '12px', offsetY: 5,
                    }
                    ,
                    style: {
                        colors: ['#fff']
                    }
                }
            }
            ,
        }
        ,
        colors: color[1],
    }
    }
    function getOption(){
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          zlevel: 0,
          x: 50,
          x2: 50,
          y: 30,
          y2: 30,
          borderWidth: 0,
        },
        xAxis: {
          type: "category",
          data: ["Week 1", "Week 2", "Week 3", "week 4"],
          axisLable: {
            color: "#ffffff",
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLable: {
            color: "#ffffff",
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
        series: [
          {
            data: [620, 832, 750, 934],
            type: "line",
          },
        ],
        color: ["#556ee6"],
        textStyle: {
          color: ["#74788d"],
        },
      }
    }
    
    function handleFilter(e, values){
        if ((new Date(values.enddate)) > (new Date(values.startdate))){
          loadData(`${BASE_URL}/api/tgcl/dashboard/contractor-reports/?startdate=${values.startdate}&enddate=${values.enddate}`)
        }else{
          alert(`To date must be grater than from Date`)
        }
        
      }


    function loadData(url){
        let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
        data.then(response => {
        setreports(response.data)
        settotalSize((response.data).length)
        setLoadData(true)
        })
    }
    function NoDataIndication(){
        if (loaddata && totalSize === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )

        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
    }

    function handleDownloadExcel() {
      let data = []
      for (let i=0; i<reports.length; i++){
        data.push({"from":reports[i].from, 'to': reports[i].to, 'new_contractor':reports[i].new_contractor})
      }
      downloadExcel({
        fileName: "Contractors Report",
        sheet: "contractors-report",
        tablePayload: {
          header,
          // accept two different data structures
          body: data,
        },
      });
    }
    
    const defaultSorted = [{
        dataField: 'contractor',
        order: 'asc'
        }];

    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Dashboard" breadcrumbItem='Contractors Report' link="/dashboard" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                  <AvForm className="needs-validation" onValidSubmit={handleFilter}>
                    <Row>
                    
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <Row className="mb-3">
                              <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                              <div className="col-md-8">
                              <AvField
                                name="startdate"
                                placeholder="To Date"
                                type="date"
                                errorMessage="To Date"
                                className="form-control"
                                value={startdate}
                                onChange={(e)=> setStartDate(e.target.value)}
                                id="to_date"
                                
                              />
                            </div>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row className="mb-3">
                              <Label htmlFor="email_bcc" className="col-md-4 col-form-label">To</Label>
                              <div className="col-md-8">
                              <AvField
                                name="enddate"
                                placeholder="To Date"
                                type="date"
                                errorMessage="To Date"
                                className="form-control"
                                value={enddate}
                                onChange={(e)=> setEnddate(e.target.value)}
                                id="to_date"
                              />
                            </div>
                            </Row>
                          </Col>
                          
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Button type="submit" color="success" className="btn-rounded me-2" >
                          Filter
                        </Button>
                        {" "}
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded mb-2 me-2"
                          onClick={() => handleDownloadExcel()}
                          >
                          <i className="mdi mdi-file-excel-outline me-1" />{" "}
                          Download
                        </Button>
                      </Col>

                      
                    </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              

            </Row>
            <Row>  
              <Col md={3}>
                  <Card>
                      <CardBody>
                      <Row>
                              <Col xs={6}>
                                  <div>
                                      <p className="text-muted fw-medium mt-1 mb-2">Search Page</p>
                                      <h4>320</h4>
                                  </div>
                              </Col>

                              <Col xs={6}>
                                  <div>
                                      <ReactApexChart
                                          options={Option(bgsearch)}
                                          series={series1}
                                          type="radialBar"
                                          height="120"
                                      />
                                  </div>
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
              <Col md={3}>
                  <Card>
                      <CardBody>
                          <Row>
                              <Col xs={6}>
                                  <div>
                                      <p className="text-muted fw-medium mt-1 mb-2">Location Page</p>
                                      <h4>320</h4>
                                  </div>
                              </Col>

                              <Col xs={6}>
                                  <div>
                                      <ReactApexChart
                                          options={Option(bglocation)}
                                          series={series1}
                                          type="radialBar"
                                          height="120"
                                      />
                                  </div>
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
              <Col md={3}>
                  <Card>
                      <CardBody>
                          <Row>
                              <Col xs={6}>
                                  <div>
                                      <p className="text-muted fw-medium mt-1 mb-2">Zip Page</p>
                                      <h4>20</h4>
                                  </div>
                              </Col>

                              <Col xs={6}>
                                  <div>
                                      <ReactApexChart
                                          options={Option(bgzip)}
                                          series={series1}
                                          type="radialBar"
                                          height="120"
                                      />
                                  </div>
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
              <Col md={3}>
                  <Card>
                      <CardBody>
                          <Row>
                              <Col xs={6}>
                                  <div>
                                      <p className="text-muted fw-medium mt-1 mb-2">Profile Page</p>
                                      <h4>620</h4>
                                  </div>
                              </Col>

                              <Col xs={6}>
                                  <div>
                                      <ReactApexChart
                                          options={Option(bgprofile)}
                                          series={series1}
                                          type="radialBar"
                                          height="120"
                                      />
                                  </div>
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
                      
            </Row>

            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18" style={{color:'black'}}>{"Search Page"}</h4>
                    </div>
                </Col>
            </Row>
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                <div className="table-responsive">

                    <BootstrapTable
                    responsive
                    keyField="week"
                    data={ reports }
                    columns={ columns }
                    bordered={false}
                    striped={true}
                    hover
                    defaultSorted={defaultSorted}
                    classes={
                        "table align-middle table-nowrap table-check"
                    }
                    headerWrapperClasses={"table-light"}
                    noDataIndication={() => NoDataIndication()}
                    />

                    </div>
                    <Link to="/contractor-analytics">{'Detail ..'}</Link>
          
                </CardBody>
                </Card>
            </Col>
            </Row>

            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18" style={{color:'black'}}>{"Location Page"}</h4>
                    </div>
                </Col>
            </Row>
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                <div className="table-responsive">

                    <BootstrapTable
                    responsive
                    keyField="week"
                    data={ reports }
                    columns={ columns }
                    bordered={false}
                    striped={true}
                    hover
                    defaultSorted={defaultSorted}
                    classes={
                        "table align-middle table-nowrap table-check"
                    }
                    headerWrapperClasses={"table-light"}
                    noDataIndication={() => NoDataIndication()}
                    />

                    </div>
                    <Link to="/contractor-analytics">{'Detail ..'}</Link>
          
                </CardBody>
                </Card>
            </Col>
            </Row>

            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18" style={{color:'black'}}>{"Zip Page"}</h4>
                    </div>
                </Col>
            </Row>
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                <div className="table-responsive">

                    <BootstrapTable
                    responsive
                    keyField="week"
                    data={ reports }
                    columns={ columns }
                    bordered={false}
                    striped={true}
                    hover
                    defaultSorted={defaultSorted}
                    classes={
                        "table align-middle table-nowrap table-check"
                    }
                    headerWrapperClasses={"table-light"}
                    noDataIndication={() => NoDataIndication()}
                    />

                    </div>
                    <Link to="/contractor-analytics">{'Detail ..'}</Link>
          
                </CardBody>
                </Card>
            </Col>
            </Row>

            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18" style={{color:'black'}}>{"Profile Page"}</h4>
                    </div>
                </Col>
            </Row>
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                <div className="table-responsive">

                    <BootstrapTable
                    responsive
                    keyField="week"
                    data={ reports }
                    columns={ columns }
                    bordered={false}
                    striped={true}
                    hover
                    defaultSorted={defaultSorted}
                    classes={
                        "table align-middle table-nowrap table-check"
                    }
                    headerWrapperClasses={"table-light"}
                    noDataIndication={() => NoDataIndication()}
                    />

                    </div>
                    <Link to="/contractor-analytics">{'Detail ..'}</Link>
          
                </CardBody>
                </Card>
            </Col>
            </Row>
        
            </div>

        </React.Fragment>
    )
}

export default PagesAnalytics
