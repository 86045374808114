import React, { useState,useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { slugify } from "../../../helpers/helper_methods";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../helpers/url_helper";
import { get } from "../../../helpers/api_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken"

const CreatePage = (props) => {

  const [selectedState, setselectedState] = useState({ label: "Select State", value: null });
  const [selectedCounty, setselectedCounty] = useState({ label: "Select County", value: null });
  const [selectedCity, setselectedCity] = useState({ label: "Select City", value: null });

  const [file, setFile] = useState(null);
 
  const [stateOptions, setStateOptions] = useState([])
  const [countyOptions, setCountyOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [didMount, setDidMount] = useState(false);
  const [slug, setSlug] = useState("");


  const StateOptions = [
    {
      label: "State",
      options: stateOptions
    },
  ];

  const CountyOptions = [
    {
      label: "County",
      options: countyOptions
    },
  ];

  const CityOptions = [
    {
      label: "City",
      options: cityOptions
    },
  ];

  ValidateUser(props)

  useEffect(() => {
    
    setDidMount(true);
    loadStateData()
    return () => setDidMount(false);
      
  },[]);

  if(!didMount) {
      return null;
    }

  function handleChange(e) {
      setFile(e.target.files[0]);
  }



  function loadStateData(){

    get(`${BASE_URL}/api/tgcl/state/?page_size=200`, { headers: { 'Content-Type': 'application/json', },validateStatus: false} )
    .then(response => {
      let options = []
        let states = response.data.results
        for(let i=0; i<states.length; i++){
            options.push({ label: `${states[i].state}`, value: states[i].id_num })
            if (states[i].abbr ===  'TX'){
              setselectedState({ label: `${states[i].state}`, value: states[i].id_num })
              loadCountyData(states[i].id_num)
            }
        }
        setStateOptions(options)
        })

  }

  async function loadCountyData(state){

    let url = `${BASE_URL}/api/tgcl/county/?state=${state}&page_size=500`
    await get(url,  { headers: { 'Content-Type': 'application/json', },validateStatus: false} )
    .then(response => {
      let options = []
        let county = response.data.results
        for(let i=0; i<county.length; i++){
            options.push({ label: `${county[i].name}`, value: county[i].id })
            if ((county[i].name).toLowerCase() ===  'dallas'){
              setselectedCounty({ label: `${county[i].name}`, value: county[i].id })
              loadCityData(county[i].id)
            }
        }
        setCountyOptions(options)
        })

  }

  async function loadCityData(county){

    await get(`${BASE_URL}/api/tgcl/city/?county=${county}&page_size=200`, { headers: { 'Content-Type': 'application/json', },validateStatus: false} )
    .then(response => {
      let options = []
        let cities = response.data.results
        for(let i=0; i<cities.length; i++){
            options.push({ label: `${cities[i].name}`, value: cities[i].id })
        }
        setCityOptions(options)
        })

  }

  function handleSubmit(e, values){
    const formData = new FormData();
    setLoading(true)
    if (file){
      formData.append('image', file, file.name) 
    }
    formData.append("state",selectedState.value)
    formData.append("county",selectedCounty.value)
    formData.append("city",selectedCity.value)
    
    const options = {
        method: 'POST',
        body: formData,
        headers: {'Authorization': getAccessToken() }
    };
    fetch(`${BASE_URL}/api/tgcl/dashboard/pages/`, options)
          .then(res => 
              {if(res.status >= 400){
                  res.json()
                  .then(
                      (data) =>{  
                        console.log(data, "---data")
                        toast.error(data.slug[0])
                        setLoading(false)

                      }
                        
                  )
              }
              else{
                  props.history.push("/location-pages")
              }    
          }
      ) 

  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Location Pages" breadcrumbItem="Create Page" link="/location-pages" />
        <Row>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle>Page Detail</CardTitle>                  
                  <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                  
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label>State</Label>
                                <Select
                                value={selectedState}
                                onChange={(e) => {
                                    setselectedState(e)
                                    loadCountyData(e.value);
                                    setselectedCounty({ label: "Select County", value: null })

                                }}
                                options={StateOptions}
                                classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label>County</Label>
                                <Select
                                value={selectedCounty}
                                onChange={(e) => {
                                    setselectedCounty(e);
                                    loadCityData(e.value);
                                    setselectedCity({ label: "Select City", value: null });
                                }}
                                options={CountyOptions}
                                classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label>City</Label>
                                <Select
                                // onInputChange = {(e)=> loadLocations(e)}
                                value={selectedCity}
                                onChange={(e) => {
                                    setselectedCity(e)
                    
                                }}
                                options={CityOptions}
                                classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col>
                    </Row>
                    {selectedCity.value ?<>
                    
                    {/* <Row>
                      <Col md="12">
                          <div className="mb-3">
                          <Label htmlFor="name">Slug</Label>
                          <AvField
                              name="slug"
                              placeholder="Page Slug"
                              type="text"
                              errorMessage="Enter Slug"
                              className="form-control"
                              value = {slug}
                              validate={{ required: { value: true } }}
                              id="slug"
                          />
                          </div>
                      </Col>
                    </Row>
                    <Row>
                        <AvField
                            className="mb-3"
                            type="textarea"
                            label="Description "
                            rows="3"
                            name="description"
                            id="description"
                            placeholder="Description"
                        />
                    </Row>
                    <Row>
                      <Col md="12">
                            <div className="mb-3">
                            <Label htmlFor="pg_title">Meta Title </Label>
                            <AvField
                                name="pg_title"
                                placeholder="Meta Title "
                                type="text"
                                className="form-control"
                                id="pg_title"
                                validate={{ required: { value: true } }}
                                value={category?.pg_title}
                            />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <AvField
                            className="mb-3"
                            type="textarea"
                            label="Meta Description "
                            rows="3"
                            name="pg_desc"
                            id="pg_desc"
                            placeholder="Meta Description"
                            value={category?.pg_desc}
                        />
                    </Row>
                    <Row>
                      <AvField
                            className="mb-3"
                            type="textarea"
                            label="Keyword"
                            rows="3"
                            name="keyword"
                            id="keyword"
                            placeholder="Keyword"
                            errorMessage="This value is required."
                            validate={{
                                required: { value: true },
                            }}
                            value={category?.keyword}
                        />
                    </Row> */}
                    <Row>
                      <Label htmlFor="icon">Background Image</Label>
                      <Col sm={6}>
                        <input id="icon" className="mb-3" type="file" accept="image/png, image/jpeg, image/jpeg" onChange={handleChange} />
                      </Col>
                      
                        <Col sm={6}>
                        { file?
                          <img src={URL.createObjectURL(file)} className="rounded img-thumbnail mb-3" alt="" />
                          : null}
                        </Col>
                    </Row>
                    <Row>
                   
                     
                    </Row>
                    {loading?
                    <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                      Submitting
                    </button>
                    :
                    <Button color="primary" type="submit">
                      Submit
                    </Button>}
                    </>:null}
                  </AvForm>
             
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default CreatePage
