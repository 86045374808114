import React, {useEffect, useState} from "react"
import { Row, Col, CardBody, Card } from "reactstrap"

import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import moment from "moment-timezone";

import UsersReport from "./UsersReport"
import ContractorReport from "./ContractorReport"
import CampaignReport from "./CampaignReport"

const Dashboard = () => {
  
  const [data, setData] = useState({}) 
  const [totalUsers, setTotalUsers] = useState(0)
  const [activeUsers, setActiveUsers] = useState(0)
  const [inactiveUsers, setInactiveUsers] = useState(0)
  const [totalContractor, setTotalContractor] = useState(0)
  const [activeContractor, setActiveContractor] = useState(0)
  const [inactiveContractor, setInactiveContractor] = useState(0)
  // const [totalMonthlyFee, setTotalMonthlyFee] = useState(0)
  // const [totalmenbershipFee, setTotalMenbershipFee] = useState(0)
  const [totalCampaign, setTotalCampaign] = useState(0)
  const [approvedCampaign, setApprovedCampaign] = useState(0)
  const [pendingCampaign, setPendingCampaign] = useState(0)

  function validate(){
    let Daate = JSON.stringify(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
    let toDay = localStorage.getItem("toDay")
    if(Daate !== toDay){
      localStorage.clear()
    }
  }


  useEffect(() => {
    loadData()   
    validate() 
  }, []);

  function loadData(){
    get(`${BASE_URL}/api/tgcl/dashboard/dashboard/`, { headers: { 'Content-Type': 'application/json' }} )
    .then(response => {
      setData(response.data)
      setTotalUsers(response.data.total_users)
      setActiveUsers(response.data.approved_users)
      setInactiveUsers(response.data.pending_users)
      setTotalContractor(response.data.total_contractor)
      setActiveContractor(response.data.active_contractor)
      setInactiveContractor(response.data.inactive_contractor)
      // setTotalMonthlyFee(response.data.total_monthly_payment)
      // setTotalMenbershipFee(response.data.total_membership_fee)
      setTotalCampaign(response.data.total_campagin)
      setApprovedCampaign(response.data.approved)
      setPendingCampaign(response.data.pending)


    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
  
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to TGCL</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>
        {/* Users */}
        <Row>
          
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-group"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Users</div>

                  </div>
                </div>
                <h4 className="mt-4">{totalUsers}</h4>
                
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-plus"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Approved Users</div>

                  </div>
                </div>
                <h4 className="mt-4">{activeUsers}</h4>
                
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-minus"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Pending Users</div>

                  </div>
                </div>
                <h4 className="mt-4">{inactiveUsers}</h4>
                
              </CardBody>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col sm={12}>
            <UsersReport 
            User_Report={data.User_Report}
            Users = {data.last_30_days_users}/>
          </Col>
        </Row>
        {/* Contractors */}
        <Row>
          
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-warning text-dark rounded">
                      <i className="mdi mdi-account-group"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Contractors</div>

                  </div>
                </div>
                <h4 className="mt-4">{totalContractor}</h4>
                
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-warning text-dark rounded">
                      <i className="mdi mdi-account-multiple-plus"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Active Contractors</div>

                  </div>
                </div>
                <h4 className="mt-4">{activeContractor}</h4>
                
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-warning text-dark rounded">
                      <i className="mdi mdi-account-multiple-minus"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Inactive Contractors</div>

                  </div>
                </div>
                <h4 className="mt-4">{inactiveContractor}</h4>
                
              </CardBody>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col sm={12}>
            <ContractorReport 
            Contractor_Report={data.Contractor_Report}
            Contractor = {data.last_30_days_contractor}/>
          </Col>
        </Row>
        {/* <Row>
          <Col sm={6}>
            <Card>
              <CardBody>
              <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-success text-dark rounded">
                      <i className="bx bx-dollar-circle"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Membership Fee</div>

                  </div>
                </div>
                <h4 className="mt-4">$ {totalmenbershipFee}</h4>
              </CardBody>
            </Card>
                        
          </Col>
          <Col sm={6}>
            <Card>
              <CardBody>
              <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-success text-dark rounded">
                      <i className="bx bxs-dollar-circle"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Monthly Fee</div>

                  </div>
                </div>
                <h4 className="mt-4">$ {totalMonthlyFee}</h4>
              </CardBody>
            </Card>
                        
          </Col>

      
        </Row> */}
        {/* Campaing */}
        <Row>
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-info text-dark rounded">
                      <i className="mdi mdi-file-chart"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Campaign</div>

                  </div>
                </div>
                <h4 className="mt-4">{totalCampaign}</h4>
                
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-info text-dark rounded">
                      <i className="mdi mdi-file-check"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Approved Campaign</div>

                  </div>
                </div>
                <h4 className="mt-4">{approvedCampaign}</h4>
                
              </CardBody>
            </Card>
          </Col>
          <Col sm={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-info text-dark rounded">
                      <i className="mdi mdi-file-alert"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Pending Campaign</div>

                  </div>
                </div>
                <h4 className="mt-4">{pendingCampaign}</h4>
                
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <CampaignReport 
            Campaign_Report={data.Campaign_Report}
            Campaign = {data.last_30_days_campaigns}/>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Dashboard