import React, {useState, useEffect} from "react"
import { Card, CardBody, Col, Row, Button} from "reactstrap"
import { Link } from "react-router-dom"
import ReactEcharts from "echarts-for-react"

const ContractorRegister = (props) => {
    const [contractorSeries, setContractorSeries] = useState([0, 0, 0, 0]) 
    const [contractorWeeks, setContractorWeeks] = useState(["Week 1", "Week 2", "Week 3", "week 4"])
    const [contractorCount, setContractorCount] = useState(0) 

    useEffect(() => {
        let data = props.data
        let series = []
        let week = []
        let count = 0
        if(data.length > 0){
            for(let i=0 ; i< data.length; i++){
                series.push(data[i].new_contractor)
                count = count + data[i].new_contractor
                week.push(`Week ${data[i].week}`)
            }
            setContractorSeries(series)
            setContractorWeeks(week)  
            setContractorCount(count)
        }
        
      }, [props.data]);

    const seriesData = {
          tooltip: {
            trigger: "axis",
          },
          grid: {
            zlevel: 0,
            x: 40,
            x2: 30,
            y: 30,
            y2: 30,
            borderWidth: 0,
          },
          xAxis: {
            type: "category",
            data: contractorWeeks,
            axisLable: {
              color: "#ffffff",
            },
            axisLine: {
              lineStyle: {
                color: "#74788d",
              },
            },
          },
          yAxis: {
            type: "value",
            axisLable: {
              color: "#ffffff",
            },
            axisLine: {
              lineStyle: {
                color: "#74788d",
              },
            },
          },
          series: [
            {
              data: contractorSeries,
              type: "line",
            },
          ],
          color: ["#556ee6"],
          textStyle: {
            color: ["#74788d"],
          },
        }
      
    return (
        <Col lg={12}>
            <Card>
                <CardBody>
                    <Row>
                    <Col sm={8}>
                    <div className="mb-4"><Link className="text-dark" to={'/analytics/contractor-registraction'}><b>Contractor Registration</b></Link></div>

                    </Col>
                    <Col sm={4}>
                    <h4 className="float-end">{contractorCount}</h4>
                    </Col> 
                    </Row>
                    <Row>
                    <div className="col-12">
                        <div>
                            <ReactEcharts style={{ height: "350px" }} option={seriesData} />
                        </div>
                    </div>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={6}>
                            <p className="mt-2">{props.startdate} - {props.enddate}</p>
                        </Col>
                        <Col sm={6}>
                        <Link to={'/analytics/contractor-registraction'}><Button color="light" className="btn-outline me-2 float-end btn-sm" >
                                view report <i className="mdi mdi-arrow-right-bold text-dark"></i>
                            </Button></Link>
                        </Col>

                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default ContractorRegister