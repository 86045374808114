import React, {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"
import { get } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getLastMontDate } from "../../../helpers/methods"
//Import Components
import TrafficSource from "./TrafficSource"
import ContractorProfileViewed from "./ContractorProfileViewed"
import TotalSearch from './TotalSearch';
// import TotalTraffic from "./TotalTraffic"
import ZipCodeSearches from "./ZipCodeSearches"
import EmailToContractor from './EmailToContractor'
import ContractorProfile from './ContractorProfile'
import CategorySearch from './CategorySearch'
import ContractorWebsite from './ContractorWebsite'
import ContractorFeedback from './ContractorFeedback'
import GuranteeRegistraction from './GuranteeRegistraction'
import ContractorRegister from './ContractorRegister'
import Calls from './Calls'
import ReceivedEmails from './ReceivedEmails'
import moment from "moment-timezone";


const AnalyticsDashboard = () => {

  const [calls, setCalls] = useState([])
  const [calls_last, setCallLast] = useState([])
  const [contractorEmail, setContractorEmail] = useState([])
  const [contractorFeedback, setContractorFeedback] = useState([])
  const [contractorProfile, setContractorProfile] = useState([])
  const [contractors, setContractors] = useState([])
  const [emailSent, setEmailSent] = useState([])
  const [profileView, setProfileView] = useState([])
  const [searchedCategories, setSearchedCategories] = useState([])
  const [searchedZipcodes, setSearchedZipcodes] = useState([])
  const [users, setUsers] = useState([])
  const [websiteClick, setWebsiteClick] = useState([])
  const [search, setSearch] = useState([])
  const [startdate, setStartDate] = useState(moment(new Date()).subtract(30,'d').format('MMMM Do'))
  const [enddate, setEnddate] = useState(moment(new Date()).format('MMMM Do'))
  

  function loadData(){
    let data = get(`${BASE_URL}/api/tgcl/dashboard/analytics-dashboard/?format=json`, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
    
    setCalls(response.data.calls)
    setContractorEmail(response.data.contractor_email)
    setContractorFeedback(response.data.contractor_feedback)
    setContractorProfile(response.data.contractor_profile)
    setContractors(response.data.contractors)
    setEmailSent(response.data.email_sent)
    setProfileView(response.data.profile_view)
    setSearchedCategories(response.data.searched_categories)
    setSearchedZipcodes(response.data.searched_zipcodes)
    setUsers(response.data.users)
    setWebsiteClick(response.data.website_click)
    setSearch(response.data.search)
    setCallLast(response.data.calls__last_30_days)

    })
  }

  useEffect(() => {
        
    loadData()
    console.log(getLastMontDate())
   
    
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
  
      <Breadcrumbs title="Dashboard" breadcrumbItem='Analytics Dashboard' link="/dashboard" />

        <Row>
          <Col lg={3}>
            <TrafficSource startdate={startdate} enddate={enddate}/>

            
            <ReceivedEmails key={'emailSent'} data= {emailSent} startdate={startdate} enddate={enddate} />
          </Col>
          
          <Col lg={6}>
              
              <Calls key={'calls'} data={calls} last={calls_last} startdate={startdate} enddate={enddate} />
              
          </Col>
          <Col lg={3}>
            
            <TotalSearch key={'search'} data={search} startdate={startdate} enddate={enddate}/>
            <Row>
              <ZipCodeSearches key={'searchedZipcodes'} data={searchedZipcodes} startdate={startdate} enddate={enddate}/>
            </Row>
            
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <ContractorProfileViewed key={'contractorProfile'} data = {contractorProfile} startdate={startdate} enddate={enddate} />
            <GuranteeRegistraction key={'users'} data={users} startdate={startdate} enddate={enddate} />
            <ContractorFeedback key={'contractorFeedback'} data={contractorFeedback} startdate={startdate} enddate={enddate} />
          </Col>
          <Col lg={6}>
          
          <EmailToContractor key={'contractorEmail'} data= {contractorEmail} startdate={startdate} enddate={enddate} />
            <CategorySearch key={'searchedCategories'} data = {searchedCategories} startdate={startdate} enddate={enddate} />
            <ContractorRegister key={'contractors'} data={contractors} startdate={startdate} enddate={enddate} />
          </Col>
          
        </Row>
        <Row>
          <Col sm={6}>
          <ContractorWebsite key={'websiteClick'} data={websiteClick} startdate={startdate} enddate={enddate}/>
            
          </Col>
          <Col sm={6}>
          <ContractorProfile key={'profileView'} data={profileView} startdate={startdate} enddate={enddate}/>
            
          </Col>
        </Row>
        
        
            

      </div>
    </React.Fragment>
  )
}

export default AnalyticsDashboard