import React, {useEffect, useState} from "react"
import { Row, Col, CardBody, Card, CardTitle, Button, Label, Spinner } from "reactstrap"
import { post } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import moment from "moment-timezone";
import { AvForm, AvField } from "availity-reactstrap-validation"
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Select from "react-select";
import { getLastMontDate } from "../../../helpers/methods"

const optionGroup = [
    {
      label: "Email Type",
      options: [

        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Last week ", value: "last_week" },
        { label: "Last month", value: "last_month" },
        { label: "Last 7 days", value: "last_7_days" },
        { label: "Last 30 days", value: "last_30_days" },
        { label: "Custom", value: "custom" },
      ],
    },
  ];

const GuranteeRegistraction = (props) => {

    const [guranteeData, setGuranteeData] = useState([]) 
    const [count, setcount] = useState([])
    const [registractionData, setRegistractionData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [startdate, setStartDate] = useState(moment(new Date()).subtract(30,'d').format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).format('YYYY-MM-DD')) 
    const [seletedDate, setSeletedDate] = useState({ label: "Last 30 days", value: "last_30_days" })
    const [loaddata, setLoaddata] = useState(false)
    
    function loadData(start_date , end_date){
        let data = {"from_date": start_date, "to_date": end_date}
        console.log(JSON.stringify(data))
        post(`${BASE_URL}/api/tgcl/dashboard/guarantee-registraction-analytics/`,data, { headers: { 'Content-Type': 'application/json', }} )
        .then(response => {
            console.log(response)
            let data = response.data.guarantee_registraction
            let series = []
            let counts = []
            if(data.length > 0){
                let length =  data.length>20? 20 : data.length
                for(let i=0 ; i< length; i++){
                    series.push(data[i].created__date)
                    counts.push(data[i].count)
                }
            }
            let count = 0
            for(let i=0; i<data.length; i++ ){
                count = count + data[i].count
            }
            setRegistractionData(data)
            setTotalCount(count)
            setGuranteeData(series)
            setcount(counts)
            setLoaddata(true)
        })
    }

    useEffect(() => {
            
        const filter =  JSON.parse(localStorage.getItem("analyticsDate"))
        let startDate = startdate
        let endDate = enddate
        if (filter){
            setStartDate(filter.startDate)
            setEnddate(filter.endDate)
            startDate = filter.startDate
            endDate = filter.endDate
            setSeletedDate(filter.type)
        }
            
        loadData(startDate, endDate)
        
    }, []);

    function handleFilter(e, values){
        
            if ((new Date(values.enddate)) >= (new Date(values.startdate))){
                localStorage.setItem("analyticsDate", JSON.stringify({'type':seletedDate,'startDate':enddate, 'endDate':enddate}));
            loadData(startdate, enddate)
            setLoaddata(false)
            
            }else{
            alert(`To date must be grater than from Date`)
            }
        
      }

    function handleSeletedDateChange(selected_date){
        setSeletedDate(selected_date)
        if(selected_date.value !== 'custom'){
            let start = startdate
            let end = enddate
            if(selected_date.value === 'today'){
                start = moment(new Date()).format('YYYY-MM-DD')
                end = moment(new Date()).format('YYYY-MM-DD')
            }else if(selected_date.value === 'yesterday'){
                start = moment(new Date()).subtract(1,'d').format('YYYY-MM-DD')
                end = moment(new Date()).subtract(1,'d').format('YYYY-MM-DD')
            }else if(selected_date.value === 'last_week'){
                start = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
                end = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_month'){
                start = getLastMontDate().firstDay
                end = getLastMontDate().lastDay
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_7_days'){
                start = moment(new Date()).subtract(7,'d').format('YYYY-MM-DD')
                end = moment(new Date()).subtract(1,'d').format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_30_days'){
                start = moment(new Date()).subtract(30,'d').format('YYYY-MM-DD')
                end = moment(new Date()).format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }
            loadData(start, end)
            setLoaddata(false)
        }
    }
    
    function NoDataIndication(){
        if (loaddata && registractionData.length === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )
    
        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
      }



    const series = [
        {
          name: "Search Count",
          data: count,
        },
      ]
    
      const options = {
        dataLabels: {
          enabled: !1
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        series: [{
          name: 'Count',
          data: count
        }],
        colors: ['#45cb85'],
        xaxis: {
          type: 'datetime',
          categories: guranteeData,
        },
        grid: {
          borderColor: '#f1f1f1',
        },
        tooltip: {
          x: {
            format: 'MM/dd/yy'
          },
        }
      }

    const columns = [
        {
          dataField: "campaign__id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "created__date",
          text: "Date",
          formatter: (cellContent, row) => (
            moment(cellContent).format('MM-DD-YYYY')
            ),
          title: (cell) => `${cell}`
        },
        {
        dataField: "count",
        text: "Registraction",
        sort: true,
        formatter: (cellContent) => (
            cellContent    
        ),
        title: (cell) => `${cell}`
        },
      ]

    const defaultSorted = [{
    dataField: 'count',
    order: 'desc'
    }];

    //pagination customization
    const pageOptions = {
        sizePerPage: 20,
        totalSize: registractionData.length,
        custom: true,
    }
    
    return (
        <React.Fragment>
        <div className="page-content">
        <Breadcrumbs title="Analytics Dashboard" breadcrumbItem='Gurantee Registraction' link="/analytics" />
        <Row>
                <Col xs="12">
                <Card>
                    <CardBody>
                    <AvForm className="needs-validation" onValidSubmit={handleFilter}>
                        <Row>
                            <Col sm="3">
                                <div className="text-sm-start mb-2">
                                    <Select
                                    value={seletedDate}
                                    onChange={(e) => handleSeletedDateChange(e)}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                                
                            </Col>
                            {seletedDate.value==='custom'?
                            
                            <Col sm="9">
                                <Row>
                                    <Col md={8}>
                                    <Row>
                                        <Col md={6}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                                            <div className="col-md-8">
                                            <AvField
                                            name="startdate"
                                            placeholder="To Date"
                                            type="date"
                                            errorMessage="To Date"
                                            className="form-control"
                                            value={startdate}
                                            onChange={(e)=> setStartDate(e.target.value)}
                                            id="to_date"
                                            
                                            />
                                        </div>
                                        </Row>
                                        </Col>
                                        <Col md={6}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_bcc" className="col-md-4 col-form-label">To</Label>
                                            <div className="col-md-8">
                                            <AvField
                                                name="enddate"
                                                placeholder="To Date"
                                                type="date"
                                                errorMessage="To Date"
                                                className="form-control"
                                                value={enddate}
                                                onChange={(e)=> setEnddate(e.target.value)}
                                                id="to_date"
                                            />
                                        </div>
                                        </Row>
                                        </Col>
                                        
                                    </Row>
                                    </Col>
                                    <Col md={4}>
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                            Filter
                                        </Button>
                                    </Col>
                                </Row>
                        </Col>:null}
                        
                        </Row>
                    
                    </AvForm>
                    </CardBody>
                </Card>
                </Col>
            </Row>
    
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">
                    Gurantee Registration{" "}
                    </CardTitle>
                    {loaddata?
                        <ReactApexChart options={options} series={series} type="area" height={250} className="apex-charts" />
                    : 
                        <Col sm="12">
                            <div className="d-flex align-items-center justify-content-center" style={{height:"250px"}}>
                            <Spinner className="my-3" color="dark" />
                            </div>
                        </Col>
                    }
                </CardBody>
                </Card>
            </Col>
            
            </Row>
            <Row>
                <Col xs="12">
                    <Card>
                    <CardBody>
                        <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        data={registractionData}
                        >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                            keyField="id"
                            data={registractionData}
                            columns={columns}
                            bootstrap5
                            search
                            >
                            
                            {toolkitProps => (
                                <React.Fragment>
                                
                                <Row className="mb-2">
                                    <Col sm="9">
                                    
                                        
                                    </Col>
                                    <Col sm="3">
                                    <div className="text-sm-end">
                                        <p><b className="mt-5">Total Gurantee Registered: {totalCount}</b></p>
                                        
                                    </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive">

                                    <BootstrapTable
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        responsive
                                        bordered={false}
                                        striped={true}
                                        defaultSorted={defaultSorted}
                                        classes={
                                            "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        noDataIndication={() => NoDataIndication()}
                                    />
                                    
                                </div>
                                <Row>
                                        <Col sm={6}>
                                        <div className="pagination  mb-2">
                                            <PaginationTotalStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        
                                        </Col>
                                        <Col sm={6}>
                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                            <PaginationListStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                            </ToolkitProvider>
                        )}
                        </PaginationProvider>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        </React.Fragment>
    )
}

export default GuranteeRegistraction