import React, { useState, useEffect, useRef} from "react";

import { Row, Col, Card, CardBody, CardTitle, Label, Button, Table } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { get, del } from "../../helpers/api_helper";
import { ValidateUser } from "../../helpers/validate_user";
import moment from "moment-timezone";

const IndividualView = (props) => {
    
    const { id } = props.match.params;
    const [reviews, setReviews] = useState([])
    const [reviewQuestions, setReviewQuestions] = useState([])
    const [didMount, setDidMount] = useState(false); 
    const [selected_review, setSelected_review] = useState(null)
    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")
    


    ValidateUser(props)

    useEffect(() => {
        setDidMount(true);
        loadData()
        // loadReviewQuestions()
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
    }


    function loadData(){
        get(`${BASE_URL}/api/tgcl/dashboard/feedback/?contractor=${id}`, 
        { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
        )
        .then(response => {
            setReviews(response.data.results)
        })
    }


    function getQuestion(id){
        let question = ""
        // let ques_id = id.split("_")
        for(let i=0; i<reviewQuestions.length ; i++){
            if(parseInt(reviewQuestions[i].id) === parseInt(id)){
                question = reviewQuestions[i].title
            }

        }
        return question
    }

    function getTotal(feedback_answer){
        let count = 0
        let len = 0
        for (let i=0; i<feedback_answer.length; i++){
            if (feedback_answer[i].answer){
                count = count + feedback_answer[i].answer.weight
                len = len +1
            }
        }
        return parseInt(count/len)
    }

    

    function handleDeleteClicks (id) { 
  
        // del(`${BASE_URL}/api/tgcl/review/${id}/`, 
        //     { headers: { 'Content-Type': 'application/json', }},
        //     {validateStatus: false}
        // )
        // .then(res => 
        //       {if(res.status >= 400){
        //         console.log("error")
        //       }
        //       else{
                
        //         loadData()
        //         setSuccess_confirm(true)
        //         setAlert_confirm(false)
        //         setDynamic_title('Deleted!')
        //         setDynamic_description("Category Has been deleted.") 
        //         setSelected_review(null)
        //       }
        //   }
        // )

        setSuccess_confirm(true)
        setAlert_confirm(false)
        setDynamic_title('Deleted!')
        setDynamic_description(`${selected_review.name} Review Has been deleted.`) 
        setSelected_review(null)
      }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Contractors Review" breadcrumbItem="Individual View" link="/contractors-feedback" />
                {reviews.length !== 0?
                <>
                    <Row>
                        <CardTitle>{`Contractor: ${reviews[0].contractor.bus_name} `}</CardTitle>      
                    </Row>
                    {reviews.map((review, i)=> {
                        return (
                        <React.Fragment key={i}>
                            {i===0?null:
                            <hr />}
                            <Row>
                                <Col className="col-6">
                                    <Card>
                                        <CardBody>
                                            <CardTitle>{`Customer details:`}</CardTitle>
                                            <p> <b>ID:</b> {review.id}<br />
                                                <b>Name:</b> {review.name}<br />
                                                <b>E-mail:</b> {review.e_mail} <br />
                                                <b>Phone:</b> {review.phone_no}<br />
                                                <b>Address:</b> {review.address}<br />
                                                <b>City/zip:</b> {review.city}/{review.zip}<br />
                                                <b>Service date:</b> {moment(review.servicedate).tz("America/Chicago").format('YYYY-DD-MM')}<br />
                                                <b>Submittion date:</b> {moment(review.date_of_submittion).tz("America/Chicago").format('YYYY-DD-MM')} </p>   
                                                {/* <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_review(review)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>           */}
                                            
                                        
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                    <CardBody>
                                        <CardTitle>{`Customer Review:`}</CardTitle>   
                                        <div className="table-responsive">
                                        <Table className="table table-bordered table-sm mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Question</th>
                                                <th>Score</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {review.feedback_answer.map((feedback, index)=>  {
                                                    return (
                                                        <tr>
                                                            <th scope="row">{index+1}</th>
                                                            <td>{feedback.question.title}</td>
                                                            <td>{feedback.answer?feedback.answer.weight:feedback.text}</td>
                                                        </tr>
                                                    )
                                                })}
                                           
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td></td>
                                                <td>{getTotal(review.feedback_answer)}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        </div>        
                                    </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>)
                    })}

                
                </>
                :
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
            }
            {alert_confirm ? (
                <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => handleDeleteClicks(selected_review.id)}
                onCancel={() =>
                { setAlert_confirm(false) 
                    setSelected_review(null)
                }
                }
                >
                You won't be able to revert this!
                </SweetAlert>
            ) : null}

            {success_confirm ? (
                <SweetAlert
                success
                title={dynamic_title}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => 
                { setSuccess_confirm(false) 
                    setAlert_confirm(false)
                }
                }
                >
                    {dynamic_description}
                </SweetAlert>
                )
            : null
            }
            </div>

        </React.Fragment>
    )
}

export default IndividualView
