import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner } from "reactstrap"

import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone,
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { HeaderFormatter } from "../../helpers/methods";
import moment from "moment-timezone";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ContractorsReview = (props) => {

  const [reviews, setReviews] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [SearchText, setsearchText] = useState("")
  const [loaddata, setLoadData] = useState(false)

  ValidateUser(props)

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "contractor",
      text: "Contractor",
      headerFormatter:HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        
        <Link to="#" className="me-3 text-dark">{(cellContent?cellContent.bus_name?cellContent.bus_name:"":"").substring(0,40)}</Link>     
      ),
      title: (cell, row) => `${row.contractor?row.contractor.bus_name:""}`
    },
    {
      dataField: "total_review",
      text: "Total Feedback",
      headerFormatter:HeaderFormatter,
      align: 'center',
    },
    {
      dataField: "counted_review",
      text: "Counted Feedback",
      headerFormatter:HeaderFormatter,
      align: 'center',
    },
    {
      dataField: "total_score",
      text: "Total Score",
      headerFormatter:HeaderFormatter,
      align: 'center',
    },
    {
      dataField: "counted_score",
      text: "Counted Score",
      headerFormatter:HeaderFormatter,
      align: 'center',
    },
    {
      dataField: "created",
      text: "Last Feedback",
      headerFormatter:HeaderFormatter,
      align: 'center',
      formatter: (cellContent, row) => (
        moment(cellContent).tz("America/Chicago").format('YYYY-DD-MM')
      ),
    },
    
    {
      dataField: "action",
      isDummyField: true,
      text: "View As",
      headerFormatter:HeaderFormatter,
      align: 'center',
      formatter: (cellContent, row) => (
        
          row.contractor?
          <React.Fragment>
            <Link to={`/cumulative-view/${row.contractor.id}`} className="me-3 text-primary">Cumulative</Link>
            <Link to={`/individual-view/${row.contractor.id}`} className="text-danger">Individual</Link>
          </React.Fragment>
          :
          <React.Fragment>
            <Link to={`#`} className="me-3 text-primary">Cumulative</Link>
            <Link to={`#`} className="text-danger">Individual</Link>
          </React.Fragment>
          

        
      ),
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/dashboard/feedback/?page_size=${sizePerPage}`, sizePerPage, page, SearchText)
    
  }, []);


  function loadData(url , sizePerPage, page , searchText){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setReviews(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
      setLoadData(true)
    })
  }

  function NoDataIndication(){
    if (loaddata && reviews.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function handleTableChange (type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setLoadData(false)
      setReviews([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/dashboard/feedback/?page_size=${sizePerPage}&&page=${new_page}`
      if (searchText && searchText !== ""){
          new_page = SearchText !== searchText? 1 : new_page
          url = `${BASE_URL}/api/tgcl/dashboard/feedback/?page_size=${sizePerPage}&page=${new_page}&search=${searchText}`
      }
      loadData(url , sizePerPage, new_page , searchText)
    }, 500);
  }
 
  const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];

  const { SearchBar } = Search

    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Contractors Feedback' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={reviews}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={reviews}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="4">
                            <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  
                                </div>
                              </div>
                            
                              
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                              noDataIndication = { NoDataIndication }
                            />
                            
                          </div>
                          <Row>
                                <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps} 
                                    
                                  />
                                </div>
                                  <div className="pagination  mb-2">
                                 
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        
      </div>

    </React.Fragment>
  )
}

export default ContractorsReview
