import React, { useState, useEffect } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row, Label, Modal, UncontrolledAlert
} from "reactstrap"

import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import image from '../../../assets/images/logo-icon.png'
import { BASE_URL } from "../../../helpers/url_helper"
import { put } from "../../../helpers/api_helper"
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [modal_changePassword, setModal_changePassword] = useState(false)
  const [alert, setAlert] = useState({"error": false, "error_msg":"" })

  const [username, setusername] = useState("Admin")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.user.username)
    }
  }, [props.success])

  function HandleLockScreen(){
    localStorage.setItem("lockScreen", true);
    props.history.push('/auth-lock-screen');

  }

  function tog_changePassword() {
    setModal_changePassword(!modal_changePassword)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function handleValidSubmit(event, values) {
    put(`${BASE_URL}/api/accounts/change-password/`,
    values,
    { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false} )
    .then(resp => {
      if (resp.status >= 400){
        console.log.log(resp)
        setAlert({"error": true, "error_msg":resp.data.old_password?resp.data.old_password:resp.data.new_password })
      }else{
        setAlert({"error": false, "error_msg":"" })
        tog_changePassword()
      }

    })
  }
  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={image}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1">{username}</span>{" "}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>{" "}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
            {("View Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="#" onClick={tog_changePassword}>
            {" "}
            <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>{" "}
            {("Change Password")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="#" onClick={HandleLockScreen}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1"></i>{" "}
            {("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
            <span>{("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

            <Modal
              isOpen={modal_changePassword}
              toggle={() => {
                tog_changePassword()
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Change Password
                    </h5>
                <button
                  type="button"
                  onClick={() => {
                    setModal_changePassword(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AvForm className="needs-validation" onValidSubmit={handleValidSubmit}>
                <div className="modal-body">
                { alert.error? (
                    <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">{alert.error_msg}</UncontrolledAlert>
                  ) : null}
                  
                  
                    <Row className="mb-3">
                        <Label htmlFor="old_password" className="col-md-3 col-form-label">Current Password:</Label>
                        <div className="col-md-9">
                            <AvField
                                name="old_password"
                                placeholder="Current Password"
                                type="password"
                                errorMessage="Current Password"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="old_password"
                            />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <Label htmlFor="new_password" className="col-md-3 col-form-label">New Password:</Label>
                        <div className="col-md-9">
                            <AvField
                                name="new_password"
                                placeholder="Enter New Password"
                                type="password"
                                errorMessage="Enter New Password"
                                className="form-control"
                                validate={{ required: { value: true },
                                            minLength: {value: 6, errorMessage: 'Your password must be grater than 6 characters'},
                                           }}
                                id="new_password"
                            />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <Label htmlFor="new_password1" className="col-md-3 col-form-label">Confirm Password:</Label>
                        <div className="col-md-9">
                            <AvField
                                name="new_password1"
                                placeholder="Enter Confirm Password"
                                type="password"
                                errorMessage="Enter Confirm Password"
                                className="form-control"
                                validate={{ required: { value: true },
                                            match: { value: "new_password" }, }}
                                id="new_password1"
                            />
                        </div>
                    </Row>
                    
                
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Change
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      tog_changePassword()
                    }}
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  
                </div>
              </AvForm>
            </Modal>
   
    </React.Fragment>
  )
}



export default ProfileMenu

