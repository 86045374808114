import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { BASE_URL } from "../../../helpers/url_helper";
import { get, put } from "../../../helpers/api_helper";
import { toast } from "react-toastify";


const EditPageMeta = (props) => {


    const slug = props.match.params.slug
    const [didMount, setDidMount] = useState(false);
    const [page, setPage] = useState(null)
    const [submitting, setSubmitting] = useState(null);

    useEffect(() => {
        setDidMount(true);
        loadMember()
    
        
    },[]);


    if(!didMount) {
        return null;
      }

    function loadMember(){
        get(`${BASE_URL}/api/tgcl/dashboard/page-meta/${slug}`, { headers: { 'Content-Type': 'application/json', }, validateStatus: false})
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing went Wrong")
                
            }else{
                setPage(response.data)
            }
        })
    }

    function handleSubmit(e, values){
        let data = values
        setSubmitting(true)
        put(`${BASE_URL}/api/tgcl/dashboard/page-meta/${page.id}/`, data,
        { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
        )
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing went Wrong")
                setSubmitting(false)

                
            }else{
               toast.success("Page Meta Detail Updated")
               setSubmitting(false)
            }
        })
        
        
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={'Pages'} breadcrumbItem={`Edit ${page?page.title:""} Page Meta  `} link={"/pages"} />
                {page?
                    <Row>
                        <Col md="12">
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="name">Title</Label>
                                                        <AvField
                                                            name="title"
                                                            placeholder="Title"
                                                            type="text"
                                                            className="form-control"
                                                            id="title"
                                                            value={page.title}
                                                            validate={{ required: { value: true } }}  
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="slug">Slug</Label>
                                                        <AvField
                                                            name="slug"
                                                            placeholder="Title"
                                                            type="text"
                                                            className="form-control"
                                                            id="slug"
                                                            value={page.slug}
                                                            disabled
                                                            validate={{ required: { value: true } }}  
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="meta_title">Meta Title</Label>
                                                        <AvField
                                                            name="meta_title"
                                                            placeholder="Meta Title"
                                                            type="text"
                                                            className="form-control"
                                                            id="meta_title"
                                                            value={page.meta_title}
                                                            validate={{ required: { value: true } }}  
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="meta_title">Keywords</Label>
                                                        <AvField
                                                            name="keywords"
                                                            placeholder="Keywords"
                                                            type="text"
                                                            className="form-control"
                                                            id="keywords"
                                                            value={page.keywords}
                                                            validate={{ required: { value: true } }}  
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <AvField
                                                        className="mb-3"
                                                        type="textarea"
                                                        label="Meta Description"
                                                        rows="3"
                                                        name="meta_desc"
                                                        id="meta_desc"
                                                        validate={{ required: { value: true } }}  
                                                        value={page.meta_desc}
                                                    />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <AvField
                                                        className="mb-3"
                                                        type="textarea"
                                                        label="Page Description"
                                                        rows="3"
                                                        name="desc"
                                                        id="desc"
                                                        validate={{ required: { value: true } }}  
                                                        value={page.desc}
                                                    />
                                                    </Col>
                                                </Row>

                                                {submitting?
                                                <button
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light my-3"
                                                    >
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                    Submit
                                                </button>
                                                :
                                                <Button color="primary my-3" type="submit">
                                                Submit
                                                </Button>
                                            }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                 
                                </Row>
                            </AvForm>
                        </Col>
                    </Row>
                :
                <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                </div>
            </div>
                }
            </div>

        </React.Fragment>
    )
}

export default EditPageMeta
