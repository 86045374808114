import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Spinner} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
  import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken";
//   import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import { get, del, patch } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import { HeaderFormatter } from "../../../helpers/methods";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Teams = (props) => {

  const [zipcodes, setZipCodes] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [SearchText, setsearchText] = useState("")
  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [loaddata, setLoadData] = useState(false)

  ValidateUser(props)

  const columns = [
    {
      dataField: "id",
      text: "ID", 
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      headerFormatter:HeaderFormatter,
      sort: true,
    //   
    formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={`/edit-team-member/${row.id}`} className="text-dark">{cellContent}</Link>
        </React.Fragment>
      ),
    },
    {
        dataField: "active",
        text: "Active",
        headerFormatter:HeaderFormatter,
        formatter: (cellContent, row) => (
          <input type='checkbox' defaultChecked={cellContent} onChange={(e)=> changeActive(e.target.checked, row.id )}></input>
        ),
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerFormatter:HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row.id)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/dashboard/team/?page_size=${sizePerPage}`, sizePerPage, page, SearchText)
    
  }, []);


  function loadData(url , sizePerPage, page , searchText){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setZipCodes(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
      setLoadData(true)
    })
  }

  function NoDataIndication(){
    if (loaddata && zipcodes.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function changeActive(active, id ){

    patch(`${BASE_URL}/api/tgcl/dashboard/team/${id}/`, {'active':active}, {  validateStatus: false})
    .then(response => {
        })

  }

  function handleItineraryClicks (id) { 
  
    del(`${BASE_URL}/api/tgcl/dashboard/team/${id}/`, 
        { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }},
        {validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            let new_page = page
            if(zipcodes.length < 2){
              new_page = new_page>1? new_page-1:1
            }
            let url = `${BASE_URL}/api/tgcl/dashboard/team/?page_size=${sizePerPage}&page=${new_page}`
            url = SearchText && SearchText !== "" ? url+`&search=${SearchText}`: url
            loadData(url , sizePerPage, new_page , SearchText)
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Deleted!')
            setDynamic_description("Page Has been deleted.") 
            setSelected_id(null)
          }
      }
    )
  }
  
  function handleTableChange (type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setLoadData(false)
      setZipCodes([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/dashboard/team/?page_size=${sizePerPage}&&page=${new_page}`
      if (searchText && searchText !== ""){
          new_page = SearchText !== searchText? 1 : new_page
          url = `${BASE_URL}/api/tgcl/dashboard/team/?page_size=${sizePerPage}&page=${new_page}&search=${searchText}`
      }
      loadData(url , sizePerPage, new_page , searchText)
    }, 300);
  }
 
  const defaultSorted = [{
      dataField: 'zipcode',
      order: 'asc'
    }];

  const { SearchBar } = Search

    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Zip Codes' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={zipcodes}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={zipcodes}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={()=> props.history.push('/new-team-member')}
                                >
                                  <i className="mdi mdi-plus me-1" />{" "}
                                  Add New Team Member
                                </Button>
                              </div>
                            </Col>
                          </Row>

                          
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                              noDataIndication={ NoDataIndication }
                            />
                            
                          </div>
                          <Row>
                                <Col sm={6}>
                                  <div className="mb-3">
                                      <SizePerPageDropdownStandalone
                                        { ...paginationProps }
                                      />
                                  </div>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                              </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleItineraryClicks(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
        
      </div>

    </React.Fragment>
  )
}

export default Teams
