export const AMPMOption = [
    {
      label: "",
      options: [
        { label: "AM", value: "AM" },
        { label: "PM", value: "PM" },
      ],
    },
  ];

export const CallStatusOptions = [
    {
      label: "Call Status",
      options: [
        { label: "Connected", value: "connected" },
        { label: "Hang Up", value: "hang-up" },
        { label: "Non-Prospect", value: "non-prospect" },
        { label: "Voicemail", value: "voicemail" },
        { label: "Spam", value: "spam" },
      ],
    },
  ];

export const JobStagesOptions = [
    {
      label: "Job Stages",
      options: [
        { value:'job-stages-1', label:'Left Message Awaiting Call Back'},
        { value:'job-stages-2', label:'Contractor Called Back – Awaiting Homeowner Response'},
        { value:'job-stages-3', label:'Scheduled Appointment'},
        { value:'job-stages-4', label:'Spoke with Contractor Went different Route'},
        { value:'job-stages-5', label:'Contractor Missed Appointment'},
        { value:'job-stages-6', label:'Waiting On Estimate'},
        { value:'job-stages-7', label:'Received Estimate Thinking About It'},
        { value:'job-stages-8', label:'Signed Contract Scheduled Start Time'},
        { value:'job-stages-9', label:'Work Started Expected Completion Date'},
        { value:'job-stages-10', label:'Job Completed – Satisfied'},
        { value:'job-stages-11', label:'Job Completed - Unsatisfied'},
        { value:'job-stages-12', label:'Chose a Contractor Outside Your Organization'},
        { value:'job-stages-13', label:'Would You Like A Follow Up from Us?'},
      ],
    },
  ];

export const IOMOption = [
    {
      label: "",
      options: [
        { label: "Incoming Call", value: "incoming" },
        { label: "Outgoing Call", value: "outgoing" },
        { label: "Email", value: "email" },
      ],
    },
  ];


export const TrueFalseStatus = [
  {
    label: "Read Status",
    options: [
      { label: "Read", value: true },
      { label: "Unread", value: false },
    ],
  },
];

export const OrderByOptions = [
  {
    label: "",
    options: [
      { label: "Call Date", value: 'call_date_str' },
      { label: "Notes Date", value: 'notes_date_str' },
    ],
  },
];

export const AscDescOptions = [
  {
    label: "",
    options: [
      { label: "Asc", value: '' },
      { label: "Desc", value: '-' },
    ],
  },
];