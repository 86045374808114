
import React, { useEffect, useState } from "react"
import { Row, Col, Container, Label, Alert } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { post } from "../../helpers/api_helper"
import { BASE_URL, SITE_URL } from "../../helpers/url_helper"

// import images
import logo from "../../assets/images/logo-sm-dark.png"

const ChangePassword = props => {
    
    const { token } = props.match.params
    const [validate_token, setValidate_token] = useState(false)
    const [error , setError] = useState(null)

    useEffect(() => {
        post(`${BASE_URL}/api/accounts/passwordreset/validate_token/`, {"token": token }, { validateStatus: false })
        .then(resp => {
        if(resp.status >=400){
            props.history.push('/forgot-password', { data: "It looks like you clicked on an invalid password reset link. Please try again." })
            
        }
        else{
            setValidate_token(true)
        }
        })
      });

  function handleValidSubmit(event, values) {
        post(`${BASE_URL}/api/accounts/passwordreset/confirm/`, {"token": token, "password":values.new_password}, { validateStatus: false })
        .then(resp => {
            console.log(resp)
        if(resp.status >=400){
            setError(resp.data.password)

            
        }
        else{
           props.history.push('/login')
        }
        })
    
    // props.history.push("/login")
  }

  return (
    <React.Fragment>

        <div className="account-pages my-5 pt-sm-5">
        {validate_token?
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <div className="card overflow-hidden">
                            <div className="bg-login text-center">
                            <div className="bg-login-overlay"></div>
                            <div className="position-relative">
                                <h5 className="text-white font-size-20">Change Password</h5>
                                <p className="text-white-50 mb-0">Reset Password with TGCL.</p>

                                <a href="/" className="logo logo-admin mt-4">
                                <img src={logo} alt="" height="30" />
                                </a>
                            </div>
                            </div>
                            <div className="card-body pt-5">

                            <div className="p-2">
                                <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                >
                                    {error ? (
                                    <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                                    {error}
                                    </Alert>
                                ) : null}
                                <Row className="mb-3">
                                    

                                    <Label htmlFor="new_password" className="col-form-label">Password</Label>
                                    <AvField
                                        name="new_password"
                                        placeholder="Enter Password"
                                        type="password"
                                        errorMessage="Enter Password"
                                        className="form-control"
                                        validate={{ required: { value: true },
                                                    minLength: {value: 8, errorMessage: 'Your password must contain at least 8 characters'},
                                                    }}
                                        id="new_password"
                                    />

                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="new_password1" className="col-form-label">Confirm Password</Label>
                                
                                    <AvField
                                        name="new_password1"
                                        placeholder="Confirm Password"
                                        type="password"
                                        errorMessage="Confirm Password"
                                        className="form-control"
                                        validate={{ required: { value: true },
                                                    match: { value: "new_password" }, }}
                                        id="new_password1"
                                    />
                                </Row>

                                <Row className="row mb-0">
                                    <Col className="col-12 text-end">
                                    <button
                                        className="btn btn-primary w-md waves-effect waves-light"
                                        type="submit"
                                    >
                                        Reset
                                    </button>
                                    </Col>
                                </Row>
                                </AvForm>
                            </div>
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                        
                            <p>© {'2023'} <a target="_blank" rel="noreferrer" href={SITE_URL}>{'thegoodcontractorslist.com'}</a> </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            :null}
        </div>
    </React.Fragment>
  )
}


export default ChangePassword
