import React, { useState, useEffect } from "react"
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"

import { Row, Col, Card, CardBody, Button, Spinner, NavLink, NavItem, Nav, TabPane,TabContent } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider from "react-bootstrap-table2-toolkit"
//   import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import avatar4 from "../../assets/images/not_avail.jpg"
import { get, del, put } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { HeaderFormatter } from "../../helpers/methods";
import { toast } from "react-toastify";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Name", value: "heading" },
    ],
  },
];

const Headings = (props) => {

  const [categories, setCategories] = useState([]);
  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [loaddata, setLoadData] = useState(false)
  const [loadPendingdata, setLoadPendingData] = useState(false)
  const [activeTab, setactiveTab] = useState("1")
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)


  const [pendingcategories, setPendingCategories] = useState([]);
  const [pendingtotalSize, setPendingtotalSize] = useState(0);
  const [pendingpage, setPendingpage] = useState(1)
  const [pendingsizePerPage, setPendingsizePerPage] = useState(20)

  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [search_by, setSearchby] = useState({ label: "Search By", value: null });

  ValidateUser(props)

  const columns = [
    {
      dataField: "id_num",
      text: "ID", 
      hidden: true,
    },
    {
      dataField:"image",
      text: 'Icon',
      headerFormatter:HeaderFormatter,
      formatter: (cellContent) => (
        <img src={cellContent?cellContent:avatar4} className="rounded avatar-sm" alt="" />
      ),     
    },
    {
      dataField: "heading",
      text: "Category",
      headerFormatter:HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/category/${row.id_num}`} className="me-3 text-dark">{(cellContent).substring(0,40)}</Link>     
      ),
      title: (cell) => `${cell}`
    },
    {
      dataField: "parent_id",
      text: "Parent Category",
      headerFormatter:HeaderFormatter,
      sort: true,
      formatter: (cellContent,) => (
        cellContent?
        <Link to={`/category/${cellContent.id_num}`} className="me-3 text-dark">{(cellContent.heading).substring(0,40)}</Link>
        :""
      ),
      title: (cell) => `${cell?cell.heading:""}`
    },
    {
      dataField: "search_count",
      text: "Search Count",
      headerFormatter:HeaderFormatter,
      sort: true
    },
    {
      dataField: "active",
      text: "Active",
      headerFormatter:HeaderFormatter,
      formatter: (cellContent, row) => (
        <input type='checkbox' defaultChecked={cellContent===1?true:false} onChange={(e)=> changeActive(e.target.checked, row.id_num )}></input>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerFormatter:HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={`/category/${row.id_num}`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
          <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row.id_num)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/headings/?active=1&page_size=${sizePerPage}`, sizePerPage, page)
    loadPendingData(`${BASE_URL}/api/tgcl/headings/?active=0&page_size=${pendingsizePerPage}`, pendingsizePerPage, pendingpage)
    
  }, []);


  function FilterData(e, values){
    let filter_data = ""
    if(!search_by.value){
      toast.error("Select Search Field")
    }else{
     
      filter_data = `&${"search"}=${values.search}`
      console.log(filter_data, "filter_data")
      
      loadData(`${BASE_URL}/api/tgcl/headings/?active=1&page_size=${sizePerPage}${filter_data}`, sizePerPage, page)
      loadPendingData(`${BASE_URL}/api/tgcl/headings/?active=0&page_size=${pendingsizePerPage}${filter_data}`, pendingsizePerPage, pendingpage)
      setFilter(filter_data)


    }
  }

  function ClearFilter(){

    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null });

    loadData(`${BASE_URL}/api/tgcl/headings/?active=1&page_size=${sizePerPage}`, sizePerPage, 1)
    loadPendingData(`${BASE_URL}/api/tgcl/headings/?active=0&page_size=${pendingsizePerPage}`, pendingsizePerPage, 1)

  }

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }



  function loadData(url , sizePerPage, page ){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setCategories(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoadData(true)
    })
  }

  function loadPendingData(url , sizePerPage, page){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setPendingCategories(response.data.results)
      setPendingtotalSize(response.data.count)
      setPendingpage(page)
      setPendingsizePerPage(sizePerPage)
      setLoadPendingData(true)
    })
  }

  function changeActive(active, id ){

    put(`${BASE_URL}/api/tgcl/headings/${id}/`, {'active':active?1:0}, {  validateStatus: false})
    .then(response => {
      
        })

  }

  function NoDataIndication(){
    if (loaddata && categories.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function pendingNoDataIndication(){
    if (loadPendingdata && pendingcategories.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function handleItineraryClicks (id) { 
  
    del(`${BASE_URL}/api/tgcl/headings/${id}/`, 
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            if (activeTab === '1'){
              let new_page = page
              if(categories.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/headings/?active=1&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadData(url , sizePerPage, new_page)
              setLoadData(false)

            }else{
              let new_page = page
              if(pendingcategories.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/headings/?active=0&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadPendingData(url , sizePerPage, new_page)
              setLoadPendingData(false)


            }
            
            
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Deleted!')
            setDynamic_description("Category Has been deleted.") 
            setSelected_id(null)
          }
      }
    )
  }
  
  function handleTableChange (type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setLoadData(false)
      setCategories([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/headings/?active=1&page_size=${sizePerPage}&&page=${new_page}`
      if (filter !== ""){
        url = `${url}${filter}`
      }
      loadData(url , sizePerPage, new_page)
      window.scrollTo(0, 0)
      
    }, 300);
  }

  function handlePendingTableChange (type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setLoadPendingData(false)
      setPendingCategories([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/headings/?active=0&page_size=${sizePerPage}&&page=${new_page}`
      if (filter !== ""){
        url = `${url}${filter}`
      }
      loadPendingData(url , sizePerPage, new_page)
      window.scrollTo(0, 0)
      
    }, 300);
  }
 
  const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];


    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  const PendingpageOptions = {
    page: pendingpage,
    sizePerPage: pendingsizePerPage,
    totalSize: pendingtotalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [ {
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    } ]
}

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Categories' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
              <Nav tabs>
                <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1")
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                      <span className="d-none d-sm-block">Active</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2")
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                      <span className="d-none d-sm-block">Inactive</span>
                    </NavLink>
                  </NavItem>
                  
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={categories}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id_num"
                        data={categories}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            
                            <Row className="mb-2">
                              <Col sm='9'>
                                <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                    <Row className="mb-2">
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                            <Select
                                            value={search_by}
                                            onChange={(e) => {
                                                setSearchby(e)
                                            }}
                                            options={optionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                        
                                        
                                      </Col>
                                      {search_by.value?
                                      <Col sm="9">
                                        <Row>
                                          <Col sm="8">
                                            {search_by.value !== null?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e)=> setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>
                                              
                                              </Col>
                                              
                                            </Row>

                                            : null}
                                            
                                          </Col>
        
                                          <Col sm="4">
                                              <Button type="submit" color="success" className="btn-rounded me-2" >
                                                Filter
                                              </Button>
                                              {" "}
                                              <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded" >
                                                Clear
                                              </Button>
                                            
                                          </Col>
                                        </Row>  
                                      </Col>
                                      :null}
                                      
                                    </Row>
                                  </AvForm>
                              </Col>
                              <Col sm="3">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={()=> props.history.push('/new-category')}
                                  >
                                    <i className="mdi mdi-plus me-1" />{" "}
                                    Add New Category
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            
                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                hover
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={ handleTableChange }
                                noDataIndication={ NoDataIndication }
                              />
                              
                            </div>
                            <Row>
                                  <Col sm={6}>
                                    <div className="mb-3">
                                        <SizePerPageDropdownStandalone
                                          { ...paginationProps }
                                        />
                                    </div>
                                    <div className="pagination  mb-2">
                                      <PaginationTotalStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    
                                  </Col>
                                  <Col sm={6}>
                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    
                                  </Col>
                                </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                    </PaginationProvider>

                  </TabPane>
                  <TabPane tabId="2" >
                      <PaginationProvider
                      pagination={paginationFactory(PendingpageOptions)}
                      data={categories}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id_num"
                          data={pendingcategories}
                          columns={columns}
                          remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                          bootstrap5
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              
                              <Row className="mb-2">
                              <Col sm='9'>
                                <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                    <Row className="mb-2">
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                            <Select
                                            value={search_by}
                                            onChange={(e) => {
                                                setSearchby(e)
                                            }}
                                            options={optionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                        
                                        
                                      </Col>
                                      {search_by.value?
                                      <Col sm="9">
                                        <Row>
                                          <Col sm="8">
                                            {search_by.value !== null?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e)=> setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>
                                              
                                              </Col>
                                              
                                            </Row>

                                            : null}
                                            
                                          </Col>
        
                                          <Col sm="4">
                                              <Button type="submit" color="success" className="btn-rounded me-2" >
                                                Filter
                                              </Button>
                                              {" "}
                                              <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded" >
                                                Clear
                                              </Button>
                                            
                                          </Col>
                                        </Row>  
                                      </Col>
                                      :null}
                                      
                                    </Row>
                                  </AvForm>
                              </Col>
                                <Col sm="3">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={()=> props.history.push('/new-category')}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      Add New Category
                                    </Button>
                                  </div>
                                </Col>
                              </Row>

                              
                              <div className="table-responsive">

                                <BootstrapTable
                                  remote
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  bordered={false}
                                  striped={true}
                                  hover
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  onTableChange={ handlePendingTableChange }
                                  noDataIndication={ pendingNoDataIndication }
                                />
                                
                              </div>
                              <Row>
                                    <Col sm={6}>
                                      <div className="mb-3">
                                          <SizePerPageDropdownStandalone
                                            { ...paginationProps }
                                          />
                                      </div>
                                      <div className="pagination  mb-2">
                                        <PaginationTotalStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      
                                    </Col>
                                    <Col sm={6}>
                                      <div className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      
                                    </Col>
                                  </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                      </PaginationProvider>
                    
                  </TabPane>
                </TabContent>
                
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleItineraryClicks(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
        
      </div>

    </React.Fragment>
  )
}

export default Headings
