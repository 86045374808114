import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { AvForm, AvField } from "availity-reactstrap-validation"
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import Select from "react-select";
import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { downloadExcel } from "react-export-table-to-excel";
import { HeaderFormatter } from "../../helpers/methods";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const orderFields = {'conctractor' : 'contractor__bus_name', 
                    'category': 'category__heading',
                    'zip': 'zip',
                    'zone':"zone",
                  'page_title': 'page_title' }

var SortOrdering = {'zone':"asc",'category': 'asc','zip': 'asc','page_title': 'asc','conctractor' : 'asc' }

const defaultOrdering = 'zone,category__heading,zip,page_title,contractor__bus_name'

const header = ["Zone", "Category","Zip","City" , "Contractor" ];

const SearchOptions = [
  {
    label: "Search By",
    options: [
      { label: "Zone", value: 'zone'},
      { label: "Category", value: 'category__heading__icontains'},
      { label: "Zip", value: 'zip'},
      { label: "City", value: 'page_title__icontains'},
      { label: "Contractor", value: 'contractor__bus_name__icontains'},
    ],
  },
];

const ZoneSold = (props) => {

  const [zoneReports, setZoneReports] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [Search_text, setsearchText] = useState("")
  const [searchBy, setSearchBy] = useState({ label: "Select Search By", value: null})
  const [filter, setFilter] = useState(null)

  ValidateUser(props)

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true
    },
    {
        dataField: "zone",
        text: "Zone",
        headerFormatter:HeaderFormatter,
        sort: true,
    },
    {
      dataField: "category",
      text: "Category",
      headerFormatter:HeaderFormatter,
      sort: true,
    },
    {
        dataField: "zip",
        text: "Zip",
        headerFormatter:HeaderFormatter,
        sort: true,
    },
    {
        dataField: "page_title",
        text: "City",
        headerFormatter:HeaderFormatter,
        sort: true,
      },
    {
      dataField: "contractor",
      text: "Contractor",
      headerFormatter:HeaderFormatter,
      sort: true,
      
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/dashboard/zone-report/?page_size=${sizePerPage}&ordering=${defaultOrdering}`, sizePerPage, page, Search_text)
    
  }, []);


  function loadData(url , sizePerPage, page , searchText){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setZoneReports(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
    })
  }

  function handleDownloadExcel() {
    let data = []
    for (let i=0; i<zoneReports.length; i++){
      data.push({"zone":zoneReports[i].zone, 'category': zoneReports[i].category, 'zip':zoneReports[i].zip,
      'page_title':zoneReports[i].page_title, 'contractor':zoneReports[i].contractor })
    }
    downloadExcel({
      fileName: "Zone Report",
      sheet: "zone-report",
      tablePayload: {
        header,
        // accept two different data structures
        body: data,
      },
    });
  }

  function FilterData(e, values){
    const data =  `&search=${values.search}&field=${searchBy.value}`
    setFilter(data)
    loadData(`${BASE_URL}/api/tgcl/dashboard/zone-report/?page_size=${sizePerPage}&ordering=${defaultOrdering}${data}`, sizePerPage, page, Search_text)

  }

   function ClearFilter(){
    setSearchBy({ label: "Select Search By", value: null})
    setFilter(null)
    loadData(`${BASE_URL}/api/tgcl/dashboard/zone-report/?page_size=${sizePerPage}&ordering=${defaultOrdering}`, sizePerPage, page, Search_text)


   }
  


  function handleTableChange (type, { sortField, sortOrder, page, sizePerPage, searchText, }) {

    SortOrdering[sortField] = sortOrder
    let ordering = ""
    // 
    for (const [key, value] of Object.entries(SortOrdering)) {
      if (sortField === key){
        if(value === "desc"){
          ordering = "-"+orderFields[key]+ (ordering === ""?"":(","+ordering))
        }else{
          ordering = orderFields[key]+(ordering === ""?"":(","+ordering))
        }

      } else{
        if(value === "desc"){
          ordering = (ordering === ""?"-":(ordering+",-")) +orderFields[key]
        }else{
          ordering = (ordering===""?"":(ordering+","))+orderFields[key]

        }

      }

    }
    
    setTimeout(() => {
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/dashboard/zone-report/?page_size=${sizePerPage}&page=${new_page}&ordering=${ordering}`
      if(filter){
        url = url+filter
      }
      window.scrollTo(0, 0)
      loadData(url , sizePerPage, new_page , searchText)
    }, 500);
  }
 
  const { SearchBar } = Search

    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  const defaultSorted = [{
    dataField: 'zone',
    order: 'asc'
  }];

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Zone Report' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={zoneReports}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={zoneReports}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            
                            <Col sm="8">
                            <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                    <Row className="mb-2">
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                            <Select
                                            value={searchBy}
                                            onChange={(e) => {
                                                setSearchBy(e)
                                            }}
                                            options={SearchOptions}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                        
                                        
                                      </Col>
                                      {searchBy.value?
                                      <Col sm="8">
                                        <Row>
                                          <Col sm="5">
                                            {searchBy.value !== null?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={searchBy.label}
                                                    type="text"
                                                    errorMessage=""
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>
                                              
                                              </Col>
                                              
                                            </Row>

                                            : null}
                                            
                                          </Col>
                                          
                                          <Col sm="4">
                                        
                                                
                                                <Button type="submit" color="success" className="btn-rounded me-2" >
                                                  Search
                                                </Button>
                                                {" "}
                                                <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded" >
                                                  Clear
                                                </Button>
                                            
                                          </Col>
                                        </Row>  
                                      </Col>
                                      :null}
                                      
                                    </Row>
                                  </AvForm>
                              {/* <Row>
                                <Col sm='2'>
                                  <p className="mt-2">Search by Field</p>
                                </Col>
                                <Col sm='2'>
                                  <div className="mb-3">
                                      <Select
                                      value={searchBy}
                                      onChange={(e) => {
                                          setSearchBy(e);
                                          if(Search_text !== "" & Search_text !== undefined){
                                            loadData(`${BASE_URL}/api/tgcl/dashboard/zone-report/?page_size=${sizePerPage}&ordering=${defaultOrdering}&search=${Search_text}&field=${e.value}`, sizePerPage, page, Search_text)
                                          }
                                          
                                      }}
                                      options={SearchOptions}
                                      classNamePrefix="select2-selection"
                                      />
                                    </div>
                                </Col>
                                <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        
                                      </div>
                                    </div>
                                </Col>
                              </Row> */}
                              
                            </Col>
                            <Col sm='4'>
                              <Button
                                  type="button"
                                  color="primary"
                                  className="btn-rounded mb-2 float-end"
                                  onClick={() => handleDownloadExcel()}
                                  >
                                  <i className="mdi mdi-file-excel-outline me-1" />{" "}
                                  Download
                              </Button>
                            </Col>
                          </Row>
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              defaultSorted={defaultSorted}
                              bordered={false}
                              striped={true}
                              hover
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                            />
                            
                          </div>
                          <Row>
                            <Col sm="6">
                              <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps} 
                                    
                                  />
                                </div>
                                <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                            </Col>
                            <Col sm="6">
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>

                            </Col>

                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
    
        </div>

    </React.Fragment>
  )
}

export default ZoneSold
