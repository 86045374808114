import React, {useEffect, useState} from "react"
import { Row, Col, CardBody, Card, CardTitle, Button, Label, Spinner } from "reactstrap"
import { post } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import moment from "moment-timezone";
import { AvForm, AvField } from "availity-reactstrap-validation"
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Select from "react-select";
import { Link } from "react-router-dom"
import { getLastMontDate } from "../../../helpers/methods"


const optionGroup = [
    {
      label: "Email Type",
      options: [

        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Last week ", value: "last_week" },
        { label: "Last month", value: "last_month" },
        { label: "Last 7 days", value: "last_7_days" },
        { label: "Last 30 days", value: "last_30_days" },
        { label: "Custom", value: "custom" },
      ],
    },
  ];

  const FilterGroup = [
    {
      label: "Filter By",
      options: [
        { label: "Filter By", value: null },
        { label: "Campaign Name", value: "campaign_name" },
        { label: "Domain Name", value: "domain_name" },
        { label: "Category", value: "category" },
      ],
    },
  ];

const WebsiteClicks = (props) => {

    const [seriesData, setSeriesData] = useState([]) 
    const [count, setcount] = useState([])
    const [websiteClickData, setWebsiteClickData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [totalClickCount, setTotalClickCount] = useState(0)
    const [startdate, setStartDate] = useState(moment(new Date()).subtract(30,'d').format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).format('YYYY-MM-DD')) 
    const [seletedDate, setSeletedDate] = useState({ label: "Last 30 days", value: "last_30_days" })
    const [seletedFilterType, setSeletedFilterType] = useState({ label: "Filter By", value: null })
    const [selectedCampaign, setSelectedCampaign] = useState({ label: "Select Campaign", value: null })
    const [selectedCategory, setSelectedCategory] = useState({ label: "Select Category", value: null })
    const [Data, setData] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [loaddata, setLoaddata] = useState(false)
    const [categories, setCategories] = useState([])
    
    function loadData(start_date , end_date){
        let data = {"from_date": start_date, "to_date": end_date}
        post(`${BASE_URL}/api/tgcl/dashboard/website-clicks-analytics/`,data, { headers: { 'Content-Type': 'application/json', }} )
        .then(response => {

            let data = response.data.website_clicks
            let series = []
            let counts = []
            let categories = []
            if(data.length > 0){
                console.log(data)
                let length =  data.length>15? 15 : data.length
                for(let i=0 ; i< length; i++){
                    series.push(data[i].campaign__pg_title)
                    counts.push(data[i].count)
                }
            }
            let count = 0
            let campaigns = []
            for(let i=0; i<data.length; i++ ){
                count = count + data[i].count
                campaigns.push({ label: data[i].campaign__pg_title , value: data[i].campaign__pg_title })
                if(!categories.some(cat => cat.label === data[i].campaign__category__heading && cat.value === data[i].campaign__category__heading)){
                    categories.push({ label: data[i].campaign__category__heading , value: data[i].campaign__category__heading })
                }
            }
            setWebsiteClickData(data)
            setData(data)
            setTotalCount(count)
            setCampaigns(campaigns)
            setSeriesData(series)
            setcount(counts)
            setLoaddata(true)
            setCategories(categories.sort())
            setTotalClickCount(count)
        })
    }

    function filterByCampaign(name){
        let newData= []
        let count = 0
        for(let i=0; i< Data.length; i++){
            if(Data[i].campaign__pg_title === name ){
                newData.push(Data[i])
                count = count + Data[i].count 
                break
            }
            
        }
        setWebsiteClickData(newData)
        setTotalClickCount(count)

    }

    function filterByCategory(name){
        let newData= []
        let count = 0
        for(let i=0; i< Data.length; i++){
            if(Data[i].campaign__category__heading === name ){
                newData.push(Data[i])
                count = count + Data[i].count 
            }
            
        }
        setWebsiteClickData(newData)
        setTotalClickCount(count)

    }

    useEffect(() => {
            
        const filter =  JSON.parse(localStorage.getItem("analyticsDate"))
        let startDate = startdate
        let endDate = enddate
        if (filter){
            setStartDate(filter.startDate)
            setEnddate(filter.endDate)
            startDate = filter.startDate
            endDate = filter.endDate
            setSeletedDate(filter.type)
        }
            
        loadData(startDate, endDate)
        
    }, []);

    function handleFilter(e, values){
        
            if ((new Date(values.enddate)) >= (new Date(values.startdate))){
                localStorage.setItem("analyticsDate", JSON.stringify({'type':seletedDate,'startDate':enddate, 'endDate':enddate}));
            loadData(startdate, enddate)
            
            }else{
            alert(`To date must be grater than from Date`)
            }
        
      }

    function handleSeletedDateChange(selected_date){
        setSeletedDate(selected_date)
        if(selected_date.value !== 'custom'){
            let start = startdate
            let end = enddate
            if(selected_date.value === 'today'){
                start = moment(new Date()).format('YYYY-MM-DD')
                end = moment(new Date()).format('YYYY-MM-DD')
            }else if(selected_date.value === 'yesterday'){
                start = moment(new Date()).subtract(1,'d').format('YYYY-MM-DD')
                end = moment(new Date()).subtract(1,'d').format('YYYY-MM-DD')
            }else if(selected_date.value === 'last_week'){
                start = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
                end = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_month'){
                start = getLastMontDate().firstDay
                end = getLastMontDate().lastDay
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_7_days'){
                start = moment(new Date()).subtract(7,'d').format('YYYY-MM-DD')
                end = moment(new Date()).subtract(1,'d').format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_30_days'){
                start = moment(new Date()).subtract(30,'d').format('YYYY-MM-DD')
                end = moment(new Date()).format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }
            loadData(start, end)
            setLoaddata(false)
        }
    }
    
    function NoDataIndication(){
        if (loaddata && websiteClickData.length === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )
    
        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
      }

    function clearFilter(){
        setSeletedFilterType({ label: "Filter By", value: null })
        setSelectedCategory({ label: "Select Category", value: null })
        setWebsiteClickData(Data)
        setTotalClickCount(totalCount)
    }

    function handleFilterTypeChange(filter_type){
    setSeletedFilterType(filter_type)
    if(filter_type.value === null){
        setWebsiteClickData(Data)
        setSelectedCampaign({ label: "Select Campaign", value: null })
    }
    }

    function filterByDomain(e, values){
    let newData = []
    let count = 0
    let domain = values.domain_name
    for (let i=0; i<Data.length; i++){
        let url  = Data[i].campaign__url.toLowerCase()
        if(url.includes(domain) || url === domain){
            newData.push(Data[i])
            count = count + Data[i].count
        }
    }
    setWebsiteClickData(newData)
    setTotalClickCount(count)


    }


    const series = [
        {
        name: "Count",
        data: count,
        },
    ]
    const options = {
        chart: {
        toolbar: {
            show: false,
        },
        },
        plotOptions: {
        bar: {
            dataLabels: {
            position: "top",
            },
        },
        },
        dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val
        },
        offsetY: -20,
        style: {
            fontSize: "12px",
            colors: ["#304758"],
        },
        },

        colors: ['#3b5de7'],
        grid: {
        borderColor: "#f1f1f1",
        },
        xaxis: {
        categories:seriesData, 
        position: "top",
        labels: {
            offsetY: 1,
        },

        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        crosshairs: {
            fill: {
            type: "gradient",
            gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
            },
            },
        },
        tooltip: {
            enabled: true,
            offsetY: -35,
        },
        },
        fill: {
        gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
        },
        },
        yaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        labels: {
            show: false,
            formatter: function (val) {
            return val
            },
        },
        },
    }

    const columns = [
        {
          dataField: "campaign__id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "campaign__pg_title",
          text: "Campaign",
          formatter: (cellContent, row) => (
            <Link className="text-dark" to={{ pathname: `/analytics/contractor-detail/${row.campaign__slug}`, campaignID:row.campaign__id, url:'/analytics/contractor-website-clicks', campaignName:cellContent ,page_name:"Contractor Website Clicks"}}>{cellContent}</Link>     
          ),
          title: (cell) => `${cell}`
        },
        {
            dataField: "campaign__category__heading",
            text: "Category",
            formatter: (cellContent) => (
              cellContent
            ),
            title: (cell) => `${cell}`
        },
        {
            dataField: "campaign__url",
            text: "Domain",
            formatter: (cellContent) => (
              cellContent.split('?')[0]  
            ),
            title: (cell) => `${cell}`
        },
        {
        dataField: "count",
        text: "Clicks",
        sort: true,
        formatter: (cellContent) => (
            cellContent    
        ),
        title: (cell) => `${cell}`
        },
      ]

    const defaultSorted = [{
    dataField: 'count',
    order: 'desc'
    }];

    //pagination customization
    const pageOptions = {
        sizePerPage: 20,
        totalSize: websiteClickData.length,
        custom: true,
    }
    
    return (
        <React.Fragment>
        <div className="page-content">
        <Breadcrumbs title="Analytics Dashboard" breadcrumbItem='Contractor Website Clicks' link="/analytics" />
        <Row>
                <Col xs="12">
                <Card>
                    <CardBody>
                    <AvForm className="needs-validation" onValidSubmit={handleFilter}>
                        <Row>
                            <Col sm="3">
                                <div className="text-sm-start mb-2">
                                    <Select
                                    value={seletedDate}
                                    onChange={(e) => handleSeletedDateChange(e)}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                                
                            </Col>
                            {seletedDate.value==='custom'?
                            
                            <Col sm="9">
                                <Row>
                                    <Col md={8}>
                                    <Row>
                                        <Col md={6}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                                            <div className="col-md-8">
                                            <AvField
                                            name="startdate"
                                            placeholder="To Date"
                                            type="date"
                                            errorMessage="To Date"
                                            className="form-control"
                                            value={startdate}
                                            onChange={(e)=> setStartDate(e.target.value)}
                                            id="to_date"
                                            
                                            />
                                        </div>
                                        </Row>
                                        </Col>
                                        <Col md={6}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_bcc" className="col-md-4 col-form-label">To</Label>
                                            <div className="col-md-8">
                                            <AvField
                                                name="enddate"
                                                placeholder="To Date"
                                                type="date"
                                                errorMessage="To Date"
                                                className="form-control"
                                                value={enddate}
                                                onChange={(e)=> setEnddate(e.target.value)}
                                                id="to_date"
                                            />
                                        </div>
                                        </Row>
                                        </Col>
                                        
                                    </Row>
                                    </Col>
                                    <Col md={4}>
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                            Filter
                                        </Button>
                                        
                                    </Col>
                                </Row>
                        </Col>:null}
                        
                        </Row>
                    
                    </AvForm>
                    </CardBody>
                </Card>
                </Col>
            </Row>
    
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <CardTitle className="h4 mb-4">
                            Contractor Website Clicks{" "}
                            </CardTitle>
                            {loaddata?
                            <ReactApexChart options={options} series={series} type="bar" height={250} />
                            : 
                            <Col sm="12">
                                <div className="d-flex align-items-center justify-content-center" style={{height:"250px"}}>
                                <Spinner className="my-3" color="dark" />
                                </div>
                            </Col>
                            }
                        </CardBody>
                    </Card>

                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card>
                    <CardBody>
                        <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        data={websiteClickData}
                        >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                            keyField="id"
                            data={websiteClickData}
                            columns={columns}
                            bootstrap5
                            search
                            >
                            
                            {toolkitProps => (
                                <React.Fragment>
                                
                                <Row className="mb-2">
                                    <Col sm="9">
                                    <AvForm className="needs-validation" onValidSubmit={filterByDomain}>
                                        <Row>
                                            <Col sm='3'>
                                                <div className="text-sm-start mb-2">
                                                    <Select
                                                    value={seletedFilterType}
                                                    onChange={(e) => handleFilterTypeChange(e)}
                                                    options={FilterGroup}
                                                    classNamePrefix="select2-selection"
                                                />
                                                </div>
                                            </Col>
                                            {seletedFilterType.value === 'campaign_name'?
                                            <>
                                            <Col sm='4'>
                                                <div className="text-sm-start mb-2">
                                                    <Select
                                                    value={selectedCampaign}
                                                    onChange={(e) => {setSelectedCampaign(e); filterByCampaign(e.value)}}
                                                    options={campaigns}
                                                    classNamePrefix="select2-selection"
                                                />
                                                </div>
                                            </Col>
                                            <Col sm='3'>
                                                <Button type="button" color="warning" onClick={clearFilter} className="btn-rounded me-2" >
                                                    Clear
                                                </Button>
                                            </Col>
                                            </>:null}
                                            {seletedFilterType.value === 'category'?
                                            <>
                                            <Col sm='4'>
                                                <div className="text-sm-start mb-2">
                                                    <Select
                                                    value={selectedCategory}
                                                    onChange={(e) => {setSelectedCategory(e); filterByCategory(e.value)}}
                                                    options={categories}
                                                    classNamePrefix="select2-selection"
                                                />
                                                </div>
                                            </Col>
                                            <Col sm='3'>
                                                <Button type="button" color="warning" onClick={clearFilter} className="btn-rounded me-2" >
                                                    Clear
                                                </Button>
                                            </Col>
                                            </>:null}
                                            {seletedFilterType.value === 'domain_name'?
                                            <>
                                                <Col sm='4'>
                                                    <div className="mb-3">
                                                    <AvField
                                                        name="domain_name"
                                                        type="text"
                                                        placeholder="Enter Domain Name"
                                                        errorMessage=" Please provide Domain Name."
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="domain_name"
                                                    />
                                                </div>
                                                </Col>
                                                <Col sm='4'>
                                                    <Button type="submit" color="success" className="btn-rounded me-2" >
                                                        Filter
                                                    </Button>{" "}
                                                    <Button type="button" color="warning" onClick={clearFilter} className="btn-rounded me-2" >
                                                        Clear
                                                    </Button>
                                                </Col>
                                            </>
                                            :null}
                                        </Row>
                                    </AvForm>
                                        
                                    </Col>
                                    <Col sm="3">
                                    <div className="text-sm-end">
                                        <p><b className="mt-5">Total Clicks Count: {totalClickCount}</b></p>
                                        
                                    </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive">

                                    <BootstrapTable
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        responsive
                                        bordered={false}
                                        striped={true}
                                        defaultSorted={defaultSorted}
                                        classes={
                                            "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        noDataIndication={() => NoDataIndication()}
                                    />
                                    
                                </div>
                                <Row>
                                        <Col sm={6}>
                                        <div className="pagination  mb-2">
                                            <PaginationTotalStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        
                                        </Col>
                                        <Col sm={6}>
                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                            <PaginationListStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                            </ToolkitProvider>
                        )}
                        </PaginationProvider>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        </React.Fragment>
    )
}

export default WebsiteClicks