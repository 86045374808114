import React, { useState } from "react";

import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { toast } from "react-toastify";
import { BASE_URL } from "../../../helpers/url_helper";

import { ValidateUser } from "../../../helpers/validate_user";
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken"


const AddHighlight = (props) => {
    
    const [file, setFile] = useState(null);
    

      ValidateUser(props)



    function handleChange(e) {
        setFile(e.target.files[0]);
        // URL.createObjectURL
    }

    function handleSubmit(e, values){
        const formData = new FormData();
        if (!file){
            toast.error(`Please Add Icon for ${values.name}`)
          
        }else{
            formData.append('image', file, file.name) 
        formData.append("name",values.name)

        const options = {
            method: 'POST',
            body: formData,
            headers: {'Authorization': getAccessToken() }
        };
        fetch(`${BASE_URL}/api/tgcl/highlights/`, options)
        .then(res => {
                if(res.status >= 400){
                res.json()
                .then(
                    (data) =>{  
                    console.log(data, "---data")
                    toast.error("Somthing went wrong ")

                    }  
                    )
                }else{
                    props.history.push("/highlights")
                }
            })
        }
    }   

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Categories" breadcrumbItem={`Add Highlight `} link="/categories" />

                <Row>
                    <Col className="col-6">
                        <Card>
                        <CardBody>
                            <CardTitle>Highlight Detail </CardTitle>                  
                            <AvForm className="needs-validation" onValidSubmit={(e, values)=>handleSubmit(e, values)}>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label htmlFor="name">Name</Label>
                                        <AvField
                                            name="name"
                                            placeholder="Name"
                                            type="text"
                                            errorMessage="Enter Category"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="name"
                                        />
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row>
                                <Label htmlFor="icon">Icon</Label>
                                <Col sm={6}>
                                    {file?
                                        <img src={URL.createObjectURL(file)} className="rounded avatar-lg m-3"/>
                                    :null}

                                </Col>
                                <Col sm={6}>
                                    <input id="icon" className="mb-3" type="file" onChange={handleChange} />
                                </Col>
                                
                                
                                
                                </Row>
                                <Button color="primary" type="submit">
                                Submit
                                </Button>
                            </AvForm>
                        
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        </React.Fragment>
    )
}

export default AddHighlight
