import React, { useState,useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { slugify } from "../../../helpers/helper_methods";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../helpers/url_helper";
import { get } from "../../../helpers/api_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken"

const CreateZipcodePage = (props) => {
    const [zipcodes, setZipcodes] = useState([]);
    const [selectedZipcode, setSelectedZipcode] = useState({ label: "Select Zip Code", value: null });
    const [loading, setLoading] = useState(false)
     const [file, setFile] = useState(null);
    const [didMount, setDidMount] = useState(false);

    const ZipCode = [
        {
        label: "Zip Codes",
        options: []
        },
    ];

    ValidateUser(props)

    useEffect(() => {
        
        setDidMount(true);
        loadZipcodes()
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
        }

    function handleChange(e) {
        setFile(e.target.files[0]);
    }

    function loadZipcodes(search=false){
        let url = `${BASE_URL}/api/tgcl/zipcode/?is_active=true`
        if(search){url = url + `&zipcode=${search}`}else{url = url +"&page_size=100"}
        let data = get(url, { headers: { 'Content-Type': 'application/json', },  validateStatus: false}  
        )
        data.then(response => {
            let options = []
            let zip_data = response.data.results
            for(let i=0; i<zip_data.length; i++){
                options.push({ label: zip_data[i].zipcode, value: zip_data[i].id })
            }
            setZipcodes(options)
        })

    }
    function handleSubmit(e, values){
        const formData = new FormData();
        setLoading(true)
        if (file){
        formData.append('image', file, file.name) 
        }
        formData.append("zipcode",selectedZipcode.value)

        const options = {
            method: 'PUT',
            body: formData,
            headers: {'Authorization': getAccessToken() }
        };
        fetch(`${BASE_URL}/api/tgcl/dashboard/zipcode-pages/`, options)
            .then(res => 
                {if(res.status >= 400){
                    res.json()
                    .then(
                        (data) =>{  
                            console.log(data, "---data")
                            toast.error("Somthing Went wrong")
                            setLoading(false)
                        }     
                    )
                }
                else{
                    res.json()
                    .then(
                        (data) =>{
                            props.history.push("/zipcode-pages")
                            }
                        )
                }    
            }
        ) 

    }
  
    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Zip Code Pages" breadcrumbItem={`Create Zip Code Pages`} link="/zipcode-pages" />
         
            <Row>
            <Col className="col-6">
                <Card>
                <CardBody>
                    <CardTitle>Page Detail</CardTitle>                  
                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                        <Row>
                        <Col md="12">
                                <div className="mb-3">
                                    <Label>Zipcode</Label>
                                    <Select
                                    onInputChange = {(e)=>
                                        loadZipcodes(e)
                                         
                                         }
                                    value={selectedZipcode}
                                    onChange={(e) => {
                                        setSelectedZipcode(e)
                                    }}
                                    options={zipcodes}
                                    classNamePrefix="select2-selection"
                                    
                                    />
                                </div>
                            </Col>
                        </Row>
                                      
                        <Row>
                            <Label htmlFor="icon">Background Image</Label>
                            <Col sm={6}>
                                <input id="icon" className="mb-3" type="file" accept="image/png, image/jpeg, image/jpeg" onChange={handleChange} />
                            </Col>
                        
                            <Col sm={6}>
                            { file?
                            <img src={URL.createObjectURL(file)} className="rounded img-thumbnail mb-3" alt="" />
                            : null}
                            </Col>
                            
                        </Row>
                        {loading?""
                        :
                        <Button color="primary" type="submit">
                        Submit
                        </Button>
                        }
         
                    </AvForm>
                
                </CardBody>
                </Card>
            </Col>
            </Row>
          
        </div>

        </React.Fragment>
    )
}

export default CreateZipcodePage
