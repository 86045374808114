import React, {useEffect, useState} from "react"
import { Row, Col, CardBody, Card, CardTitle, Table,Button, Label, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { post } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import moment from "moment-timezone";
import { AvForm, AvField } from "availity-reactstrap-validation"

import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Select from "react-select";

const optionGroup = [
    {
      label: "Email Type",
      options: [

        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Last week ", value: "last_week" },
        { label: "Last month", value: "last_month" },
        { label: "Last 7 days", value: "last_7_days" },
        { label: "Last 30 days", value: "last_30_days" },
        { label: "Custom", value: "custom" },
      ],
    },
  ];

const ZipcodeAnalytics = (props) => {

    const [zicodesData, setZicodesData] = useState([]) 
    const [count, setcount] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [Data, setData] = useState([])
    const [nonExpandablee, setNonExpandable] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [totalSearchCount, setTotalSearchCount] = useState(0)
    const [categories, setCategories] = useState([])
    const [startdate, setStartDate] = useState(moment(new Date()).subtract(30,'d').tz("America/Chicago").format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')) 
    const [seletedDate, setSeletedDate] = useState({ label: "Last 30 days", value: "last_30_days" })
    const [seletedCategory, setSeletedCategory] = useState({ label: "Select Zipcode", value: null })
    const [loaddata, setLoaddata] = useState(false)
  
    
    function loadData(start_date , end_date){
        let data = {"from_date": start_date, "to_date": end_date}
        console.log(data)
        post(`${BASE_URL}/api/tgcl/dashboard/zipcodes-analytics/`,data, { headers: { 'Content-Type': 'application/json', }} )
        .then(response => {

            let data = response.data.zipcodes
            let series = []
            let counts = []
            if(data.length > 0){
                let length =  data.length>15? 15 : data.length
                for(let i=0 ; i< length; i++){
                    series.push(data[i].zip)
                    counts.push(data[i].count)
                }
                setZicodesData(series)
                setcount(counts)  
            }
            let catData = response.data.grouped_data
            let nonExpandable = []
            let totalCount = 0
            let categoryData = []
            let categories = []
            Object.keys(catData).map((key) => {
                
                let category = {}
                category['zipcode'] = key
                category['id'] = key
                categories.push({ label:key, value: key })

                let zipData =  catData[key]
                console.log(zipData,"zipData")
                if (zipData.length < 2){
                    nonExpandable.push(parseInt(key))
                    category['zipcode_range'] = zipData[0].category__heading
                    category['count'] = zipData[0].count
                    category['zipdata'] = []
                    totalCount = totalCount+zipData[0].count

                }else{
                    let count = 0
                    for(let i=0; i<zipData.length; i++){
                        count = count + zipData[i].count
                    }
                    category['zipcode_range'] = `${zipData[0].category__heading}, ${zipData[1].category__heading}`
                    category['count'] = count
                    totalCount = totalCount + count
                    category['zipdata'] = zipData.sort((a, b) => b.count - a.count);

                }
                categoryData.push(category)
            })
            setNonExpandable(nonExpandable)
            setTotalCount(totalCount)
            setCategoryData(categoryData)
            setCategories(categories)
            setData(categoryData)
            setLoaddata(true)
            setTotalSearchCount(totalCount)

        })
    }

    useEffect(() => {
            
        const filter =  JSON.parse(localStorage.getItem("analyticsDate"))
        let startDate = startdate
        let endDate = enddate
        if (filter){
            setStartDate(filter.startDate)
            setEnddate(filter.endDate)
            startDate = filter.startDate
            endDate = filter.endDate
            setSeletedDate(filter.type)
        }
            
        loadData(startDate, endDate)
        
    }, []);

    function handleFilter(e, values){

        if ((new Date(values.enddate)) >= (new Date(values.startdate))){
        localStorage.setItem("analyticsDate", JSON.stringify({'type':seletedDate,'startDate':enddate, 'endDate':enddate}));
        loadData(startdate, enddate)
        setLoaddata(true)
        
        }else{
        alert(`To date must be grater than from Date`)
        }
      }

    function filterByCategory(zipcode){
        let newdata = []
        let count = 0
        for (let i=0; i< Data.length; i++){
            if(Data[i].zipcode === zipcode){
                newdata.push(Data[i])
                count = count + Data[i].count
                break
            }
        }
        setCategoryData(newdata)
        setTotalSearchCount(count)
    }

    function handleSeletedDateChange(selected_date){
        setSeletedDate(selected_date)
        if(selected_date.value !== 'custom'){
            let start = startdate
            let end = enddate
            if(selected_date.value === 'today'){
                start = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
            }else if(selected_date.value === 'yesterday'){
                start = moment(new Date()).subtract(1,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).subtract(1,'d').tz("America/Chicago").format('YYYY-MM-DD')
            }else if(selected_date.value === 'last_week'){
                start = moment(new Date()).subtract(14,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).subtract(7,'d').tz("America/Chicago").format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_month'){
                start = moment(new Date()).subtract(60,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).subtract(30,'d').tz("America/Chicago").format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_7_days'){
                start = moment(new Date()).subtract(7,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }else if(selected_date.value === 'last_30_days'){
                start = moment(new Date()).subtract(30,'d').tz("America/Chicago").format('YYYY-MM-DD')
                end = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
                setStartDate(start)
                setEnddate(end)
            }
            loadData(start, end)
            setLoaddata(false)
        }
    }
    
    function NoDataIndication(){
        if (loaddata && categoryData.length === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )
    
        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
      }



    const series = [
        {
        name: "Count",
        data: count,
        },
    ]
    const options = {
        chart: {
        toolbar: {
            show: false,
        },
        },
        plotOptions: {
        bar: {
            dataLabels: {
            position: "top",
            },
        },
        },
        dataLabels: {
        enabled: true,
        formatter: function (val) {
            return val
        },
        offsetY: -20,
        style: {
            fontSize: "12px",
            colors: ["#304758"],
        },
        },

        colors: ['#3b5de7'],
        grid: {
        borderColor: "#f1f1f1",
        },
        xaxis: {
        categories:zicodesData, 
        position: "top",
        labels: {
            offsetY: 1,
        },

        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        crosshairs: {
            fill: {
            type: "gradient",
            gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
            },
            },
        },
        tooltip: {
            enabled: true,
            offsetY: -35,
        },
        },
        fill: {
        gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
        },
        },
        yaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        labels: {
            show: false,
            formatter: function (val) {
            return val
            },
        },
        },
    }

    const columns = [
        {
          dataField: "id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "zipcode",
          text: "Zip Code",
          formatter: (cellContent, row) => (
            <Link className="text-dark" to={{ pathname: `/analytics/zipcode-search-detail/${cellContent}`, categoryName:cellContent }}>{cellContent}</Link>    
          ),
          title: (cell) => `${cell}`
        },
        {
            dataField: "zipcode_range",
            text: "Category",
            formatter: (cellContent, row) => (
            //   `${cellContent.substring(0, 50)} ...` 
            <>{cellContent} </>
            ),
            title: (cell) => `${cell}`
        },
        {
        dataField: "count",
        text: "Search Count",
        sort: true,
        formatter: (cellContent) => (
            cellContent    
        ),
        title: (cell) => `${cell}`,
        align: 'center',
        headerAlign: 'center',
        },
      ]

    const defaultSorted = [{
    dataField: 'count',
    order: 'desc'
    }];


    //pagination customization
    const pageOptions = {
        sizePerPage: 20,
        totalSize: categoryData.length,
        custom: true,
    }

    const expandRow = {
        onlyOneExpanding: true,
        renderer: row => (
            <Row>
                    <Table className="table-centered">
                       
                        <tbody>
                            {row.zipdata.map((category, index) => (
                                <tr key={`index-${index}`} >
                                    <td>{row.zipcode}</td>
                                    <td>{category.category__heading}</td>
                                    <td>{category.count}</td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </Table>

            
        </Row>
           
        ),
        showExpandColumn: true,
        nonExpandable: nonExpandablee,
        expandColumnPosition: 'right',
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return "";
          },
          
    };
    
    return (
        <React.Fragment>
        <div className="page-content">
        <Breadcrumbs title="Analytics Dashboard" breadcrumbItem='Zip Code Searches' link="/analytics" />
        <Row>
                <Col xs="12">
                <Card>
                    <CardBody>
                    <AvForm className="needs-validation" id="datefilter" onValidSubmit={handleFilter}>
                        <Row>
                            <Col sm="3">
                                <div className="text-sm-start mb-2">
                                    <Select
                                    value={seletedDate}
                                    onChange={(e) => handleSeletedDateChange(e)}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                                
                            </Col>
                            {seletedDate.value==='custom'?
                            
                            <Col sm="9">
                                <Row>
                                    <Col md={8}>
                                    <Row>
                                        <Col md={6}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                                            <div className="col-md-8">
                                            <AvField
                                            name="startdate"
                                            placeholder="To Date"
                                            type="date"
                                            errorMessage="To Date"
                                            className="form-control"
                                            value={startdate}
                                            onChange={(e)=> setStartDate(e.target.value)}
                                            id="to_date"
                                            
                                            />
                                        </div>
                                        </Row>
                                        </Col>
                                        <Col md={6}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_bcc" className="col-md-4 col-form-label">To</Label>
                                            <div className="col-md-8">
                                            <AvField
                                            name="enddate"
                                            placeholder="To Date"
                                            type="date"
                                            errorMessage="To Date"
                                            className="form-control"
                                            value={enddate}
                                            onChange={(e)=> setEnddate(e.target.value)}
                                            id="to_date"
                                            />
                                        </div>
                                        </Row>
                                        </Col>
                                        
                                    </Row>
                                    </Col>
                                    <Col md={4}>
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                            Filter
                                        </Button>
                                    </Col>
                                </Row>
                        </Col>:null}
                        
                        </Row>
                    
                    </AvForm>
                    </CardBody>
                </Card>
                </Col>
            </Row>
    
            <Row>
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <CardTitle className="h4 mb-4">
                            Zip Code Searches{" "}
                            </CardTitle>
                            {loaddata?
                            <ReactApexChart options={options} series={series} type="bar" height={250} />
                            : 
                            <Col sm="12">
                                <div className="d-flex align-items-center justify-content-center" style={{height:"250px"}}>
                                <Spinner className="my-3" color="dark" />
                                </div>
                            </Col>
                            }
                        </CardBody>
                    </Card>
                </Col>
            
            
            </Row>
            <Row>
            
                <Col xs="12">
                    <Card>
                    <CardBody>
                        <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        data={categoryData}
                        >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                            keyField="id"
                            data={categoryData}
                            columns={columns}
                            bootstrap5
                            search
                            >
                            
                            {toolkitProps => (
                                <React.Fragment>
                                
                                <Row className="mb-2">
                                    <Col sm="8">
                                        <Row>
                                            <Col sm="4">
                                                <div className="text-sm-start mb-2">
                                                    <Select
                                                    value={seletedCategory}
                                                    onChange={(e) => {setSeletedCategory(e); e.value === null?setCategoryData(Data):filterByCategory(e.value)}}
                                                    options={categories}
                                                    classNamePrefix="select2-selection"
                                                />
                                                </div>
                                            </Col>
                                            {seletedCategory.value !== null?
                                            <Col sm="3">
                                            <Button type="button" color="warning" onClick={() => {setSeletedCategory({ label: "Select Zipcode", value: null }); setCategoryData(Data); setTotalSearchCount(totalCount)}} className="btn-rounded me-2" >
                                                Clear
                                            </Button>
                                            </Col>
                                            :null}

                                        </Row>
                                        
                                    </Col>
                                    <Col sm="4">
                                    <div className="text-sm-end">
                                        
                                        <p><b className="mt-4">Total Search Count: {totalSearchCount}</b></p>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="table-responsive">

                                    <BootstrapTable
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        responsive
                                        bordered={false}
                                        striped={true}
                                        defaultSorted={defaultSorted}
                                        classes={
                                            "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        expandRow={ expandRow }
                                        noDataIndication={() => NoDataIndication()}
                                    />
                                    
                                </div>
                                <Row>
                                        <Col sm={6}>
                                        <div className="pagination  mb-2">
                                            <PaginationTotalStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        
                                        </Col>
                                        <Col sm={6}>
                                        <div className="pagination pagination-rounded justify-content-end mb-2">
                                            <PaginationListStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                            </ToolkitProvider>
                        )}
                        </PaginationProvider>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
        </React.Fragment>
    )
}

export default ZipcodeAnalytics