import React from "react"
import { Container, Row, Col } from "reactstrap"
import moment from "moment-timezone";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6} className="text-dark">{`© Copyright 2012-${moment().format('YYYY')}`},  <a target="_blank" className="text-dark" rel="noreferrer" href="https://thegoodcontractorslist.com/">The Good Contractors List.</a> All Rights Reserved.</Col>
            <Col sm={6}>
              <div className="text-sm-end text-dark d-none d-sm-block">
              Designed & Developed with  
               <i className="mdi mdi-heart text-danger"></i> by Ocode Technologies
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
