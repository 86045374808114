import React, { useState, useEffect } from "react";

import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { BASE_URL } from "../../helpers/url_helper";
import { get } from "../../helpers/api_helper";
import { ValidateUser } from "../../helpers/validate_user";


const CumulativeView = (props) => {
    
    const { id } = props.match.params;
    const [reviews, setFeedbacks] = useState([])
    const [contractor, setContractor] = useState(null)
    const [didMount, setDidMount] = useState(false);    


    ValidateUser(props)

    useEffect(() => {
        setDidMount(true);
        loadData()
        getContractor()
        return () => setDidMount(false);
        
    },[]);


    function loadData(){
        get(`${BASE_URL}/api/tgcl/dashboard/cumulative/${id}`, 
        { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
        )
        .then(response => {
            setFeedbacks(response.data)
        })
    }
    
    function getContractor(){
        get(`${BASE_URL}/api/tgcl/contractor/${id}/`, 
        { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
        )
        .then(response => {
            setContractor(response.data)
        })

    }

    function getTotalScore(){
        let score = 0
        for (let i=0; i< reviews.length; i++){
           score = score+reviews[i].score
        }
        score = score/reviews.length
        return score>=100? 100: score<=0?0:score.toFixed(1)
    }


    if(!didMount) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Contractors Feedback" breadcrumbItem="Cumulative View" link="/contractors-feedback" />
                {reviews.length !== 0?
                <>
                 {/* ${reviews[0].contractor_id.bus_name} */}
                    <Row>
                        <CardTitle>{`Contractor: ${contractor?contractor.bus_name:""}`}</CardTitle>      
                    </Row>

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>{`Customer Feedback:`}</CardTitle>   
                                    <div className="table-responsive">
                                        <Table className="table table-bordered table-sm mb-0">
                                            <thead className="table-light">
                                            <tr>
                                                <th>#</th>
                                                <th>Question</th>
                                                <th>Score</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {reviews.map((review, index)=> {
                                                    return (
                                                        <tr key = {index}>
                                                            <th scope="row">{index+1}</th>
                                                            <td>{review.question}</td>
                                                            <td>{review.score}</td>
                                                        </tr>

                                                    )
                                                })}
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td></td>
                                                <td>{getTotalScore()}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>        
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                
                </>
                :
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
            }
            </div>

        </React.Fragment>
    )
}

export default CumulativeView
