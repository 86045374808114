import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { HereFromOptions } from "../InitialScreening/Options";
import { BASE_URL } from "../../helpers/url_helper";
import { get, put } from "../../helpers/api_helper";
import { toast } from 'react-toastify';
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken";


const EditCustomer = (props) => {
    const  state  = props.location.statedata
    const { id } = props.match.params
    const [hearFrom, setHearFrom] = useState({ label: "Select Option", value: null });
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)

    useEffect(() => {
        setDidMount(true);
        get(`${BASE_URL}/api/tgcl/registerusers/${id}/`, 
        { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
        )
        .then(response => {
            setUser(response.data)
            setHearFrom({ label: response.data.hear_from, value: response.data.hear_from })
        })
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
      }

    function handleSelectHearFrom(selectedhearFrom) {
        setHearFrom(selectedhearFrom);
    }

    function handleSubmit(e, values){
        let data =  {
            "fb_id": user.fb_id,
            "email": user.email,
            "pass_field": user.pass_field,
            "f_name": values.f_name,
            "l_name": values.l_name,
            "phone": values.phone,
            "address": values.address,
            "city": values.city,
            "state": values.state,
            "zip": values.zip,
            "active": user.active,
            "registration_date": user.registration_date,
            "hear_from": hearFrom.value,
            "zone": user.zone,
            'approved': document.getElementById('approved').checked,
            'archive': document.getElementById('archive').checked
        }
        put(`${BASE_URL}/api/tgcl/registerusers/${id}/`, data,
        { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false}
        )
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing Went Wrong")
            }else{
                props.history.push("/customers")
            }
        })
        
    }
    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Register Users" breadcrumbItem={"Edit User"} link="/customers" data={state} />
                {user?
                    <Row>
                        <Col md="12">
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <CardTitle>User Details </CardTitle> 
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="f_name">First Name</Label>
                                                            <AvField
                                                                name="f_name"
                                                                placeholder="First Name"
                                                                type="text"
                                                                errorMessage=" Please Enter First Name."
                                                                className="form-control"
                                                                
                                                                id="f_name"
                                                                value={user.f_name}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                    <div className="mb-3">
                                                            <Label htmlFor="l_name">Last Name</Label>
                                                            <AvField
                                                                name="l_name"
                                                                placeholder="Last Name"
                                                                type="text"
                                                                errorMessage=" Please Enter Last Name."
                                                                className="form-control"
                                                                
                                                                id="l_name"
                                                                value={user.l_name}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="email">Email</Label>
                                                            <AvField
                                                                name="email"
                                                                placeholder="Enter Email"
                                                                type="email"
                                                                errorMessage=" Please Enter Email."
                                                                className="form-control"
                                                                
                                                                id="email"
                                                                value={user.email}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                    <div className="mb-3">
                                                            <Label htmlFor="address">Address</Label>
                                                            <AvField
                                                                name="address"
                                                                placeholder="Address"
                                                                type="text"
                                                                errorMessage=" Please Enter Address."
                                                                className="form-control"
                                                                
                                                                id="address"
                                                                value={user.address}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="state">State</Label>
                                                            <AvField
                                                                name="state"
                                                                placeholder="State"
                                                                type="text"
                                                                errorMessage=" Please provide a valid State."
                                                                className="form-control"
                                                               
                                                                id="state"
                                                                value={user.state}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="city">City</Label>
                                                            <AvField
                                                                name="city"
                                                                placeholder="City"
                                                                type="text"
                                                                errorMessage=" Please provide a valid city."
                                                                className="form-control"
                                                                
                                                                id="city"
                                                                value={user.city}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row> 
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                        <Label htmlFor="zip">Zip</Label>
                                                        <AvField
                                                            name="zip"
                                                            placeholder="Zip Code"
                                                            type="text"
                                                            errorMessage=" Please provide a valid zip."
                                                            className="form-control"
                                                            
                                                            id="zip"
                                                            value={user.zip}
                                                        />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="phone"
                                                                label="Phone"
                                                                placeholder="Contact Number(+91-9999999999)"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9+-]+$",
                                                                        errorMessage: "Invalid Contact"
                                                                    },
                                                                }}
                                                                value={user.phone}
                                                                />
                                                        </div>
                                                    </Col>
                                                </Row> 
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label>How did you first hear of us?</Label>
                                                            <Select
                                                            value={hearFrom}
                                                            onChange={(e) => {
                                                                handleSelectHearFrom(e);
                                                            }}
                                                            options={HereFromOptions}
                                                            classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                    
                                                </Row> 
                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-check m-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={user.approved} id="approved"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="approved">
                                                            Approved</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-check m-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={user.archive} id="archive"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="archive">
                                                            Archived</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Button color="primary" type="submit">
                                                    Submit
                                                </Button>                          
                                        
                                            
                                            </CardBody>
                                        </Card>
                                    </Col>
                                
                                </Row>
                                
                            </AvForm>
                        </Col>
                    </Row>
                :
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
                }

            </div>

        </React.Fragment>
    )
}

export default EditCustomer
