import React, {useState, useEffect} from "react"
import ReactApexChart from "react-apexcharts"
import { Row, Col, CardBody, Card, Button } from "reactstrap"
import { Link } from "react-router-dom"

const ZipCodeSearches = (props) => {

  const [zicodesData, setZicodesData] = useState([]) 
  const [count, setcount] = useState([]) 

  useEffect(() => {
      let data = props.data
      let series = []
      let counts = []
      if(data.length > 0){
          for(let i=0 ; i< data.length; i++){
              series.push(data[i].zip)
              counts.push(data[i].count)
          }
          setZicodesData(series)
          setcount(counts)  
      }
      
    }, [props.data]);



  const series = [
    {
      name: "Zipcode",
      data: count,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    colors: ['#3b5de7'],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories:zicodesData, 
      position: "top",
      labels: {
        offsetY: 1,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val
        },
      },
    },
  }
  return (
    <Card>
      <CardBody>
        <div className="mb-4"><Link className="text-dark" to={'/analytics/zipcode-search'}><b>Top ZipCode Searches</b></Link></div>

        <ReactApexChart options={options} series={series} type="bar" height={250} />
        <Row className="mt-3">
            <Col sm={6}>
                <p className="mt-2">{props.startdate} - {props.enddate}</p>
            </Col>
            <Col sm={6}>
              <Link to={'/analytics/zipcode-search'}><Button color="light" className="btn-outline me-2 float-end btn-sm" >
                      view report <i className="mdi mdi-arrow-right-bold text-dark"></i>
                  </Button>
              </Link>
            </Col>

        </Row>
      </CardBody>
    </Card>
  )
}
export default ZipCodeSearches
