export const HereFromOptions = [
    {
      label: "Here From",
      options: [
        {value: "1080AM KRLD", label: "1080AM KRLD"},
        {value: "1310AM THE TICKET", label: "1310AM THE TICKET"},
        {value: "570AM  KLIF", label: "570AM  KLIF"},
        {value: "660AM  KSKY", label: "660AM  KSKY"},
        {value: "820AM WBAP", label: "820AM WBAP"},
        {value: "89.7 WAY-FM", label: "89.7 WAY-FM"},
        {value: "94.9 KLTY", label: "94.9 KLTY"},
        {value: "98.7 KLUV", label: "98.7 KLUV"},
        {value: "99.5 THE WOLF", label: "99.5 THE WOLF"},
        {value: "A Friend/Referral", label: "A Friend/Referral"},
        {value: "BBB", label: "BBB"},
        {value: "Cable Channel", label: "Cable Channel"},
        {value: "Contractor", label: "Contractor"},
        {value: "Email", label: "Email"},
        {value: "FOX4", label: "FOX4"},
        {value: "Facebook", label: "Facebook"},
        {value: "Mailer", label: "Mailer"},
        {value: "Other", label: "Other"},
        {value: "Other Radio", label: "Other Radio"},
        {value: "Other Social Media", label: "Other Social Media"},
        {value: "Vehicle Signage", label: "Vehicle Signage"},
        ],
    },
  ];


export const YesNoOptions = [
    {
      label: "",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
    },
  ];

export const YesNoNAOptions = [
    {
      label: "",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
        { label: "N/A", value: "n/a" },
      ],
    },
  ];