import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Spinner, Label, Modal} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//   import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { toast } from "react-toastify";
import { get, del, put, post } from "../../helpers/api_helper"
import { BASE_URL, SITE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { HeaderFormatter } from "../../helpers/methods";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const UrlRedirects = (props) => {

  const [redirectUrls, setredirectUrls] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(100)
  const [SearchText, setsearchText] = useState("")
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [loaddata, setLoadData] = useState(false)
  const [value, setValue] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false)
  const [loading, setLoading] = useState(false)

  ValidateUser(props)

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const columns = [
    {
      dataField: "id",
      text: "ID", 
      hidden: true,
    },
    {
      dataField: "old",
      text: "Old URL",
      headerFormatter:HeaderFormatter,
      sort: true,

    formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={"#"} onClick={(e)=> {setValue(row); tog_standard()}} className="text-dark">{cellContent}</Link>
        </React.Fragment>
      ),
    },
    {
        dataField: "new",
        text: "New URL",
        headerFormatter:HeaderFormatter,
        sort: true,
    },
    {
      dataField: "preview",
      isDummyField: true,
      text: "Preview",
      headerFormatter:HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
            <a href={`${SITE_URL}/${row.old}`} target="_blank" rel="noreferrer" className="text-primary me-2"><i className="mdi mdi-web font-size-18"></i></a>
        </React.Fragment>
      ),
    },



    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerFormatter:HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
            <Link to="#" onClick={() => {setValue(row);tog_standard()}} className="text-primary me-2"><i className="mdi mdi-pencil font-size-18"></i></Link>
          <Link to="#" onClick={() => {setAlert_confirm(true); setValue(row)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/dashboard/site-rerirects/?limit=${sizePerPage}`, sizePerPage, page, SearchText)
    
  }, []);


  function loadData(url , sizePerPage, page , searchText){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setredirectUrls(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
      setLoadData(true)
    })
  }

  function NoDataIndication(){
    if (loaddata && redirectUrls.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function handleSubmit(e, values){
    setLoading(true)
    if (value){
        put(`${BASE_URL}/api/tgcl/dashboard/site-rerirects/${value.id}/`, values,
        { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
        )
        .then(response => {
        if(response.status >= 400){
            console.log(response)
            toast.error("Somthing went Wrong")
            setmodal_standard(false)
            setValue(null)
            setLoading(false)
            
        }else{

            toast.success("URL Updated")
            loadData(`${BASE_URL}/api/tgcl/dashboard/site-rerirects/?limit=${sizePerPage}`, sizePerPage, page, SearchText)
            setValue(null)
            setLoading(false)
            setmodal_standard(false)
        }
    })

    }else{
        post(`${BASE_URL}/api/tgcl/dashboard/site-rerirects/`, values,
        { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false}
        )
        .then(response => {
        if(response.status >= 400){
            console.log(response)
            toast.error("Somthing went Wrong")
            setValue(null)
            setLoading(false)
            setmodal_standard(false)
            
        }else{

            toast.success("URL Added")
            loadData(`${BASE_URL}/api/tgcl/dashboard/site-rerirects/?limit=${sizePerPage}`, sizePerPage, page, SearchText)
            setValue(null)
            setLoading(false)
            setmodal_standard(false)
        }
    })

    }   
}

  function handleItineraryClicks (id) { 
  
    del(`${BASE_URL}/api/tgcl/dashboard/site-rerirects/${id}/`, 
    { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            
            let url = `${BASE_URL}/api/tgcl/dashboard/site-rerirects/?limit=${sizePerPage}&page=${1}`
            loadData(url , sizePerPage, 1 , SearchText)
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Deleted!')
            setDynamic_description("URL Has been deleted.") 
            setValue(null)
          }
      }
    )
  }
  
  function handleTableChange (type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setLoadData(false)
      setredirectUrls([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/dashboard/site-rerirects/?limit=${sizePerPage}&page=${new_page}`
      if (searchText && searchText !== ""){
          new_page = SearchText !== searchText? 1 : new_page
          url = url + `&search=${searchText}`
      }
      loadData(url , sizePerPage, new_page , searchText)
      
    }, 300);
  }
 
  const defaultSorted = [{
      dataField: 'name',
      order: 'asc'
    }];

  const { SearchBar } = Search

    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='URL Redirects' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={redirectUrls}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={redirectUrls}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={()=> {tog_standard(); setValue(null)}}
                                >
                                  <i className="mdi mdi-plus me-1" />{" "}
                                  Add New URL
                                </Button>
                              </div>
                            </Col>
                          </Row>

                          
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                              noDataIndication={ NoDataIndication }
                            />
                            
                          </div>
                          <Row>
                                <Col sm={6}>
                                  <div className="mb-3">
                                      <SizePerPageDropdownStandalone
                                        { ...paginationProps }
                                      />
                                  </div>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                              </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>

        </Row>
        {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleItineraryClicks(value.id)}
            onCancel={() =>
              { setAlert_confirm(false) 
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
        
      </div>
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{value?`Edit ${value?.old}`:`Add New Redirect URL` }  </h5>     

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
            <div className="modal-body">
              <Row>
                    <Col md="12">
                        <div className="mb-3">
                        <Label htmlFor="old">Old URL</Label>
                        <AvField
                            name="old"
                            placeholder=""
                            type="text"
                            errorMessage="Enter URL"
                            className="form-control"
                            value = {value?value.old:""}
                            validate={{ required: { value: true } }}
                            id="old"
                        />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="mb-3">
                        <Label htmlFor="new">New URL</Label>
                        <AvField
                            name="new"
                            placeholder=""
                            type="text"
                            errorMessage="Enter URL"
                            className="form-control"
                            value = {value?value.new:""}
                            validate={{ required: { value: true } }}
                            id="new"
                        />
                        </div>
                    </Col>
                </Row>
              
              
              {/* <Row>
                <Col md="6">
                    <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" defaultChecked={selected_id?selected_id.isactive === 1?true:false:false} id="isactive"
                            required />{" "}
                        <label className="form-check-label" htmlFor="isactive">
                        Active</label>

                    </div>
                </Col>
              </Row> */}
                
            </div>
            <div className="modal-footer">
            {loading?
              <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              >
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Submitting
              </button>
              :
              <Button color="primary" type="submit">
                        {value ?"Update":"Submit"}
              </Button>}
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-primary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            
          </div>
          </AvForm>

        </Modal>
      </Col>

    </React.Fragment>
  )
}

export default UrlRedirects
