import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

class TrafficSource extends Component {
  constructor(props) {
    super(props)

    this.state = {
      series: [38, 26, 14, 21],
      options: {
        labels: ["Direct", "Goggle", "Bing", "Other"],
        plotOptions: {
          pie: {
            donut: {
              size: '60%'
            }
          }
        },
        legend: {
          show: false,
        },
        colors: ['#3b5de7', '#45cb85', '#eeb902', '#ff715b'],
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Traffic Source</h4>
            <Row>
            <Col sm={12}>
                <div>
                  <Row>
                    <Col sm={6}>
                      <p className="mb-1 text-truncate"><i
                            className="mdi mdi-square text-primary me-1"></i>{" "}Direct</p>
                      
                    </Col>
                    <Col sm={6}>
                      <p className="mb-1 "><i
                            className="mdi mdi-square text-success me-1"></i>{" "}Google</p>
                    </Col>
                    <Col sm={6}>
                      <p className="mb-1 text-truncate"><i
                            className="mdi mdi-square text-warning me-1"></i>{" "}Bing</p>
                    </Col>
                    <Col sm={6}>
                    <p className="mb-1 text-truncate"><i
                            className="mdi mdi-square text-danger me-1"></i>{" "}Other</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col sm={12}>
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="donut"
                  height={245}
                  className="apex-charts"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default TrafficSource
