import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//   import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import Select from "react-select";
import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { downloadExcel } from "react-export-table-to-excel";
import { HeaderFormatter } from "../../helpers/methods";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const header = ["Zip", "Category", "Contractor" ];

const orderFields = {'contractor' : 'contractor__bus_name', 
                    'category': 'category__heading',
                    'zip': 'zip',}

var SortOrdering = {'zip': 'asc','category': 'asc','contractor' : 'asc' }

const defaultOrdering = 'zip,category__heading,contractor__bus_name'


const SearchOptions = [
  {
    label: "Search By",
    options: [
      { label: "Zip", value: 'zip'},
      { label: "Category", value: 'category__heading'},
      { label: "Contractor", value: 'contractor__bus_name'},
    ],
  },
];

const ZipSoldReport = (props) => {

  const [zipReports, setzipReports] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [Search_Text, setsearchText] = useState("")
  const [searchBy, setSearchBy] = useState({ label: "Zip", value: 'zip'})

  ValidateUser(props)

  const columns = [
    {
      dataField: "id",
      text: "Id",
      hidden: true
     },
    {
        dataField: "zip",
        text: "Zip",
        headerFormatter:HeaderFormatter,
        sort: true
    },
    {
      dataField: "category",
      text: "Category",
      headerFormatter:HeaderFormatter,
      sort: true,
    },
    {
      dataField: "contractor",
      text: "Contractor",
      headerFormatter:HeaderFormatter,
      sort: true
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/dashboard/zip-report/?page_size=${sizePerPage}`, sizePerPage, page, Search_Text)
    
  }, []);


  function loadData(url , sizePerPage, page , searchText){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setzipReports(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
    })
  }

  function handleDownloadExcel() {
    let data = []
    for (let i=0; i<zipReports.length; i++){
      data.push({"zip":zipReports[i].zip, 'category': zipReports[i].category, 'contractor':zipReports[i].contractor })
    }
    downloadExcel({
      fileName: "Zone Report",
      sheet: "zone-report",
      tablePayload: {
        header,
        // accept two different data structures
        body: data,
      },
    });
  }



  function handleTableChange (type, { sortField, sortOrder,page, sizePerPage, searchText, }) {
    SortOrdering[sortField] = sortOrder
    let ordering = ""
    for (const [key, value] of Object.entries(SortOrdering)) {

      if (sortField === key){
        if(value === "desc"){
          ordering = "-"+orderFields[key]+ (ordering === ""?"":(","+ordering))
        }else{
          ordering = orderFields[key]+(ordering === ""?"":(","+ordering))
        }

      } else{
        if(value === "desc"){
          ordering = (ordering === ""?"-":(ordering+",-")) +orderFields[key]
        }else{
          ordering = (ordering===""?"":(ordering+","))+orderFields[key]

        }

      }

    }window.scrollTo(0, 0)
    setTimeout(() => {
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/dashboard/zip-report/?page_size=${sizePerPage}&page=${new_page}&ordering=${ordering}`
      if (searchText && searchText !== ""){
          new_page = Search_Text !== searchText? 1 : new_page
          url =url + `&search=${searchText}&filed=${'zip'}`
      }
      loadData(url , sizePerPage, new_page , searchText)
    }, 500);
  }
 
  const defaultSorted = [{
      dataField: 'zip',
      order: 'desc'
    }];

  const { SearchBar } = Search

    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Zip Report' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={zipReports}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={zipReports}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            
                            {/* <Col sm="6">
                            
                              <div className="search-box mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  
                                </div>
                              </div>
                            
                            </Col> */}
                            <Col sm="8">
                              <Row>
                                <Col sm='2'>
                                  <p className="mt-2">Search by Field</p>
                                </Col>
                                <Col sm='2'>
                                  <div className="mb-3">
                                      <Select
                                      value={searchBy}
                                      onChange={(e) => {
                                          setSearchBy(e);
                                          if(Search_Text !== "" & Search_Text !== undefined){
                                            loadData(`${BASE_URL}/api/tgcl/dashboard/zip-report/?page_size=${sizePerPage}&ordering=${defaultOrdering}&search=${Search_Text}&field=${e.value}`, sizePerPage, page, Search_Text)
                                          }
                                          
                                      }}
                                      options={SearchOptions}
                                      classNamePrefix="select2-selection"
                                      />
                                    </div>
                                </Col>
                                <Col sm="4">
                                  {/* <div className="text-sm-end"> */}
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        
                                      </div>
                                    </div>
                                  {/* </div> */}

                                </Col>
                              </Row>
                            </Col>
                            <Col sm="4">
                              <Button
                                    type="button"
                                    color="primary"
                                    className="btn-rounded mb-2 float-end"
                                    onClick={() => handleDownloadExcel()}
                                    >
                                    <i className="mdi mdi-file-excel-outline me-1" />{" "}
                                    Download
                                </Button>
                              
                              
                            </Col>
                          </Row>
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                            />
                            
                          </div>
                          <Row>
                            <Col sm="6">
                              <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps} 
                                    
                                  />
                                </div>
                                <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                            </Col>
                            <Col sm="6">
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>

                            </Col>

                          </Row>
                          
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
    
        </div>

    </React.Fragment>
  )
}

export default ZipSoldReport
