import React, { useState, useEffect } from "react"

import { Row, Col, Modal, Spinner } from "reactstrap"


import { post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import moment from "moment-timezone";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"


const CallNotesCronJobs = (props) => {

  ValidateUser(props)

  const [modal_center, setmodal_center] = useState(false)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [error_confirm, setError_confirm] = useState(false)
  const [dynamic_description, setDynamic_description] = useState("")
  const [alert_confirm, setAlert_confirm] = useState(false)
  const[startDate, setStartDate]=useState("")
  const [endDate, setEndDate] = useState("")

  useEffect(() => {
    const statedata = props.location.state
    
    if (statedata){
      setStartDate(statedata.startdate)
      setEndDate(statedata.enddate)
    }else{
      let end_date = moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD')
      let start_date = moment(new Date()).subtract(3,'d').tz("America/Chicago").format('YYYY-MM-DD')
      setStartDate(start_date)
      setEndDate(end_date)
    }
    setAlert_confirm(true)
  }, []);


  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function runCronjob(startdate, enddate){
    let data = {"startdate": startdate,"enddate": enddate }
    post(`${BASE_URL}/api/tgcl/dashboard/call-notes-cron-jobs/`, data, {  validateStatus: false})
    .then(response => {
        if (response.status>= 400){
            console.log(response)
            setmodal_center(false)
            setDynamic_description("Somthing Went Wrong Please Try Again")
            setError_confirm(true)
        }else{
          setmodal_center(false)
          setDynamic_description(`Call processed between ${startdate} and ${enddate}`)
          setSuccess_confirm(true)
        }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Call Notes Cron Jobs' link="/dashboard" />
        
      </div>
      {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {tog_center(); runCronjob(startDate, endDate); setAlert_confirm(false)}}
            onCancel={() =>
              { props.history.goBack() }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}
      {success_confirm ? (
            <SweetAlert
            success
            title={"Success"}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>  props.history.goBack() }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
      {error_confirm ? (
        <SweetAlert
            danger
            title={"Error"}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>  props.history.goBack() }
            >
                {dynamic_description}
            </SweetAlert>)
            : null
          }
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_center}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Creating Call Notes</h5>
          </div>
          <div className="modal-body">
            <Row>
            <h5>Script is running Please do not close or reload this page</h5>
            <div className="container d-flex align-items-center justify-content-center">
              <Spinner className="mt-4 mb-3" color="primary" />
            </div>
            
    
            </Row>
          
          </div>
        </Modal>

      </Col>

    </React.Fragment>
  )
}

export default CallNotesCronJobs

