import React, {useState, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import moment from "moment-timezone"

// const options = {
//     chart: {
//         sparkline: {
//             enabled: !0
//         }
//         ,
//         toolbar: {
//             show: !1
//         }
//         ,
//     }
//     ,
//     dataLabels: {
//         enabled: !1
//     }
//     ,
//     stroke: {
//         curve: 'smooth', width: 3
//     }
//     ,
//     colors: ['#eeb9025e'],
// }
const options = {
    chart: {
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    colors: ['#eeb9025e'],
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'smooth',
        width: '3',
        // dashArray: [4, 0],
    },

    markers: {
        size: 3
    },
    xaxis: {
        categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        title: {
            text: 'Weeks'
        }
    },

    fill: {
        type: 'solid',
        opacity: [1, 0.1],
    },

    legend: {
        position: 'top',
        horizontalAlign: 'right',
    }
}

const ContractorReport = ({ Contractor_Report, Contractor }) => {


    const [data, setData] = useState([0,0,0,0,0])
    const [contractor, setContractor] = useState(0)

    
    useEffect(() => {
        if(Contractor){
            setContractor(Contractor);
        }
       
    }, [Contractor]) 
    
    useEffect(() => {
        if(Contractor_Report){
            let data = []
            for(let i=0; i<Contractor_Report.length; i++){
                data.push(Contractor_Report[i].new_contractor)
            }
            setData(data)
        }
       
    }, [Contractor_Report])// now this listens to changes in contact

    const series = [{
        name: "Week",
        data: data,
        type: 'area',
    }
    ]


    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Contractors Report</h4>
                    <Row>
                        <Col sm={3}>
                            <div>
                                {Contractor_Report?
                                <h6 className="mb-2">{moment(Contractor_Report[0].from).tz("America/Chicago").format('ll')}- {moment(Contractor_Report[(Contractor_Report.length)-1].to).tz("America/Chicago").format('ll')}</h6>
                                :null
                                }
                                
                                <h4>{contractor} New Contractors</h4>
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div className="mt-4 mt-sm-0">
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="line"
                                    height="260"
                                    className="apex-charts"
                                />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
export default ContractorReport