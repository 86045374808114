import React, { useState, useEffect } from "react";

import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { toast } from "react-toastify";
import { BASE_URL } from "../../../helpers/url_helper";
import { get } from "../../../helpers/api_helper";

import { ValidateUser } from "../../../helpers/validate_user";
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken"


const EditHighlight = (props) => {
    
    const { id } = props.match.params;
    const [highlight, setHighlight] = useState([]);
    const [didMount, setDidMount] = useState(false); 
    const [file, setFile] = useState(null);
    

      ValidateUser(props)

    useEffect(() => {
        setDidMount(true);
        let data = get(`${BASE_URL}/api/tgcl/highlights/${id}/`, 
        { headers: { 'Content-Type': 'application/json', },  validateStatus: false}  
        )
        data.then(response => {
            setHighlight(response.data)
        })
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
    }

    function handleChange(e) {
        setFile(e.target.files[0]);
        // URL.createObjectURL
    }

    function handleSubmit(e, values){
        const formData = new FormData();
        if (file){
          formData.append('image', file, file.name) 
        }

        formData.append("name",values.name)

        const options = {
            method: 'PUT',
            body: formData,
            headers: {'Authorization': getAccessToken() }
        };
        fetch(`${BASE_URL}/api/tgcl/highlights/${id}/`, options)
        .then(res => 
            {if(res.status >= 400){
                res.json()
                .then(
                    (data) =>{  
                    console.log(data, "---data")
                    toast.error("Somthing went wrong ")

                    }  
                )
            }else{
                res.json()
                .then(
                    (data) =>{
                        setHighlight(data)
                        setFile(null)
                        toast.success(`${data.name} has been updated`)
                        }
                    )
                }    
            }
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Highlights" breadcrumbItem={`Edit ${highlight?highlight.name:"Highlight"} `} link="/highlights" />
                {highlight?
                <Row>
                    <Col className="col-6">
                        <Card>
                        <CardBody>
                            <CardTitle>Highlight Detail </CardTitle>                  
                            <AvForm className="needs-validation" onValidSubmit={(e, values)=>handleSubmit(e, values)}>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label htmlFor="name">Name</Label>
                                        <AvField
                                            name="name"
                                            placeholder="Name"
                                            type="text"
                                            errorMessage="Enter Category"
                                            className="form-control"
                                            value = {highlight?.name}
                                            validate={{ required: { value: true } }}
                                            id="name"
                                        />
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row>
                                <Label htmlFor="icon">Icon</Label>
                                <Col sm={6}>
                                    {file?
                                        <img src={URL.createObjectURL(file)} style={{width:"40%"}} className="m-3"/>
                                    : highlight.image?
                                        <img src={highlight.image} className="rounded avatar-lg" alt="" />
                                    :null}

                                </Col>
                                <Col sm={6}>
                                    <input id="icon" className="mb-3" type="file" onChange={handleChange} />
                                </Col>
                                
                                
                                
                                </Row>
                                <Button color="primary" type="submit">
                                Submit
                                </Button>
                            </AvForm>
                        
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
                :
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
            }
            </div>

        </React.Fragment>
    )
}

export default EditHighlight
