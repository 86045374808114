import React, { useEffect, useState } from "react"
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"

import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert";
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { BASE_URL } from "../../helpers/url_helper";
import { get, del } from "../../helpers/api_helper"
import { ValidateUser } from "../../helpers/validate_user";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"



const InitialScreening = (props) => {
  const [didMount, setDidMount] = useState(false); 
  const [screening, setScreening] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [searchText, setsearchText] = useState("")
  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [loaddata, setloaddata] = useState(false)

  
  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "company",
      text: "Company Name",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/edit-initial-screening/${row.id}`} className="me-3 text-dark">{(cellContent).substring(0,30)}</Link>     
      ),
      title: (cell) => `${cell}`
    },
    {
      dataField: "contact",
      text: "Business Name",
      sort: true
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cellContent) => (
        (cellContent).substring(0,30)
      ),
      title: (cell) => `${cell}`
    },
    {
      dataField: "phone",
      text: "Trecking Phone #1",
    },
    {
      dataField: "hear_from",
      text: "Found Us",
    },
    {
      dataField: "url",
      text: "Site",
      formatter: (cellContent) => (
        <a href={`${cellContent}`} target="_blank" className="me-3 text-primary"><i className="mdi mdi-search-web font-size-18"></i></a>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row.id)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  ValidateUser(props)
  
  useEffect(() => {
    setDidMount(true);
    // loadData(`${BASE_URL}/api/tgcl/screening/`, sizePerPage, page, searchText)
    return () => setDidMount(false);
  }, []);

  if(!didMount) {
    return null;
  }


  function loadData(url , sizePerPage, page , searchText){
    get(url, { headers: { 'Content-Type': 'application/json', }}, )
    .then(response => {
      setScreening(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
      setloaddata(true)
    })
  }

  function NoDataIndication(){
    if (loaddata && screening.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function handleScreeningClicks (id) { 
    
    del(`${BASE_URL}/api/tgcl/screening/${id}/`,
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
    )
    .then(res => 
        
        { if(res.status >= 400){
          console.log("error")
        }
        else{
          let new_page = page
          if(screening.length < 2){
            new_page = new_page>1? new_page-1:1
          }
          let url = `${BASE_URL}/api/tgcl/screening/?page_size=${sizePerPage}&page=${new_page}`
          url = searchText && searchText !== "" ? url+`&search=${searchText}`: url
          loadData(url , sizePerPage, new_page , searchText)
          setSuccess_confirm(true)
          setAlert_confirm(false)
          setDynamic_title('Deleted!')
          setDynamic_description("Screening Form Data Has been deleted.") 
          setSelected_id(null)
        }
      }
    )
  }

  function handleTableChange (type, { page, sizePerPage, search_text, }) {
    setTimeout(() => {
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/screening/?page_size=${sizePerPage}&&page=${new_page}`
      if (search_text && search_text !== ""){
        new_page = searchText !== search_text? 1 : new_page
        url = `${BASE_URL}/api/tgcl/screening/?page_size=${sizePerPage}&page=${new_page}&search=${search_text}`
      }
      window.scrollTo(0, 0)
      loadData(url , sizePerPage, new_page , search_text)
    }, 1000);
  }

  const { SearchBar } = Search

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    custom: true,
    }

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Initial Screening Form' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={screening}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={screening}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                          
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                              noDataIndication={NoDataIndication}
                            />
                            
                          </div>
                          <Row>
                            <Col sm={6}>
                              <div className="pagination pagination-rounded mb-2">
                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                              </div>
                              
                            </Col>
                            <Col sm={6}>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                              
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert_confirm ? (
          <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleScreeningClicks(selected_id)}
          onCancel={() =>
            { setAlert_confirm(false) 
              setSelected_id(null)
            }
          }
          >
          You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {success_confirm ? (
          <SweetAlert
          success
          title={dynamic_title}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => 
            { setSuccess_confirm(false) 
              setAlert_confirm(false)
            }
          }
          >
              {dynamic_description}
          </SweetAlert>
          )
          : null
        }
      </div>

    </React.Fragment>
  )
}

export default InitialScreening
