import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
  Modal,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import { slugify } from "../../../helpers/helper_methods";
import { toast } from "react-toastify";
import { BASE_URL, SITE_URL } from "../../../helpers/url_helper";
import { get, del, patch, post } from "../../../helpers/api_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditPage = (props) => {
  const { id } = props.match.params;
  const [page, setPage] = useState(null);
  const [selectedCategory, setselectedCategory] = useState({
    label: "Select Category",
    value: null,
  });
  const [selectedState, setselectedState] = useState({
    label: "Select State",
    value: null,
  });
  const [selectedCounty, setselectedCounty] = useState({
    label: "Select County",
    value: null,
  });
  const [selectedCity, setselectedCity] = useState({
    label: "Select City",
    value: null,
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [locationPageContents, setLocationPageContents] = useState([]);
  const [loading, setLoading] = useState(false);

  const [submitting, setSubmitting] = useState(null);

  const [success_confirm, setSuccess_confirm] = useState(false);
  const [alert_confirm, setAlert_confirm] = useState(false);
  const [selected_id, setSelected_id] = useState(null);
  const [dynamic_title, setDynamic_title] = useState("");
  const [dynamic_description, setDynamic_description] = useState("");
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);

  const [rawMessage, setrawMessage] = useState("");
  const [stateChange, setStateChange] = useState("editor");
  const [showHTMLChange, setShowHTMLChange] = useState(false);

  const [file, setFile] = useState(null);
  const [didMount, setDidMount] = useState(false);

  const Categories = [
    {
      label: "Categoties",
      options: [],
    },
  ];

  const StateOptions = [
    {
      label: "State",
      options: stateOptions,
    },
  ];
  
  const CityOptions = [
    {
      label: "City",
      options: cityOptions,
    },
  ];
  ValidateUser(props);

  useEffect(() => {
    setDidMount(true);

    LoadPageData(id);
    loadStateData();
    return () => setDidMount(false);
  }, [props]);

  if (!didMount) {
    return null;
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function handleContentEdit(content) {
    setrawMessage(content);
  }

  function handleEditorChange(editor) {
    const editor_Data = editor.getData();
    setrawMessage(editor_Data);
  }

  function handleChange(e) {
    setFile(e.target.files[0]);
  }

  function loadLocationPageContent(cat_slug, city_slug) {
    get(
      `${BASE_URL}/api/tgcl/dashboard/location-page-content/?page_size=${20}&page=${1}&city__slug=${city_slug}&category__slug=${cat_slug}`,
      { headers: { "Content-Type": "application/json" } }
    ).then((response) => {
      setLocationPageContents(response.data.results);
    });
  }

  function handleDeleteContent(id) {
    del(
      `${BASE_URL}/api/tgcl/dashboard/location-page-content/${id}/`,
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}

    ).then((res) => {
      if (res.status >= 400) {
        console.log("error");
      } else {
        loadLocationPageContent(page.category.slug, page.city.slug);

        setSuccess_confirm(true);
        setAlert_confirm(false);
        setDynamic_title("Deleted!");
        setDynamic_description("Content Has been deleted.");
        setSelected_id(null);
      }
    });
  }

  function LoadPageData(id) {
    let data = get(`${BASE_URL}/api/tgcl/dashboard/pages/${id}/`, {
      headers: { "Content-Type": "application/json" },
      validateStatus: false,
    });
    data.then((response) => {
      loadLocationPageContent(
        response.data.category.slug,
        response.data.city.slug
      );
      setselectedCategory({
        label: response.data.category.heading,
        value: response.data.category.id_num,
      });
      setselectedState({
        label: response.data.state.state,
        value: response.data.state,
      });

      if (response.data.category.parent_id) {
        // loadCountyData(response.data.state.id_num);
        loadCityData(response.data.county.id);
        setselectedCounty({
          label: response.data.city.county.name,
          value: response.data.city.county.id,
        });
        setselectedCity({
          label: response.data.city.name,
          value: response.data.city,
        });
      }

      setPage(response.data);
    });
  }
  function handleSubmit(e, values) {
    const formData = new FormData();
    console.log(selectedState, "selectedState")
    setLoading(true);
    if (file) {
      formData.append("image", file, file.name);
    }
    if (page.category.parent_id) {
      console.log(selectedCity, "selectedCity");
      formData.append("county", selectedCity.value.county.id);
      formData.append("city", selectedCity.value.id);
    }
    formData.append("state", selectedState.value.id_num);
    formData.append("category", selectedCategory.value);
    formData.append("pg_title", values.pg_title);
    formData.append("pg_desc", values.pg_desc);
    formData.append("keyword", values.keyword);
    formData.append("slug", values.slug);
    formData.append("description", values.description);
    formData.append("follow", document.getElementById("follow").checked);
    formData.append("index", document.getElementById("index").checked);
    formData.append("canonical", values.canonical);

    // Iterate over the form data and log it to the console
    let jsonData = {};

    if (page.category.parent_id) {
      console.log(selectedCity, "selectedCity");
      jsonData.county = selectedCity.value.county.id;
      jsonData.city = selectedCity.value.id;
    }

    jsonData.state = selectedState.value.id;
    jsonData.category = selectedCategory.value;
    jsonData.pg_title = values.pg_title;
    jsonData.pg_desc = values.pg_desc;
    jsonData.keyword = values.keyword;
    jsonData.slug = values.slug;
    jsonData.description = values.description;
    jsonData.follow = document.getElementById("follow").checked;
    jsonData.index = document.getElementById("index").checked;
    jsonData.canonical = values.canonical;

    let jsonString = JSON.stringify(jsonData);
    console.log(jsonString);

    const options = {
      method: "PATCH",
      body: formData,
      headers: { Authorization: getAccessToken() },
    };
    fetch(`${BASE_URL}/api/tgcl/dashboard/pages/${id}/`, options).then(
      (res) => {
        if (res.status >= 400) {
          res.json().then((data) => {
            toast.error("Somthing Went wrong");
            setLoading(false);
          });
        } else {
          res.json().then((data) => {
            setPage(data);
            setFile(null);
            toast.success(`${data.category.heading} has been updated`);
            setLoading(false);
          });
        }
      }
    );
  }

  function handleUpdateContent(e, values) {
    let data = {};
    data = {
      content: rawMessage,
      index: values.index,
      sub_cat: document.getElementById("sub_cat").checked,
      category: selectedCategory.value,
      heading: values.title,
      city: selectedCity.value.id,
    };
    setSubmitting(true);
    if (selected_id) {
      patch(
        `${BASE_URL}/api/tgcl/dashboard/location-page-content/${selected_id.id}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAccessToken(),
          },
          validateStatus: false,
        }
      ).then((response) => {
        if (response.status >= 400) {
          console.log(response);
          let errors = Object.entries(response.data);
          errors.map(([key, val] = errors) => toast.error(val[0]));
          setSubmitting(false);
        } else {
          loadLocationPageContent(page.category.slug, page.city.slug);
          setSubmitting(false);
          toast.success("Page Content Updated");
          tog_fullscreen();
        }
      });
    } else {
      post(`${BASE_URL}/api/tgcl/dashboard/location-page-content/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
        validateStatus: false,
      }).then((response) => {
        if (response.status >= 400) {
          console.log(response);
          let errors = Object.entries(response.data);
          errors.map(([key, val] = errors) => toast.error(val[0]));
          setSubmitting(false);
        } else {
          loadLocationPageContent(page.category.slug, page.city.slug);
          setSubmitting(false);
          toast.success("Page Content Added");
          tog_fullscreen();
        }
      });
    }
  }

  function generateSlug(category, city) {
    if (category && city)
      return `${slugify(category)}-${slugify(city)}-${slugify(
        selectedState.value.abbr
      )}`;
    // setselectedLocation(e)
  }

  function loadStateData() {
    get(`${BASE_URL}/api/tgcl/state/?page_size=200`, {
      headers: { "Content-Type": "application/json" },
      validateStatus: false,
    }).then((response) => {
      let options = [];
      let states = response.data.results;
      for (let i = 0; i < states.length; i++) {
        options.push({ label: `${states[i].state}`, value: states[i] });
        if (states[i].abbr === "TX") {
        }
      }
      setStateOptions(options);
    });
  }

  async function loadCityData(state_abbr) {
    await get(
      `${BASE_URL}/api/tgcl/city/?state_abbr=${state_abbr}&page_size=200`,
      {
        headers: { "Content-Type": "application/json" },
        validateStatus: false,
      }
    ).then((response) => {
      let options = [];
      let cities = response.data.results;
      for (let i = 0; i < cities.length; i++) {
        options.push({ label: `${cities[i].name}`, value: cities[i] });
      }
      setCityOptions(options);
    });
  }

  function handleSelectedCategory(e) {
    setselectedCategory(e);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title="Location Pages"
          breadcrumbItem={`Edit ${page ? page.category.heading : " Page"} `}
          link="/location-pages"
        />
        {page ? (
          <Row>
            <Col className="col-6">
              <Card>
                <CardBody>
                  <CardTitle>Page Detail</CardTitle>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={handleSubmit}
                  >
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            value={selectedCategory}
                            onChange={(e) => {
                              handleSelectedCategory(e);
                            }}
                            options={Categories}
                            classNamePrefix="select2-selection"
                            // isDisabled = {true}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            value={selectedState}
                            onChange={(e) => {
                              setselectedState(e);
                              // loadCountyData(e.value);
                              loadCityData(e.value.abbr);
                              setselectedCity({
                                label: "Select City",
                                value: null,
                              });
                            }}
                            options={StateOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label>County</Label>
                          <Select
                            value={selectedCounty}
                            onChange={(e) => {
                              setselectedCounty(e);
                              loadCityData(e.value);
                              setselectedCity({
                                label: "Select City",
                                value: null,
                              });
                            }}
                            options={CountyOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label>City</Label>
                          <Select
                            // onInputChange = {(e)=> loadLocations(e)}
                            value={selectedCity}
                            onChange={(e) => {
                              setselectedCity(e);
                              generateSlug(selectedCategory.label, e.label);
                            }}
                            options={CityOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    {selectedCategory.value ? (
                      <>
                        <Row>
                          <Col md="12">
                            <div className="mb-3">
                              <Label htmlFor="name">Slug</Label>
                              <AvField
                                name="slug"
                                placeholder="Page Slug"
                                type="text"
                                errorMessage="Enter Slug"
                                className="form-control"
                                value={page.slug}
                                validate={{ required: { value: true } }}
                                id="slug"
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <AvField
                            className="mb-3"
                            type="textarea"
                            label="Description "
                            rows="3"
                            name="description"
                            id="description"
                            placeholder="Description"
                            value={page?.description}
                          />
                        </Row>
                        <Row>
                          <Col md="12">
                            <div className="mb-3">
                              <Label htmlFor="pg_title">Meta Title </Label>
                              <AvField
                                name="pg_title"
                                placeholder="Meta Title "
                                type="text"
                                className="form-control"
                                id="pg_title"
                                validate={{ required: { value: true } }}
                                value={page?.pg_title}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <AvField
                            className="mb-3"
                            type="textarea"
                            label="Meta Description "
                            rows="3"
                            name="pg_desc"
                            id="pg_desc"
                            placeholder="Meta Description"
                            value={page?.pg_desc}
                          />
                        </Row>
                        <Row>
                          <AvField
                            className="mb-3"
                            type="textarea"
                            label="Keyword"
                            rows="3"
                            name="keyword"
                            id="keyword"
                            placeholder="Keyword"
                            errorMessage="This value is required."
                            validate={{
                              required: { value: true },
                            }}
                            value={page?.keyword}
                          />
                        </Row>
                        <Row>
                          <Col md="12">
                            <div className="mb-3">
                              <Label htmlFor="name">Canonical</Label>
                              <AvField
                                name="canonical"
                                placeholder="Canonical"
                                type="text"
                                errorMessage="Enter Canonical"
                                className="form-control"
                                value={page?.canonical}
                                validate={{ required: { value: true } }}
                                id="canonical"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                defaultChecked={page.follow}
                                type="checkbox"
                                id="follow"
                                required
                              />{" "}
                              <label
                                className="form-check-label"
                                htmlFor="follow"
                              >
                                Follow
                              </label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultChecked={page.index}
                                id="index"
                                required
                              />{" "}
                              <label
                                className="form-check-label"
                                htmlFor="index"
                              >
                                Active
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Label htmlFor="icon">Background Image</Label>
                          <Col sm={6}>
                            <input
                              id="icon"
                              className="mb-3"
                              type="file"
                              accept="image/png, image/jpeg, image/jpeg"
                              onChange={handleChange}
                            />
                          </Col>

                          <Col sm={6}>
                            {file ? (
                              <img
                                src={URL.createObjectURL(file)}
                                className="rounded img-thumbnail mb-3"
                                alt=""
                              />
                            ) : page.image ? (
                              <img
                                src={page.image}
                                className="rounded img-thumbnail mb-3"
                                alt=""
                              />
                            ) : null}
                          </Col>
                        </Row>

                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      </>
                    ) : null}
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-6">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <CardTitle>Manage Location Page Content </CardTitle>
                    </Col>
                    <Col md="6">
                      <Link
                        to={"#"}
                        onClick={() => {
                          tog_fullscreen();
                          setrawMessage("");
                          setSelected_id(null);
                        }}
                        className="me-3 text-primary float-end"
                      >
                        <i className="mdi mdi-plus font-size-18"></i> Add New
                        Content
                      </Link>
                    </Col>
                  </Row>

                  {locationPageContents.map((content) => (
                    <React.Fragment key={content.index}>
                      <hr />

                      <Row className="mt-2">
                        <Col md="6">
                          <p>Index: {content.index}</p>
                        </Col>
                        <Col md="6">
                          <p>
                            Sub Categories: {content.sub_cat ? "Yes" : "No"}
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <h4>{content.heading}</h4>
                          {content.content
                            .replace(/<[^>]+>/g, "")
                            .substring(0, 400)}{" "}
                          ...
                        </Col>
                        <Col md="12">
                          <div className="float-end">
                            <a
                              href={`${SITE_URL}/location/${page.city.slug}/${page.category.slug}/`}
                              target="_blank"
                              rel="noreferrer"
                              className="text-dark"
                            >
                              <u>View</u>
                            </a>
                            {" | "}
                            <Link
                              to={"#"}
                              onClick={() => {
                                tog_fullscreen();
                                handleContentEdit(content.content);
                                setSelected_id(content);
                              }}
                              className="text-dark"
                            >
                              {" "}
                              <u>Edit</u>
                            </Link>
                            {" | "}
                            <Link
                              to="#"
                              onClick={() => {
                                setAlert_confirm(true);
                                setSelected_id(content);
                              }}
                              className="text-dark"
                            >
                              <u>Delete</u>{" "}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Row></Row>
                    </React.Fragment>
                  ))}
                </CardBody>
              </Card>
            </Col>
            <Col sm={6} md={4} xl={3}>
              <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => {
                  tog_fullscreen();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    {selected_id ? "Update Page Content" : "Add Page Content"}
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_fullscreen(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col className="col-12">
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={handleUpdateContent}
                      >
                        <Row>
                          <Col md="12">
                            <div className="mb-3">
                              <Label htmlFor="name">Title</Label>
                              <AvField
                                name="title"
                                placeholder="Title"
                                type="text"
                                errorMessage="Enter Title"
                                className="form-control"
                                value={selected_id?.heading}
                                validate={{ required: { value: true } }}
                                id="title"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <div className="mb-3">
                              <Label htmlFor="name">Position</Label>
                              <AvField
                                name="index"
                                placeholder="Position"
                                type="text"
                                className="form-control"
                                value={
                                  selected_id ? selected_id?.index + "" : ""
                                }
                                validate={{
                                  number: true,
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter position",
                                  },
                                }}
                                id="index"
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col md="6">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultChecked={selected_id?.sub_cat}
                                id="sub_cat"
                                required
                              />{" "}
                              <label
                                className="form-check-label"
                                htmlFor="sub_cat"
                              >
                                Show Sub Categories
                              </label>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(e) =>
                                  setShowHTMLChange(e.target.checked)
                                }
                                id="html_content"
                                required
                              />{" "}
                              <label
                                className="form-check-label"
                                htmlFor="html_content"
                              >
                                Show HTML Content
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={showHTMLChange ? "6" : "12"}
                            className="mb-3"
                          >
                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                heading: {
                                  options: [
                                    {
                                      model: "paragraph",
                                      title: "Paragraph",
                                      class: "ck-heading_paragraph",
                                    },
                                    {
                                      model: "heading3",
                                      view: "h3",
                                      title: "Heading 3",
                                      class: "ck-heading_heading3",
                                    },
                                    {
                                      model: "heading4",
                                      view: "h4",
                                      title: "Heading 4",
                                      class: "ck-heading_heading4",
                                    },
                                  ],
                                },
                              }}
                              data={rawMessage}
                              onChange={(event, editor) => {
                                handleEditorChange(editor);
                              }}
                              onFocus={(event, editor) => {
                                setStateChange("editor");
                              }}
                            />
                          </Col>
                          {showHTMLChange ? (
                            <Col md="6">
                              {stateChange === "text" ? (
                                <AvField
                                  className="mb-3"
                                  type="textarea"
                                  label="HTML Content "
                                  rows="16"
                                  name="pg_desc"
                                  id="page_description"
                                  // value={rawMessage}
                                  onChange={(e) => {
                                    setrawMessage(e.target.value);
                                    setStateChange("text");
                                  }}
                                  placeholder="HTML Content"
                                />
                              ) : (
                                <AvField
                                  className="mb-3"
                                  type="textarea"
                                  // label="HTML Content "
                                  rows="16"
                                  name="pg_desc"
                                  id="page_description"
                                  value={rawMessage}
                                  onChange={(e) => {
                                    setrawMessage(e.target.value);
                                    setStateChange("text");
                                  }}
                                  placeholder="HTML Content"
                                />
                              )}
                            </Col>
                          ) : null}
                        </Row>
                        <p style={{ color: "green" }}>
                          {" "}
                          Use (mobile_read_more) for mobile readmore and
                          (desktop_read_more) for desktop and tab readmore
                        </p>
                        <p style={{ color: "green" }}>
                          {" "}
                          Use (dynamic_location) to show location and
                          (dynamic_category) to show category name
                        </p>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setmodal_fullscreen(false)}
                          >
                            Close
                          </button>
                          {submitting ? (
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light my-3"
                            >
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                              Submitting
                            </button>
                          ) : (
                            <Button color="primary my-3" type="submit">
                              Submit
                            </Button>
                          )}
                        </div>
                      </AvForm>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </Col>
          </Row>
        ) : (
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      {alert_confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDeleteContent(selected_id.id)}
          onCancel={() => {
            setAlert_confirm(false);
            setSelected_id(null);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {success_confirm ? (
        <SweetAlert
          success
          title={dynamic_title}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setSuccess_confirm(false);
            setAlert_confirm(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
};

export default EditPage;
