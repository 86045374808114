import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Label, Modal,Spinner} from "reactstrap"
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert";
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { AvForm, AvField } from "availity-reactstrap-validation"

import { get, post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { HeaderFormatter } from "../../helpers/methods";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import FileSaver from "file-saver";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"


const CallRepots = (props) => {
  ValidateUser(props)

  const [users, setUsers] = useState([]);
  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [loaddata, setLoadData] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [startdate, setStartDate] = useState(moment(new Date()).subtract(3,'d').tz("America/Chicago").format('YYYY-MM-DD'))
  const [enddate, setEnddate] = useState(moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD'))
  const [type, setType] = useState("")
  const [modal_center, setmodal_center] = useState(false)
  const [audio, setAudio] = useState("")

  const columns = [
    {
      dataField: "sid",
      text: "sid",
      hidden: true
    },
    {
        dataField: "start_time",
        text: "Date",
        headerFormatter :HeaderFormatter,
        formatter: (cellContent) => (
          moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY hh:mm A')
        ),
        editable: false
    },
    {
      dataField: "to",
      text: "Tracking # Called",
      headerFormatter :HeaderFormatter,
      formatter: (cellContent) => (
        cellContent.replace("+1", "")  
      ),
      editable: false
    },
    {
      dataField: "from_num",
      text: "Caller ID",
      headerFormatter :HeaderFormatter,
      formatter: (cellContent) => (
        cellContent.replace("+1", "")
      ),
      editable: false
    },
    
    {
        dataField: "contractor",
        text: "Contractor",
        headerFormatter :HeaderFormatter,
        editable: false,
        formatter: (cellContent) => (
          <Link to="#" className="me-3 text-dark">{(cellContent?cellContent:"").substring(0,10)}</Link>     
          ),
          title: (cell) => cell
    },
    {
        dataField: "status",
        text: "Status",
        headerFormatter :HeaderFormatter,
        editable: false
    },
    {
        dataField: "duration",
        text: "Duration (Sec.)",
        headerFormatter :HeaderFormatter,
        editable: false
    },
    {
      dataField: "recording",
      isDummyField: true,
      text: "Recording",
      headerFormatter :HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={'#'} onClick={()=> getRecording(row.sid, 0)} className="me-3 text-primary"><i className="fas fa-play-circle font-size-18"></i></Link>
          <Link to={'#'} onClick={()=> getRecording(row.sid, 1)} className="me-3 text-primary"><i className="fas fa-save font-size-18"></i></Link>
         
        </React.Fragment>
      ),
    },
    {
      dataField: "reviewed",
      text: "Reviewed",
      formatter: (cellContent, row) => (
        <input type='checkbox' defaultChecked={cellContent} onChange={(e)=> changeReviewed(e.target.checked, row.sid )}></input>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row); setType("Archive")}} className="text-warning"><i className="mdi mdi-archive-arrow-down font-size-18"></i></Link>

          <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row); setType("Delete")}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    loadData(startdate, enddate)
    
  }, []);


  function loadData(start_date, end_date){
    setLoadData(false)
    setUsers([])
    console.log(`${BASE_URL}/api/tgcl/dashboard/call-tracking/?startdate=${start_date}&enddate=${end_date}&page_size=10`)  
    let data = get(`${BASE_URL}/api/tgcl/dashboard/call-tracking/?startdate=${start_date}&enddate=${end_date}&page_size=10`, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setUsers(response.data)
      setLoadData(true)
    })
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function  changeReviewed(value, sid){
    // 
    let data = {"sid": sid,}
    if (value){
      data["status"] = 1
    }else{
      data["status"] = 0
    }
    post(`${BASE_URL}/api/tgcl/dashboard/update-reviewed/`, data, { headers: { 'Content-Type': 'application/json', }} )
    .then(response => {
      if (response.status< 300){
        loadData(startdate, enddate)
      }
    })
  }

  function getRecording(sid, download){
    get(`${BASE_URL}/api/tgcl/dashboard/get-recording/?sid=${sid}`, { headers: { 'Content-Type': 'application/json', }} )
    .then(response => {
      if (response.status< 300){
        let media_url = response.data.recordings[0]
        if (media_url){
          media_url = media_url.media_url
          if (download === 0){
            setAudio(`${media_url}.mp3`)
            tog_center()
          }
          else{
            let name = media_url.split("/")
            name = name[name.length-1]
            FileSaver.saveAs(`${media_url}.mp3`, `${name}.mp3`);

          }

        }else{
          alert("Audio Not Found")
        }
      }
    })

  }

  function handleItineraryClicks (row) { 
    let date = new Date(row.start_time) 
    let data = {
      "start_time": row.start_time,
      "to": row.to,
      "from_field": row.from_num,
      "status": row.status,
      "duration": row.duration,
      "sid": row.sid,
      "created": `${date.getFullYear()}-${(date.getMonth())+1}-${date.getDate()}`,
      "bucket": "twilioarchivebh"
    }
    if (type === "Archive"){
        data['del_field'] = 0
        post(`${BASE_URL}/api/tgcl/callarchive/`, data,
          { headers: { 'Content-Type': 'application/json', }},
          {validateStatus: false}
        )
        .then(res => 
              {if(res.status >= 400){
                console.log("error")
              }
              else{
                loadData(startdate, enddate)
                setSuccess_confirm(true)
                setAlert_confirm(false)
                setDynamic_title(type)
                setDynamic_description("Call Has been Archived.") 
                setSelected_id(null)
              }
          }
        )

    }else{
        data['del_field'] = 1
        post(`${BASE_URL}/api/tgcl/callarchive/`, data,
          { headers: { 'Content-Type': 'application/json', }},
          {validateStatus: false}
        )
        .then(res => 
              {if(res.status >= 400){
                console.log("error")
              }
              else{
                loadData(startdate, enddate)
                setSuccess_confirm(true)
                setAlert_confirm(false)
                setDynamic_title(type)
                setDynamic_description("Call Has been deleted.") 
                setSelected_id(null)
              }
          }
        )
    }
  }

  function handleFilter(e, values){
    if ((new Date(values.enddate)) > (new Date(values.startdate))){
      loadData(values.startdate, values.enddate)
    }else{
      toast.error(`To date must be grater than from Date`)
    }
    
  }

  function NoDataIndication(){
    if (loaddata && users.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];


    const { SearchBar } = Search

    //pagination customization
    const pageOptions = {
        sizePerPage: 20,
        totalSize: users.length, // replace later with size(Order),
        custom: true,
      }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Call Tracking Report' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
              <AvForm className="needs-validation" onValidSubmit={handleFilter}>
                <Row>
                
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <Row className="mb-3">
                          <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                          <div className="col-md-8">
                          <AvField
                            name="startdate"
                            placeholder="To Date"
                            type="date"
                            errorMessage="To Date"
                            className="form-control"
                            value={startdate}
                            onChange={(e)=> setStartDate(e.target.value)}
                            id="to_date"
                            
                          />
                        </div>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mb-3">
                          <Label htmlFor="email_bcc" className="col-md-4 text-center col-form-label">To</Label>
                          <div className="col-md-8">
                          <AvField
                            name="enddate"
                            placeholder="To Date"
                            type="date"
                            errorMessage="To Date"
                            className="form-control"
                            value={enddate}
                            onChange={(e)=> setEnddate(e.target.value)}
                            id="to_date"
                          />
                        </div>
                        </Row>
                      </Col>
                      
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Button type="submit" color="success" className="btn-rounded me-2" >
                      Filter
                    </Button>
                    {" "}
                    <Link rel="noreferrer"  to={{ pathname: "/call-notes-cron-job", state: { startdate: startdate, enddate:enddate } }}>
  
    
                      <Button type="button" color="primary"   className="btn-rounded me-2" >
                        Create Notes
                      </Button>
                    </Link>
                  </Col>
                  
                </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={users}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="sid"
                      data={users}
                      columns={columns}
                      bootstrap5
                      search
                    >
                      
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="8">
                              
                            </Col>
                            <Col sm="4">
                              <div className="text-sm-end">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  
                                </div>
                              </div>
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive">

                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              noDataIndication={() => NoDataIndication()}
                              
                            />
                            
                          </div>
                          <Row>
                                <Col sm={6}>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                              </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
            {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleItineraryClicks(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
      </div>
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center()
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Recording</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={10}>
                <div>
                  <audio autoPlay controls >
                
                    <source src={audio} type="audio/mp3" />
                    Your browser does not support the audio tag.
                  </audio>

                </div>
              

              </Col>
            </Row>
          
          </div>
        </Modal>

      </Col>

    </React.Fragment>
  )
}

export default CallRepots

