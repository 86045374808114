import React, { useState, useEffect } from "react"
import ContractorDetailData from "./ContractorDetailData";
import VerticalLayout from "../../../components/VerticalLayout"
import { post } from "../../../helpers/api_helper"
import { BASE_URL, SITE_URL } from "../../../helpers/url_helper";
import moment from "moment-timezone";
import { toast } from 'react-toastify';

const ContractorDetailReport = (props) => {

    const { slug } = props.match.params
    let obj = JSON.parse(localStorage.getItem("authUser"))
    

    const [startdate, setStartDate] = useState(moment(new Date()).subtract(30,'d').format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).format('YYYY-MM-DD'))

    const [print, setPrint] = useState(false)
    const [contractorFeedback, setContractorFeedback] = useState([])
    const [receivedCalls, setReceivedCalls] = useState([])
    const [contractorEmail, setContractorEmail] = useState([])
    const [profileView, setProfileView] = useState([])
    const [websiteClick, setWebsiteClick] = useState([])
    const [profileShare, setProfileShare] = useState([])
    const [campaign , setCampaign] = useState(null)
    const [loaddata, setLoaddata] = useState(false)
    const [password, setPassword] = useState(false)

    function loadCampaignData(data){
        
        post(`${BASE_URL}/api/tgcl/dashboard/contractor-analytics/`, data,
          { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
          )
          .then(response => {
              if(response.status >= 400){
                  if(obj){
                      toast.error("Invalid URL or Contractor Not Found")
                      props.history.push('/analytics')
  
                  }else{
                      window.location = `${SITE_URL}/analytics/contractor-detail/${slug}`
                  }
                  
                  
              }else{
                if(obj){setPassword(true)}
                setContractorFeedback((response.data.contractor_feedback).reverse())
                setReceivedCalls((response.data.received_calls).reverse())
                setContractorEmail((response.data.contractor_email).reverse())
                setProfileView((response.data.profile_view).reverse())
                setWebsiteClick((response.data.website_click).reverse())
                setProfileShare((response.data.profile_share).reverse())
                setCampaign(response.data.campaign)
                setLoaddata(true)
  
              }
          })
        }


        

    return (
             !print  && obj?
            <VerticalLayout>
                <ContractorDetailData 
                match = {props.match}
                history = {props.history}
                location = {props.location}
                obj = {obj}
                print = {print}
                setPrint = {setPrint}
                loadCampaignData = {loadCampaignData}
                loaddata = {loaddata}
                contractorFeedback={contractorFeedback}
                receivedCalls={receivedCalls}
                contractorEmail={contractorEmail}
                profileView={profileView}
                websiteClick={websiteClick}
                profileShare={profileShare}
                campaign={campaign}
                password={password}
                setPassword={setPassword}
                />
            </VerticalLayout>

            :

            <ContractorDetailData 
                match = {props.match}
                history = {props.history}
                location = {props.location}
                obj = {obj}
                print = {print}
                setPrint = {setPrint}
                loadCampaignData = {loadCampaignData}
                loaddata = {loaddata}
                contractorFeedback={contractorFeedback}
                receivedCalls={receivedCalls}
                contractorEmail={contractorEmail}
                profileView={profileView}
                websiteClick={websiteClick}
                profileShare={profileShare}
                campaign={campaign}
                password={password}
                setPassword={setPassword}
                
            />
      
    );

}

export default ContractorDetailReport
