import React, { useState, useEffect, useRef } from "react"
import { CSVLink } from "react-csv";
import { Row, Col, Card, CardBody, Spinner, NavLink, NavItem, Nav, TabPane,TabContent, Button, Label, Modal} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
import classnames from "classnames"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
// import { downloadExcel } from "react-export-table-to-excel";

import { toast } from "react-toastify";
import { get, post, patch } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import { ValidateUser } from "../../helpers/validate_user";
import moment from "moment-timezone";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Name", value: "f_name" },
      { label: "Email", value: "email" },
      { label: "Phone", value: "phone" },
      { label: "Date", value: "created" },
    ],
  },
];


const sortOptionGroup = [
  {
    label: "Order by",
    options: [
      { label: "Asc", value: "" },
      { label: "Desc", value: "-" },
    ],
  },
];

const PendingActionOption = [
  {
    label: "Order by",
    options: [
      { label: "Move to Approved", value: "approved" },
      { label: "Move to Archive", value: "archive" },
    ],
  },
];

const ApprovedActionOption = [
  {
    label: "Order by",
    options: [
      { label: "Move to Pending", value: "pending" },
      { label: "Move to Archive", value: "archive" },
      { label: "Send Email", value: "send_email" },
      { label: "Export CSV", value: "export" },
    ],
  },
];

const ArchiveActionOption = [
  {
    label: "Order by",
    options: [
      { label: "Move to Approved", value: "approved" },
      { label: "Move to Pending", value: "pending" },
    ],
  },
];

const ExportOption = [
  {
    label: "Order by",
    options: [
      { label: "Selected Users", value: "selected" },
      { label: "All Users", value: "all_users" },
    ],
  },
];

const Customers = (props) => {
  const info = props.location.info
  const [activeTab, setactiveTab] = useState("1")
  const [users, setUsers] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)


  const [pendingUsers, setPendingUsers] = useState([]);
  const [pendingTotalSize, setPendingtotalSize] = useState(0);
  const [pendingPage, setPendingpage] = useState(1)


  const [archiveUsers, setArchiveUsers] = useState([]);
  const [archiveTotalSize, setArchivetotalSize] = useState(0);
  const [archivePage, setArchivepage] = useState(1)



  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [loaddata, setLoaData] = useState(false)
  const [emails, setEmails] = useState([])
  const [ids, setIds] = useState([])
  const [pendingIds, setIdsPending] = useState([])
  const [archiveIds, setArchiveIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal_standard, setmodal_standard] = useState(false)

  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [search_by, setSearchby] = useState({ label: "Search By", value: null });
  const [actionApproved, setSetActionApproved] = useState({ label: "Select Action", value: null });
  const [actionPending, setSetActionPending] = useState({ label: "Select Action", value: null });
  const [actionArchived, setSetActionArchived] = useState({ label: "Select Action", value: null });
  const [selectedExport, setSelectedExport] = useState({ label: "Selected Users", value: "selected" });
  const [order_by, setOrder_by] = useState({ label: "Asc", value: "" });
  const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
  const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))

  const [exportData, setExportData] = useState([])
  const [downloading, setDownloading] = useState(false)

  const csvInstance = useRef()

  ValidateUser(props)


  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden:true,
    },
    
    {
      dataField: "f_name",
      text: "First Name",
      sort: true,
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        
        <Link to={{ pathname: `/edit-registered-user/${row.id}`, statedata:{'activeTab':activeTab, 'page': {"2":page, '1':pendingPage, '3': archivePage} } }} className="me-3 text-dark">{cellContent}</Link>        
      ),
      title: (cell) => `${cell}`
    },
    {
        dataField: "email",
        text: "Email",
        headerFormatter: HeaderFormatter,
        sort: true,
        formatter: (cellContent, row) => (
          <Link to={{ pathname: `/edit-registered-user/${row.id}`, statedata:{'activeTab':activeTab, 'page': {"2":page, '1':pendingPage, '3': archivePage} } }} className="me-3 text-dark">{cellContent}</Link>     
        ),
    },
    {
      dataField: "phone",
      text: "Phone",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        cellContent
      ),
  },

    {
      dataField: "created",
      text: "Registration Date",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={{ pathname: `/edit-registered-user/${row.id}`, statedata:{'activeTab':activeTab, 'page': {"2":page, '1':pendingPage, '3': archivePage} } }} className="me-3 text-dark">{moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY hh:mm A')}</Link>     
      ),
      
    },
    {
      dataField: "action",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "Action",
      formatter: (cellContent, row) => (
        row.archive?
        <React.Fragment>
          <Link to={`/edit-registered-user/${row.id}`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
        </React.Fragment>
        :
        <React.Fragment>
          <Link to={{ pathname: `/edit-registered-user/${row.id}`, statedata:{'activeTab':activeTab, 'page': {"2":page, '1':pendingPage, '3': archivePage} } }} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
          <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row.id)}} className="text-warning"><i className="mdi mdi-archive font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    if (info){
      setactiveTab(info.activeTab)
      setpage(info.page['2'])
      setPendingpage(info.page['1'])
      setArchivepage(info.page['3'])
    }
    loadData(`${BASE_URL}/api/tgcl/registerusers/?approved=true&archive=false`, 20, page)
    loadPendingData(`${BASE_URL}/api/tgcl/registerusers/?approved=false&archive=false`, 20, pendingPage)
    loadArchiveData(`${BASE_URL}/api/tgcl/registerusers/?archive=true`, 20, archivePage)
    return () => {
      setUsers([])
      setPendingUsers([])
    }
    
  }, []);

  function loadData(url , sizePerPage, page ){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setUsers(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoaData(true)
    })
  }

  function loadPendingData(url , sizePerPage, page ){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setPendingUsers(response.data.results)
      setPendingtotalSize(response.data.count)
      setPendingpage(page)
      setsizePerPage(sizePerPage)
      setLoaData(true)
    })
  }

  function loadArchiveData(url , sizePerPage, page ){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setArchiveUsers(response.data.results)
      setArchivetotalSize(response.data.count)
      setArchivepage(page)
      setsizePerPage(sizePerPage)
      setLoaData(true)
    })
  }

  function ClearFilter(){

    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null });
    setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
    setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))

    loadData(`${BASE_URL}/api/tgcl/registerusers/?approved=true&archive=false`, 20, 1)
    loadPendingData(`${BASE_URL}/api/tgcl/registerusers/?approved=false&archive=false`, 20, 1)
    loadArchiveData(`${BASE_URL}/api/tgcl/registerusers/?archive=true`, 20, 1)

  }
  function FilterData(e, values){
    let filter_data = ""
    if(!search_by.value){
      toast.error("Select Search Field")
    }else{
      if(search_by.value === "created"){
        filter_data = `&from_date=${fromDate}&to_date=${toDate}`
      }else{
        filter_data = `&${search_by.value}=${values.search}`
      }
      filter_data = filter_data+`&order_by=${order_by.value}${search_by.value}`
      loadData(`${BASE_URL}/api/tgcl/registerusers/?approved=true&archive=false${filter_data}`, 20, 1)
      loadPendingData(`${BASE_URL}/api/tgcl/registerusers/?approved=false&archive=false${filter_data}`, 20, 1)
      loadArchiveData(`${BASE_URL}/api/tgcl/registerusers/?archive=true${filter_data}`, 20, 1)
      setFilter(filter_data)


    }
  }


  function NoDataIndication(){
    if (loaddata && users.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )    
    } 
  }

  function PendingNoDataIndication(){
    if (loaddata && pendingUsers.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function ArchiveNoDataIndication(){
    if (loaddata && archiveUsers.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function handleCustomerClicks (id) { 
    let data =  {"archive": true }
  
    patch(`${BASE_URL}/api/tgcl/registerusers/${id}/`, data,
        { headers: { 'Content-Type': 'application/json', }},
        {validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            if (activeTab ==='2'){
              let new_page = page
              if(users.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadData(url , sizePerPage, new_page )

            }else if (activeTab ==='1'){
              let new_page = pendingPage
              if(pendingUsers.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadPendingData(url , sizePerPage, new_page )

            }else{
              let new_page = archivePage
              if(archiveUsers.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?archive=true&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadArchiveData(url , sizePerPage, new_page )
            }
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Archived!')
            setDynamic_description("User Has been Archived.") 
            setSelected_id(null)
          }
      }
    )
  }

  function handleTableChange (type, { page, sizePerPage, searchText, }) {
   
    setTimeout(() => {
      setLoaData(false)
      setUsers([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/registerusers/?approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
      if (filter !== ""){
        url = `${url}${filter}`
      }
      window.scrollTo(0, 0)
      loadData(url , sizePerPage, new_page , searchText)
    }, 1000);
  }

  function handlePendingTableChange (type, { page, sizePerPage, searchText, }) {
   
    setTimeout(() => {
      setLoaData(false)
      setPendingUsers([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/registerusers/?approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
      if (filter !== ""){
        url = `${url}${filter}`
      }
      window.scrollTo(0, 0)
      loadPendingData(url , sizePerPage, new_page , searchText)
    }, 1000);
  }

  function handleArchiveTableChange (type, { page, sizePerPage, searchText, }) {
   
    setTimeout(() => {
      setLoaData(false)
      setArchiveUsers([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/registerusers/?archive=true&page_size=${sizePerPage}&page=${new_page}`
      if (filter !== ""){
        url = `${url}${filter}`
      }
      window.scrollTo(0, 0)
      loadArchiveData(url , sizePerPage, new_page , searchText)
    }, 1000);
  }
  

  function sendEmail(e, values){
    setLoading(true)
    let data = {'subject': values.subject, "emails": emails, "message": values.message}
    post(`${BASE_URL}/api/tgcl/send-email-to-customers/`, data,
    { headers: { 'Content-Type': 'application/json', }},
    {validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log(res, "error")
            toast.error("Somthing Went Wrong")
            setLoading(false)
            tog_standard()
          }
          else{
            toast.success("Email Sent to Selected Users")
            setLoading(false)
            tog_standard()
            setEmails([])
          }
      }
    ) 
  }


  function getData(){
    return exportData
  }

  async function getUsers(){
    setDownloading(true)
    let data = []
    if(selectedExport.value === "selected"){
      if (ids.length> 0){
        for (let i=0; i<users.length; i++){
          if(ids.includes(users[i].id)){
            data.push({"f_name":users[i].f_name, 'l_name': users[i].l_name, 'email':users[i].email, 'address':users[i].address, 
                      'state':users[i].state, 'city':users[i].city?users[i].city:"", 'zip':users[i].zip?users[i].zip:"", 
                      'phone':users[i].phone?users[i].phone:"",})  
          }
          
          }
        setExportData(data)
        csvInstance.current.link.click()
        setDownloading(false)

      }else{
        toast.error("Select User")
        setDownloading(false)
      }

    }else{
      await get(`${BASE_URL}/api/tgcl/all-customers/`, { headers: { 'Content-Type': 'application/json', }} )
      .then(response => {
      for (let i=0; i<response.data.length; i++){
        data.push({"f_name":response.data[i].f_name, 'l_name': response.data[i].l_name, 'email':response.data[i].email, 'address':response.data[i].address, 
                    'state':response.data[i].state, 'city':response.data[i].city?response.data[i].city:"", 'zip':response.data[i].zip?response.data[i].zip:"", 
                    'phone':response.data[i].phone?response.data[i].phone:"",})
        }
      setExportData(data)
      csvInstance.current.link.click()
      setDownloading(false)

    })

    }

   
    
    

  }

  function MovetoArchive(value){
    let archive_ids = []
    if (value === 'approved'){
      archive_ids = ids
    }else{
      archive_ids = pendingIds
    }
    let data = {'ids': archive_ids}
    post(`${BASE_URL}/api/tgcl/customers-bulk-archive/`, data,
    { headers: { 'Content-Type': 'application/json', }},
    {validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log(res, "error")
            toast.error("Somthing Went Wrong")
          }
          else{
            if (activeTab ==='1'){
              let new_page = pendingPage
              if(pendingUsers.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadPendingData(url , sizePerPage, new_page )
              setIdsPending([])
              setPendingUsers([])

            }else if (activeTab ==='2'){

              let new_page = page
              if(users.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadData(url , sizePerPage, new_page )
              setIds([])
              setUsers([])
              

            }
            let new_page = archivePage
            if(archiveUsers.length < 2){
              new_page = new_page>1? new_page-1:1
            }
            let url = `${BASE_URL}/api/tgcl/registerusers/?archive=true&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== ""){
              url = `${url}${filter}`
            }
            loadArchiveData(url , sizePerPage, new_page )
            setArchiveUsers([])
          
            
          }
      }
    )
    
  }
  function MovetoPending(value){
    let archive_ids = []
    if (value === 'approved'){
      archive_ids = ids
    }else{
      archive_ids = archiveIds
    }
    let data = {'ids': archive_ids}

    post(`${BASE_URL}/api/tgcl/customers-bulk-pending/`, data,
    { headers: { 'Content-Type': 'application/json', }},
    {validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log(res, "error")
            toast.error("Somthing Went Wrong")
          }
          else{
            if (activeTab ==='2'){
              let new_page = page
              if(users.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadData(url , sizePerPage, new_page)
              setIds([])
              setUsers([])

            }else if (activeTab ==='3'){
              let new_page = archivePage
            if(archiveUsers.length < 2){
              new_page = new_page>1? new_page-1:1
            }
            let url = `${BASE_URL}/api/tgcl/registerusers/?archive=true&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== ""){
              url = `${url}${filter}`
            }
            setArchiveUsers([])
            setArchiveIds([])
            loadArchiveData(url , sizePerPage, new_page)
            
            
            }
            let new_page = pendingPage
              if(pendingUsers.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadPendingData(url , sizePerPage, new_page)
              setPendingUsers([])

            
            
          }
      }
    )
    
  }

  function MovetoApproved(value){

    let archive_ids = []
    if (value === 'pending'){
      archive_ids = pendingIds
    }else{
      archive_ids = archiveIds
    }
    let data = {'ids': archive_ids}

    post(`${BASE_URL}/api/tgcl/customers-bulk-approved/`, data,
    { headers: { 'Content-Type': 'application/json', }},
    {validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log(res, "error")
            toast.error("Somthing Went Wrong")
          }
          else{
            if (activeTab ==='1'){
              let new_page = pendingPage
              if(pendingUsers.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?approved=false&archive=false&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadPendingData(url , sizePerPage, new_page)
              setPendingUsers([])
              setIdsPending([])

              

            }else if (activeTab ==='3'){
              let new_page = archivePage
            if(archiveUsers.length < 2){
              new_page = new_page>1? new_page-1:1
            }
            let url = `${BASE_URL}/api/tgcl/registerusers/?archive=true&page_size=${sizePerPage}&page=${new_page}`
            if (filter !== ""){
              url = `${url}${filter}`
            }
            loadArchiveData(url , sizePerPage, new_page)
            setArchiveUsers([])
            setArchiveIds([])
            }
            let new_page = page
              if(users.length < 2){
                new_page = new_page>1? new_page-1:1
              }
              let url = `${BASE_URL}/api/tgcl/registerusers/?approved=true&archive=false&page_size=${sizePerPage}&page=${new_page}`
              if (filter !== ""){
                url = `${url}${filter}`
              }
              loadData(url , sizePerPage, new_page )
              setUsers([])
            
          }
      }
    )
    
  }

  function handleOnSelect(row, isSelect) {
    let email = []
    let id = []
    if (isSelect){
      email = emails
      id = ids
      email.push(row.email.toLowerCase())
      id.push(row.id)
    }else{
      for(let i=0; i< emails.length; i++){
        if (emails[i].toLowerCase() !== row.email.toLowerCase()){
          email.push(emails[i])
        }
      }
      for(let i=0; i< ids.length; i++){
        if (ids[i] !== row.id){
          id.push(ids[i])
        }
      }

    }
    setEmails(email)
    setIds(id)
  }

  function handleOnSelectAll(isSelect, rows) {
    if (isSelect) {
      let email = [] 
      let id = []
     for(let i=0; i< rows.length; i++){
      email.push(rows[i].email.toLowerCase())
      id.push(rows[i].id)
     }
     setEmails(email)
     setIds(id)
    }else{
      setEmails([])
      setIds([])
    }
  }

  function handleOnSelectRow(row, isSelect) {
    let id = []
    if (isSelect){
      id = pendingIds
      id.push(row.id)
    }else{
      for(let i=0; i< pendingIds.length; i++){
        if (pendingIds[i] !== row.id){
          id.push(pendingIds[i])
        }
      }

    }
    setIdsPending(id)
  }
  function handleOnSelectAllRow(isSelect, rows) {
    if (isSelect) {
      let id = [] 
     for(let i=0; i< rows.length; i++){
      id.push(rows[i].id)
     }
     setIdsPending(id)
    }else{
      setIdsPending([])
    }
  }

  function handleOnSelectArchiveRow(row, isSelect) {
    let id = []
    if (isSelect){
      id = archiveIds
      id.push(row.id)
    }else{
      for(let i=0; i< archiveIds.length; i++){
        if (archiveIds[i] !== row.id){
          id.push(archiveIds[i])
        }
      }

    }
    setArchiveIds(id)
  }
  function handleOnSelectAllArchiveRow(isSelect, rows) {
    if (isSelect) {
      let id = [] 
     for(let i=0; i< rows.length; i++){
      id.push(rows[i].id)
     }
     setArchiveIds(id)
    }else{
      setArchiveIds([])
    }
  }


  function HandleAction(){
    if(activeTab ==="2"){
      if(actionApproved.value === "pending"){
        ids.length>0?MovetoPending("approved"):toast.error("Select Users")
      }else if(actionApproved.value === "archive"){
        ids.length>0?MovetoArchive('approved'):toast.error("Select Users")
      }else if(actionApproved.value === "send_email"){
        emails.length>0?tog_standard():toast.error("Select Email")
      }
    }else if(activeTab === '1'){
      if(actionPending.value === "approved"){
        pendingIds.length>0?MovetoApproved("pending"):toast.error("Select Users")
      }else if(actionPending.value === "archive"){
        pendingIds.length>0?MovetoArchive("pending"):toast.error("Select Users")
      }
    }else if(activeTab === '3'){
      if(actionPending.value === "pending"){
        archiveIds.length>0?MovetoPending("archive"):toast.error("Select Users")
      }else if(actionPending.value === "approved"){
        archiveIds.length>0?MovetoApproved("archive"):toast.error("Select Users")
      }
    }

  }
  
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };

  const selectRowforPending = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelectRow,
    onSelectAll: handleOnSelectAllRow
  };

  const selectRowforArchive = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelectArchiveRow,
    onSelectAll: handleOnSelectAllArchiveRow
  };
 
  const defaultSorted = [{
      dataField: 'f_name',
      order: 'desc',
      savestate: true
    }];

    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
      
  }
  const PendingpageOptions = {
    page: pendingPage,
    sizePerPage: sizePerPage,
    totalSize: pendingTotalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [ {
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    } ]
  } 

  const ArchivepageOptions = {
    page: archivePage,
    sizePerPage: sizePerPage,
    totalSize: archiveTotalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [ {
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    } ]
  } 
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Customers' link="/dashboard" />
        <Col xs={12}>
            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1")
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                      <span className="d-none d-sm-block">Pending</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2")
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                      <span className="d-none d-sm-block">Approved</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggle("3")
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                      <span className="d-none d-sm-block">Archived</span>
                    </NavLink>
                  </NavItem>
                  
                </Nav>

                <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="2">
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        data={users}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={users}
                            columns={columns}
                            remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                            bootstrap5
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <AvForm className="needs-validation">
                                  <Row className="mb-2">
                                    <Col sm="3">
                                      <div className="text-sm-start mb-2">
                                          <Select
                                          value={actionApproved}
                                          onChange={(e) => {
                                              setSetActionApproved(e)
                                          }}
                                          options={ApprovedActionOption}
                                          classNamePrefix="select2-selection"
                                        />
                                      </div>
                                    </Col>
                                    <Col sm="1">
                                      <Button type="button" color="primary" onClick={()=> HandleAction()} className="btn-rounded me-2" >
                                        Apply
                                      </Button>
                                    </Col>
                                    <Col sm="3">
                                      <div className="text-sm-start mb-2">
                                          <Select
                                          value={search_by}
                                          onChange={(e) => {
                                              setSearchby(e)
                                          }}
                                          options={optionGroup}
                                          classNamePrefix="select2-selection"
                                        />
                                      </div>
                                    </Col>
                                  </Row >
                                </AvForm>
                                <Row>
                                  <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                  {search_by.value?
                                      <Col sm="9">
                                        <Row>
                                          <Col sm="5">
                                            {search_by.value === "created"?
                                            <Row>
                                              <Col sm="6">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="from_date"
                                                    placeholder="Received From"
                                                    type="date"
                                                    errorMessage="From Date"
                                                    className="form-control"
                                                    value={fromDate}
                                                    onChange={(e)=> setFromDate(e.target.value)}
                                                    id="from_date"
                                                  />
                                                </div>
                                              
                                              </Col>
                                              <Col sm="6">
                                                <div className="text-sm-end mb-2">
                                                <AvField
                                                    name="to_date"
                                                    placeholder="Category"
                                                    type="date"
                                                    errorMessage="To Date"
                                                    className="form-control"
                                                    value={toDate}
                                                    onChange={(e)=> setToDate(e.target.value)}
                                                    id="to_date"
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            :search_by.value !== null?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e)=> setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>
                                              
                                              </Col>
                                              
                                            </Row>

                                            : null}
                                            
                                          </Col>
                                          <Col sm="3">
                                                <div className="text-sm-start mb-2">
                                                    <Select
                                                    value={order_by}
                                                    onChange={(e) => {
                                                        setOrder_by(e)
                                                    }}
                                                    options={sortOptionGroup}
                                                    classNamePrefix="select2-selection"
                                                  />
                                                </div>
                                          </Col>
                                          <Col sm="4">
                                                <Button type="submit" color="success" className="btn-rounded me-2" >
                                                  Filter
                                                </Button>
                                                {" "}
                                                <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded" >
                                                  Clear
                                                </Button>
                                          </Col>
                                        </Row>  
                                      </Col>
                                      :null}
                                  </AvForm>
                                </Row>
                                {actionApproved.value === 'export'?
                                <Row className="my-2">
                               
                                  <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                            <Select
                                            value={selectedExport}
                                            onChange={(e) => {
                                              setSelectedExport(e)
                                            }}
                                            options={ExportOption}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="3" >
                                      {" "}
                                      {downloading?
                                      <Button
                                      type="button"
                                      color="primary"
                                      className="btn btn-rounded waves-effect waves-light my-3"
                                      >
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                      Downloading
                                    </Button>
                                    :
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="btn-rounded mb-2 me-2"
                                        onClick={() => getUsers()}
                                        >
                                        <i className="mdi mdi-file-excel-outline me-1" />{" "}
                                        Download
                                      </Button>
                                      }
                                      
                                      <CSVLink
                                          data={getData()}
                                          filename={"customers-data.csv"}
                                          asyncOnClick={true}
                                          data-interception='off'                                        
                                          ref={csvInstance}
                                        >
                                        </CSVLink>
                                    </Col>
                                </Row>:null}
                                
                             

                                
                                <div className="table-responsive">

                                  <BootstrapTable
                                    remote
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    defaultSorted={defaultSorted}
                                    hover
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    onTableChange={ handleTableChange }
                                    noDataIndication={() => NoDataIndication()}
                                    selectRow={ selectRow }
                                  />
                                  
                                </div>
                                <Row>
                                  <Col sm={6}>
                                  <div className="mb-3">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps} 
                                    />
                                  </div>
                                    <div className="pagination pagination-rounded mb-2">
                                      <PaginationTotalStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    
                                  </Col>
                                  <Col sm={6}>
                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    
                                  </Col>
                                </Row>
                                
                                
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </TabPane>
                    <TabPane tabId="1" >
                      <PaginationProvider
                          pagination={paginationFactory(PendingpageOptions)}
                          data={pendingUsers}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={pendingUsers}
                              columns={columns}
                              remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                              bootstrap5
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  
                            
                                  
                                    <Row className="mb-2">
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={actionPending}
                                            onChange={(e) => {
                                                setSetActionPending(e)
                                            }}
                                            options={PendingActionOption}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="1">
                                        <Button type="button" color="primary" onClick={()=> HandleAction()} className="btn-rounded me-2" >
                                          Apply
                                        </Button>
                                      </Col>
                                      <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                            <Select
                                            value={search_by}
                                            onChange={(e) => {
                                                setSearchby(e)
                                            }}
                                            options={optionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                        
                                        
                                      </Col>
                                      
                                      
                                    </Row>
                                    <Row>
                                    <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                      {search_by.value?
                                      <Col sm="8">
                                        <Row>
                                          <Col sm="5">
                                            {search_by.value === "created"?
                                            <Row>
                                              <Col sm="6">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="from_date"
                                                    placeholder="Received From"
                                                    type="date"
                                                    errorMessage="From Date"
                                                    className="form-control"
                                                    value={fromDate}
                                                    onChange={(e)=> setFromDate(e.target.value)}
                                                    id="from_date"
                                                  />
                                                </div>
                                              
                                              </Col>
                                              <Col sm="6">
                                                <div className="text-sm-end mb-2">
                                                <AvField
                                                    name="to_date"
                                                    placeholder="Category"
                                                    type="date"
                                                    errorMessage="To Date"
                                                    className="form-control"
                                                    value={toDate}
                                                    onChange={(e)=> setToDate(e.target.value)}
                                                    id="to_date"
                                                  />
                                                </div>
                                              </Col>
                                            </Row>
                                            :search_by.value !== null?
                                            <Row>
                                              <Col sm="12">
                                                <div className="text-sm-end mb-2">
                                                  <AvField
                                                    name="search"
                                                    placeholder={search_by.label}
                                                    type="text"
                                                    value={search}
                                                    onChange={(e)=> setSearch(e.target.value)}
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                  />
                                                </div>
                                              
                                              </Col>
                                              
                                            </Row>

                                            : null}
                                            
                                          </Col>
                                          <Col sm="3">
                                                <div className="text-sm-start mb-2">
                                                    <Select
                                                    value={order_by}
                                                    onChange={(e) => {
                                                        setOrder_by(e)
                                                    }}
                                                    options={sortOptionGroup}
                                                    classNamePrefix="select2-selection"
                                                  />
                                                </div>
                                          </Col>
                                          <Col sm="4">
                                        
                                                
                                                <Button type="submit" color="success" className="btn-rounded me-2" >
                                                  Filter
                                                </Button>
                                                {" "}
                                                <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded" >
                                                  Clear
                                                </Button>
                                            
                                          </Col>
                                        </Row>  
                                      </Col>
                                      :null}
                                      </AvForm>
                                    </Row>
                                  
                                  <div className="table-responsive">

                                    <BootstrapTable
                                      remote
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      responsive
                                      bordered={false}
                                      striped={true}
                                      defaultSorted={defaultSorted}
                                      hover
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      onTableChange={ handlePendingTableChange }
                                      noDataIndication={() => PendingNoDataIndication()}
                                      selectRow={ selectRowforPending }
                                    />
                                    
                                  </div>
                                  <Row>
                                    <Col sm={6}>
                                    <div className="mb-3">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps} 
                                        
                                      />
                                    </div>
                                      <div className="pagination pagination-rounded mb-2">
                                        <PaginationTotalStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      
                                    </Col>
                                    <Col sm={6}>
                                      <div className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      
                                    </Col>
                                  </Row>
                                  
                                  
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      
                    </TabPane>
                    <TabPane tabId="3" >
                      <PaginationProvider
                          pagination={paginationFactory(ArchivepageOptions)}
                          data={archiveUsers}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={archiveUsers}
                              columns={columns}
                              remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                              bootstrap5
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  
                                 
                                  <Row className="mb-2">
                                    <Col sm="3">
                                        <div className="text-sm-start mb-2">
                                          <Select
                                            value={actionArchived}
                                            onChange={(e) => {
                                                setSetActionArchived(e)
                                            }}
                                            options={ArchiveActionOption}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="1">
                                        <Button type="button" color="primary" onClick={()=> HandleAction()} className="btn-rounded me-2" >
                                          Apply
                                        </Button>
                                      </Col>
                                    <Col sm="3">
                                      <div className="text-sm-start mb-2">
                                          <Select
                                          value={search_by}
                                          onChange={(e) => {
                                              setSearchby(e)
                                          }}
                                          options={optionGroup}
                                          classNamePrefix="select2-selection"
                                        />
                                      </div>
                                      
                                      
                                    </Col>
                                    
                                    
                                  </Row>
                                  <Row>
                                  <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                  {search_by.value?
                                    <Col sm="9">
                                      <Row>
                                        <Col sm="5">
                                          {search_by.value === "created"?
                                          <Row>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="from_date"
                                                  placeholder="Received From"
                                                  type="date"
                                                  errorMessage="From Date"
                                                  className="form-control"
                                                  value={fromDate}
                                                  onChange={(e)=> setFromDate(e.target.value)}
                                                  id="from_date"
                                                />
                                              </div>
                                            
                                            </Col>
                                            <Col sm="6">
                                              <div className="text-sm-end mb-2">
                                              <AvField
                                                  name="to_date"
                                                  placeholder="Category"
                                                  type="date"
                                                  errorMessage="To Date"
                                                  className="form-control"
                                                  value={toDate}
                                                  onChange={(e)=> setToDate(e.target.value)}
                                                  id="to_date"
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                          :search_by.value !== null?
                                          <Row>
                                            <Col sm="12">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="search"
                                                  placeholder={search_by.label}
                                                  type="text"
                                                  value={search}
                                                  onChange={(e)=> setSearch(e.target.value)}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                />
                                              </div>
                                            
                                            </Col>
                                            
                                          </Row>

                                          : null}
                                          
                                        </Col>
                                        <Col sm="3">
                                              <div className="text-sm-start mb-2">
                                                  <Select
                                                  value={order_by}
                                                  onChange={(e) => {
                                                      setOrder_by(e)
                                                  }}
                                                  options={sortOptionGroup}
                                                  classNamePrefix="select2-selection"
                                                />
                                              </div>
                                        </Col>
                                        <Col sm="4">
                                      
                                              
                                              <Button type="submit" color="success" className="btn-rounded me-2" >
                                                Filter
                                              </Button>
                                              {" "}
                                              <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded" >
                                                Clear
                                              </Button>
                                          
                                        </Col>
                                      </Row>  
                                    </Col>
                                    :null}
                                  </AvForm>
                                  </Row>
                                  
                                  <div className="table-responsive">

                                    <BootstrapTable
                                      remote
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      responsive
                                      bordered={false}
                                      striped={true}
                                      defaultSorted={defaultSorted}
                                      hover
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      onTableChange={ handleArchiveTableChange }
                                      noDataIndication={() => ArchiveNoDataIndication()}
                                      selectRow={ selectRowforArchive }
                                      
                                    />
                                    
                                  </div>
                                  <Row>
                                    <Col sm={6}>
                                    <div className="mb-3">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps} 
                                        
                                      />
                                    </div>
                                      <div className="pagination pagination-rounded mb-2">
                                        <PaginationTotalStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      
                                    </Col>
                                    <Col sm={6}>
                                      <div className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      
                                    </Col>
                                  </Row>
                                  
                                  
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                      </PaginationProvider>
                      
                    </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        {/* <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={users}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={users}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                               
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                              noDataIndication = { NoDataIndication }
                            />
                            
                          </div>
                          <Row>
                            <Col sm={6}>
                              <div className="pagination pagination-rounded mb-2">
                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                              </div>
                              
                            </Col>
                            <Col sm={6}>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                              
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleCustomerClicks(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            {/* You won't be able to revert this! */}
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
        
      </div>
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{`Add Location`} </h5>     

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={sendEmail}>
            <div className="modal-body">
              <Row>
                  <Col md="12">
                      <div className="mb-3">
                      <Label htmlFor="subject">Subject</Label>
                      <AvField
                          name="subject"
                          placeholder="Subject"
                          type="text"
                          errorMessage="Enter Subject"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="subject"
                      />
                      </div>
                  </Col>
              </Row>
              <Row>
                <Col md="12">
                    <AvField
                    className="mb-3"
                    type="textarea"
                    label="Meaasge"
                    rows="3"
                    name="message"
                    id="message"
                    validate={{ required: { value: true } }}  
                />
                </Col>
                                    
            </Row>
          
                
            </div>
            <div className="modal-footer">
            {loading?
              <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              >
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Submitting
              </button>
              :
              <Button color="primary" type="submit">
                Submit
              </Button>}
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-primary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            
          </div>
          </AvForm>

        </Modal>
      </Col>

    </React.Fragment>
  )
}

export default Customers
