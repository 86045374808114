import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button, Modal} from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"
import { BASE_URL } from "../../helpers/url_helper";
import { get, put, post } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const roleOptions = [
    {
      label: "Admin Type",
      options: [
        {value: 0, label: "Super Admin"},
        {value: 1, label: "Sub Admin"},
        
        ],
    },
  ];


const EditUser = (props) => {
    
    const { id } = props.match.params
    const [hearFrom, setHearFrom] = useState({value: 1, label: "Sub Admin"});
    const [changePassword, setChangePassword] = useState(false)
    const [didMount, setDidMount] = useState(false);
    const [user, setUser] = useState(null)

    useEffect(() => {
        setDidMount(true);
        get(`${BASE_URL}/api/accounts/users/${id}/`, 
        { headers: { 'Content-Type': 'application/json', }, validateStatus: false}  
        )
        .then(response => {
            setUser(response.data)
            if(response.data.role === 0){
                setHearFrom({ label: "Super Admin", value: 0 })
            }
        })
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
      }

    function handleSelectHearFrom(selectedhearFrom) {
        setHearFrom(selectedhearFrom);
    }

    function tog_ChangePassword() {
        setChangePassword(!changePassword)
        removeBodyCss()
      }
      function removeBodyCss() {
        document.body.classList.add("no_padding")
      }

    function handleSubmit(e, values){
        let data =  values
        data["role"] = hearFrom.value
        put(`${BASE_URL}/api/accounts/users/${id}/`, data,
            { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false}
        )
        .then(response => {
            if(response.status >= 400){
                console.log(response)
            }else{
                props.history.push("/admins")
            }
        })
        
    }

    function handleChangePassword(e, values){
        let data =  values
        data['username'] = user.username
        post(`${BASE_URL}/api/accounts/change-user-password/`, data,
        { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false}
        )
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing Went Wrong . ")
                tog_ChangePassword()
            }else{
                toast.success("Password Updated . ")
                tog_ChangePassword()
            }
        })

    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Users" breadcrumbItem={user?`Edit ${user.username}`:"Edit User"} link="/admins" />
                {user?
                    <Row>
                        <Col md="12">
                            
                                <Row>
                                    <Col sm="6">
                                    
                                        <Card>
                                            <CardBody>
                                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                                <CardTitle>User Details </CardTitle> 
                                                <Row className="mb-3">
                                                    <Label htmlFor="username" className="col-md-3 col-form-label">Login Name <span style={{color:'red'}}>*</span></Label>
                                                    <div className="col-md-9">
                                                        <AvField
                                                            name="username"
                                                            placeholder="Login Name"
                                                            type="text"
                                                            errorMessage="Enter Login Name"
                                                            className="form-control"
                                                            validate={{ required: { value: true },
                                                                        pattern: {value: '^[a-z0-9_]+$', errorMessage: 'Login name must be composed only with small letter, numbers and _.'}, 
                                                                    }}
                                                            id="username"
                                                            value={user.username}
                                                            disabled
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="first_name" className="col-md-3 col-form-label">First Name <span style={{color:'red'}}>*</span></Label>
                                                    <div className="col-md-9">
                                                        <AvField
                                                            name="first_name"
                                                            placeholder="First Name"
                                                            type="text"
                                                            errorMessage="Enter Login Name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="first_name"
                                                            value={user.first_name}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="last_name" className="col-md-3 col-form-label">Last Name <span style={{color:'red'}}>*</span></Label>
                                                    <div className="col-md-9">
                                                        <AvField
                                                            name="last_name"
                                                            placeholder="Last Name"
                                                            type="text"
                                                            errorMessage="Enter Login Name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="last_name"
                                                            value={user.last_name}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="email" className="col-md-3 col-form-label">Email <span style={{color:'red'}}>*</span></Label>
                                                    <div className="col-md-9">
                                                        <AvField
                                                            name="email"
                                                            placeholder="Email"
                                                            type="email"
                                                            errorMessage="Enter Email"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="email"
                                                            value={user.email}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Label htmlFor="role" className="col-md-3 col-form-label">Admin Type</Label>
                                                    <div className="col-md-9">
                                                        <Select
                                                            value={hearFrom}
                                                            onChange={(e) => {
                                                                handleSelectHearFrom(e);
                                                            }}
                                                            options={roleOptions}
                                                            classNamePrefix="select2-selection"
                                                            id="role"
                                                            />
                                                    </div>
                                                </Row>
                                               
                                                <Button color="primary" type="submit">
                                                    Submit
                                                </Button>   
                                                  

                                                                        
                                                
                                                </AvForm>
                                                <Button color="secondry" className="float-end" type="buton" onClick={() =>tog_ChangePassword()}>
                                                    Change Password
                                                </Button>
                                            </CardBody>
                                        </Card>
                                       
                                    </Col>
                                
                                </Row>
                                
                            
                            
                        </Col>
                        
                    </Row>
                :
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
                }
                <Modal
              isOpen={changePassword}
              toggle={() => {
                tog_ChangePassword()
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Change Password
                    </h5>
                <button
                  type="button"
                  onClick={() => {
                    setChangePassword(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AvForm className="needs-validation" onValidSubmit={handleChangePassword}>
                <div className="modal-body">
                  
                    <Row className="mb-3">
                        <Label htmlFor="new_password" className="col-md-4 col-form-label">New Password:</Label>
                        <div className="col-md-8">
                            <AvField
                                name="new_password"
                                placeholder="Enter New Password"
                                type="password"
                                errorMessage="Enter New Password"
                                className="form-control"
                                validate={{ required: { value: true },
                                            minLength: {value: 6, errorMessage: 'Your password must be grater than 6 characters'},
                                           }}
                                id="new_password"
                            />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <Label htmlFor="new_password1" className="col-md-4 col-form-label">Confirm Password:</Label>
                        <div className="col-md-8">
                            <AvField
                                name="new_password1"
                                placeholder="Enter Confirm Password"
                                type="password"
                                errorMessage="Enter Confirm Password"
                                className="form-control"
                                validate={{ required: { value: true },
                                            match: { value: "new_password" }, }}
                                id="new_password1"
                            />
                        </div>
                    </Row>
                    
                
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Change
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      tog_ChangePassword()
                    }}
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  
                </div>
              </AvForm>
            </Modal>

            </div>


        </React.Fragment>
    )
}

export default EditUser
