import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
  Modal,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from "react-toastify";
import { BASE_URL, SITE_URL } from "../../helpers/url_helper";
import { get, del, patch, post, put } from "../../helpers/api_helper";
import { slugify } from "../../helpers/helper_methods";
import { ValidateUser } from "../../helpers/validate_user";
import { toTitleCase } from "../../helpers/methods";
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";
import SweetAlert from "react-bootstrap-sweetalert";
import icon from "../../assets/images/icon.png";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ActivityLog from "../ActivityLog/ActivityLog";

const EditHeading = (props) => {
  const { id } = props.match.params;
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [blogcategories, setBlogCategories] = useState([]);
  const [pageContents, setPageContents] = useState([]);
  // const [locationPageContents, setLocationPageContents] = useState([]);
  const [selectedGroup, setselectedGroup] = useState({
    label: "Select Parent Category",
    value: null,
  });
  const [selectedBlogCat, setselectedBlogCat] = useState({
    label: "Select Blog Category",
    value: null,
  });
  const [didMount, setDidMount] = useState(false);
  const [file, setFile] = useState(null);
  const [slug, setSlug] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [success_confirm, setSuccess_confirm] = useState(false);
  const [alert_confirm, setAlert_confirm] = useState(false);
  const [selected_id, setSelected_id] = useState(null);
  const [dynamic_title, setDynamic_title] = useState("");
  const [dynamic_description, setDynamic_description] = useState("");
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  const [rawMessage, setrawMessage] = useState("");
  const [stateChange, setStateChange] = useState("editor");
  const [showHTMLChange, setShowHTMLChange] = useState(false);
  const [contentSection, setContentSection] = useState("page-content");

  const Categories = [
    {
      label: "Categoties",
      options: categories,
    },
  ];

  ValidateUser(props);

  useEffect(() => {
    setDidMount(true);
    loadPageContent();
    loadCategoryData();

    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  function loadCategoryData() {
    let data = get(`${BASE_URL}/api/tgcl/headings/${id}/`, {
      headers: { "Content-Type": "application/json" },
      validateStatus: false,
    });
    data.then((response) => {
      setCategory(response.data);
      setSlug(response.data.slug);
      setDesc(response.data.pg_desc);
      setTitle(response.data.pg_title);
      if (response.data.parent_id) {
        setselectedGroup({
          label: response.data.parent_id.heading,
          value: response.data.parent_id.id_num,
        });
      }
      loadBlogCategories(response.data.blog_heading);
    });

    loadParentCategory();
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  function handleContentEdit(content) {
    setrawMessage(content);
  }

  function handleChange(e) {
    setFile(e.target.files[0]);
    // URL.createObjectURL
  }

  function handleEditorChange(editor) {
    const editor_Data = editor.getData();
    // setStateChange('editor')
    setrawMessage(editor_Data);

    // You can store or further process the HTML data here
  }

  function loadParentCategory() {
    get(`${BASE_URL}/api/tgcl/all-parents/?page_size=200`, {
      headers: { "Content-Type": "application/json" },
      validateStatus: false,
    }).then(
      (response) => {
        let options = [];
        let cat_data = response.data.results;
        for (let i = 0; i < cat_data.length; i++) {
          if (parseInt(cat_data[i].id_num) !== parseInt(id)) {
            options.push({
              label: cat_data[i].heading,
              value: cat_data[i].id_num,
            });
          }
        }
        setCategories(options);
      },
      (error) => {
        props.history.push("/categories");
      }
    );
  }

  function loadBlogCategories(selected_cat) {
    get(
      `https://www.thegoodcontractorslist.com/blog/wp-json/wp/v2/categories?per_page=100&page=1`,
      { headers: { "Content-Type": "application/json" }, validateStatus: false }
    ).then(
      (response) => {
        let options = [{ label: "Remove Blog", value: null }];
        let cat_data = response.data;
        for (let i = 0; i < cat_data.length; i++) {
          if (cat_data[i].id === parseInt(selected_cat)) {
            setselectedBlogCat({
              label: cat_data[i].name,
              value: cat_data[i].id,
            });
          }
          options.push({ label: cat_data[i].name, value: cat_data[i].id });
        }
        setBlogCategories(options);
      },
      (error) => {
        console.log(error, "--> error in Blog Category");
      }
    );
  }

  function loadPageContent() {
    get(
      `${BASE_URL}/api/tgcl/dashboard/near-page-content/?page_size=${20}&page=${1}&category=${id}`,
      { headers: { "Content-Type": "application/json" } }
    ).then((response) => {
      setPageContents(response.data.results);
    });
  }

  function handleUpdateContent(e, values) {
    let data = {};
    data = {
      content: rawMessage,
      index: values.index,
      sub_cat: document.getElementById("sub_cat").checked,
      category: id,
      heading: values.title,
    };
    setSubmitting(true);
    if (selected_id) {
      let url = "";
      if (contentSection === "page-content") {
        url = `${BASE_URL}/api/tgcl/dashboard/near-page-content/${selected_id.id}/`;
      } else {
        url = `${BASE_URL}/api/tgcl/dashboard/location-page-content/${selected_id.id}/`;
      }
      put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
        validateStatus: false,
      }).then((response) => {
        if (response.status >= 400) {
          console.log(response);
          let errors = Object.entries(response.data);
          errors.map(([key, val] = errors) => toast.error(val[0]));
          setSubmitting(false);
        } else {
          if (contentSection === "page-content") {
            loadPageContent();
          } else {
          }
          setSubmitting(false);
          toast.success("Page Content Updated");
          tog_fullscreen();
        }
      });
    } else {
      let url = "";
      if (contentSection === "page-content") {
        url = `${BASE_URL}/api/tgcl/dashboard/near-page-content/`;
      } else {
        url = `${BASE_URL}/api/tgcl/dashboard/location-page-content/`;
      }
      post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
        validateStatus: false,
      }).then((response) => {
        if (response.status >= 400) {
          console.log(response);
          let errors = Object.entries(response.data);
          errors.map(([key, val] = errors) => toast.error(val[0]));
          setSubmitting(false);
        } else {
          if (contentSection === "page-content") {
            loadPageContent();
          } else {
          }
          setSubmitting(false);
          toast.success("Page Content Added");
          tog_fullscreen();
        }
      });
    }
  }

  function handleSubmit(e, values) {
    const formData = new FormData();
    if (file) {
      formData.append("image", file, file.name);
    }
    if (selectedGroup.value) {
      formData.append("parent_id", selectedGroup.value);
    }
    formData.append("heading", toTitleCase(values.heading));
    formData.append("pg_title", values.pg_title);
    formData.append("pg_desc", values.pg_desc);
    formData.append("desc", category.desc);
    formData.append("keyword", values.keyword);
    formData.append("slug", slug);

    const options = {
      method: "PUT",
      body: formData,
      headers: { Authorization: getAccessToken() },
    };
    fetch(`${BASE_URL}/api/tgcl/headings/${id}/`, options).then((res) => {
      if (res.status >= 400) {
        res.json().then((data) => {
          if (data["slug"]) {
            toast.error("Category with this slug already exists.");
          } else {
            toast.error("Somthing Went Wrong");
          }
        });
      } else {
        res.json().then((data) => {
          setCategory(data);
          setFile(null);
          toast.success(`${data.heading} has been updated`);
        });
      }
    });
  }

  function handleBlogCatSubmit(e, values) {
    let data = {};
    data = {
      blog_heading: selectedBlogCat.value,
      blog_title: values.blog_title,
    };
    if (category.blog_heading) {
      patch(`${BASE_URL}/api/tgcl/headings/${id}/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
        validateStatus: false,
      }).then((response) => {
        if (response.status >= 400) {
          console.log(response);
        } else {
          toast.success("Blog listing Updated");
          loadCategoryData();
        }
      });
    } else {
      data["id_num"] = id;
      post(`${BASE_URL}/api/tgcl/dashboard/add-blog-posts/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
        validateStatus: false,
      }).then((response) => {
        if (response.status >= 400) {
          console.log(response);
        } else {
          toast.success("Added Blog Posts");
          loadCategoryData();
        }
      });
    }
  }

  function handleUpdatePageDescSubmit(e, values) {
    let data = {
      page_heading: values.page_heading,
      desc: values.desc,
      canonical: values.canonical,
    };

    patch(`${BASE_URL}/api/tgcl/headings/${id}/`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAccessToken(),
      },
      validateStatus: false,
    }).then((response) => {
      if (response.status >= 400) {
        console.log(response);
      } else {
        toast.success("Feature Content Updated");
        loadCategoryData();
      }
    });
  }

  function handleDeleteContent(id) {
    let url = "";
    if (contentSection === "page-content") {
      url = `${BASE_URL}/api/tgcl/dashboard/near-page-content/${id}/`;
    } else {
      url = `${BASE_URL}/api/tgcl/dashboard/location-page-content/${id}/`;
    }

    del(
      url,
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
    ).then((res) => {
      if (res.status >= 400) {
        console.log("error");
      } else {
        if (contentSection === "page-content") {
          loadPageContent();
        } else {
        }
        setSuccess_confirm(true);
        setAlert_confirm(false);
        setDynamic_title("Deleted!");
        setDynamic_description("Content Has been deleted.");
        setSelected_id(null);
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title="Categories"
          breadcrumbItem={`Edit ${category ? category.heading : "Category"} `}
          link="/categories"
        />
        {category ? (
          <React.Fragment>
            <Row>
              <Col className="col-6">
                <Card>
                  <CardBody>
                    <CardTitle>Category Detail </CardTitle>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={(e, values) => handleSubmit(e, values)}
                    >
                      <Row>
                        <Col md="12">
                          <div className="mb-3">
                            <Label>Parent</Label>
                            <Select
                              value={selectedGroup}
                              onChange={(e) => {
                                handleSelectGroup(e);
                              }}
                              options={Categories}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="mb-3">
                            <Label htmlFor="name">Category</Label>
                            <AvField
                              name="heading"
                              placeholder="Category"
                              type="text"
                              errorMessage="Enter Category"
                              className="form-control"
                              value={category?.heading}
                              onChange={(e) => setSlug(slugify(e.target.value))}
                              validate={{ required: { value: true } }}
                              id="name"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="mb-3">
                            <Label htmlFor="name">Slug</Label>
                            <AvField
                              name="slug"
                              placeholder="Category"
                              type="text"
                              errorMessage="Enter Category"
                              className="form-control"
                              value={slug}
                              onChange={(e) => setSlug(slugify(e.target.value))}
                              validate={{ required: { value: true } }}
                              id="slug"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <div className="mb-3">
                            <Label htmlFor="name">Meta Title</Label>
                            <AvField
                              name="pg_title"
                              placeholder="Meta Title"
                              type="text"
                              // errorMessage="Enter Keyword"
                              className="form-control"
                              value={category?.pg_title}
                              onChange={(e) => setTitle(e.target.value)}
                              // validate={{ required: { value: true } }}
                              id="pg_title"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <AvField
                            className="mb-3"
                            type="textarea"
                            label="Keyword"
                            rows="3"
                            name="keyword"
                            id="keyword"
                            value={category.keyword ? category.keyword : ""}
                            onChange={(e) => setDesc(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <AvField
                          className="mb-3"
                          type="textarea"
                          label="Meta Description "
                          rows="3"
                          name="pg_desc"
                          id="page_description"
                          value={category.pg_desc ? category.pg_desc : ""}
                          placeholder="Meta Description"
                        />
                      </Row>

                      <Row>
                        <Label htmlFor="icon">Category Icon</Label>
                        <Col sm={6}>
                          <input
                            id="icon"
                            className="mb-3"
                            type="file"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col sm={6}>
                          {file ? (
                            <img
                              src={URL.createObjectURL(file)}
                              alt=""
                              style={{ width: "40%" }}
                              className="m-3"
                            />
                          ) : category.image ? (
                            <img
                              src={category.image}
                              className="rounded avatar-lg"
                              alt=""
                            />
                          ) : null}
                        </Col>
                      </Row>
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-6">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col sm="12" style={{ display: "flex" }}>
                            <div>
                              <img
                                src={icon}
                                style={{
                                  backgroundColor: "#f1f3f4",
                                  border: "1px solid #ecedef",
                                  margin: "5px 12px 0px 0px",
                                  height: "35px",
                                  borderRadius: "50%",
                                  width: "35px",
                                }}
                                alt=""
                              />
                            </div>
                            <div>
                              <span
                                class="VuuXrf"
                                style={{
                                  color: "202124",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                }}
                              >
                                thegoodcontractorslist.com
                              </span>
                              <br />
                              <a
                                href={`${SITE_URL}/near/${slug}`}
                                target={"_blank"}
                                rel="noreferrer"
                                style={{
                                  textDecoration: "None",
                                  color: "gray",
                                  fontSize: "12px",
                                }}
                              >
                                {SITE_URL}
                                <span> › near › {slug}</span>
                              </a>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <h5
                              className="mt-2 LC20lb"
                              style={{ color: "#1a0dab" }}
                            >
                              {title.replace(
                                "(dynamic_location)",
                                "Dallas & Fort Worth"
                              )}
                            </h5>
                            <p>
                              {desc.replace(
                                "(dynamic_location)",
                                "Dallas & Fort Worth"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* Manage Feature Content */}
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <CardTitle>Manage Feature Content </CardTitle>
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={handleUpdatePageDescSubmit}
                        >
                          <Row>
                            <Col md="12">
                              <div className="mb-3">
                                <Label htmlFor="name">Title</Label>
                                <AvField
                                  name="page_heading"
                                  placeholder="Title"
                                  type="text"
                                  errorMessage="Enter Title"
                                  className="form-control"
                                  value={category?.page_heading}
                                  validate={{ required: { value: true } }}
                                  id="page_heading"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <div className="mb-3">
                                <AvField
                                  className="mb-3"
                                  type="textarea"
                                  label="Page Description (This content will display only on matching campaign if available else center of the page. )"
                                  rows="3"
                                  name="desc"
                                  id="desc"
                                  value={category.desc ? category.desc : ""}
                                  placeholder="Page Description"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <div className="mb-3">
                                <Label htmlFor="name">Canonical</Label>
                                <AvField
                                  name="canonical"
                                  placeholder="Canonical"
                                  type="text"
                                  errorMessage="Enter Canonical"
                                  className="form-control"
                                  value={category?.canonical}
                                  validate={{ required: { value: true } }}
                                  id="canonical"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={3}>
                              <Button color="primary" type="submit">
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </AvForm>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* Manage Content Section  */}
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <CardTitle>Manage Page Content </CardTitle>
                      </Col>
                      <Col md="6">
                        <Link
                          to={"#"}
                          onClick={() => {
                            tog_fullscreen();
                            setrawMessage("");
                            setSelected_id(null);
                            setContentSection("page-content");
                          }}
                          className="me-3 text-primary float-end"
                        >
                          <i className="mdi mdi-plus font-size-18"></i> Add New
                          Content
                        </Link>
                      </Col>
                    </Row>

                    {pageContents.map((content) => (
                      <React.Fragment key={content.index}>
                        <hr />

                        <Row className="mt-2">
                          <Col md="6">
                            <p>Index: {content.index}</p>
                          </Col>
                          <Col md="6">
                            <p>
                              Sub Categories: {content.sub_cat ? "Yes" : "No"}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <h4>{content.heading}</h4>
                            {content.content
                              .replace(/<[^>]+>/g, "")
                              .substring(0, 400)}{" "}
                            ...
                          </Col>
                          <Col md="12">
                            <div className="float-end">
                              <a
                                href={`${SITE_URL}/near/${category.slug}`}
                                target="_blank"
                                rel="noreferrer"
                                className="text-dark"
                              >
                                <u>View</u>
                              </a>
                              {" | "}
                              <Link
                                to={"#"}
                                onClick={() => {
                                  tog_fullscreen();
                                  handleContentEdit(content.content);
                                  setSelected_id(content);
                                  setContentSection("page-content");
                                }}
                                className="text-dark"
                              >
                                {" "}
                                <u>Edit</u>
                              </Link>
                              {" | "}
                              <Link
                                to="#"
                                onClick={() => {
                                  setAlert_confirm(true);
                                  setSelected_id(content);
                                  setContentSection("page-content");
                                }}
                                className="text-dark"
                              >
                                <u>Delete</u>{" "}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        <Row></Row>
                      </React.Fragment>
                    ))}
                  </CardBody>
                </Card>
                {/* Manage Blog Section */}
                <Card>
                  <CardBody>
                    <CardTitle>Manage Blogs </CardTitle>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={handleBlogCatSubmit}
                    >
                      <Row>
                        <Col md="12">
                          <div className="mb-3">
                            <Label htmlFor="name">Title</Label>
                            <AvField
                              name="blog_title"
                              placeholder="Title"
                              type="text"
                              errorMessage="Enter Title"
                              className="form-control"
                              value={category?.blog_title}
                              validate={{ required: { value: true } }}
                              id="blog_title"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="mb-3">
                            <Label>
                              Blog Category (you want to show related blogs)
                            </Label>
                            <Select
                              value={selectedBlogCat}
                              onChange={(e) => {
                                setselectedBlogCat(e);
                              }}
                              options={blogcategories}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={3}>
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ActivityLog
              key={"activity-log"}
              id={id}
              url={"/api/tgcl/heading-logs/"}
              filter={"heading"}
            />
          </React.Fragment>
        ) : (
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
        )}
      </div>
      {alert_confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDeleteContent(selected_id.id)}
          onCancel={() => {
            setAlert_confirm(false);
            setSelected_id(null);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {success_confirm ? (
        <SweetAlert
          success
          title={dynamic_title}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setSuccess_confirm(false);
            setAlert_confirm(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Col sm={6} md={4} xl={3}>
        <Modal
          size="xl"
          isOpen={modal_fullscreen}
          toggle={() => {
            tog_fullscreen();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              {selected_id ? "Update Page Content" : "Add Page Content"}
            </h5>
            <button
              onClick={() => {
                setmodal_fullscreen(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col className="col-12">
                <AvForm
                  className="needs-validation"
                  onValidSubmit={handleUpdateContent}
                >
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="name">Title</Label>
                        <AvField
                          name="title"
                          placeholder="Title"
                          type="text"
                          errorMessage="Enter Title"
                          className="form-control"
                          value={selected_id?.heading}
                          validate={{ required: { value: true } }}
                          id="title"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="name">Position</Label>
                        <AvField
                          name="index"
                          placeholder="Position"
                          type="text"
                          className="form-control"
                          value={selected_id ? selected_id?.index + "" : ""}
                          validate={{
                            number: true,
                            required: {
                              value: true,
                              errorMessage: "Please enter position",
                            },
                          }}
                          id="index"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md="6">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={selected_id?.sub_cat}
                          id="sub_cat"
                          required
                        />{" "}
                        <label className="form-check-label" htmlFor="sub_cat">
                          Show Sub Categories
                        </label>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => setShowHTMLChange(e.target.checked)}
                          id="html_content"
                          required
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="html_content"
                        >
                          Show HTML Content
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={showHTMLChange ? "6" : "12"} className="mb-3">
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          heading: {
                            options: [
                              {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                              },
                              {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                              },
                              {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                              },
                            ],
                          },
                          link: {
                            addTargetToExternalLinks: true,
                          },
                        }}
                        data={rawMessage}
                        onChange={(event, editor) => {
                          handleEditorChange(editor);
                        }}
                        onFocus={(event, editor) => {
                          setStateChange("editor");
                        }}
                      />
                    </Col>
                    {showHTMLChange ? (
                      <Col md="6">
                        {stateChange === "text" ? (
                          <AvField
                            className="mb-3"
                            type="textarea"
                            label="HTML Content "
                            rows="16"
                            name="pg_desc"
                            id="page_description"
                            // value={rawMessage}
                            onChange={(e) => {
                              setrawMessage(e.target.value);
                              setStateChange("text");
                            }}
                            placeholder="HTML Content"
                          />
                        ) : (
                          <AvField
                            className="mb-3"
                            type="textarea"
                            // label="HTML Content "
                            rows="16"
                            name="pg_desc"
                            id="page_description"
                            value={rawMessage}
                            onChange={(e) => {
                              setrawMessage(e.target.value);
                              setStateChange("text");
                            }}
                            placeholder="HTML Content"
                          />
                        )}
                      </Col>
                    ) : null}
                  </Row>
                  <p style={{ color: "green" }}>
                    {" "}
                    Use (mobile_read_more) for mobile readmore and
                    (desktop_read_more) for desktop and tab readmore
                  </p>
                  <p style={{ color: "green" }}>
                    {" "}
                    Use (dynamic_location) to show location and
                    (dynamic_category) to show category name
                  </p>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setmodal_fullscreen(false)}
                    >
                      Close
                    </button>
                    {submitting ? (
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light my-3"
                      >
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                        Submitting
                      </button>
                    ) : (
                      <Button color="primary my-3" type="submit">
                        Submit
                      </Button>
                    )}
                  </div>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Modal>
      </Col>
    </React.Fragment>
  );
};

export default EditHeading;
