import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment-timezone";
import { BASE_URL } from "../../helpers/url_helper";
import { Link } from "react-router-dom"


class ActivityLog extends Component {
    constructor(props) {
        super(props);
        this.url = this.props.url
        this.id = this.props.id
        this.filter = this.props.filter

        this.state = {
            Activity_log:[],
            count:0,
            page_size:3,
        }
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }   

    componentDidCatch(error) {
        console.log(error)
    }

    async componentDidMount() { 
        this.loadItineraryLogs(`?${this.filter}=${this.id}&size=${this.state.page_size}`)  

    }

    async loadItineraryLogs(filter_data){

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',},
        };
        await fetch(`${BASE_URL}${this.url}${filter_data}`,requestOptions)
            .then(res => res.json())
            .then(
                (data) => {   
                    this.setState({ Activity_log: data.results, count: data.count, loading:false })          
                    
                },
                (error) => {
                    console.log(error, 'error--------------') 
                }
            )  
    }

    handleLoadMore(){
        const { count, page_size } = this.state
        if(page_size+3 < count){
            this.setState({ page_size:page_size+3, loading:true})
            this.loadItineraryLogs(`?${this.filter}=${this.id}&size=${page_size+3}`)
        }else{
            this.loadItineraryLogs(`?${this.filter}=${this.id}&size=${count}`)
            this.setState({ page_size:count, loading:true})

        }
    }

    render() {
        const { Activity_log, count, loading, page_size } = this.state
        return (
            <React.Fragment key={"activity-Detail"}>
              
                
                <Row>
                    <Col xs={12}>
                    <h4 className='my-2'>Activity Log</h4>
                        <Card>
                            <CardBody>
                                {Activity_log.length === 0?
                                <p><b>Not Available</b></p>
                                :
                                Activity_log.map((log) => {
                                   return  (
                                    
                                    <p><b>Changed By: </b> {log.user.email} at {moment(log.datetime).tz("America/Chicago").format('MM-DD-YYYY hh:mm A')}</p>
                                )})}
                                {loading?
                                    <Link to="#" className="text-primary">
                                        <i className="mdi mdi-loading mdi-spin font-size-20 align-middle me-2"></i>{" "}
                                        Loading{" "}
                                    </Link>
                                :null}
                                {page_size !== count && count > 3 && !loading? 
                                <Link to="#" onClick={() => this.handleLoadMore()} className="text-primary">
                                    Load more
                                </Link>:null}

                               
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>
            
        );
    }
}

export default ActivityLog;