import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody,CardTitle, Button, Label, Spinner, TabContent,
    TabPane, NavLink, NavItem, Nav, Modal } from "reactstrap"
import { Link } from "react-router-dom"
// Editable
import { toast } from 'react-toastify';
import Select from "react-select";
import classnames from "classnames"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { get, put, post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { toTitleCase } from "../../helpers/methods";
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"

import moment from 'moment';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { CallStatusOptions, JobStagesOptions, IOMOption, AMPMOption } from "./Options";



const CallNotes = (props) => {

    const { id } = props.match.params;
    const [callNotes, setCallNotes] = useState([]);
    const [emailForms, setEmailForms] = useState(null);
    const [selectedEmail, setSelectedEmail] = useState(false);
    const [totalSize, settotalSize] = useState(0);
    const [newCallNotes, setNewCallNotes] = useState(false)
    const [nextPage, setNext] = useState(null)
    const [previous, setPrevious] = useState(null)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [selectedNote, setSelectedNote] = useState(null)
    const [job_stages_time_ampm, setjob_stages_time_ampm] = useState({ label: "AM", value: "AM" })
    const [notes_time_ampm, setnotes_time_ampm] = useState({ label: "AM", value: "AM" })
    const [call_time_ampm, setcall_time_ampm] = useState({ label: "AM", value: "AM" })
    const [original_time_ampm, setoriginal_time_ampm] = useState({ label: "AM", value: "AM" })
    const [job_stages, setjob_stages] = useState({ value:'job-stages-1', label:'Left Message Awaiting Call Back'})
    const [pos_neg, setpos_neg] = useState({ label: "Connected", value: "connected" })
    const [in_out, setin_out] = useState({ label: "Incoming Call", value: "incoming" })
    const [contractor, setContractor] = useState({ label: "Select Contractor", value: null })
    const [contractorGroup, setContractorGroup] = useState([])
    const [loading, setLoading] = useState(false)
    const [activeTabJustify, setactiveTabJustify] = useState("5")
    const [modal_center, setmodal_center] = useState(false)
    const [audio, setAudio] = useState("")
    const [filter, setFilter] = useState(false)
    const [filterurl, setFilterUrl] = useState(null)
    
    

    ValidateUser(props)

    useEffect(() => {
        if (localStorage.getItem("CommNotesFilter")) {
            const obj = JSON.parse(localStorage.getItem("CommNotesFilter"))
            setFilter(obj)
            let url = obj.filter.url
            url = url.split("&notes_date")[0]
            url = url.split("&call_date_start")[0]
            url = url.split("&original_call_date")[0]
            url = url.split("&in_out=")[0]
            if (obj.inOut.checked && ['incoming', 'outgoing'].includes(obj.inOut.in_out.value)){
                url = url+`&in_out=${obj.inOut.in_out.value}`
            }else{
                url = url+`&in_out=incoming,outgoing`
            }
            setFilterUrl(url)

            loadSelectedNote(id,  url)
        }else{
            loadSelectedNote(id)
        }
        
        getContractors()
        
    }, []);

    function tog_center() {
        setmodal_center(!modal_center)
        removeBodyCss()
    }
    
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }



    function loadData(url){
        get(url, { headers: { 'Content-Type': 'application/json', }} )
        .then(response => {
            setCallNotes(response.data.results)
            settotalSize(response.data.count)
            setNext(response.data.next)
            setPrevious(response.data.previous)
            setLoading(true)
        })
    }

    function getContractors(search=null){
        let url = `${BASE_URL}/api/tgcl/contractor/?approved=true`
        if (search){
        url = url + `&search=${search}`
        }
        get(url, { headers: { 'Content-Type': 'application/json', }} )
        .then(response => {
        let data  = response.data.results
        let options = []
        for (let i=0; i<data.length; i++){
            options.push({ label: data[i].bus_name, value: data[i].bus_name })
        }
        
        let Contractors  = [{
            label: "Contractors",
            options: options
        }]
        setContractorGroup(Contractors)
        })
    }

    function loadSelectedNote(id, filter_url = null){
        
        get(`${BASE_URL}/api/tgcl/callnotes/${id}/`, { headers: { 'Content-Type': 'application/json', }} )
        .then(response => {
            if (filter_url){
                let url = filter_url+`&page_size=${sizePerPage}&search=${response.data.phone_numbers}` 
                loadData(url)
                
                loadAssociatedEmails(`${filter_url}&page_size=${sizePerPage}&search=${response.data.phone_numbers}&in_out=email`)

            }else{
                loadData(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}&search=${response.data.phone_numbers}&in_out=incoming,outgoing`)
                loadAssociatedEmails(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}&search=${response.data.phone_numbers}&in_out=email`)
            }
            
            setnotes_time_ampm({ label: response.data.notes_time_ampm, value: response.data.notes_time_ampm })
            setcall_time_ampm({ label: response.data.call_time_ampm, value: response.data.call_time_ampm })
            setpos_neg({ label: toTitleCase(response.data.pos_neg), value: response.data.pos_neg })
            setin_out({ label: toTitleCase(response.data.in_out), value: response.data.in_out })
            if (response.data.original_call_date){
                setoriginal_time_ampm({ label: (response.data.original_call_date).split(" ").at(-1), value: (response.data.original_call_date).split(" ").at(-1) })
            }
            let jobstage = getJobStage(response.data)
            if (jobstage.value){
                setjob_stages(jobstage)
            }
            // loadAssociatedEmails(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}&in_out=email`)
            // loadAssociatedEmails(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}&search=${response.data.phone_numbers}&in_out=email`)
            
            let contractor_name = (response.data.contractor_name).split("(")
            if (contractor_name[0] !== ""){
                get(`${BASE_URL}/api/tgcl/all-contractors?approved=true&bus_name=${contractor_name[0].trim()}`, { headers: { 'Content-Type': 'application/json', }} )
                .then(resp => {
                let contract = resp.data.results
                if (contract.length >0){
                setContractor({ label: contract[0].bus_name, value: contract[0].id })
            
                }
            })

            }

            
            setSelectedNote(response.data)
        
        })

    }

    function loadAssociatedEmails(url){
            get(url, { headers: { 'Content-Type': 'application/json', }} )
            .then(response => {
                setEmailForms(response.data)
            })
    }

    function getJobStage(stage){

        if(stage.job_stages === "job-stages-1"){
            return ({ value:'job-stages-1', label:'Left Message Awaiting Call Back'})
        }else if(stage.job_stages === "job-stages-2"){
            return ({ value:'job-stages-2', label:'Contractor Called Back – Awaiting Homeowner Response'})
        }else if(stage.job_stages === "job-stages-3"){
            return ({ value:'job-stages-3', label:'Scheduled Appointment'})
        }else if(stage.job_stages === "job-stages-4"){
            return ({ value:'job-stages-4', label:'Spoke with Contractor Went different Route'})
        }else if(stage.job_stages === "job-stages-5"){
            return ({ value:'job-stages-5', label:'Contractor Missed Appointment'})
        }else if(stage.job_stages === "job-stages-6"){
            return ({ value:'job-stages-6', label:'Waiting On Estimate'})
        }else if(stage.job_stages === "job-stages-7"){
            return ({ value:'job-stages-7', label:'Received Estimate Thinking About It'})
        }else if(stage.job_stages === "job-stages-8"){
            return ({ value:'job-stages-8', label:'Signed Contract Scheduled Start Time'})
        }else if(stage.job_stages === "job-stages-9"){
            return ({ value:'job-stages-9', label:'Work Started Expected Completion Date'})
        }else if(stage.job_stages === "job-stages-10"){
            return ({ value:'job-stages-10', label:'Job Completed – Satisfied'})
        }else if(stage.job_stages === "job-stages-11"){
            return ({ value:'job-stages-11', label:'Job Completed - Unsatisfied'})
        }else if(stage.job_stages === "job-stages-12"){
            return ({ value:'job-stages-12', label:'Chose a Contractor Outside Your Organization'})
        }else if(stage.job_stages === "job-stages-13"){
            return ({ value:'job-stages-13', label:'Would You Like A Follow Up from Us?'})
        }else{
            return ({ value:null, label:''})
        }

    }

    function getRecording(sid){

        get(`${BASE_URL}/api/tgcl/dashboard/get-recording/?sid=${sid}`, { headers: { 'Content-Type': 'application/json', }} )
        .then(response => {
          if (response.status< 300){
            let media_url = response.data.recordings[0]
            if (media_url){
              media_url = media_url.media_url
              setAudio(`${media_url}.mp3`)
              tog_center()
            }else{
                toast.error("Audio Not Found")
            }
          }else{
            toast.error("Invalid Call SID")
          }
        }).catch((error) => {

            toast.error("Invalid Call SID")
          });
       
      }


  

    function handleSubmitCallNotes(e, values, id){
        if (!contractor.value){
        alert("Please Select Contractor")
        }else{
        let data = {
            "contractor_name": contractor.label,
            "customer_name": values.customer_name,
            "email": values.email,
            "phone_numbers": values.phone_numbers,
            "zip_code": values.zip_code,
            "zone": 13,
            "call_date": values.call_date,
            "call_time_h":  values.call_time_h,
            "call_time_i": values.call_time_i,
            "call_time_ampm": call_time_ampm.value,
            "call_date_str": moment(values.call_date+' '+values.call_time_h+':'+values.call_time_i+' '+call_time_ampm.value).format('X'),
            "notes_date": values.notes_date,
            "notes_time_h": values.notes_time_h,
            "notes_time_i": values.notes_time_i,
            "notes_time_ampm": notes_time_ampm.value,
            "notes_date_str": moment(values.notes_date+' '+values.notes_time_h+':'+values.notes_time_i+' '+notes_time_ampm.value).format('X'),
            "original_call_date": `${values.original_call_date} ${values.original_time_h} ${values.original_time_i} ${original_time_ampm.value}`,
            "in_out": in_out.value,
            "pos_neg": pos_neg.value,
            "description": values.description,
            "rep_name": values.rep_name,
            "job_stages": job_stages.value,
            'sid': values.sid

        }
        if(["job-stages-3", "job-stages-6", "job-stages-8", "job-stages-9", "job-stages-13"].includes(job_stages.value)){
            data["job_stages_date"] = values.job_stages_date
        }
        if(job_stages.value==="job-stages-3"){
            data["job_stages_time_h"] = values.job_stages_time_h
            data["job_stages_time_i"] = values.job_stages_time_i
            data["job_stages_time_ampm"] = job_stages_time_ampm.value
        }
        if(id){
            data['status'] = selectedNote.status
            put(`${BASE_URL}/api/tgcl/callnotes/${id}/`, data, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false} )
            .then(response => {
            if(response.status >= 400){
                toast.error('Somthing Went Wrong');

            }else{
                loadData(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}&search=${selectedNote.phone_numbers}`)
                setNewCallNotes(false)
                toast.success('Note has been Updated');
            }
            })
        }else{
            data['status'] = 1
            post(`${BASE_URL}/api/tgcl/callnotes/`, data, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false} )
            .then(response => {
            if(response.status >= 400){
                toast.error('Somthing Went Wrong');
            }else{
                loadData(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}&search=${selectedNote.phone_numbers}`)
                setNewCallNotes(false)
                toast.success('Note is Created');
            }
            })
        }
        }
    }

    function ChangeReview(checkbox_id, noteid){
        let data = {'status':document.getElementById(checkbox_id).checked?2:1}
        put(`${BASE_URL}/api/tgcl/callnotes/${noteid}/`, data, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false} )
            .then(response => {
            if(response.status >= 400){
            }
            })

    }

    function handlePrint(id){
        var content = document.getElementById(id);
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    function toggleCustomJustified(tab) {
        if (activeTabJustify !== tab) {
        setactiveTabJustify(tab)
        }
    }
 

    return (
        <React.Fragment>
        <div className="page-content">

        <Breadcrumbs title="Comm. Notes" breadcrumbItem={`Edit ${"Comm. Note"} `} link="/comm-notes" />
            <Row>
                {selectedNote?
            <Col xs="12">
                <Row>
                    
                    <Col md={4}>
                        <Card >
                            <CardBody>
                            <Row>
                                <Col sm={6}>
                                    <CardTitle>{newCallNotes?"Add":"Update"} Call Note </CardTitle>
                                </Col>
                                <Col sm={6}>
                                    {newCallNotes?<button
                                        type="button"
                                        onClick={() => {
                                            setNewCallNotes(false)
                                            setSelectedEmail(false)
                                        }}
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        >
                                        <span aria-hidden="true">&times;</span>
                                    </button>:null}
                                </Col>
                            </Row>
                            
                            <AvForm className="needs-validation" onValidSubmit={(e, values) => handleSubmitCallNotes(e, values, newCallNotes?null:selectedNote.id)}>
                                <div className="modal-body">
                                {selectedEmail?
                                <>
                                <Row>

                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Contractors</Label>
                                            <Select
                                            onInputChange={(e)=> getContractors(e)}
                                            value={contractor}
                                            onChange={(e) => {
                                                setContractor(e);
                                            }}
                                            options={contractorGroup}
                                            classNamePrefix="select2-selection"

                                            />
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label htmlFor="title">Customer Associated Phone #("," seprated)</Label>
                                        <AvField
                                            name="phone_numbers"
                                            placeholder="+19723******"
                                            type="text"
                                            errorMessage="Enter Customer Phone"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="phone_numbers"
                                            value = {selectedEmail.phone}
                                        />
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label htmlFor="title">Customer Name</Label>
                                        <AvField
                                            name="customer_name"
                                            placeholder="Customer Name"
                                            type="text"
                                            errorMessage="Enter Customer Name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="customer_name"
                                            value = {selectedEmail.name}
                                        />
                                        </div>
                                    </Col>
                                    </Row>



                                    <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="order">Call Date</Label>
                                            <AvField
                                                name="call_date"
                                                placeholder="Call date"
                                                type="date"
                                                errorMessage=" Please provide a valid Order."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="call_date"
                                                
                                            />
                                        </div>
                                    </Col>

                                    </Row>
                                    <Row>
                                    <Label>Call Time</Label>

                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="call_time_h"
                                                placeholder="Hours"
                                                type="text"
                                                errorMessage=" Please provide a valid Hour."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 1}, max: {value: 12} }}
                                                id="call_time_h"
                                                
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="call_time_i"
                                                placeholder="Minuts"
                                                type="text"
                                                errorMessage=" Please provide a valid Minuts."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 1}, max: {value: 60} }}
                                                id="call_time_i"
                                                
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Select
                                            value={call_time_ampm}
                                            onChange={(e) => {
                                                setcall_time_ampm(e);
                                            }}
                                            options={AMPMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>   
                                    </Col>

                                    </Row>
                                    <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="order">Date Note was taken</Label>
                                            <AvField
                                                name="notes_date"
                                                type="date"
                                                errorMessage=" Please provide a valid Date."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="notes_date"
                                            />
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Label>Time Note was taken</Label>

                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="notes_time_h"
                                                placeholder="Hours"
                                                type="text"
                                                errorMessage=" Please provide a valid Hour."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                                id="notes_time_h"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="notes_time_i"
                                                placeholder="Minuts"
                                                type="text"
                                                errorMessage=" Please provide a valid Minuts."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                                id="notes_time_i"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Select
                                            value={notes_time_ampm}
                                            onChange={(e) => {
                                                setnotes_time_ampm(e)
                                            }}
                                            options={AMPMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>   
                                    </Col>

                                    </Row>
                                    <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Job Stages</Label>
                                            <Select
                                            value={job_stages}
                                            onChange={(e) => {
                                                setjob_stages(e);
                                            }}
                                            options={JobStagesOptions}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    </Row>
                                    {["job-stages-3", "job-stages-6", "job-stages-8", "job-stages-9", "job-stages-13"].includes(job_stages.value)?
                                    <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label>Job Stage date</Label>
                                        <AvField
                                            name="job_stages_date"
                                            placeholder="Date"
                                            type="date"
                                            errorMessage="Date"
                                            className="form-control"
                                            validate={{ required: { value: true }}}
                                            id="job_stages_date"
                                        />
                                        </div>
                                    </Col>
                                    </Row>: null}
                                    {job_stages.value === "job-stages-3"?
                                    <Row>
                                    <Label>Job Stage Time</Label>

                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="job_stages_time_h"
                                                placeholder="Hours"
                                                type="text"
                                                errorMessage=" Please provide a valid Hour."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                                id="job_stages_time_h"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="job_stages_time_i"
                                                placeholder="Minuts"
                                                type="text"
                                                errorMessage=" Please provide a valid Minuts."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                                id="job_stages_time_i"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Select
                                            value={job_stages_time_ampm}
                                            onChange={(e) => {
                                                setjob_stages_time_ampm(e)
                                            }}
                                            options={AMPMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>   
                                    </Col>

                                    </Row>: null}
                                    {/* lkljlkjlk */}
                                    <Row>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <Label>Call Status</Label>
                                            <Select
                                            value={pos_neg}
                                            onChange={(e) => {
                                                setpos_neg(e);
                                            }}
                                            options={CallStatusOptions}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <Label>Incoming/Outgoing/Email</Label>
                                            <Select
                                            value={in_out}
                                            onChange={(e) => {
                                                setin_out(e);
                                            }}
                                            options={IOMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="title">Email</Label>
                                                <AvField
                                                    name="email"
                                                    placeholder="Email"
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    value = {selectedNote.email}
                                                />
                                            </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="title">CS Rep Name</Label>
                                                <AvField
                                                    name="rep_name"
                                                    placeholder="CS Rep Name"
                                                    type="text"
                                                    className="form-control"
                                                    id="rep_name"
                                                />
                                            </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="title">Zip Code</Label>
                                                <AvField
                                                    name="zip_code"
                                                    placeholder="Zip Code"
                                                    type="text"
                                                    className="form-control"
                                                    id="zip_code"
                                                    value = {selectedNote.email}
                                                />
                                            </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="order">Original Call Date</Label>
                                            <AvField
                                                name="original_call_date"
                                                type="date"
                                                errorMessage=" Please provide a valid Date."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="original_call_date"
                                            />
                                        </div>
                                    </Col>
                                    </Row>

                                    <Row>
                                    <Label>Original Call Time</Label>

                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="original_time_h"
                                                placeholder="Hours"
                                                type="text"
                                                errorMessage=" Please provide a valid Hour."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                                id="original_time_h"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="original_time_i"
                                                placeholder="Minuts"
                                                type="text"
                                                errorMessage=" Please provide a valid Minuts."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                                id="original_time_i"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Select
                                            value={original_time_ampm}
                                            onChange={(e) => {
                                                setoriginal_time_ampm(e)
                                            }}
                                            options={AMPMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>   
                                    </Col>

                                    

                                    </Row>
                                    <Row>
                                        <AvField
                                            className="mb-3"
                                            type="textarea"
                                            label="Notes "
                                            rows="2"
                                            name="description"
                                            id="description"
                                            placeholder="Notes"
                                            value = {selectedEmail.needs}
                                        />
                                    </Row>
                                </>
                                :
                                <>
                                <Row>

                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Contractors</Label>
                                            <Select
                                            onInputChange={(e)=> getContractors(e)}
                                            value={contractor}
                                            onChange={(e) => {
                                                setContractor(e);
                                            }}
                                            options={contractorGroup}
                                            classNamePrefix="select2-selection"

                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label htmlFor="title">Customer Associated Phone #("," seprated)</Label>
                                        <AvField
                                            name="phone_numbers"
                                            placeholder="+19723******"
                                            type="text"
                                            errorMessage="Enter Customer Phone"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="phone_numbers"
                                            value = {selectedNote? selectedNote.phone_numbers:""}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label htmlFor="title">Customer Name</Label>
                                        <AvField
                                            name="customer_name"
                                            placeholder="Customer Name"
                                            type="text"
                                            errorMessage="Enter Customer Name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="customer_name"
                                            value = {selectedNote? selectedNote.customer_name:""}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="order">Call Date</Label>
                                            <AvField
                                                name="call_date"
                                                placeholder="Call date"
                                                type="date"
                                                errorMessage=" Please provide a valid Order."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="call_date"
                                                value = {selectedNote? selectedNote.call_date:""}
                                            />
                                        </div>
                                    </Col>
                                
                                </Row>
                                <Row>
                                    <Label>Call Time</Label>
                                    
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="call_time_h"
                                                placeholder="Hours"
                                                type="text"
                                                errorMessage=" Please provide a valid Hour."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 1}, max: {value: 12} }}
                                                id="call_time_h"
                                                value = {selectedNote? selectedNote.call_time_h:""}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="call_time_i"
                                                placeholder="Minuts"
                                                type="text"
                                                errorMessage=" Please provide a valid Minuts."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 1}, max: {value: 60} }}
                                                id="call_time_i"
                                                value = {selectedNote? selectedNote.call_time_i:""}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Select
                                            value={call_time_ampm}
                                            onChange={(e) => {
                                                setcall_time_ampm(e);
                                            }}
                                            options={AMPMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>   
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="order">Date Note was taken</Label>
                                            <AvField
                                                name="notes_date"
                                                type="date"
                                                errorMessage=" Please provide a valid Date."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="notes_date"
                                                value = {selectedNote? selectedNote.notes_date:""}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label>Time Note was taken</Label>
                                    
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="notes_time_h"
                                                placeholder="Hours"
                                                type="text"
                                                errorMessage=" Please provide a valid Hour."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                                id="notes_time_h"
                                                value = {selectedNote? selectedNote.notes_time_h:""}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="notes_time_i"
                                                placeholder="Minuts"
                                                type="text"
                                                errorMessage=" Please provide a valid Minuts."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                                id="notes_time_i"
                                                value = {selectedNote? selectedNote.notes_time_i:""}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Select
                                            value={notes_time_ampm}
                                            onChange={(e) => {
                                                setnotes_time_ampm(e)
                                            }}
                                            options={AMPMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>   
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label>Job Stages</Label>
                                            <Select
                                            value={job_stages}
                                            onChange={(e) => {
                                                setjob_stages(e);
                                            }}
                                            options={JobStagesOptions}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {["job-stages-3", "job-stages-6", "job-stages-8", "job-stages-9", "job-stages-13"].includes(job_stages.value)?
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label>Job Stage date</Label>
                                        <AvField
                                            name="job_stages_date"
                                            placeholder="Date"
                                            type="date"
                                            errorMessage="Date"
                                            className="form-control"
                                            validate={{ required: { value: true }}}
                                            id="job_stages_date"
                                            value = {selectedNote? selectedNote.job_stages_date:""}
                                        />
                                        </div>
                                    </Col>
                                </Row>: null}
                                {job_stages.value === "job-stages-3"?
                                <Row>
                                    <Label>Job Stage Time</Label>
                                    
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="job_stages_time_h"
                                                placeholder="Hours"
                                                type="text"
                                                errorMessage=" Please provide a valid Hour."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                                id="job_stages_time_h"
                                                value = {selectedNote? selectedNote.job_stages_time_h:""}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="job_stages_time_i"
                                                placeholder="Minuts"
                                                type="text"
                                                errorMessage=" Please provide a valid Minuts."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                                id="job_stages_time_i"
                                                value = {selectedNote? selectedNote.job_stages_time_i:""}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Select
                                            value={job_stages_time_ampm}
                                            onChange={(e) => {
                                                setjob_stages_time_ampm(e)
                                            }}
                                            options={AMPMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>   
                                    </Col>

                                </Row>: null}
                                {/* lkljlkjlk */}
                                <Row>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <Label>Call Status</Label>
                                            <Select
                                            value={pos_neg}
                                            onChange={(e) => {
                                                setpos_neg(e);
                                            }}
                                            options={CallStatusOptions}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <Label>Incoming/Outgoing/Email</Label>
                                            <Select
                                            value={in_out}
                                            onChange={(e) => {
                                                setin_out(e);
                                            }}
                                            options={IOMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="title">Email</Label>
                                                <AvField
                                                    name="email"
                                                    placeholder="Email"
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    value = {selectedNote? selectedNote.email:""}
                                                />
                                            </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="title">CS Rep Name</Label>
                                                <AvField
                                                    name="rep_name"
                                                    placeholder="CS Rep Name"
                                                    type="text"
                                                    className="form-control"
                                                    id="rep_name"
                                                    value = {newCallNotes?"":selectedNote.rep_name}
                                                />
                                            </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                            <div className="mb-3">
                                                <Label htmlFor="title">Zip Code</Label>
                                                <AvField
                                                    name="zip_code"
                                                    placeholder="Zip Code"
                                                    type="text"
                                                    className="form-control"
                                                    id="zip_code"
                                                    value = {selectedNote? selectedNote.zip_code:""}
                                                />
                                            </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="order">Original Call Date</Label>
                                            <AvField
                                                name="original_call_date"
                                                type="date"
                                                errorMessage=" Please provide a valid Date."
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="original_call_date"
                                                value = {selectedNote? selectedNote.original_call_date?(selectedNote.original_call_date).split(" ")[0]:"":""}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Label>Original Call Time</Label>
                                    
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="original_time_h"
                                                placeholder="Hours"
                                                type="text"
                                                errorMessage=" Please provide a valid Hour."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                                id="original_time_h"
                                                value = {selectedNote? selectedNote.original_call_date?(selectedNote.original_call_date).split(" ")[1]:"":""}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <AvField
                                                name="original_time_i"
                                                placeholder="Minuts"
                                                type="text"
                                                errorMessage=" Please provide a valid Minuts."
                                                className="form-control"
                                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                                id="original_time_i"
                                                value = {selectedNote?selectedNote.original_call_date?(selectedNote.original_call_date).split(" ")[2]:"":""}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Select
                                            value={original_time_ampm}
                                            onChange={(e) => {
                                                setoriginal_time_ampm(e)
                                            }}
                                            options={AMPMOption}
                                            classNamePrefix="select2-selection"
                                            />
                                        </div>   
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                        <Label htmlFor="title">Call SID</Label>
                                        <AvField
                                            name="sid"
                                            placeholder="Call SID"
                                            type="text"
                                            errorMessage="Enter Call SID"
                                            className="form-control"
                                            id="sid"
                                            value = {!newCallNotes && selectedNote? selectedNote.sid:""}
                                        />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <AvField
                                        className="mb-3"
                                        type="textarea"
                                        label="Notes "
                                        rows="2"
                                        name="description"
                                        id="description"
                                        placeholder="Notes"
                                        value = {selectedNote? selectedNote.description:""}
                                    />
                                </Row>
                                </>}
                                
                                
                                    
                                </div>
                                <div className="modal-footer">
                                    <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                    >
                                    {newCallNotes?"Create":"Update"}
                                    </button>
                            
                                
                                </div>
                            </AvForm>

                            </CardBody>
                        </Card>
                    </Col>
                    
                    <Col md={8}>
                    {loading?
                        <>
                            <Card>
                                <CardBody>
                                    <h5>Found {totalSize} Notes and {emailForms?emailForms.count:0} Emails Associated with: {selectedNote.phone_numbers} </h5>
                                </CardBody>
                            </Card>
                            <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTabJustify === "5",
                                })}
                                onClick={() => {
                                    toggleCustomJustified("5")
                                }}
                                >
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Calls Notes</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTabJustify === "6",
                                })}
                                onClick={() => {
                                    toggleCustomJustified("6")
                                }}
                                >
                                <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                <span className="d-none d-sm-block">Email Notes</span>
                                </NavLink>
                            </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTabJustify}>
                            <TabPane tabId="5">
                            {callNotes.map((callNote, index) => (
                                    <Card key={index}>
                                        <CardBody>
                                            <Row>
                                                <Col sm={6}>
                                                    <Row>
                                                        <Col sm={4}>
                                                        <b className="mt-2">Read Status: </b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <label className="toggler-wrapper style-6">
                                                            <input type="checkbox" id={`checkbox-${index}`} defaultChecked={callNote.status===2?true:false} onChange={()=> ChangeReview(`checkbox-${index}`, callNote.id)}/>
                                                                <div className="toggler-slider">
                                                                    <div className="toggler-knob"></div>
                                                                </div>
                                                            </label>

                                                        </Col>
                                                    </Row>
                                                
                                                </Col>
                                                <Col sm={6}>
                                                    <Link to="#" onClick={() => {setNewCallNotes(true); setSelectedEmail(false); loadSelectedNote(callNote.id, filterurl)}} className="text-success me-3"><i className="mdi mdi-plus font-size-18"></i>Create</Link>
                                                    <Link to="#" onClick={() => {loadSelectedNote(callNote.id, filterurl); setSelectedEmail(false); setNewCallNotes(false) }} className="text-info me-3"><i className="mdi mdi-grease-pencil font-size-18"></i>Edit</Link>
                                                    <Link to="#" onClick={() => handlePrint(`divcontents${index}`)} className="text-dark me-3"><i className="mdi mdi-printer font-size-18"></i>Print</Link>
                                                    {callNote.sid && callNote.sid !== "" ?
                                                    <Link to="#" onClick={() => getRecording(callNote.sid)} className="text-danger me-3"><i className="fas fa-play-circle font-size-18"></i>Listin</Link>
                                                    :null}
                                                </Col>
                                            </Row>
                                            <Row className="mt-2" id={"divcontents"+index}>
                                                <Col sm={6}>
                                                    <b className="mt-5">Original Call Date:</b> {callNote.original_call_date}<br />
                                                    <b className="mt-5">Call Date:</b> {moment(callNote.call_date).tz("America/Chicago").format('MM-DD-YYYY')} <br />
                                                    <b className="mt-5">Call Time:</b> {`${callNote.call_time_h} ${callNote.call_time_i} ${callNote.call_time_ampm}`} <br />
                                                    <b className="mt-5">Note Date:</b> {moment(callNote.notes_date).tz("America/Chicago").format('MM-DD-YYYY')}<br />
                                                    <b className="mt-5">Note Time:</b> {`${callNote.notes_time_h} ${callNote.notes_time_i} ${callNote.notes_time_ampm}`} <br />
                                                    <b className="mt-5">Incoming - Associated Phone #:</b> {callNote.phone_numbers} <br />
                                                    <b className="mt-5">Job Stage:</b> {getJobStage(callNote).label }<br />
                                                </Col>
                                                <Col sm={6}>
                                                    <b className="mt-5">Contractor Associated:</b> {callNote.contractor_name} <br />
                                                    <b className="mt-5">Customers Name:</b> {callNote.customer_name}<br />
                                                    <b className="mt-5">Zip Code:</b> {callNote.zip_code}<br />
                                                    <b className="mt-5">CS Rep:</b> {callNote.rep_name} <br />
                                                    <b className="mt-5">Notes:</b> {callNote.description} <br />
                                                </Col>

                                            </Row>
                                        
                                        </CardBody>
                                    </Card>

                                ))}
                                <Row className="mb-2">
                                    <Col sm="6">
                                        {previous?
                                        <div className="">
                                            <Button
                                            type="button"
                                            color="dark"
                                            className="btn-outline btn-sm mb-2 me-2"
                                            onClick={() => loadData(previous)}
                                            >
                                            {" "}
                                            {`<<< Previous`}
                                            </Button>
                                        </div>:null}    
                                    </Col>
                                    <Col sm="6">
                                        {nextPage?
                                        
                                        <div className="text-sm-end">
                                            <Button
                                                type="button"
                                                color="dark"
                                                className="btn-outline btn-sm mb-2 me-2"
                                                onClick={() => loadData(nextPage)}
                                                >
                                        
                                                {`Next >>>`}
                                            </Button>
                                            {" "}
                                            Found {totalSize} notes matching your query

                                        </div>:null}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="6">
                            {emailForms?.results?.map((email, index) => (
                                    // <Card key={email.id}>
                                    //     <CardBody>
                                    //         <Row>
                                    //             <Col sm={6}>
                                    //             </Col>
                                    //             <Col sm={6}>
                                    //                 <Link to="#" onClick={() => {setNewCallNotes(true); setSelectedEmail(email)}} className="text-success me-3 float-end"><i className="mdi mdi-plus font-size-18"></i>Create</Link>
                                    //             </Col>
                                    //         </Row>
                                    //         <Row className="mt-2" id={"divcontents"+index}>
                                    //             <Col sm={6}>
                                    //                 <b className="mt-5">Mail Date:</b> {(email.created).split('T')[0]}<br />
                                    //                 <b className="mt-5">Incoming - Associated Phone #:</b> {email.phone} <br />
                                    //                 <b className="mt-5">Customers Name:</b> {email.name}<br />
                                    //                 <b className="mt-5">Email</b> {email.email} <br />
                                                    
                                    //             </Col>
                                    //             <Col sm={6}>
                                                
                                                    
                                    //                 <b className="mt-5">Address</b> {email.address} <br />
                                    //                 <b className="mt-5">City</b> {email.city} <br />
                                    //                 <b className="mt-5">State</b> {email.state} <br />
                                    //                 <b className="mt-5">Zip Code:</b> {email.zip}<br />
                                    //                 <b className="mt-5">Contracted By</b> {email.contacted_by }<br />
                                                
                                    //             </Col>


                                    //         </Row>
                                    //         <b className="mt-5">Notes:</b> {email.needs} <br />
                                        
                                    //     </CardBody>
                                    // </Card>
                                    <Card key={index}>
                                        <CardBody>
                                            <Row>
                                                <Col sm={6}>
                                                    <Row>
                                                        <Col sm={4}>
                                                        <b className="mt-2">Read Status: </b>
                                                        </Col>
                                                        <Col sm={8}>
                                                            <label className="toggler-wrapper style-6">
                                                            <input type="checkbox" id={`email-${index}`} defaultChecked={email.status===2?true:false} onChange={()=> ChangeReview(`email-${index}`, email.id)}/>
                                                                <div className="toggler-slider">
                                                                    <div className="toggler-knob"></div>
                                                                </div>
                                                            </label>

                                                        </Col>
                                                    </Row>
                                                
                                                </Col>
                                                <Col sm={6}>
                                                    <Link to="#" onClick={() => {setNewCallNotes(true); setSelectedEmail(false); loadSelectedNote(email.id, filterurl)}} className="text-success me-3"><i className="mdi mdi-plus font-size-18"></i>Create</Link>
                                                    <Link to="#" onClick={() => {loadSelectedNote(email.id, filterurl); setSelectedEmail(false); setNewCallNotes(false) }} className="text-info me-3"><i className="mdi mdi-grease-pencil font-size-18"></i>Edit</Link>
                                                    <Link to="#" onClick={() => handlePrint(`divcontentemail${index}`)} className="text-dark me-3"><i className="mdi mdi-printer font-size-18"></i>Print</Link>
                            
                                                </Col>
                                            </Row>
                                            <Row className="mt-2" id={"divcontentemail"+index}>
                                                <Col sm={6}>
                                                    <b className="mt-5">Original Email Date:</b> {email.original_call_date}<br />
                                                    <b className="mt-5">Email Date:</b> {moment(email.call_date).tz("America/Chicago").format('MM-DD-YYYY')} <br />
                                                    <b className="mt-5">Email Time:</b> {`${email.call_time_h} ${email.call_time_i} ${email.call_time_ampm}`} <br />
                                                    <b className="mt-5">Note Date:</b> {moment(email.notes_date).tz("America/Chicago").format('MM-DD-YYYY')}<br />
                                                    <b className="mt-5">Note Time:</b> {`${email.notes_time_h} ${email.notes_time_i} ${email.notes_time_ampm}`} <br />
                                                    <b className="mt-5">Incoming - Associated Phone #:</b> {email.phone_numbers} <br />
                                                    <b className="mt-5">Job Stage:</b> {getJobStage(email).label }<br />
                                                </Col>
                                                <Col sm={6}>
                                                    <b className="mt-5">Contractor Associated:</b> {email.contractor_name} <br />
                                                    <b className="mt-5">Customers Name:</b> {email.customer_name}<br />
                                                    <b className="mt-5">Zip Code:</b> {email.zip_code}<br />
                                                    <b className="mt-5">CS Rep:</b> {email.rep_name} <br />
                                                    <b className="mt-5">message:</b> {email.message?email.message:""} <br />
                                                    <b className="mt-5">Notes:</b> {email.description} <br />
                                                </Col>

                                            </Row>
                                        
                                        </CardBody>
                                    </Card>

                                ))}
                                <Row className="mb-2">
                                    <Col sm="6">
                                        {emailForms?.previous?
                                        <div className="">
                                            <Button
                                            type="button"
                                            color="dark"
                                            className="btn-outline btn-sm mb-2 me-2"
                                            onClick={() => loadAssociatedEmails(emailForms.previous)}
                                            >
                                            {" "}
                                            {`<<< Previous`}
                                            </Button>
                                        </div>:null}    
                                    </Col>
                                    <Col sm="6">
                                        {emailForms?.next?
                                        
                                        <div className="text-sm-end">
                                            <Button
                                                type="button"
                                                color="dark"
                                                className="btn-outline btn-sm mb-2 me-2"
                                                onClick={() => loadAssociatedEmails(emailForms.next)}
                                                >
                                        
                                                {`Next >>>`}
                                            </Button>
                                            {" "}
                                            Found {emailForms?.count} notes matching your query

                                        </div>:null}
                                    </Col>
                                </Row>
                            </TabPane>
                            </TabContent>
                            <iframe title={"Print Call Note"} id={"ifmcontentstoprint"} style={{height:"0", width:'0', position:'absolute'}}></iframe>
        
                        </>
                        :
                        <Card >
                            <CardBody>
                                <div className="d-flex align-items-center justify-content-center">
                                    <Spinner className="my-3" color="dark" />
                                </div>
                            </CardBody>
                        </Card>
                    }               
                    </Col>
                    

                    

                </Row>

                
                
            </Col>
            :
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
                }
                <Col sm={6} md={4} xl={3}>
                    <Modal
                    isOpen={modal_center}
                    toggle={() => {
                        tog_center()
                    }}
                    centered={true}
                    >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">Recording</h5>
                        <button
                        type="button"
                        onClick={() => {
                            setmodal_center(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row>
                        <Col md={10}>
                            <div>
                            <audio autoPlay controls >
                            
                                <source src={audio} type="audio/mp3" />
                                Your browser does not support the audio tag.
                            </audio>

                            </div>
                        

                        </Col>
                        </Row>
                    
                    </div>
                    </Modal>

                </Col>
            </Row>
            
        </div>

        </React.Fragment>
    )
}

export default CallNotes
