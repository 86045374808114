import React, {useState, useEffect} from "react"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"


const ContractorFeedback = props => {

    const [feedbackSeries, setFeedbackSeries] = useState([1, 1, 1, 1, 1]) 
    const [feedbackCount, setFeedbackCount] = useState(0) 

// {week: 1, count: 78, from: '2023-04-20', to: '2023-04-26'}
    useEffect(() => {
        let data = props.data
        let series = []
        let count = 0
        if(data.length > 0){
            for(let i=0 ; i< data.length; i++){
                series.push(data[i].count)
                count = count + data[i].count
            }
            setFeedbackCount(count)
            setFeedbackSeries(series)  
        }
        
      }, [props.data]);

      const series = [{
        name: "Customers Feedback",
        data: feedbackSeries
    }
    ]
    
    const options = {
        chart: {
            sparkline: {
                enabled: !0
            }
            ,
            toolbar: {
                show: !1
            }
            ,
        }
        ,
        dataLabels: {
            enabled: !1
        }
        ,
        stroke: {
            curve: 'smooth', width: 3
        }
        ,
        colors: ['#3b5de7'],
    }
    
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="mb-4"><Link className="text-dark" to={'/analytics/contractor-feedback'}><b>Customers Feedback</b></Link></div>

                    <Row>
                        <Col sm={6}>
                            <div>
                                <h4>{feedbackCount}</h4>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="mt-4 mt-sm-0">
                                <ReactApexChart
                                    options={options}
                                    series={series}
                                    type="line"
                                    height="100"
                                    className="apex-charts"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={6}>
                            <p className="mt-2">{props.startdate} - {props.enddate}</p>
                        </Col>
                        <Col sm={6}>
                        <Link to={'/analytics/contractor-feedback'}><Button color="light" className="btn-outline me-2 float-end btn-sm" >
                                view report <i className="mdi mdi-arrow-right-bold text-dark"></i>
                            </Button></Link>
                        </Col>

                    </Row>
                </CardBody>
                
            </Card>
        </React.Fragment>
    )
}
export default ContractorFeedback
