import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Badge, Spinner } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"

// Editable
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"

import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { get, del } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper"
import { HeaderFormatter } from "../../helpers/methods";


const ContractorCampaign = (props) => {
    const { id } = props.match.params
    const [campaigns, setCampaigns] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [page, setpage] = useState(1)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [Search_Text, setsearchText] = useState("")
    const [selected_id, setSelected_id] = useState(null)
    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")
    const [loaddata, setLoadData] = useState(false)



    useEffect(()=> {
        setLoadData(true)
        loadData(`${BASE_URL}/api/tgcl/dashboard/campaign/?contractor=${id}`,sizePerPage, page, Search_Text )
        // loadPendingData(`${BASE_URL}/api/tgcl/dashboard/campaign/?active=2&status=false`,pendingsizePerPage, pendingpage, pendingSearchText )
    }, [])

    const columns = [
        {
        dataField: "id",
        text: "ID",
        sort: true
        },
        {
        dataField: "category",
        text: "Category",
        headerFormatter: HeaderFormatter,
        formatter: (cellContent, row) => (
            <Link to={{ pathname: `/edit-campaign/${row.id}`, contractorId:id }} className="me-3 text-dark">{(cellContent.heading).substring(0,30)}</Link>     
          ),
        title: (cell) => `${cell.heading}`
        },
        {
        dataField: "page_title",
        text: "Assigned City",
        headerFormatter: HeaderFormatter,
        formatter: (cellContent) => (
            cellContent?
            (cellContent).substring(0,30)
            :""
        ),
        title: (cell) => `${cell}`
        },
        {
            dataField: "phone",
            text: "Tracking Phone",
            headerStyle: { width: '20%'  },
            headerFormatter: HeaderFormatter,
            formatter: (cellContent) => (
              cellContent
            ),
            title: (cell) => `${cell}`
        },
        {
        dataField: "zip",
        text: "Zip",
        headerFormatter: HeaderFormatter,

        },
        {
        dataField: "start",
        text: "Start",
        headerFormatter: HeaderFormatter,

        },
        {
        dataField: "exp",
        text: "Expire",
        headerFormatter: HeaderFormatter,

        },
        {
        dataField: "active",
        text: "Active",
        headerFormatter: HeaderFormatter,
        headerStyle: { width: '5%' },
        align: 'center',
        formatter: (cellContent) => (
            cellContent === 1?
            <i className="fa fa-check text-success font-size-18"></i> 
            :
            <i className="fa fa-times text-danger font-size-18"></i> 
        ),
        },    
        {
            dataField: "status",
            text: "Status",
            headerFormatter: HeaderFormatter,
            headerStyle: { width: '5%' },
            formatter: (cellContent) => (
            cellContent?
            <span className="bg-success btn-rounded text-light p-2"> {"Approved"} </span>
            :
            <span className="bg-warning btn-rounded text-light p-2"> {"Pending"} </span>
            ),
        }, 
    ]

    const activeColumn = [...columns, {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        headerFormatter: HeaderFormatter,
        formatter: (cellContent, row) => (
        <React.Fragment>
            <Link to={`/edit-campaign/${row.id}`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
            <Link to="#" onClick={()=> {setSelected_id(row.id); setAlert_confirm(true)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
        ),
    },]


    function loadData(url , sizePerPage, page , searchText){
        
        let data = get(url, { headers: { 'Content-Type': 'application/json' }} )
        data.then(response => {
        setLoadData(false)
        setCampaigns(response.data.results)
        settotalSize(response.data.count)
        setpage(page)
        setsizePerPage(sizePerPage)
        setsearchText(searchText)
        })
    }

    function NoDataIndication(){
        if (!loaddata && campaigns.length === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )
    
        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
      }


    function handleCampaignClicks (del_id) { 
    
        del(`${BASE_URL}/api/tgcl/dashboard/campaign/${del_id}/`, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false})
        .then(res => 
            {if(res.status >= 400){
                console.log("error")
            }
            else{
                
                let new_page = page
                if(campaigns.length < 2){
                    new_page = new_page>1? new_page-1:1
                }
                let url = `${BASE_URL}/api/tgcl/dashboard/campaign/?contractor=${id}&size=${sizePerPage}&page=${new_page}`
                url = Search_Text && Search_Text !== "" ? url+`&search=${Search_Text}`: url
                loadData(url , sizePerPage, new_page , Search_Text)
                setSuccess_confirm(true)
                setAlert_confirm(false)
                setDynamic_title('Deleted!')
                setDynamic_description("Campaign Has been deleted.") 
                setSelected_id(null)
                

                
            }
        }
        )
    }


    function handleTableChange (type, { page, sizePerPage, searchText, }) {
        setTimeout(() => {
        setLoadData(true)
        let new_page = page
        let url = `${BASE_URL}/api/tgcl/dashboard/campaign/?contractor=${id}&size=${sizePerPage}&&page=${new_page}`
        if (searchText && searchText !== ""){
            new_page = Search_Text !== searchText? 1 : new_page
            url = `${BASE_URL}/api/tgcl/dashboard/campaign/?contractor=${id}&size=${sizePerPage}&page=${new_page}&search=${searchText}`
        }
        window.scrollTo(0, 0)
        loadData(url , sizePerPage, new_page , searchText)
        }, 300);
    }


    const defaultSorted = [{
        dataField: 'id_num',
        order: 'desc'
        }];

    const { SearchBar } = Search

        //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
        sizePerPageList: [ {
            text: '20', value: 20
          }, {
            text: '50', value: 50
          }, {
            text: '200', value: 200
          }, {
            text: '1000', value: 1000
          } ]
    }

    return (
        <React.Fragment>

            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        data={campaigns}
                        >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                            keyField="id"
                            data={campaigns}
                            columns={activeColumn}
                            remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                            bootstrap5
                            search
                            >
                            {toolkitProps => (
                                <React.Fragment>
                                
                                <Row className="mb-2">
                                    <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                        <SearchBar
                                            {...toolkitProps.searchProps}
                                        />
                                        
                                        </div>
                                    </div>
                                    </Col>
                                    <Col sm="8">
                                    <div className="text-sm-end">
                                        <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded mb-2 me-2"
                                        // onClick={()=> props.history.push('/new-campaign')}
                                        onClick={()=> props.history.push('/new-campaign', { data: {"id":id} })}
                                        >
                                        <i className="mdi mdi-plus me-1" />{" "}
                                        Add New Campaign
                                        </Button>
                                    </div>
                                    </Col>
                                </Row>
                                
                                <div className="table-responsive">

                                    <BootstrapTable
                                    remote
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    hover
                                    defaultSorted={defaultSorted}
                                    classes={
                                        "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    onTableChange={ handleTableChange }
                                    noDataIndication={() => NoDataIndication()}
                                    />
                                    
                                </div>
                                <Row>
                                    <Col sm={6}>
                                    <div className="mb-3">
                                    <SizePerPageDropdownStandalone
                                      { ...paginationProps }
                                    />
                                </div>
                                    <div className="pagination pagination-rounded mb-2">
                                        <PaginationTotalStandalone
                                        {...paginationProps}
                                        />
                                    </div>
                                    
                                    </Col>
                                    <Col sm={6}>
                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                        <PaginationListStandalone
                                        {...paginationProps}
                                        />
                                    </div>
                                    
                                    </Col>
                                </Row>
                                
                                
                                </React.Fragment>
                            )}
                            </ToolkitProvider>
                        )}
                        </PaginationProvider>
                    
                </CardBody>
                </Card>
            </Col>
            </Row>
            {alert_confirm ? (
                <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => handleCampaignClicks(selected_id)}
                onCancel={() =>
                { setAlert_confirm(false) 
                    setSelected_id(null)
                }
                }
                >
                You won't be able to revert this!
                </SweetAlert>
            ) : null}

            {success_confirm ? (
                <SweetAlert
                success
                title={dynamic_title}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => 
                { setSuccess_confirm(false) 
                    setAlert_confirm(false)
                }
                }
                >
                    {dynamic_description}
                </SweetAlert>
                )
            : null
            }


        </React.Fragment>
    )
}

export default ContractorCampaign
