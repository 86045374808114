import React, { useState,useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { slugify } from "../../../helpers/helper_methods";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../helpers/url_helper";
import { get } from "../../../helpers/api_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken"

const EditZipPage = (props) => {
    const { id } = props.match.params;
    const [page, setPage] = useState(null);
    const [selectedCategory, setselectedCategory] = useState({ label: "Select Category", value: null });
    const [selectedZipcode, setSelectedZipcode] = useState({ label: "Select Zip Code", value: null });
    const [stateOptions, setStateOptions] = useState([])
    const [countyOptions, setCountyOptions] = useState([])
    const [cityOptions, setCityOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [slug, setSlug] = useState("");

    const [file, setFile] = useState(null);
    const [didMount, setDidMount] = useState(false);

    const Categories = [
        {
        label: "Categoties",
        options: []
        },
    ];

    const ZipCode = [
        {
        label: "Zip Codes",
        options: []
        },
    ];

    ValidateUser(props)

    useEffect(() => {
        
        setDidMount(true);
        LoadPageData(id)
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
        }

    function handleChange(e) {
        setFile(e.target.files[0]);
    }

    function LoadPageData(id){
        let data = get(`${BASE_URL}/api/tgcl/dashboard/zipcode-pages/${id}/`, 
        { headers: { 'Content-Type': 'application/json', },  validateStatus: false}  
        )
        data.then(response => {
            setselectedCategory({ label: response.data.category.heading, value: response.data.category.id_num })
            setSelectedZipcode({ label: response.data.zipcode.zipcode, value: response.data.zipcode.id })
            setPage(response.data)
        })

    }
    function handleSubmit(e, values){
        const formData = new FormData();
        setLoading(true)
        if (file){
        formData.append('image', file, file.name) 
        }
        formData.append("zipcode",selectedZipcode.value)
        formData.append("category",selectedCategory.value)
        formData.append("pg_title",values.pg_title)
        formData.append("pg_desc",values.pg_desc)
        formData.append("keyword",values.keyword)
        formData.append("slug",values.slug)
        formData.append("description",values.description)
        formData.append("follow",document.getElementById('follow').checked)
        formData.append("index",document.getElementById('index').checked)

        const options = {
            method: 'PUT',
            body: formData,
            headers: {'Authorization': getAccessToken() }
        };
        fetch(`${BASE_URL}/api/tgcl/dashboard/zipcode-pages/${id}/`, options)
            .then(res => 
                {if(res.status >= 400){
                    res.json()
                    .then(
                        (data) =>{  
                            console.log(data, "---data")
                            toast.error("Somthing Went wrong")
                            setLoading(false)
                        }     
                    )
                }
                else{
                    res.json()
                    .then(
                        (data) =>{
                            setPage(data)
                            setFile(null)
                            toast.success(`${data.category.heading} has been updated`)
                            setLoading(false)
                            }
                        )
                }    
            }
        ) 

    }

    function generateSlug(category, zipcode){
        if (category && zipcode)
        setSlug(`${slugify(category)}-${slugify(zipcode)}`)
      // setselectedLocation(e)
    
    }

  
    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Zip Code Pages" breadcrumbItem={`Edit ${page?page.pg_title:" Page"} `} link="/zipcode-pages" />
            {page?
            <Row>
            <Col className="col-6">
                <Card>
                <CardBody>
                    <CardTitle>Page Detail</CardTitle>                  
                    <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                        <Row>
                        <Col md="12">
                                <div className="mb-3">
                                    <Label>Zipcode</Label>
                                    <Select
                                    value={selectedZipcode}
                                    onChange={(e) => {
                                        setSelectedZipcode(e)
                                    }}
                                    options={[]}
                                    classNamePrefix="select2-selection"
                                    isDisabled = {true}
                                    
                                    />
                                </div>
                            </Col>
                        </Row>
                    
                        <Row>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label>Category</Label>
                                    <Select
                                    value={selectedCategory}
                                    onChange={(e) => {
                                        setselectedCategory(e)
                                    }}
                                    options={Categories}
                                    classNamePrefix="select2-selection"
                                    isDisabled = {true}
                                    
                                    />
                                </div>
                            </Col>
                        </Row>
                
                        
                      
                        <Row>
                        <Col md="12">
                            <div className="mb-3">
                            <Label htmlFor="name">Slug</Label>
                            <AvField
                                name="slug"
                                placeholder="Page Slug"
                                type="text"
                                errorMessage="Enter Slug"
                                className="form-control"
                                value = {page.slug}
                                onChange={(e)=> setSlug(e.target.value)}
                                validate={{ required: { value: true } }}
                                id="slug"
                            />
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <AvField
                            className="mb-3"
                            type="textarea"
                            label="Description "
                            rows="3"
                            name="description"
                            id="description"
                            placeholder="Description"
                            value={page?.description}
                        />
                    </Row>
                    <Row>
                        <Col md="12">
                                <div className="mb-3">
                                <Label htmlFor="pg_title">Meta Title </Label>
                                <AvField
                                    name="pg_title"
                                    placeholder="Meta Title "
                                    type="text"
                                    className="form-control"
                                    id="pg_title"
                                    validate={{ required: { value: true } }}
                                    value={page?.pg_title}
                                />
                                </div>
                            </Col>
                    </Row>
                    <Row>
                        <AvField
                            className="mb-3"
                            type="textarea"
                            label="Meta Description "
                            rows="3"
                            name="pg_desc"
                            id="pg_desc"
                            placeholder="Meta Description"
                            value={page?.pg_desc}
                        />
                    </Row>
                    <Row>
                      <AvField
                            className="mb-3"
                            type="textarea"
                            label="Keyword"
                            rows="3"
                            name="keyword"
                            id="keyword"
                            placeholder="Keyword"
                            errorMessage="This value is required."
                            validate={{
                                required: { value: true },
                            }}
                            value={page?.keyword}
                        />
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="form-check mb-3">
                                <input className="form-check-input" defaultChecked={page.follow} type="checkbox" id="follow"
                                    required />{" "}
                                <label className="form-check-label" htmlFor="follow">
                                Follow</label>

                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" defaultChecked={page.index} id="index"
                                    required />{" "}
                                <label className="form-check-label" htmlFor="index">
                                Active</label>

                            </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Label htmlFor="icon">Background Image</Label>
                        <Col sm={6}>
                            <input id="icon" className="mb-3" type="file" accept="image/png, image/jpeg, image/jpeg" onChange={handleChange} />
                        </Col>
                    
                        <Col sm={6}>
                        { file?
                        <img src={URL.createObjectURL(file)} className="rounded img-thumbnail mb-3" alt="" />
                        :
                        page.image?
                            <img src={page.image} className="rounded img-thumbnail mb-3" alt="" />
                        : null}
                        </Col>
                        
                    </Row>
                        
                        <Button color="primary" type="submit">
                        Submit
                        </Button>
         
                    </AvForm>
                
                </CardBody>
                </Card>
            </Col>
            </Row>
            :
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
            }
        </div>

        </React.Fragment>
    )
}

export default EditZipPage
