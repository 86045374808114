import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Label, Modal, Spinner} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
// Editable
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { get, del, post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import moment from 'moment';
import { downloadExcel } from "react-export-table-to-excel";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { CallStatusOptions, JobStagesOptions, IOMOption, AMPMOption, TrueFalseStatus, OrderByOptions, AscDescOptions } from "./Options";
import { toast } from 'react-toastify';
import { HeaderFormatter } from "../../helpers/methods";
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"

const header = ["Original Call Date", "Call Date", "Note Date", "Notes", "Zip Code","CS Rep","Customers Name","Contractor Associated", 
                                        "Incoming - Associated Phone", "Job Stage", "Type" ];

                        

const CallNotes = (props) => {

  const [callNotes, setCallNotes] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [curr_page, setpage] = useState(1)
  const [loaddata, setLoadData] = useState(false)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [Search_Text, setsearchText] = useState("")
  const [selectedNote, setSelectedNote] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_search, setmodal_search] = useState(false)
  const [job_stages_time_ampm, setjob_stages_time_ampm] = useState({ label: "AM", value: "AM" })
  const [notes_time_ampm, setnotes_time_ampm] = useState({ label: "AM", value: "AM" })
  const [call_time_ampm, setcall_time_ampm] = useState({ label: "AM", value: "AM" })
  const [original_time_ampm, setoriginal_time_ampm] = useState({ label: "AM", value: "AM" })
  const [job_stages, setjob_stages] = useState({ value:'job-stages-1', label:'Left Message Awaiting Call Back'})
  const [pos_neg, setpos_neg] = useState({ label: "Connected", value: "connected" })
  const [in_out, setin_out] = useState({ label: "Incoming Call", value: "incoming" })
  const [contractor, setContractor] = useState({ label: "Select Contractor", value: null })
  const [contractorGroup, setContractorGroup] = useState([])

  // Filter Fields 
  const [filter, setFilter] = useState({checked:false, url : ""})
  const [orderBy, setOrderBy] = useState({ label: "Call Date", value: 'call_date_str' })
  const [ascDesc, setAscDesc] = useState({ label: "Desc", value: '-' })
  const [readStatus, setReasStatus] = useState({checked: false, "status": { label: "Read", value: true }})
  const [customer_name, setCustomer_name] = useState({checked: false, value: ""})
  const [call_date, setCall_date] = useState({checked: false, start: "", end:""})
  const [original_call_date, setOriginal_call_date] = useState({checked: false, value: ""})
  const [phone_numbers, setPhone_numbers] = useState({checked: false, value: ""})
  const [contractor_name, setContractor_name] = useState({checked: false, value: { label: "Select Contractor", value: null }})
  const [notes_date, setNotes_date] = useState({checked: false, value: ""})
  const [callStatus, setCallStatus] = useState({checked: false, "pos_neg": { label: "Connected", value: "connected" }})
  const [jobStages, setJobStages] = useState({checked: false, "job_stages": { value:'job-stages-1', label:'Left Message Awaiting Call Back'}})
  const [inOut, setInOut] = useState({checked: false, "in_out":{ label: "Incoming Call", value: "incoming" }})
  const [email, setEmail] = useState({checked: false, value: ""})
  const [rep_name, setRep_name] = useState({checked: false, value: ""})
  const [zipcode, setZipcode] = useState({checked: false, value: ""})

  ValidateUser(props)

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "contractor_name",
      text: "Associated Campaign",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
          <Link to={`/edit-comm-notes/${row.id}`} className="me-3 text-dark">{(cellContent?cellContent.split("(")[0].substring(0,20):"")}</Link>     
        ),
     },
    {
        dataField: "original_call_date",
        text: "Original Call Date",
        headerFormatter: HeaderFormatter,
        formatter: (cellContent) => (
        <Link to="#" className="me-3 text-dark">{cellContent?moment(`${cellContent.split(" ")[0]} ${cellContent.split(" ")[1]}:${cellContent.split(" ")[2]} ${cellContent.split(" ")[3]}`).format('MM-DD-YYYY hh:mm A'):""}</Link>    
        ),
    },
    {
      dataField: "call_date",
      text: "Call Date",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <Link to="#" className="me-3 text-dark">{moment(`${cellContent} ${row.call_time_h}:${row.call_time_i} ${row.call_time_ampm}`).format('MM-DD-YYYY hh:mm A')}</Link>     
      ),
    
    },
    {
      dataField: "notes_date",
      text: "Note Date",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <Link to="#" className="me-3 text-dark">{moment(`${cellContent} ${row.notes_time_h}:${row.notes_time_i} ${row.notes_time_ampm}`).format('MM-DD-YYYY hh:mm A')}</Link>     
      ),
    },
    {
        dataField: "phone_numbers",
        text: "Incoming Phone",
        headerFormatter: HeaderFormatter,
        formatter: (cellContent) => (
          cellContent?.split(",")[0]     
        ),
        title: (cell) => cell
    },
    {
      dataField: "in_out",
      text: "Type",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent) => (
        cellContent==="email"?"Email":"Call"  
      ),
  },
    {
      dataField: "action",
      isDummyField: true,
      headerFormatter: HeaderFormatter,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={`/edit-comm-notes/${row.id}`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
          <Link to="#" onClick={() => {setAlert_confirm(true); setSelectedNote(row)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    if (localStorage.getItem("CommNotesFilter")) {
      const obj = JSON.parse(localStorage.getItem("CommNotesFilter"))
      let url = obj.filter.url + `&page_size=${sizePerPage}`
      loadData(url, sizePerPage, 1, Search_Text)
      setFilter(obj.filter)
      setOrderBy(obj.orderBy)
      setAscDesc(obj.ascDesc)
      setReasStatus(obj.readStatus)
      setCustomer_name(obj.customer_name)
      setCall_date(obj.call_date)
      setOriginal_call_date(obj.original_call_date)
      setPhone_numbers(obj.phone_numbers)
      setContractor_name(obj.contractor_name)
      setNotes_date(obj.notes_date)
      setCallStatus(obj.callStatus)
      setJobStages(obj.jobStages)
      setInOut(obj.inOut)
      setEmail(obj.email)
      setRep_name(obj.rep_name)
      setZipcode(obj.zipcode)
      setContractor(obj.contractor_name.value)
      setjob_stages(obj.jobStages.job_stages)
      setpos_neg(obj.callStatus.pos_neg)
      setin_out(obj.inOut.in_out)
    }else{
      loadData(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}`, sizePerPage, curr_page, Search_Text)
    }
    
    getContractors()
    
    
  }, []);

  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  function tog_search() {
    setmodal_search(!modal_search)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function loadData(url , sizePerPage, page , searchText){

    get(url, { headers: { 'Content-Type': 'application/json', }} )
    .then(response => {
      setCallNotes(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
      setLoadData(true)
      
    })
    
  }

  function getContractors(search=null){
    let url = `${BASE_URL}/api/tgcl/contractor/?approved=true`
    if (search){
      url = url + `&search=${search}`
    }
    get(url, { headers: { 'Content-Type': 'application/json', }} )
    .then(response => {
      let data  = response.data.results
      let options = []
      for (let i=0; i<data.length; i++){
        options.push({ label: data[i].bus_name, value: data[i].bus_name })
      }
      
      let Contractors  = [{
        label: "Contractors",
        options: options
      }]
      setContractorGroup(Contractors)
    })
  }

  function loadSelectedNoteAssociateWithPhone(phone_numbers){
    if (phone_numbers !== ""){
      get(`${BASE_URL}/api/tgcl/callnotes/?page_size=${1}&search=${phone_numbers}`, { headers: { 'Content-Type': 'application/json', }} )
    .then(response => {
      if ((response.data.results.length)>0){
        setSelectedNote(response.data.results[0])
      }
    })
  }
  }

  function saveFilter_to_storage(){
    let filters = {
      "filter" : filter,
      "orderBy" : orderBy,
      "ascDesc" : ascDesc,
      "readStatus" : readStatus,
      "customer_name" : customer_name,
      "call_date" : call_date,
      "original_call_date" : original_call_date,
      "phone_numbers" : phone_numbers,
      "contractor_name" : contractor_name,
      "notes_date" : notes_date,
      "callStatus" : callStatus,
      "jobStages" : jobStages,
      "inOut" : inOut,
      "email" : email,
      "rep_name" : rep_name,
      "zipcode" : zipcode,

    }
    localStorage.setItem("CommNotesFilter", JSON.stringify(filters));
  }

  function handleFilter(e, values){
    
    let url= `${BASE_URL}/api/tgcl/callnotes/?order_by=${ascDesc.value+orderBy.value}`
    if(readStatus.checked){
      url = url + `&status=${readStatus.status.value?2:1}`
    }
    if(customer_name.checked){
      url = url + `&customer_name=${values.customer_name}`
      setCustomer_name({checked: customer_name.checked, value: customer_name})
    }
    if(phone_numbers.checked){
      url = url + `&numbers=${values.phone_numbers}`
      setPhone_numbers({checked: true, value: values.phone_numbers})
    }
    if(contractor_name.checked){
      url = url + `&contractor_name=${contractor_name.value.label}`
      setContractor_name({checked: true, value: contractor})
    }
    if(callStatus.checked){
      url = url + `&pos_neg=${callStatus.pos_neg.value}`
    }
    if(inOut.checked){
      url = url + `&in_out=${inOut.in_out.value}`
    }
    if(email.checked){
      url = url + `&email=${values.email}`
      setEmail({checked: true, value: values.email})
    }
    if(rep_name.checked){
      url = url + `&rep_name=${values.rep_name}`
      setRep_name({checked: true, value: values.rep_name})
    }
    if(zipcode.checked){
      url = url + `&zipcode=${values.zip_code}`
      setZipcode({checked: true, value: values.zip_code})
    }
    if(jobStages.checked){
      url = url + `&job_stages=${jobStages.job_stages.value}`
      if(["job-stages-3", "job-stages-6", "job-stages-8", "job-stages-9", "job-stages-13"].includes(jobStages.job_stages.value)){
        url = url + `&job_stages_date=${values.job_stages_date}`
      }
      if(jobStages.job_stages.value==="job-stages-3"){
        url = url + `&job_stages_time_h=${values.job_stages_time_h}&job_stages_time_i=${values.job_stages_time_i}
                    &job_stages_time_ampm=${job_stages_time_ampm.value}`
      }
    }
    if(notes_date.checked){
      url = url + `&notes_date=${values.notes_date}`
      setNotes_date({checked: true, value: values.notes_date})
    }
    if(call_date.checked){
      url = url + `&call_date_start=${values.call_date_start}&call_date_end=${values.call_date_end}`
      setCall_date({checked: true, start: values.call_date_start, end:values.call_date_end})
    }
    if(original_call_date.checked){
      url = url + `&original_call_date=${values.original_call_date}`
      setOriginal_call_date({checked: true, value: values.original_call_date})
    }

    setFilter({checked:true, url : url})
    url = url + `&page_size=${sizePerPage}`
   loadData(url, sizePerPage, 1, Search_Text)
   tog_search()

  }

  function clearFilter(){
    setReasStatus({checked: false, "status": { label: "Unread", value: false }})
    setCustomer_name({checked: false, value: ""})
    setCall_date({checked: false, start: "", end:""})
    setOriginal_call_date({checked: false, value: ""})
    setPhone_numbers({checked: false, value: ""})
    setContractor_name({checked: false, value: ""})
    setNotes_date({checked: false, value: ""})
    setCallStatus({checked: false, "pos_neg": { label: "Connected", value: "connected" }})
    setJobStages({checked: false, "job_stages": { value:'job-stages-1', label:'Left Message Awaiting Call Back'}})
    setInOut({checked: false, "in_out":{ label: "Incoming Call", value: "incoming" }})
    setEmail({checked: false, value: ""})
    setRep_name({checked: false, value: ""})
    setZipcode({checked: false, value: ""})
    setFilter({checked:false, url:""})
    loadData(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}`, sizePerPage, 1, Search_Text)
    localStorage.removeItem('CommNotesFilter');
    
  }

  function NoDataIndication(){
    if (loaddata && callNotes.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
    } 
  }

  function handleDownloadExcel() {
    let data = []
    for (let i=0; i<callNotes.length; i++){
        data.push({"original_call_date":callNotes[i].original_call_date, 'call_date': callNotes[i].call_date,
        'notes_date':callNotes[i].notes_date, 'description':callNotes[i].description, 
        'zip_code':callNotes[i].zip_code, 'rep_name':callNotes[i].rep_name,
        'customer_name':callNotes[i].customer_name, 'contractor_name':callNotes[i].contractor_name,
        'phone_numbers':callNotes[i].phone_numbers, 'job_stage':getJobStage(callNotes[i]).label,
        'type':callNotes[i].in_out==="email"?"Email":"Call"  
         })
    }
    downloadExcel({
      fileName: "Comm Notes.xlsx",
      sheet: "comm-notes",
      tablePayload: {
        header,
        body: data,
      },
    });
  }

  function getJobStage(stage){

    if(stage.job_stages === "job-stages-1"){
        return ({ value:'job-stages-1', label:'Left Message Awaiting Call Back'})
    }else if(stage.job_stages === "job-stages-2"){
        return ({ value:'job-stages-2', label:'Contractor Called Back – Awaiting Homeowner Response'})
    }else if(stage.job_stages === "job-stages-3"){
        return ({ value:'job-stages-3', label:'Scheduled Appointment'})
    }else if(stage.job_stages === "job-stages-4"){
        return ({ value:'job-stages-4', label:'Spoke with Contractor Went different Route'})
    }else if(stage.job_stages === "job-stages-5"){
        return ({ value:'job-stages-5', label:'Contractor Missed Appointment'})
    }else if(stage.job_stages === "job-stages-6"){
        return ({ value:'job-stages-6', label:'Waiting On Estimate'})
    }else if(stage.job_stages === "job-stages-7"){
        return ({ value:'job-stages-7', label:'Received Estimate Thinking About It'})
    }else if(stage.job_stages === "job-stages-8"){
        return ({ value:'job-stages-8', label:'Signed Contract Scheduled Start Time'})
    }else if(stage.job_stages === "job-stages-9"){
        return ({ value:'job-stages-9', label:'Work Started Expected Completion Date'})
    }else if(stage.job_stages === "job-stages-10"){
        return ({ value:'job-stages-10', label:'Job Completed – Satisfied'})
    }else if(stage.job_stages === "job-stages-11"){
        return ({ value:'job-stages-11', label:'Job Completed - Unsatisfied'})
    }else if(stage.job_stages === "job-stages-12"){
        return ({ value:'job-stages-12', label:'Chose a Contractor Outside Your Organization'})
    }else if(stage.job_stages === "job-stages-13"){
        return ({ value:'job-stages-13', label:'Would You Like A Follow Up from Us?'})
    }else{
        return ({ value:null, label:''})
    }

}

  function handleNoteClick (id) { 
  
    del(`${BASE_URL}/api/tgcl/callnotes/${id}/`, 
        { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()  }},
        {validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            let new_page = curr_page
            if(callNotes.length < 2){
              new_page = new_page>1? new_page-1:1
            }
            let url = `${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}&page=${new_page}`
            if (filter.checked){
              url = filter.url + `&page_size=${sizePerPage}&page=${new_page}`
            }

            loadData(url , sizePerPage, new_page , Search_Text)
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Deleted!')
            setDynamic_description("Call Note Has been deleted.") 
            setSelectedNote(null)
          }
      }
    )
  }

  function handleSubmitCallNotes(e, values){
    if (!contractor.value){
      alert("Please Select Contractor")
    }else{
      let data = {
        "contractor_name": contractor.label,
        "customer_name": values.customer_name,
        "email": values.email,
        "phone_numbers": values.phone_numbers,
        "zip_code": values.zip_code,
        "zone": 13,
        "call_date": values.call_date,
        "call_time_h":  values.call_time_h,
        "call_time_i": values.call_time_i,
        "call_time_ampm": call_time_ampm.value,
        "call_date_str": moment(values.call_date+' '+values.call_time_h+':'+values.call_time_i+' '+call_time_ampm.value).tz("America/Chicago").format('X'),
        "notes_date": values.notes_date,
        "notes_time_h": values.notes_time_h,
        "notes_time_i": values.notes_time_i,
        "notes_time_ampm": notes_time_ampm.value,
        "notes_date_str": moment(values.notes_date+' '+values.notes_time_h+':'+values.notes_time_i+' '+notes_time_ampm.value).tz("America/Chicago").format('X'),
        "original_call_date": `${values.original_call_date} ${values.original_time_h} ${values.original_time_i} ${original_time_ampm.value}`,
        "in_out": in_out.value,
        "pos_neg": pos_neg.value,
        "description": values.description,
        "rep_name": values.rep_name,
        "job_stages": job_stages.value,
        'status': 1,
        'sid': values.sid
      }
      if(["job-stages-3", "job-stages-6", "job-stages-8", "job-stages-9", "job-stages-13"].includes(job_stages.value)){
        data["job_stages_date"] = values.job_stages_date
      }
      if(job_stages.value==="job-stages-3"){
        data["job_stages_time_h"] = values.job_stages_time_h
        data["job_stages_time_i"] = values.job_stages_time_i
        data["job_stages_time_ampm"] = job_stages_time_ampm.value
      }
      post(`${BASE_URL}/api/tgcl/callnotes/`, data, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false} )
      .then(response => {
        if(response.status >= 400){
            console.log(response)
            toast.error('Somthing Went Wrong');
        }else{
          loadData(`${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}`, sizePerPage, curr_page, Search_Text)
          setSelectedNote(null)
          tog_standard()
          toast.success('call Note Created ');
        }
      })
    }
  }

  function handleTableChange (type, { page, sizePerPage, searchText, }) {
    let new_page = page
    let url = `${BASE_URL}/api/tgcl/callnotes/?page_size=${sizePerPage}&page=${new_page}`
      setLoadData(false)
      setCallNotes([])
      setTimeout(() => {
       
        if (filter.checked){
          url = filter.url + `&page_size=${sizePerPage}&page=${new_page}`
        }
        window.scrollTo(0, 0)
        loadData(url , sizePerPage, new_page , searchText)
      }, 300);
  }
 
  const defaultSorted = [{
      dataField: 'id',
      order: 'desc'
    }];

  const pageOptions = {
      page: curr_page,
      sizePerPage: sizePerPage,
      totalSize: totalSize,
      custom: true,
      sizePerPageList: [ {
          text: '20', value: 20
        }, {
          text: '50', value: 50
        }, {
          text: '100', value: 100
        }, {
          text: '200', value: 200
        } ]
  }

  if(filter.checked){
    saveFilter_to_storage()}

  return (
    <React.Fragment>
      <div className="page-content">
      
        <Breadcrumbs title="Dashboard" breadcrumbItem='Comm Notes' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={callNotes}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={callNotes}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="4">
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={() => {setSelectedNote(null);tog_standard()}}
                                >
                                  <i className="mdi mdi-plus me-1" />{" "}
                                  Add New Call Notes
                                </Button>
                                {" "}
                                <Button
                                  type="button"
                                  color="info"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={() => {tog_search()}}
                                >
                                  <i className="mdi mdi-database-search me-1" />{" "}
                                  Filter Call Notes
                                </Button>
                                {" "}
                                {filter.checked?
                                <Button
                                  type="button"
                                  color="info"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={() => clearFilter()}
                                >
                                  <i className="mdi mdi-database-search me-1" />{" "}
                                  Clear Filter
                                </Button>:null}
                                {" "}
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={() => handleDownloadExcel()}
                                  >
                                  <i className="mdi mdi-file-excel-outline me-1" />{" "}
                                  Download
                                </Button>
                              </div>
                            </Col>
                          </Row>
                            <div className="table-responsive">

                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                hover
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={ handleTableChange }
                                noDataIndication={() => NoDataIndication()}
                              />
                              
                            </div>
                          <Row>
                                <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                          { ...paginationProps }
                                        />
                                </div>
                                  <div className="pagination  mb-2">
                                  
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleNoteClick(selectedNote.id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelectedNote(null)
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
      </div>
      <Col sm={6} md={4} xl={3}>
            <Modal
              isOpen={modal_standard}
              toggle={() => {
                tog_standard()
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">Add Call Note</h5>     

                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AvForm className="needs-validation" onValidSubmit={(e, values) => handleSubmitCallNotes(e, values)}>
                <div className="modal-body">
                <Row>
                    <Col md="12">
                        <div className="mb-3">
                        <Label htmlFor="title">Customer Name</Label>
                        <AvField
                            name="customer_name"
                            placeholder="Customer Name"
                            type="text"
                            errorMessage="Enter Customer Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="customer_name"
                            value = {selectedNote? selectedNote.customer_name:""}
                        />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="mb-3">
                        <Label htmlFor="title">Customer Associated Phone #("," seprated)</Label>
                        <AvField
                            name="phone_numbers"
                            placeholder="+19723******"
                            type="text"
                            errorMessage="Enter Customer Phone"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="phone_numbers"
                            onBlur={(e)=> loadSelectedNoteAssociateWithPhone(e.target.value)}
                        />
                        </div>
                    </Col>
                </Row>
                <Row>

                    <Col md="12">
                        <div className="mb-3">
                            <Label>Contractors</Label>
                            <Select
                            onInputChange={(e)=> getContractors(e)}
                            value={contractor}
                            onChange={(e) => {
                                setContractor(e);
                            }}
                            options={contractorGroup}
                            classNamePrefix="select2-selection"

                            />
                        </div>
                    </Col>
                </Row>
                
                
                <Row>
                    <Col md="4">
                        <div className="mb-3">
                            <Label htmlFor="order">Call Date</Label>
                            <AvField
                                name="call_date"
                                placeholder="Call date"
                                type="date"
                                errorMessage=" Please provide a valid Order."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="call_date"
                            />
                        </div>
                    </Col>
                    <Col md="8">
                        <Row>
                            <Label>Call Time</Label>
                            
                            <Col md="4">
                                <div className="mb-3">
                                    <AvField
                                        name="call_time_h"
                                        placeholder="Hours"
                                        type="text"
                                        errorMessage=" Please provide a valid Hour."
                                        className="form-control"
                                        validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                        id="call_time_h"
                                    />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="mb-3">
                                    <AvField
                                        name="call_time_i"
                                        placeholder="Minuts"
                                        type="text"
                                        errorMessage=" Please provide a valid Minuts."
                                        className="form-control"
                                        validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                        id="call_time_i"
                                    />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="mb-3">
                                    <Select
                                    value={call_time_ampm}
                                    onChange={(e) => {
                                        setcall_time_ampm(e);
                                    }}
                                    options={AMPMOption}
                                    classNamePrefix="select2-selection"
                                    />
                                </div>   
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <div className="mb-3">
                            <Label htmlFor="order">Date Note was taken</Label>
                            <AvField
                                name="notes_date"
                                type="date"
                                errorMessage=" Please provide a valid Date."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="notes_date"
                            />
                        </div>
                    </Col>
                    <Col md="8">
                        <Row>
                            <Label>Time Note was taken</Label>
                            
                            <Col md="4">
                                <div className="mb-3">
                                    <AvField
                                        name="notes_time_h"
                                        placeholder="Hours"
                                        type="text"
                                        errorMessage=" Please provide a valid Hour."
                                        className="form-control"
                                        validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                        id="notes_time_h"
                                    />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="mb-3">
                                    <AvField
                                        name="notes_time_i"
                                        placeholder="Minuts"
                                        type="text"
                                        errorMessage=" Please provide a valid Minuts."
                                        className="form-control"
                                        validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                        id="notes_time_i"                                                />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="mb-3">
                                    <Select
                                    value={notes_time_ampm}
                                    onChange={(e) => {
                                        setnotes_time_ampm(e)
                                    }}
                                    options={AMPMOption}
                                    classNamePrefix="select2-selection"
                                    />
                                </div>   
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="mb-3">
                            <Label>Job Stages</Label>
                            <Select
                            value={job_stages}
                            onChange={(e) => {
                                setjob_stages(e);
                            }}
                            options={JobStagesOptions}
                            classNamePrefix="select2-selection"
                            />
                        </div>
                    </Col>
                </Row>
                {["job-stages-3", "job-stages-6", "job-stages-8", "job-stages-9", "job-stages-13"].includes(job_stages.value)?
                <Row>
                    <Col md="12">
                        <div className="mb-3">
                        <Label>Job Stage date</Label>
                        <AvField
                            name="job_stages_date"
                            placeholder="Date"
                            type="date"
                            errorMessage="Date"
                            className="form-control"
                            validate={{ required: { value: true }}}
                            id="job_stages_date"
                        />
                        </div>
                    </Col>
                </Row>: null}
                {job_stages.value === "job-stages-3"?
                <Row>
                    <Label>Job Stage Time</Label>
                    
                    <Col md="4">
                        <div className="mb-3">
                            <AvField
                                name="job_stages_time_h"
                                placeholder="Hours"
                                type="text"
                                errorMessage=" Please provide a valid Hour."
                                className="form-control"
                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 12} }}
                                id="job_stages_time_h"
                            />
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="mb-3">
                            <AvField
                                name="job_stages_time_i"
                                placeholder="Minuts"
                                type="text"
                                errorMessage=" Please provide a valid Minuts."
                                className="form-control"
                                validate={{ required: { value: true }, min: {value: 0}, max: {value: 60} }}
                                id="job_stages_time_i"
                            />
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="mb-3">
                            <Select
                            value={job_stages_time_ampm}
                            onChange={(e) => {
                                setjob_stages_time_ampm(e)
                            }}
                            options={AMPMOption}
                            classNamePrefix="select2-selection"
                            />
                        </div>   
                    </Col>

                </Row>: null}
                {/* lkljlkjlk */}
                <Row>
                    <Col md="6">
                        <div className="mb-3">
                            <Label>Call Status</Label>
                            <Select
                            value={pos_neg}
                            onChange={(e) => {
                                setpos_neg(e);
                            }}
                            options={CallStatusOptions}
                            classNamePrefix="select2-selection"
                            />
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mb-3">
                            <Label>Incoming/ Outgoing/Email</Label>
                            <Select
                            value={in_out}
                            onChange={(e) => {
                                setin_out(e);
                            }}
                            options={IOMOption}
                            classNamePrefix="select2-selection"
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                            <div className="mb-3">
                                <Label htmlFor="title">Email</Label>
                                <AvField
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value = {selectedNote? selectedNote.email:""}
                                />
                            </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                            <div className="mb-3">
                                <Label htmlFor="title">CS Rep Name</Label>
                                <AvField
                                    name="rep_name"
                                    placeholder="CS Rep Name"
                                    type="text"
                                    className="form-control"
                                    id="rep_name"
                                    value = {selectedNote?selectedNote.rep_name:""}
                                />
                            </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                            <div className="mb-3">
                                <Label htmlFor="title">Zip Code</Label>
                                <AvField
                                    name="zip_code"
                                    placeholder="Zip Code"
                                    type="text"
                                    className="form-control"
                                    id="zip_code"
                                    value = {selectedNote? selectedNote.zip_code:""}
                                />
                            </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <div className="mb-3">
                            <Label htmlFor="order">Original Call Date</Label>
                            <AvField
                                name="original_call_date"
                                type="date"
                                errorMessage=" Please provide a valid Date."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="original_call_date"
                            />
                        </div>
                    </Col>
                    <Col md="8">
                        <Row>
                            <Label>Original Call Time</Label>
                            
                            <Col md="4">
                                <div className="mb-3">
                                    <AvField
                                        name="original_time_h"
                                        placeholder="Hours"
                                        type="text"
                                        errorMessage=" Please provide a valid Hour."
                                        className="form-control"
                                        validate={{ required: { value: true }, min: {value: 1}, max: {value: 12} }}
                                        id="original_time_h"
                                    />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="mb-3">
                                    <AvField
                                        name="original_time_i"
                                        placeholder="Minuts"
                                        type="text"
                                        errorMessage=" Please provide a valid Minuts."
                                        className="form-control"
                                        validate={{ required: { value: true }, min: {value: 1}, max: {value: 60} }}
                                        id="original_time_i"
                                    />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="mb-3">
                                    <Select
                                    value={original_time_ampm}
                                    onChange={(e) => {
                                        setoriginal_time_ampm(e)
                                    }}
                                    options={AMPMOption}
                                    classNamePrefix="select2-selection"
                                    />
                                </div>   
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="mb-3">
                        <Label htmlFor="title">Call SID</Label>
                        <AvField
                            name="sid"
                            placeholder="Call SID"
                            type="text"
                            errorMessage="Enter Call SID"
                            className="form-control"
                            id="sid"
                            value = {selectedNote? selectedNote.sid:""}
                        />
                        </div>
                    </Col>
                </Row>
                <Row>
                        <AvField
                            className="mb-3"
                            type="textarea"
                            label="Notes "
                            rows="2"
                            name="description"
                            id="description"
                            placeholder="Notes"
                            value = {selectedNote? selectedNote.description:""}
                        />
                    </Row>
                
                
                    
                </div>
                <div className="modal-footer">
                  <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                  >
                  {"Create"}
                  </button>
                </div>
              </AvForm>

            </Modal>
      </Col>
      {/* Filter modal */}
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_search}
          toggle={() => {
            tog_search()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{`Filter Notes`} </h5>  
            <button
              type="button"
              onClick={() => {
                setmodal_search(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={handleFilter}>
            <div className="modal-body">
            <Row>
                <Col md="4">
                  <div className="form-check mb-3">
                      
                      <label className="form-check-label" htmlFor="status">Order By</label>
                  </div>
                </Col>
                <Col md="8">
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Select
                          value={orderBy}
                          onChange={(e) => {
                              setOrderBy(e);
                          }}
                          options={OrderByOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Select
                          value={ascDesc}
                          onChange={(e) => {
                            setAscDesc(e);
                          }}
                          options={AscDescOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                  </Row>
                    
                </Col>
            </Row>
            <hr />

            <Row>
                <Col md="4">
                  <div className="form-check mb-5">
                      <input  className="form-check-input" type="checkbox" id="read_status" 
                      defaultChecked={readStatus.checked}
                              onChange={(e)=> setReasStatus({checked: e.target.checked, "status": readStatus.status})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="status">Read Status</label>
                  </div>
                </Col>
                {readStatus.checked?
                <Col md="7">
                  <div className="mb-3">
                    <Select
                    value={readStatus.status}
                        onChange={(e) => {
                            setReasStatus({checked: readStatus.checked, "status": e});
                        
                            
                        }}
                        options={TrueFalseStatus}
                        classNamePrefix="select2-selection"

                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="customer_name"
                        defaultChecked={customer_name.checked} 
                              onChange={(e)=> setCustomer_name({checked: e.target.checked, value: customer_name.value})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="status">Customer Name</label>
                  </div>
                </Col>
                {customer_name.checked?
                <Col md="8">
                  <div className="mb-3">
                    <AvField
                        name="customer_name"
                        placeholder="Customer Name"
                        type="text"
                        errorMessage="Enter Customer Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="customer_name"
                        value = {customer_name.value}
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-3">
                      <input  className="form-check-input" type="checkbox" id="customer_name"
                        defaultChecked={original_call_date.checked} 
                              onChange={(e)=> setOriginal_call_date({checked: e.target.checked, value: original_call_date.value})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="status">Original Call Date</label>
                  </div>
                </Col>
                {original_call_date.checked?
                <Col md="8">
                  <div className="mb-3">
                    <AvField
                      name="original_call_date"
                      type="date"
                      errorMessage=" Please provide a valid Date."
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="original_call_date"
                      value ={original_call_date.value}
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="customer_name"
                        defaultChecked={call_date.checked} 
                              onChange={(e)=> setCall_date({checked: e.target.checked, start: call_date.start, end: call_date.end})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="status">Call Date</label>
                  </div>
                </Col>
                {call_date.checked?
                <Col md="8">
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <AvField
                            name="call_date_start"
                            placeholder="Call date"
                            type="date"
                            errorMessage=" Please provide a valid Order."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="call_date_start"
                            value={call_date.start}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <AvField
                            name="call_date_end"
                            placeholder="Call date"
                            type="date"
                            errorMessage=" Please provide a valid Order."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="call_date_end"
                            value={call_date.end}
                        />
                    </Col>
                  </Row>
                    
                </Col>:null}
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="customer_name"
                        defaultChecked={phone_numbers.checked} 
                              onChange={(e)=> setPhone_numbers({checked: e.target.checked, value: phone_numbers.value})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="status">Customer Phone #</label>
                  </div>
                </Col>
                {phone_numbers.checked?
                <Col md="8">
                  <div className="mb-3">
                    <AvField
                          name="phone_numbers"
                          placeholder="Customer Phone"
                          type="text"
                          errorMessage="Enter Customer Phone"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="phone_numbers"
                          value={phone_numbers.value}
                      />
                    </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="customer_name"
                        defaultChecked={contractor_name.checked} 
                              onChange={(e)=> setContractor_name({checked: e.target.checked, value: contractor_name.value})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="status">Contractor Name</label>
                  </div>
                </Col>
                {contractor_name.checked?
                <Col md="8">
                  <div className="mb-3">
                    <Select
                      onInputChange={(e)=> getContractors(e)}
                      value={contractor}
                      onChange={(e) => {
                          setContractor(e);
                          setContractor_name({checked: contractor_name.checked, value: e})
                      }}
                      options={contractorGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="customer_name"
                        defaultChecked={notes_date.checked} 
                              onChange={(e)=> setNotes_date({checked: e.target.checked, value: notes_date.value})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="status">Date Note was taken</label>
                  </div>
                </Col>
                {notes_date.checked?
                <Col md="8">
                  <div className="mb-3">
                    <AvField
                        name="notes_date"
                        type="date"
                        errorMessage=" Please provide a valid Date."
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="notes_date"
                        value = {notes_date.value}
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="callStatus"
                        defaultChecked={callStatus.checked} 
                              onChange={(e)=> setCallStatus({checked: e.target.checked, "pos_neg": callStatus.pos_neg})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="callStatus">Call Status</label>
                  </div>
                </Col>
                {callStatus.checked?
                <Col md="8">
                  <div className="mb-3">
                    <Select
                      value={callStatus.pos_neg}
                      onChange={(e) => {
                        setCallStatus({checked: callStatus.checked, "pos_neg": e});
                      }}
                      options={CallStatusOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="jobStages"
                        defaultChecked={jobStages.checked} 
                              onChange={(e)=> setJobStages({checked: e.target.checked, "job_stages": jobStages.job_stages})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="jobStages">Job Stages</label>
                  </div>
                </Col>
                {jobStages.checked?
                <Col md="8">
                  <div className="mb-3">
                    <Select
                      value={job_stages}
                      onChange={(e) => {
                        setJobStages({checked: jobStages.checked, "job_stages": e});
                      }}
                      options={JobStagesOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
          
            {["job-stages-3", "job-stages-6", "job-stages-8", "job-stages-9", "job-stages-13"].includes(jobStages.job_stages.value) && jobStages.checked?
            <Row>
                <Col md="12">
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <Label>Job Stage date</Label>
                      </div>
                    </Col>
                    <Col md="8">
                      <div className="mb-3">
                        <AvField
                            name="job_stages_date"
                            placeholder="Date"
                            type="date"
                            errorMessage="Date"
                            className="form-control"
                            validate={{ required: { value: true }}}
                            id="job_stages_date"
                            value = {selectedNote? selectedNote.job_stages_date:""}
                        />
                      </div>
                    </Col>

                  </Row>
                    
                </Col>
            </Row>: null}
            {jobStages.job_stages.value === "job-stages-3" && jobStages.checked?
            <Row>
              <Col md="4">
                <div className="mb-3">
                <Label>Job Stage Time</Label>
                </div>
              </Col>
              <Col md="8">
                <Row>
                  <Col md="4">
                      <div className="mb-3">
                          <AvField
                              name="job_stages_time_h"
                              placeholder="Hours"
                              type="text"
                              errorMessage=" Please provide a valid Hour."
                              className="form-control"
                              validate={{ required: { value: true }, min: {value: 1}, max: {value: 12} }}
                              id="job_stages_time_h"
                              value = {selectedNote? selectedNote.job_stages_time_h:""}
                          />
                      </div>
                  </Col>
                  <Col md="4">
                      <div className="mb-3">
                          <AvField
                              name="job_stages_time_i"
                              placeholder="Minuts"
                              type="text"
                              errorMessage=" Please provide a valid Minuts."
                              className="form-control"
                              validate={{ required: { value: true }, min: {value: 1}, max: {value: 60} }}
                              id="job_stages_time_i"
                              value = {selectedNote? selectedNote.job_stages_time_i:""}
                          />
                      </div>
                  </Col>
                  <Col md="4">
                      <div className="mb-3">
                          <Select
                          value={job_stages_time_ampm}
                          onChange={(e) => {
                              setjob_stages_time_ampm(e)
                          }}
                          options={AMPMOption}
                          classNamePrefix="select2-selection"
                          />
                      </div>   
                  </Col>

                </Row>
              </Col>
            </Row>: null}
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="callStatus"
                        defaultChecked={inOut.checked} 
                              onChange={(e)=> setInOut({checked: e.target.checked, "in_out": inOut.in_out})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="callStatus">Incoming/ Outgoing/Email</label>
                  </div>
                </Col>
                {inOut.checked?
                <Col md="8">
                  <div className="mb-3">
                    <Select
                      value={inOut.in_out}
                      onChange={(e) => {
                        setInOut({checked: inOut.checked, "in_out": e});
                      }}
                      options={IOMOption}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="callStatus"
                        defaultChecked={email.checked} 
                              onChange={(e)=> setEmail({checked: e.target.checked, value: email.value})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="callStatus">Email</label>
                  </div>
                </Col>
                {email.checked?
                <Col md="8">
                  <div className="mb-3">
                    <AvField
                        name="email"
                        placeholder="Email"
                        type="email"
                        className="form-control"
                        id="email"
                        validate={{ required: { value: true } }}
                        value = {email.value}
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-4">
                      <input  className="form-check-input" type="checkbox" id="callStatus"
                        defaultChecked={rep_name.checked} 
                              onChange={(e)=> setRep_name({checked: e.target.checked, value: rep_name.value})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="callStatus">CS Rep Name</label>
                  </div>
                </Col>
                {rep_name.checked?
                <Col md="8">
                  <div className="mb-3">
                    <AvField
                      name="rep_name"
                      placeholder="CS Rep Name"
                      type="text"
                      className="form-control"
                      id="rep_name"
                      validate={{ required: { value: true } }}
                      value = {rep_name.value}
                  />
                  </div>
                </Col>
                 : null}
               
            </Row>
            <Row>
                <Col md="4">
                  <div className="form-check mb-3">
                      <input  className="form-check-input" type="checkbox" id="callStatus"
                        defaultChecked={zipcode.checked} 
                              onChange={(e)=> setZipcode({checked: e.target.checked, value: zipcode.value})}/>
                      {" "}
                      <label className="form-check-label" htmlFor="callStatus">Lead Zone</label>
                  </div>
                </Col>
                {zipcode.checked?
                <Col md="8">
                  <div className="mb-3">
                    <AvField
                      name="zip_code"
                      placeholder="Lead Zone"
                      type="number"
                      className="form-control"
                      id="zip_code"
                      validate={{ required: { value: true } }}
                      value = {zipcode.value}
                    />
                  </div>
                </Col>
                 : null}
               
            </Row>
                
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                >
                {"Filter"}
              </button>
              
              
            </div>
          </AvForm>

        </Modal>
      </Col>

    </React.Fragment>
  )
}

export default CallNotes
