import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { BASE_URL } from "../../../helpers/url_helper";
import { post } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import { EditorState, convertToRaw, } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

const Addteam = (props) => {



    const [didMount, setDidMount] = useState(false);


    const [editorState, setEditorState] = useState(EditorState.createEmpty()); 
    const [rawMessage, setrawMessage] = useState(''); 
    const [submitting, setSubmitting] = useState(null);


    useEffect(() => {
        setDidMount(true);
    
        
    },[]);

    if(!didMount) {
        return null;
      }

    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    function handleSubmit(e, values){
        let data = values
        data['description'] = rawMessage
        data['active'] = document.getElementById('active').checked
        setSubmitting(true)
        post(`${BASE_URL}/api/tgcl/dashboard/team/`, data,
        { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false}  
        )
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing went Wrong")
                setSubmitting(false)
                
            }else{
                props.history.push('/team')
            }
        })
        
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={'Add Team Member'} breadcrumbItem={`Add Campaigns`} link={"/team"} />
                    <Row>
                        <Col md="12">
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="name">Name</Label>
                                                        <AvField
                                                            name="name"
                                                            placeholder="Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="name"
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="title">Title</Label>
                                                        <AvField
                                                            name="title"
                                                            placeholder="title"
                                                            type="text"
                                                            className="form-control"
                                                            id="title"
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                    <Label >Description</Label>
                                                        <Editor       
                                                        toolbar={{
                                                            options: ['inline'],
                                                            inline: {
                                                            options: ['bold', 'italic', 'underline'],
                                                            },
                                                        }}      
                                                        editorState={editorState}            
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={onEditorStateChange}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={true} id="active"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="active">
                                                            Active</label>

                                                        </div>
                                                    </Col>
                                                </Row>
                                        
                                           
                                                {submitting?
                                                <button
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light my-3"
                                                    >
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                    Submit
                                                </button>
                                                :
                                                <Button color="primary my-3" type="submit">
                                                Submit
                                                </Button>
                                            }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </Col>
                    </Row>

            </div>

        </React.Fragment>
    )
}

export default Addteam
