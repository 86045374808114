import React from "react"
import { Card, CardBody, Table, Col, Row, Button } from "reactstrap"
import { Link } from "react-router-dom"

const LatestTransaction = (props) => {
    return (
        <Col lg={12}>
            <Card>
                <CardBody>
                    <div className="mb-4"><Link className="text-dark" to={'/analytics/category-search'}><b>Top Categories Search</b></Link></div>
                    <div className="table-responsive">
                        <Table className="table-centered">
                            <thead>
                                <tr>
                                    <th scope="col">Contractor Name</th>
                                    <th scope="col">Total Events</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.map((category) => 
                                <tr>
                                    <td>{category.category__heading}</td>
                                    <td>{category.count}</td>
                                </tr>
                                 )}
                            </tbody>
                        </Table>
                    </div>
                    <Row className="mt-3">
                        <Col sm={6}>
                            <p className="mt-2">{props.startdate} - {props.enddate}</p>
                        </Col>
                        <Col sm={6}>
                        <Link to={'/analytics/category-search'}><Button color="light" className="btn-outline me-2 float-end btn-sm" >
                                view report <i className="mdi mdi-arrow-right-bold text-dark"></i>
                            </Button></Link>
                        </Col>

                    </Row>
                    
                    
                   
                </CardBody>
            </Card>
        </Col>
    )
}

export default LatestTransaction