import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import BootstrapTable from "react-bootstrap-table-next"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {getAccessToken} from "../../../helpers/jwt-token-access/accessToken"
import { BASE_URL } from "../../../helpers/url_helper";
import { get, put, del } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import { EditorState, convertToRaw, ContentState} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';



const EditMember = (props) => {


    const id = props.match.params.id
    const [didMount, setDidMount] = useState(false);
    const [member, setMember] = useState(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty()); 
    const [rawMessage, setrawMessage] = useState(''); 
    const [submitting, setSubmitting] = useState(null);
    const [gallery, setGallery] = useState([]);
    const [galleryLoading, setGalleryLoading] = useState(false);
    const [selected_id, setSelected_id] = useState(null)
    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")
    const [modal_standard, setmodal_standard] = useState(false)
    const [file, setFile] = useState(null);


    useEffect(() => {
        setDidMount(true);
        loadMember()
    
        
    },[]);

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
      }
      function removeBodyCss() {
        document.body.classList.add("no_padding")
      }

    if(!didMount) {
        return null;
      }

      const columns = [
        {
          dataField: "id",
          text: "ID", 
          hidden: true,
        },
        {
          dataField:"image",
          text: 'Image',
          formatter: (cellContent) => (
            <a href={cellContent} target={"_blank"} ><img src={cellContent?cellContent:""} className="rounded avatar-md"  alt="" /></a>
          ),     
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          formatter: (cellContent, row) => (
            <React.Fragment>
              <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row.id)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
            </React.Fragment>
          ),
        },
      ]

    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    function handleChange(e) {
        setFile(e.target.files[0]);
    }


    function loadGallery(id){
        get(`${BASE_URL}/api/tgcl/dashboard/team-gallery/?team=${id}&page_size=200`,  {  validateStatus: false} )
        .then(response => {
            setGallery(response.data.results)
        }
       )

    }

    function loadMember(){
        get(`${BASE_URL}/api/tgcl/dashboard/team/${id}`, { headers: { 'Content-Type': 'application/json', }, validateStatus: false})
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing went Wrong")
                
            }else{
                setMember(response.data)
                loadGallery(response.data.id)
                const { contentBlocks, entityMap } = htmlToDraft(response.data.description);
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorrState = EditorState.createWithContent(contentState);
            setrawMessage(draftToHtml(convertToRaw(editorrState.getCurrentContent())))
            setEditorState(editorrState)
            }
        })
    }

    function handleImageClick(id){
        del(`${BASE_URL}/api/tgcl/dashboard/team-gallery/${id}/`, 
        { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()  }},
        {validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            loadGallery(member.id)
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Deleted!')
            setDynamic_description("Images Has been deleted.") 
            setSelected_id(null)
          }
      }
    )
    }


    function handleSubmit(e, values){
        let data = values
        data['description'] = rawMessage
        data['active'] = document.getElementById('active').checked
        setSubmitting(true)
        put(`${BASE_URL}/api/tgcl/dashboard/team/${member.id}/`, data,
        { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false}  
        )
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing went Wrong")
                setSubmitting(false)
                
            }else{
                props.history.push('/team')
            }
        })
        
    }


    function handleImageSubmit(e, values){
        if(!file){
            toast.error("Select Image")
        }else{
            setGalleryLoading(true)
            const formData = new FormData();
        formData.append('image', file, file.name) 
        formData.append("name",values.name)
        formData.append("team",member.id)

        const options = {
            method: 'POST',
            body: formData,
            headers: {'Authorization': getAccessToken() }
        };
        fetch(`${BASE_URL}/api/tgcl/dashboard/team-gallery/`, options)
            .then(res => 
                {if(res.status >= 400){
                    res.json()
                    .then(
                        (data) =>{  
                            console.log(data, "---data")
                            toast.error("Somthing Went wrong")
                            tog_standard()
                            setGalleryLoading(false)
                        }      
                    )
                }
                else{
                    tog_standard()
                    loadGallery(member.id)
                    setGalleryLoading(false)
                }    
            }
        ) 

        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={'Team Members'} breadcrumbItem={`Edit ${member?member.name:"Team Member"} `} link={"/team"} />
                {member?
                    <Row>
                        <Col md="12">
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="name">Name</Label>
                                                        <AvField
                                                            name="name"
                                                            placeholder="Name"
                                                            type="text"
                                                            className="form-control"
                                                            id="name"
                                                            value={member.name}
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="title">Title</Label>
                                                        <AvField
                                                            name="title"
                                                            placeholder="title"
                                                            type="text"
                                                            className="form-control"
                                                            id="title"
                                                            value={member.title}
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                    <Label >Description</Label>
                                                        <Editor       
                                                        toolbar={{
                                                            options: ['inline'],
                                                            inline: {
                                                            options: ['bold', 'italic', 'underline'],
                                                            },
                                                        }}      
                                                        editorState={editorState}            
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={onEditorStateChange}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={member.active} id="active"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="active">
                                                            Active</label>

                                                        </div>
                                                    </Col>
                                                </Row>
                                        
                                           
                                                {submitting?
                                                <button
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light my-3"
                                                    >
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                    Submit
                                                </button>
                                                :
                                                <Button color="primary my-3" type="submit">
                                                Submit
                                                </Button>
                                            }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="6">
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Gallery </CardTitle>  
                                                <div className="table-responsive">

                                                    <BootstrapTable 
                                                        bootstrap4 
                                                        keyField="id"
                                                        data={ gallery } 
                                                        columns={ columns }
                                                        bordered={false}
                                                        striped={false}
                                                        classes={
                                                        "table align-middle table-nowrap table-check"
                                                        }
                                                        headerWrapperClasses={"table-light"} />
                                                </div>
                                                
                                                <Button color="primary my-3" type="button" onClick={() => tog_standard()}>
                                                Add Images
                                                </Button>
                                             

                                                
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </Col>
                    </Row>
                :
                <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                </div>
            </div>
                }
            </div>
            {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleImageClick(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
            <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{`Add New Image`} </h5>     

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={handleImageSubmit}>
            <div className="modal-body">
            <Row>
                <Col md="12">
                <div className="mb-3">
                    <Label htmlFor="image_name">Name</Label>
                    <AvField
                    name="name"
                    placeholder="Name"
                    type="text"
                    errorMessage="Enter Name"
                    className="form-control"
                    id="image_name"
                    />
                </div>
                </Col>
            </Row>
            <Row>
                <Label htmlFor="icon">Image</Label>
                <Col sm={6}>
                    <input id="icon" className="mb-3" type="file" required onChange={handleChange} />
                </Col>
                {file?
                <Col sm={6}>
                    <img src={URL.createObjectURL(file)} className="rounded avatar-lg m-2" alt="" />
                </Col>: null}
                
            </Row>
                
            </div>
            <div className="modal-footer">
            {galleryLoading?
                <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light my-3"
                    >
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                    Submitting
                </button>
                :
                <>
                <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                >
                Submit
                </button>
                <button
                type="button"
                onClick={() => {
                    tog_standard()
                }}
                className="btn btn-primary waves-effect"
                data-dismiss="modal"
                >
                Close
                </button>
            </>  }
          </div>
          
          </AvForm>

        </Modal>
      </Col>

        </React.Fragment>
    )
}

export default EditMember
