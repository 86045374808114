import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner} from "reactstrap"
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { get } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import { HeaderFormatter } from "../../../helpers/methods";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const Pages = (props) => {

  const [categories, setCategories] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [SearchText, setsearchText] = useState("")
  const [loaddata, setLoadData] = useState(false)

  ValidateUser(props)

  const columns = [
    {
      dataField: "id",
      text: "ID", 
      hidden: true,
    },
    {
      dataField: "title",
      text: "Title",
      headerFormatter:HeaderFormatter,
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/edit-page-meta/${row.slug}`} className="me-3 text-dark">{(`${cellContent}`).substring(0,60)}</Link>     
      ),
      title: (cell) => `${cell}`
    },
    // {
    //     dataField: "slug",
    //     text: "Site",
    //     headerFormatter:HeaderFormatter,
    //     sort: true,
    //     formatter: (cellContent, row) => (  
    //       <a target="_blank" rel="noreferrer" href={`${SITE_URL}/locations/${row.city.slug}/${row.category.slug}`} className="me-3 text-dark"><i className="mdi mdi-search-web font-size-18"></i></a>
    // )
    // },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerFormatter:HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={`/edit-page-meta/${row.slug}`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/dashboard/page-meta/?page_size=${sizePerPage}`, sizePerPage, page, SearchText)
    
  }, []);


  function loadData(url , sizePerPage, page , searchText){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setCategories(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
      setLoadData(true)
    })
  }

  function NoDataIndication(){
    if (loaddata && categories.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }
  
  function handleTableChange (type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      setLoadData(false)
      setCategories([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/dashboard/page-meta/?page_size=${sizePerPage}&&page=${new_page}`
      if (searchText && searchText !== ""){
          new_page = SearchText !== searchText? 1 : new_page
          url = `${BASE_URL}/api/tgcl/dashboard/page-meta/?page_size=${sizePerPage}&page=${new_page}&search=${searchText}`
      }
      loadData(url , sizePerPage, new_page , searchText)
    }, 300);
  }
 
  const defaultSorted = [{
      dataField: 'title',
      order: 'asc'
    }];

    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Pages' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={categories}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={categories}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="4">
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                
                              </div>
                            </Col>
                          </Row>

                          
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                              noDataIndication={ NoDataIndication }
                            />
                            
                          </div>
                          <Row>
                                <Col sm={6}>
                                  <div className="mb-3">
                                      <SizePerPageDropdownStandalone
                                        { ...paginationProps }
                                      />
                                  </div>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                              </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
       
        
      </div>

    </React.Fragment>
  )
}

export default Pages
