import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Spinner, Label, Button } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'moment-timezone';
import { AvForm, AvField } from "availity-reactstrap-validation"
import ReactApexChart from "react-apexcharts"
import ReactEcharts from "echarts-for-react"

import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { downloadExcel } from "react-export-table-to-excel";
import { HeaderFormatter } from "../../helpers/methods";
import moment from "moment-timezone";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"

const series = [70]
const bglocation = ['rgba(30, 136, 229, 0.25)', ['#1e88e5']]
const bgzip = ['rgba(251, 192, 45, 0.25)', ['#fbc02d']]
const bgsearch = ['rgba(59, 93, 231, .25)', ['#3b5de7']]
const bgprofile =['rgba(69, 203, 133, .25)', ['#45CB85']]

const series1 = [81]



const FormsAnalytics = (props) => {

    const [reports, setreports] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [loaddata, setLoadData] = useState(false)

    const [contractor, setContractor] = useState(0)
    const [contractordata, setContractorData] = useState([620, 832, 750, 934])
    const [contractorweeks, setContractorWeeks] = useState( ["Week 1", "Week 2", "Week 3", "week 4"])

    const [usersreports, setUsersReports] = useState([]);
    const [users, setUsers] = useState(0)
    const [usersdata, setUsersData] = useState([620, 832, 750, 934])
    const [usersweeks, setUsersWeeks] = useState( ["Week 1", "Week 2", "Week 3", "week 4"])

    const [feedbackreports, setFeedbackReports] = useState([]);
    const [feedback, setFeedback] = useState(0)
    const [feedbackdata, setFeedbackData] = useState([620, 832, 750, 934])
    const [feedbackweeks, setFeedbackWeeks] = useState( ["Week 1", "Week 2", "Week 3", "week 4"])

    const [emailreports, setEmailReports] = useState([]);
    const [email, setEmail] = useState(0)
    const [emaildata, setEmailData] = useState([620, 832, 750, 934])
    const [emailweeks, setEmailWeeks] = useState( ["Week 1", "Week 2", "Week 3", "week 4"])

    const [startdate, setStartDate] = useState(moment(new Date()).tz("America/Chicago").subtract(30,'d').format('YYYY-MM-DD'))
    const [enddate, setEnddate] = useState(moment(new Date()).tz("America/Chicago").format('YYYY-MM-DD'))

    ValidateUser(props)

    const columns = [
        {
            dataField: "week",
            text: "ID",
            hidden:true,
        
        },
        {
            dataField: "from",
            text: "From Date",
            headerFormatter:HeaderFormatter,
            sort: true,
            formatter: (cellContent) => (
              moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY')
            ),
        },
        {
            dataField: "to",
            text: "To Date",
            headerFormatter:HeaderFormatter,
            sort: true,
            formatter: (cellContent) => (
              moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY')
            ),

        },
        
    ]
    const ContractorColumn = [...columns, {
        dataField: "new_contractor",
        text: "No of New Contractors",
        headerFormatter:HeaderFormatter,
        sort: true,
    },]

    const UserColumn = [...columns, {
        dataField: "new_user",
        text: "No of New Contractors",
        headerFormatter:HeaderFormatter,
        sort: true,
    },]
    const FeedbackColumn = [...columns, {
        dataField: "count",
        text: "No of New Contractors",
        headerFormatter:HeaderFormatter,
        sort: true,
    },]

    const header = ["From Date", "To Date", "No of New Contractors"];



    useEffect(() => {
        
        loadData(`${BASE_URL}/api/tgcl/dashboard/contractor-reports/?startdate=${startdate}&enddate=${enddate}`)
        loadUsersData(`${BASE_URL}/api/tgcl/dashboard/user-reports/?startdate=${startdate}&enddate=${enddate}`)
        loadFedbackData(`${BASE_URL}/api/tgcl/dashboard/contractor-feedback-reports/?startdate=${startdate}&enddate=${enddate}`)
        loadEmailData(`${BASE_URL}/api/tgcl/dashboard/email-to-contractor-reports/?startdate=${startdate}&enddate=${enddate}`)
        
    }, []);
    
    function getOption(weeks=["Week 1", "Week 2", "Week 3", "week 4"], data= [620, 832, 750, 934]){
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          zlevel: 0,
          x: 40,
          x2: 30,
          y: 30,
          y2: 30,
          borderWidth: 0,
        },
        xAxis: {
          type: "category",
          data: weeks,
          axisLable: {
            color: "#ffffff",
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLable: {
            color: "#ffffff",
          },
          axisLine: {
            lineStyle: {
              color: "#74788d",
            },
          },
        },
        series: [
          {
            data: data,
            type: "line",
          },
        ],
        color: ["#556ee6"],
        textStyle: {
          color: ["#74788d"],
        },
      }
    }
    
    function handleFilter(e, values){
        if ((new Date(values.enddate)) > (new Date(values.startdate))){
          loadData(`${BASE_URL}/api/tgcl/dashboard/contractor-reports/?startdate=${values.startdate}&enddate=${values.enddate}`)
          
        }else{
          alert(`To date must be grater than from Date`)
        }
        
      }


    function loadData(url){
        let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
        data.then(response => {
        let total = 0
        let weeks = []
        let data = []
        for(let i=0; i<response.data.length; i++ ){
            total = total + response.data[i].new_contractor
            data.push(response.data[i].new_contractor)
            weeks.push(`Week ${response.data[i].week}`)
        }
        setreports(response.data)
        settotalSize((response.data).length)
        setLoadData(true)
        setContractorData(data)
        setContractor(total)
        setContractorWeeks(weeks)
        })
    }

    function loadUsersData(url){
        let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
        data.then(response => {
            console.log(response.data)
        let total = 0
        let weeks = []
        let data = []
        for(let i=0; i<response.data.length; i++ ){
            total = total + response.data[i].new_user
            data.push(response.data[i].new_user)
            weeks.push(`Week ${response.data[i].week}`)
        }
        setUsersReports(response.data)
        setUsersData(data)
        setUsers(total)
        setUsersWeeks(weeks)
        })
    }

    function loadFedbackData(url){
        let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
        data.then(response => {
            console.log(response.data)
        let total = 0
        let weeks = []
        let data = []
        for(let i=0; i<response.data.length; i++ ){
            total = total + response.data[i].count
            data.push(response.data[i].count)
            weeks.push(`Week ${response.data[i].week}`)
        }
        setFeedbackReports(response.data)
        setFeedbackData(data)
        setFeedback(total)
        setFeedbackWeeks(weeks)
        })
    }

    function loadEmailData(url){
        let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
        data.then(response => {
            console.log(response.data)
        let total = 0
        let weeks = []
        let data = []
        for(let i=0; i<response.data.length; i++ ){
            total = total + response.data[i].count
            data.push(response.data[i].count)
            weeks.push(`Week ${response.data[i].week}`)
        }
        setEmailReports(response.data)
        setEmailData(data)
        setEmail(total)
        setEmailWeeks(weeks)
        })
    }

    function NoDataIndication(){
        if (loaddata && totalSize === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )

        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
    }

    const defaultSorted = [{
        dataField: 'contractor',
        order: 'asc'
        }];

    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Dashboard" breadcrumbItem='Contractors Report' link="/dashboard" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                  <AvForm className="needs-validation" onValidSubmit={handleFilter}>
                    <Row>
                    
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <Row className="mb-3">
                              <Label htmlFor="email_bcc" className="col-md-4 col-form-label">From</Label>
                              <div className="col-md-8">
                              <AvField
                                name="startdate"
                                placeholder="To Date"
                                type="date"
                                errorMessage="To Date"
                                className="form-control"
                                value={startdate}
                                onChange={(e)=> setStartDate(e.target.value)}
                                id="to_date"
                                
                              />
                            </div>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row className="mb-3">
                              <Label htmlFor="email_bcc" className="col-md-4 col-form-label">To</Label>
                              <div className="col-md-8">
                              <AvField
                                name="enddate"
                                placeholder="To Date"
                                type="date"
                                errorMessage="To Date"
                                className="form-control"
                                value={enddate}
                                onChange={(e)=> setEnddate(e.target.value)}
                                id="to_date"
                              />
                            </div>
                            </Row>
                          </Col>
                          
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Button type="submit" color="success" className="btn-rounded me-2" >
                          Filter
                        </Button>
                        {" "}
                        <Button
                          type="button"
                          color="primary"
                          className="btn-rounded mb-2 me-2"
                          // onClick={() => handleDownloadExcel()}
                          >
                          <i className="mdi mdi-file-excel-outline me-1" />{" "}
                          Download
                        </Button>
                      </Col>

                      
                    </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              

            </Row>
            <Row>  
              <Col md={3}>
                  <Card>
                      <CardBody>
                          <Row>
                            <Col sm={8}>
                            <p className="text-muted fw-medium mt-1 mb-2">Guarantee Registration</p>
                            </Col>
                            <Col sm={4}>
                            <h4 className="">{users}</h4>
                            </Col> 
                          </Row>
                            <Row>
                            <div className="col-12">
                                <div>
                                    <ReactEcharts style={{ height: "350px" }} option={getOption(usersweeks, usersdata)} />
                                </div>
                            </div>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
              <Col md={3}>
                  <Card>
                      <CardBody>
                          <Row>
                            <Col sm={8}>
                            <p className="text-muted fw-medium mt-1 mb-2">Contractor Registration</p>
                            </Col>
                            <Col sm={4}>
                            <h4 className="">{contractor}</h4>
                            </Col> 
                          </Row>
                            <Row>
                            <div className="col-12">
                                <div>
                                    <ReactEcharts style={{ height: "350px" }} option={getOption(contractorweeks, contractordata)} />
                                </div>
                            </div>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
              <Col md={3}>
                  <Card>
                      <CardBody>
                          <Row>
                            <Col sm={8}>
                            <p className="text-muted fw-medium mt-1 mb-2">Email to Contractor</p>
                            </Col>
                            <Col sm={4}>
                            <h4 className="">{email}</h4>
                            </Col> 
                          </Row>
                            <Row>
                            <div className="col-12">
                                <div>
                                    <ReactEcharts style={{ height: "350px" }} option={getOption(emailweeks, emaildata)} />
                                </div>
                            </div>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
              <Col md={3}>
                  <Card>
                      <CardBody>
                          <Row>
                            <Col sm={8}>
                            <p className="text-muted fw-medium mt-1 mb-2">Customer Feedback</p>
                            </Col>
                            <Col sm={4}>
                            <h4 className="">{feedback}</h4>
                            </Col> 
                          </Row>
                            <Row>
                            <div className="col-12">
                                <div>
                                    <ReactEcharts style={{ height: "350px" }} option={getOption(feedbackweeks, feedbackdata)} />
                                </div>
                            </div>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
                      
            </Row>
            
            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18" style={{color:'black'}}>{"Gurantee Registraction"}</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card>
                    <CardBody>
                    <div className="table-responsive">

                        <BootstrapTable
                        responsive
                        keyField="week"
                        data={ usersreports }
                        columns={ UserColumn }
                        bordered={false}
                        striped={true}
                        hover
                        defaultSorted={defaultSorted}
                        classes={
                            "table align-middle table-nowrap table-check"
                        }
                        headerWrapperClasses={"table-light"}
                        noDataIndication={() => NoDataIndication()}
                        />

                        </div>
                        <Link to="/contractor-analytics">{'Detail ..'}</Link>
            
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18" style={{color:'black'}}>{"Contractor Registraction"}</h4>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <Card>
                    <CardBody>
                    <div className="table-responsive">

                        <BootstrapTable
                        responsive
                        keyField="week"
                        data={ reports }
                        columns={ ContractorColumn }
                        bordered={false}
                        striped={true}
                        hover
                        defaultSorted={defaultSorted}
                        classes={
                            "table align-middle table-nowrap table-check"
                        }
                        headerWrapperClasses={"table-light"}
                        noDataIndication={() => NoDataIndication()}
                        />

                        </div>
                        <Link to="/contractor-analytics">{'Detail ..'}</Link>
            
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18" style={{color:'black'}}>{"Email To Contractor"}</h4>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <Card>
                    <CardBody>
                    <div className="table-responsive">

                        <BootstrapTable
                        responsive
                        keyField="week"
                        data={ emailreports }
                        columns={ FeedbackColumn }
                        bordered={false}
                        striped={true}
                        hover
                        defaultSorted={defaultSorted}
                        classes={
                            "table align-middle table-nowrap table-check"
                        }
                        headerWrapperClasses={"table-light"}
                        noDataIndication={() => NoDataIndication()}
                        />

                        </div>
                        <Link to="/contractor-analytics">{'Detail ..'}</Link>
            
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="col-12">
                    <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
                    <h4 className="page-title mb-0 font-size-18" style={{color:'black'}}>{"Customer Feedback"}</h4>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <Card>
                    <CardBody>
                    <div className="table-responsive">

                        <BootstrapTable
                        responsive
                        keyField="week"
                        data={ feedbackreports }
                        columns={ FeedbackColumn }
                        bordered={false}
                        striped={true}
                        hover
                        defaultSorted={defaultSorted}
                        classes={
                            "table align-middle table-nowrap table-check"
                        }
                        headerWrapperClasses={"table-light"}
                        noDataIndication={() => NoDataIndication()}
                        />

                        </div>
                        <Link to="/contractor-analytics">{'Detail ..'}</Link>
            
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* <ContractorReport /> */}
        
            </div>

        </React.Fragment>
    )
}

export default FormsAnalytics
