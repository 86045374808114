import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
  import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import { get } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { HeaderFormatter } from "../../helpers/methods";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"



const FinancialReport = (props) => {

    const [reports, setreports] = useState([]);
    const [totalSize, settotalSize] = useState(0);
    const [loaddata, setLoadData] = useState(false)
    const [membershipFee, setMembershipFee] = useState(0);
    const [monthlyFee, setMonthlyFee] = useState(0);

    ValidateUser(props)

    const columns = [
        {
            dataField: "id",
            text: "ID",
            hidden:true,
        
        },
        {
            dataField: "contractor",
            text: "Contractor Name",
            headerFormatter:HeaderFormatter,
            sort: true,
        },
        {
            dataField: "membership_fee",
            text: "Membership Fee",
            headerFormatter:HeaderFormatter,
            sort: true,

        },
        {
            dataField: "monthly_payment",
            text: "Monthly Fee",
            headerFormatter:HeaderFormatter,
            sort: true,
        },
    ]



    useEffect(() => {
        loadData(`${BASE_URL}/api/tgcl/dashboard/financial-report/`)
        
    }, []);   


    function loadData(url){
        let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
        data.then(response => {
        setreports(response.data.data)
        settotalSize(response.data.count)
        setMembershipFee(response.data.total_membership_fee)
        setMonthlyFee(response.data.total_monthly_payment)
        setLoadData(true)
        })
    }
    function NoDataIndication(){
        if (loaddata && totalSize === 0){
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className="my-3">Table is Empty</h4>
                </div>
          )

        }else{
            return (
            
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner className="my-3" color="dark" />
                </div>
          )
            
        } 
    }
    
    const defaultSorted = [{
        dataField: 'contractor',
        order: 'asc'
        }];

    const { SearchBar } = Search

        //pagination customization
    const pageOptions = {
        sizePerPage: reports.length,
        totalSize: reports.length, // replace later with size(Order),
        custom: true,
    }

    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Dashboard" breadcrumbItem='Financial Report' link="/dashboard" />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="4">
                                    <CardTitle>{`Campaigns: ${totalSize} `}</CardTitle>  
                                </Col>
                                <Col sm="4">
                                    <CardTitle>{`Total Membership Fees: $${membershipFee} `}  </CardTitle>  
                                </Col>
                                <Col sm="4">
                                    <CardTitle>{`Total Monthly: $${monthlyFee} `} </CardTitle>  
                                </Col>
                            </Row>    
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                    <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={reports}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                        keyField="id"
                        data={reports}
                        columns={columns}
                        bootstrap5
                        search
                        >
                        {toolkitProps => (
                            <React.Fragment>
                            
                            <Row className="mb-2">
                                <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                    <SearchBar
                                        {...toolkitProps.searchProps}
                                    />
                                    
                                    </div>
                                </div>
                                </Col>
                                <Col sm="8">
                                <div className="text-sm-end">
                                </div>
                                </Col>
                            </Row>
                            <div className="table-responsive">

                                <BootstrapTable
                                // remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                defaultSorted={defaultSorted}
                                classes={
                                    "table align-middle table-nowrap table-check"
                                }
                                hover
                                headerWrapperClasses={"table-light"}
                                noDataIndication={() => NoDataIndication()}
                                />
                                
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                {...paginationProps}
                                />
                            </div>
                            </React.Fragment>
                        )}
                        </ToolkitProvider>
                    )}
                    </PaginationProvider>
          
                </CardBody>
                </Card>
            </Col>
            </Row>
        
            </div>

        </React.Fragment>
    )
}

export default FinancialReport
