import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { BASE_URL } from "../../helpers/url_helper";
import { get, put, } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { EditorState, convertToRaw, ContentState, left, right, center, justify, undo, redo, color} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken";
import { ValidateUser } from "../../helpers/validate_user";



const TermsCondition = (props) => {
    ValidateUser(props)


    const [didMount, setDidMount] = useState(false);
    const [terms, setTerms] = useState(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty()); 
    const [rawMessage, setrawMessage] = useState(''); 
    const [submitting, setSubmitting] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false)


    useEffect(() => {
        setDidMount(true);
        loadTerms()
    
        
    },[]);

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
      }
      function removeBodyCss() {
        document.body.classList.add("no_padding")
      }

    if(!didMount) {
        return null;
      }


    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };


    function loadTerms(){
        get(`${BASE_URL}/api/tgcl/dashboard/terms-conditions/1/`, { headers: { 'Content-Type': 'application/json', }, validateStatus: false})
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing went Wrong")
                
            }else{
                setTerms(response.data)
                const { contentBlocks, entityMap } = htmlToDraft(response.data.content);
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorrState = EditorState.createWithContent(contentState);
                setrawMessage(draftToHtml(convertToRaw(editorrState.getCurrentContent())))
                setEditorState(editorrState)
            }
        })
    }


    function handleSubmit(e, values){
        let data = {}
        data['content'] = rawMessage
        setSubmitting(true)
        put(`${BASE_URL}/api/tgcl/dashboard/terms-conditions/${terms.id}/`, data,
        { headers: { 'Content-Type': 'application/json','Authorization': getAccessToken() }, validateStatus: false}
        )
        .then(response => {
            if(response.status >= 400){
                console.log(response)
                toast.error("Somthing went Wrong")
                setSubmitting(false)
                
            }else{
                setSubmitting(false)
                toast.success("Terms Updated")
            }
        })
        
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title={'Dashboard'} breadcrumbItem={`Terms & Conditions `} link={"/dashboard"} />
                {terms?
                    <Row>
                        <Col md="12">
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardBody>
                                               
                                                
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                    {/* <Label >Terms & Condition</Label> */}
                                                        <Editor       
                                                        toolbar={{
                                                        
                                                          options: ['inline', 'blockType','textAlign','colorPicker', 'history'],
                                                          inline: {
                                                          options: ['bold', 'italic', 'underline'],
                                                          },
                                                          blockType: {
                                                              inDropdown: true,
                                                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                            },
                                                            textAlign: {
                                                              inDropdown: false,
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                              options: ['left', 'center', 'right', 'justify'],
                                                              left: { icon: left, className: undefined },
                                                              center: { icon: center, className: undefined },
                                                              right: { icon: right, className: undefined },
                                                              justify: { icon: justify, className: undefined },
                                                            },
                                                            history: {
                                                              inDropdown: false,
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                              options: ['undo', 'redo'],
                                                              undo: { icon: undo, className: undefined },
                                                              redo: { icon: redo, className: undefined },
                                                            },
                                                            fontFamily: {
                                                              options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'metropolisRegular', 'gothambold'],
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                            },
                                                            colorPicker: {
                                                              icon: color,
                                                              className: undefined,
                                                              component: undefined,
                                                              popupClassName: undefined,
                                                              colors: ['rgb(0,0,0)','rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(128, 128, 128)',
                                                                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                                            },
                                                      }}   
                                                        editorStyle={{ height: '450px', border:'solid 2px', padding:'10px' }}   
                                                        editorState={editorState}            
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={onEditorStateChange}
                                                        />
                                                    </Col>
                                                </Row>
                                        
                                           
                                                {submitting?
                                                <button
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light my-3"
                                                    >
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                    Submit
                                                </button>
                                                :
                                                <Button color="primary my-3" type="submit">
                                                Submit
                                                </Button>
                                            }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </AvForm>
                        </Col>
                    </Row>
                :
                <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                </div>
            </div>
                }
            </div>
          
            <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{`Add New Image`} </h5>     

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={() => alert("Sunmit")}>
            <div className="modal-body">
            <Row>
                <Col md="12">
                <div className="mb-3">
                    <Label htmlFor="image_name">Name</Label>
                    <AvField
                    name="name"
                    placeholder="Name"
                    type="text"
                    errorMessage="Enter Name"
                    className="form-control"
                    id="image_name"
                    />
                </div>
                </Col>
            </Row>
                
            </div>
            <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-light"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-primary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            
          </div>
          </AvForm>

        </Modal>
      </Col>

        </React.Fragment>
    )
}

export default TermsCondition
