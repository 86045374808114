import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Spinner, Label, Modal,} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { get, del, post } from "../../../helpers/api_helper"
import { BASE_URL } from "../../../helpers/url_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import { toast } from "react-toastify";
import { HeaderFormatter } from "../../../helpers/methods";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import { slugify } from "../../../helpers/helper_methods";
import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken";



const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Name", value: "search" },
    ],
  },
];


const States = (props) => {

  const [locations, setLocations] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [loaddata, setLoadData] = useState(false)
  const [modal_standard, setmodal_standard] = useState(false)
  const [loading, setLoading] = useState(false)
  const [slug, setSlug] = useState(false)

  const [filter, setFilter] = useState("")
  const [search, setSearch] = useState("")
  const [search_by, setSearchby] = useState({ label: "Search By", value: null });


  ValidateUser(props)


  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const columns = [
    {
      dataField: "id_num",
      text: "ID", 
      hidden: true,
    },
    {
      dataField: "state",
      text: "State",
      headerFormatter:HeaderFormatter,
      sort: true,
    },
    {
      dataField: "abbr",
      text: "Postal Abbreviation",
      headerFormatter:HeaderFormatter,
      sort: true,
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/state/?page_size=${sizePerPage}`, sizePerPage, page)    
  }, []);


  function FilterData(e, values){
    let filter_data = ""
    if(!search_by.value){
      toast.error("Select Search Field")
    }else{
     
      filter_data = `&${"search"}=${values.search}`     
      loadData(`${BASE_URL}/api/tgcl/state/?page_size=${sizePerPage}${filter_data}`, sizePerPage, page)
      setFilter(filter_data)


    }
  }

  function ClearFilter(){

    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null });

    loadData(`${BASE_URL}/api/tgcl/state/?page_size=${sizePerPage}`, sizePerPage, 1)
  }


  function loadData(url , sizePerPage, page){
    let data = get(url, { headers: { 'Content-Type': 'application/json', }} )
    data.then(response => {
      setLocations(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setLoadData(true)
    })
  }

  function NoDataIndication(){
    if (loaddata && locations.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  function handleStateClicks (id) { 
  
    del(`${BASE_URL}/api/tgcl/state/${id}/`, 
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            let new_page = page
            if(locations.length < 2){
              new_page = new_page>1? new_page-1:1
            }
            let url = `${BASE_URL}/api/tgcl/state/?page_size=${sizePerPage}&page=${new_page}`
            if (filter !== ""){
              url = `${url}${filter}`
            }
            loadData(url , sizePerPage, new_page)
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Deleted!')
            setDynamic_description("State Has been deleted.") 
            setSelected_id(null)
          }
      }
    )
  }


  function AddNewLocation(e, values){
  
    setLoading(true)
      post(`${BASE_URL}/api/tgcl/state/`, values,
      { headers: { 'Content-Type': 'application/json', 
        Authorization: getAccessToken()
    }, validateStatus: false}  
      )
      .then(response => {
          if(response.status >= 400){
            let errors = response.data.detail
              console.log(response)
            if(typeof errors === "string" && errors){
                toast.error(response.data.detail)
            }else if(errors){
                Object.keys(errors).map((field)=> {
                    toast.error(errors[field].join(', '))
                })
              }else{
                toast.error("Somthing went Wrong")
              }
              tog_standard()
              setLoading(false)
              
          }else{
            toast.success(`${response.data.state} Added`)
              tog_standard()
              setLoading(false)
          }
      })
    console.log(values)
  }
  
  function handleTableChange (type, { page, sizePerPage, }) {
    setTimeout(() => {
      setLoadData(false)
      setLocations([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/state/?page_size=${sizePerPage}&&page=${new_page}`
      if (filter !== ""){
        url = `${url}${filter}`
      }
      window.scrollTo(0, 0)
      loadData(url , sizePerPage, new_page)
    }, 300);
  }
 
  const defaultSorted = [{
      dataField: 'name',
      order: 'asc'
    }];


    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='States' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={locations}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={locations}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                          <Col sm='9'>
                              <AvForm className="needs-validation" onValidSubmit={FilterData}>
                                <Row className="mb-2">
                                  <Col sm="3">
                                    <div className="text-sm-start mb-2">
                                        <Select
                                        value={search_by}
                                        onChange={(e) => {
                                            setSearchby(e)
                                        }}
                                        options={optionGroup}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                    
                                    
                                  </Col>
                                  {search_by.value?
                                  <Col sm="9">
                                    <Row>
                                      <Col sm="8">
                                        {search_by.value !== null?
                                        <Row>
                                          <Col sm="12">
                                            <div className="text-sm-end mb-2">
                                              <AvField
                                                name="search"
                                                placeholder={search_by.label}
                                                type="text"
                                                value={search}
                                                onChange={(e)=> setSearch(e.target.value)}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                              />
                                            </div>
                                          
                                          </Col>
                                          
                                        </Row>

                                        : null}
                                        
                                      </Col>
    
                                      <Col sm="4">
                                          <Button type="submit" color="success" className="btn-rounded me-2" >
                                            Filter
                                          </Button>
                                          {" "}
                                          <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded" >
                                            Clear
                                          </Button>
                                        
                                      </Col>
                                    </Row>  
                                  </Col>
                                  :null}
                                  
                                </Row>
                              </AvForm>
                            </Col>
                            <Col sm="3">
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={tog_standard}
                                >
                                  <i className="mdi mdi-plus me-1" />{" "}
                                  Add New State
                                </Button>
                              </div>
                            </Col>
                          </Row>

                          
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                              noDataIndication={ NoDataIndication }
                            />
                            
                          </div>
                          <Row>
                                <Col sm={6}>
                                  <div className="mb-3">
                                      <SizePerPageDropdownStandalone
                                        { ...paginationProps }
                                      />
                                  </div>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                              </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleStateClicks(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
        
      </div>
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{`Add State`} </h5>     

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={AddNewLocation}>
            <div className="modal-body">
              <Row>
                  <Col md="12">
                      <div className="mb-3">
                      <Label htmlFor="state">Name</Label>
                      <AvField
                          name="state"
                          placeholder="State Name"
                          type="text"
                          errorMessage="Enter State Name"
                          className="form-control"
                          validate={{ 
                            required: { value: true } }}
                        //   onChange={(e)=> setSlug(slugify(`${e.target.value} ${selectedCounty.label} ${selectedState.value?.abbr}`))}
                          id="state"
                      />
                      </div>
                  </Col>
              </Row>
              <Row>
                  <Col md="12">
                      <div className="mb-3">
                      <Label htmlFor="abbr">Abbreviation</Label>
                      <AvField
                          name="abbr"
                          placeholder="Postal Abbreviation"
                          type="text"
                          errorMessage="Enter Abbreviation"
                          className="form-control"
                          validate={{ required: { value: true },
                                    pattern: {value: '^[A-Z]+$', errorMessage:"Enter Only in Capital Letters"},
                                    minLength: {value: 2, errorMessage:"Abbreviation must be 2 characters"},
                                    maxLength: {value: 2, errorMessage:"Abbreviation must be 2 characters"}
                                    }}
                          id="abbr"
                      />
                      </div>
                  </Col>
              </Row>
                
            </div>
            <div className="modal-footer">
            {loading?
              <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              >
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                Submitting
              </button>
              :
              <Button color="primary" type="submit">
                Submit
              </Button>}
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-primary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            
          </div>
          </AvForm>

        </Modal>
      </Col>

    </React.Fragment>
  )
}

export default States
