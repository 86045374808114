import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container } from "reactstrap"

import {  Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { post } from "../../helpers/api_helper"
import { BASE_URL, SITE_URL } from "../../helpers/url_helper"

// import images
import logo from "../../assets/images/logo-sm-dark.png"

const ForgetPasswordPage = props => {

  const statedata = props.history.location.state?.data
  const [confirm, setConfirm] = useState(false)
  const [alert, setAlert] = useState(null)
  

  useEffect(() => {
    if (statedata){
      setAlert(statedata)
      props.history.replace({ state: {} })
    }
    
  }, [])

  function handleValidSubmit(event, values) {
    let data = {'email': values.email}
    post(`${BASE_URL}/api/accounts/passwordreset/`, data, { validateStatus: false })
    .then(resp => {
      if(resp.status >=400){
        setAlert(resp.data.email)
      }
       else{
        setConfirm(true)
        setAlert(null)
       }
    })
    // setConfirm(true)
  }
  return (
    <React.Fragment>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Reset Password</h5>
                    {/* <p className="text-white-50 mb-0">Re-Password with TGCL.</p> */}

                    <a href="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="30" />
                    </a>
                  </div>
                </div>
                <div className="card-body pt-5">

                  <div className="p-2">

                    
                    {confirm?
                        <div className="p-2">
                          <div className="text-center">
                            <div className="avatar-md mx-auto">
                              <div className="avatar-title rounded-circle bg-light">
                                <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                              </div>
                            </div>
                            <div className="p-2 mt-4">
                              <h4>Success !</h4>
                              <p className="text-muted">
                                Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.


                                </p>
                              <div className="mt-4">
                                <Link to="/login" className="btn btn-success">
                                  Back to Login
                                  </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                    :
                      
                      <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                        >
                        {alert ? (
                        <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          {alert}
                        </Alert>
                      ) : null}
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </AvForm> 
                    }
                   
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
              {confirm?null:<p>Remember It ? <Link to="/login" className="fw-medium text-primary"> Sign In here</Link> </p>}
                  <p>© {'2023'} <a target="_blank" rel="noreferrer" href={SITE_URL}>{'thegoodcontractorslist.com'}</a> </p>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPasswordPage

