import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button, Modal } from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { slugify } from "../../../helpers/helper_methods";
import { toast } from "react-toastify";
import { BASE_URL, SITE_URL } from "../../../helpers/url_helper";
import { get, patch, post, del } from "../../../helpers/api_helper";
import { ValidateUser } from "../../../helpers/validate_user";
import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const ManageCities = (props) => {
  const { id } = props.match.params;
  const debounceTimeout = useRef(null);

  const [selectedState, setselectedState] = useState({
    label: "Select State",
    value: null,
  });
  const [selectedCounty, setselectedCounty] = useState({
    label: "Select County",
    value: null,
  });

  const [cityData, setCityData] = useState(null);
  const [campainsOptions, setCampainsOptions] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [metaData, setMetaData] = useState(null);
  const [metaLoading, setMetaLoading] = useState(null);
  const [loading, setLoading] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [slug, setSlug] = useState("");
  const [pageContent, setPageContent] = useState([])

  const [submitting, setSubmitting] = useState(null);
  const [success_confirm, setSuccess_confirm] = useState(false);
  const [alert_confirm, setAlert_confirm] = useState(false);
  const [selected_id, setSelected_id] = useState(null);
  const [dynamic_title, setDynamic_title] = useState("");
  const [dynamic_description, setDynamic_description] = useState("");
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);

  const [rawMessage, setrawMessage] = useState("");
  const [stateChange, setStateChange] = useState("editor");
  const [showHTMLChange, setShowHTMLChange] = useState(false);

  const Campaigns = [
    {
      label: "Campains",
      options: campainsOptions,
    },
  ];

  const StateOptions = [
    {
      label: "State",
      options: [],
    },
  ];

  const CountyOptions = [
    {
      label: "County",
      options: [],
    },
  ];

  ValidateUser(props);

  useEffect(() => {
    setDidMount(true);
    loadClityData();
    getCampaigns(`${BASE_URL}/api/tgcl/dashboard/campaign/`);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function handleContentEdit(content) {
    setrawMessage(content);
  }

  function handleEditorChange(editor) {
    const editor_Data = editor.getData();
    setrawMessage(editor_Data);
  }

  function loadMetaDetail(slug) {
    let data = get(`${BASE_URL}/api/tgcl/dashboard/location-listing/?location__slug=${slug}`, {
      headers: { "Content-Type": "application/json" },
      validateStatus: false,
    });
    data.then((response) => {
      if (response.status >= 400) {
        console.log("error", response);
      } else {
        setMetaData(response.data.results[0]);
        let campa = response.data.results[0].campaigns
        let options = []
        for (let i=0; i<campa.length; i++ ){
          options.push({label:campa[i].pg_title , value:campa[i].id})
        }
        setSelectedCampaigns(options)
      }
    });
  }

  function loadClityData() {
    let data = get(`${BASE_URL}/api/tgcl/city/${id}/`, {
      headers: { "Content-Type": "application/json" },
      validateStatus: false,
    });
    data.then((response) => {
      if (response.status >= 400) {
        console.log("error", response);
      } else {
        loadMetaDetail(response.data.slug)
        loadLocationPageContent(response.data.slug)
        setCityData(response.data);
        setSlug(response.data.slug);
        setselectedCounty({
            label: response.data.county.name,
            value: response.data.county.id,
          })
        setselectedState({
            label: response.data.county.state.state,
            value: response.data.county.state.id,
          })
      }
    });
  }

  function getCampaigns(url){
    let data = get(url, {
      headers: { "Content-Type": "application/json" },
      validateStatus: false,
    });
    data.then((response) => {
      if (response.status >= 400) {
        console.log("error", response);
      } else {
        let campa = response.data.results
        let options = []
        for (let i=0; i<campa.length; i++ ){
          options.push({label:`${campa[i].pg_title} (${campa[i].page_title})` , value:campa[i].id})
        }
        setCampainsOptions(options)
      }
    });
  }

  function handleSubmit(e, values) {
    setLoading(true)

    patch(`${BASE_URL}/api/tgcl/city/${id}/`, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
        validateStatus: false,
      }).then((response) => {
        if (response.status >= 400) {
          console.log(response);
        } else {
          toast.success(`${response.data.name} Location Updated Successfully`);
          setLoading(false)
          loadClityData();
        }
      });
  }

  function handleSubmitPageMeta(e, values) {  
    if(selectedCampaigns.length <3){
      toast.error("3 Campaigns are required")
      return 
    }
    let campaigns=[]
    for(let i=0; i< selectedCampaigns.length; i++){
      campaigns.push(selectedCampaigns[i].value)
    }
    let data = {...values, 'campaigns':campaigns}
    setMetaLoading(true)
    patch(`${BASE_URL}/api/tgcl/dashboard/location-listing/${metaData.id}/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getAccessToken(),
        },
        validateStatus: false,
      }).then((response) => {
        if (response.status >= 400) {
          console.log(response);
        } else {
          toast.success(`${response.data.location.name} Location Updated Successfully`);
          setMetaLoading(false)
          loadMetaDetail(cityData.slug)
        }
      });
  }

  function handleCampaignChange(e){
    if(e.length>3){
      toast.error("You Can not Add More than 3")
    }else{
      setSelectedCampaigns(e)
    }
  }

  function onInputChange(e) {  
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      getCampaigns(`${BASE_URL}/api/tgcl/dashboard/campaign/?search=${e}`);
    }, 1000);
  }

  function loadLocationPageContent(slug) {
    get(
      `${BASE_URL}/api/tgcl/dashboard/location-content/?page_size=${50}&page=${1}&location_slug=${slug}`,
      { headers: { "Content-Type": "application/json" } }
    ).then((response) => {
      setPageContent(response.data.results);
    });
  }

  function handleUpdateContent(e, values) {
    let data = {
      "location_page": metaData.id,
      "heading": values.title,
      "content": rawMessage,
      "listing_index": values.listing_index
    }
    setSubmitting(true);

    const url = selected_id? `${BASE_URL}/api/tgcl/dashboard/location-content/${selected_id.id}/`: `${BASE_URL}/api/tgcl/dashboard/location-content/`
    const method = selected_id? patch: post

    method(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getAccessToken(),
      },
      validateStatus: false,
    }).then((response) => {
      if (response.status >= 400) {
        console.log(response);
        let errors = Object.entries(response.data);
        errors.map(([key, val] = errors) => toast.error(val[0]));
        setSubmitting(false);
      } else {
        loadLocationPageContent(cityData.slug);
        setSubmitting(false);
        toast.success("Page Content Added");
        tog_fullscreen();
      }
    });
    // if (selected_id) {
    //   patch(
    //     `${BASE_URL}/api/tgcl/dashboard/location-content/${selected_id.id}/`,
    //     data,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: getAccessToken(),
    //       },
    //       validateStatus: false,
    //     }
    //   ).then((response) => {
    //     if (response.status >= 400) {
    //       console.log(response);
    //       let errors = Object.entries(response.data);
    //       errors.map(([key, val] = errors) => toast.error(val[0]));
    //       setSubmitting(false);
    //     } else {
    //       loadLocationPageContent(cityData.slug);
    //       setSubmitting(false);
    //       toast.success("Page Content Updated");
    //       tog_fullscreen();
    //     }
    //   });
    // } else {
    //   post(`${BASE_URL}/api/tgcl/dashboard/location-content/`, data, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: getAccessToken(),
    //     },
    //     validateStatus: false,
    //   }).then((response) => {
    //     if (response.status >= 400) {
    //       console.log(response);
    //       let errors = Object.entries(response.data);
    //       errors.map(([key, val] = errors) => toast.error(val[0]));
    //       setSubmitting(false);
    //     } else {
    //       loadLocationPageContent(cityData.slug);
    //       setSubmitting(false);
    //       toast.success("Page Content Added");
    //       tog_fullscreen();
    //     }
    //   });
    // }
  }

  function handleDeleteContent(id) {
    del(
      `${BASE_URL}/api/tgcl/dashboard/location-content/${id}/`,
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
    ).then((res) => {
      if (res.status >= 400) {
        console.log("error");
      } else {
        loadLocationPageContent(cityData.slug);

        setSuccess_confirm(true);
        setAlert_confirm(false);
        setDynamic_title("Deleted!");
        setDynamic_description("Content Has been deleted.");
        setSelected_id(null);
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title="Locations"
          breadcrumbItem="Add New location"
          link="/locations"
        />
        <Row>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle>Location Detail</CardTitle>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={handleSubmit}
                >
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>State</Label>
                        <Select
                          value={selectedState}
                          onChange={(e) => {
                            setselectedState(e);
                          }}
                          options={StateOptions}
                          classNamePrefix="select2-selection"
                          isDisabled = {true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>County</Label>
                        <Select
                          value={selectedCounty}
                          onChange={(e) => {
                            setselectedCounty(e);
                          }}
                          options={CountyOptions}
                          classNamePrefix="select2-selection"
                          isDisabled = {true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="name">Location Name</Label>
                        <AvField
                          name="name"
                          placeholder="Location Name"
                          type="text"
                          errorMessage="Enter Location Name"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          value={cityData?.name}
                          id="name"
                          onChange={(e) =>
                            setSlug(
                              slugify(
                                `${e.target.value} ${selectedCounty.label} ${selectedState.value.abbr}`
                              )
                            )
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="name">Slug</Label>
                        <AvField
                          name="slug"
                          placeholder="Location Slug"
                          type="text"
                          errorMessage="Enter Slug"
                          className="form-control"
                          value={slug}
                          onChange={(e) => setSlug(e.target.value)}
                          validate={{ required: { value: true } }}
                          id="slug"
                        />
                      </div>
                    </Col>
                  </Row>

                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                      Submitting
                    </button>
                  ) : (
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </AvForm>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                    <Col md="6">
                      <CardTitle>Manage Page Content </CardTitle>
                    </Col>
                    <Col md="6">
                      <Link
                        to={"#"}
                        onClick={() => {
                          tog_fullscreen();
                          setrawMessage("");
                          setSelected_id(null);
                        }}
                        className="me-3 text-primary float-end"
                      >
                        <i className="mdi mdi-plus font-size-18"></i> Add New
                        Content
                      </Link>
                    </Col>
                  </Row>
                  {pageContent.map((content) => (
                    <React.Fragment key={content.listing_index}>
                      <hr />

                      <Row className="mt-2">
                        <Col md="6">
                          <p>Index: {content.listing_index}</p>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <h4>{content.heading}</h4>
                          {content.content
                            .replace(/<[^>]+>/g, "")
                            .substring(0, 400)}{" "}
                          ...
                        </Col>
                        <Col md="12">
                          <div className="float-end">
                            <a
                              href={`${SITE_URL}/location/${cityData.slug}`}
                              target="_blank"
                              rel="noreferrer"
                              className="text-dark"
                            >
                              <u>View</u>
                            </a>
                            {" | "}
                            <Link
                              to={"#"}
                              onClick={() => {
                                tog_fullscreen();
                                handleContentEdit(content.content);
                                setSelected_id(content);
                              }}
                              className="text-dark"
                            >
                              {" "}
                              <u>Edit</u>
                            </Link>
                            {" | "}
                            <Link
                              to="#"
                              onClick={() => {
                                setAlert_confirm(true);
                                setSelected_id(content);
                              }}
                              className="text-dark"
                            >
                              <u>Delete</u>{" "}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Row></Row>
                    </React.Fragment>
                  ))}

            
              </CardBody>
            </Card>
          </Col>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle>Manage Page Content</CardTitle>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={handleSubmitPageMeta}
                >
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="page_title">Page Title</Label>
                        <AvField
                          name="page_title"
                          placeholder="Page Title"
                          type="text"
                          className="form-control"
                          id="page_title"
                          value={metaData?.page_title}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="description_heading">Heading</Label>
                        <AvField
                          name="description_heading"
                          placeholder="Heading"
                          type="text"
                          className="form-control"
                          id="description_heading"
                          value={metaData?.description_heading}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <AvField
                        className="mb-3"
                        type="textarea"
                        label="Page Description"
                        rows="3"
                        name="page_description"
                        id="page_description"
                        validate={{ required: { value: true } }}
                        value={metaData?.page_description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="meta_title">Meta Title</Label>
                        <AvField
                          name="meta_title"
                          placeholder="Meta Title"
                          type="text"
                          className="form-control"
                          id="meta_title"
                          value={metaData?.meta_title}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <AvField
                        className="mb-3"
                        type="textarea"
                        label="Meta Description"
                        rows="3"
                        name="meta_description"
                        id="meta_description"
                        validate={{ required: { value: true } }}
                        value={metaData?.meta_description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="keywords">Keywords</Label>
                        <AvField
                          name="keywords"
                          placeholder="Keywords"
                          type="text"
                          className="form-control"
                          id="keywords"
                          value={metaData?.keywords}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="campaign_heading">Campaign Heading</Label>
                        <AvField
                          name="campaign_heading"
                          placeholder="Campaign Heading"
                          type="text"
                          className="form-control"
                          id="campaign_heading"
                          value={metaData?.campaign_heading}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label htmlFor="listing_index">Index</Label>
                        <AvField
                          name="listing_index"
                          placeholder="Meta Title"
                          type="number"
                          className="form-control"
                          id="listing_index"
                          value={metaData?.listing_index}
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="mb-3">
                        <Label>Campaigns</Label>
                        <Select
                          value={selectedCampaigns}
                          onChange={handleCampaignChange}
                          options={Campaigns}
                          classNamePrefix="select2-selection"
                          onInputChange={(e)=> onInputChange(e)}
                          isMulti
                        />
                      </div>
                    </Col>
                  </Row>

                  {metaLoading ? (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                      Submitting
                    </button>
                  ) : (
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  )}
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Col sm={6} md={4} xl={3}>
              <Modal
                size="xl"
                isOpen={modal_fullscreen}
                toggle={() => {
                  tog_fullscreen();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    {selected_id ? "Update Page Content" : "Add Page Content"}
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_fullscreen(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col className="col-12">
                      <AvForm
                        className="needs-validation"
                        onValidSubmit={handleUpdateContent}
                      >
                        <Row>
                          <Col md="12">
                            <div className="mb-3">
                              <Label htmlFor="name">Title</Label>
                              <AvField
                                name="title"
                                placeholder="Title"
                                type="text"
                                errorMessage="Enter Title"
                                className="form-control"
                                value={selected_id?.heading}
                                validate={{ required: { value: true } }}
                                id="title"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <div className="mb-3">
                              <Label htmlFor="listing_index">Position</Label>
                              <AvField
                                name="listing_index"
                                placeholder="Position"
                                type="text"
                                className="form-control"
                                value={
                                  selected_id ? selected_id?.listing_index + "" : ""
                                }
                                validate={{
                                  number: true,
                                  required: {
                                    value: true,
                                    errorMessage: "Please enter position",
                                  },
                                }}
                                id="listing_index"
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col md="6">
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(e) =>
                                  setShowHTMLChange(e.target.checked)
                                }
                                id="html_content"
                                required
                              />{" "}
                              <label
                                className="form-check-label"
                                htmlFor="html_content"
                              >
                                Show HTML Content
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={showHTMLChange ? "6" : "12"}
                            className="mb-3"
                          >
                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                heading: {
                                  options: [
                                    {
                                      model: "paragraph",
                                      title: "Paragraph",
                                      class: "ck-heading_paragraph",
                                    },
                                    {
                                      model: "heading3",
                                      view: "h3",
                                      title: "Heading 3",
                                      class: "ck-heading_heading3",
                                    },
                                    {
                                      model: "heading4",
                                      view: "h4",
                                      title: "Heading 4",
                                      class: "ck-heading_heading4",
                                    },
                                  ],
                                },
                              }}
                              data={rawMessage}
                              onChange={(event, editor) => {
                                handleEditorChange(editor);
                              }}
                              onFocus={() => {
                                setStateChange("editor");
                              }}
                            />
                          </Col>
                          {showHTMLChange ? (
                            <Col md="6">
                              {stateChange === "text" ? (
                                <AvField
                                  className="mb-3"
                                  type="textarea"
                                  label="HTML Content "
                                  rows="16"
                                  name="pg_desc"
                                  id="page_description"
                                  // value={rawMessage}
                                  onChange={(e) => {
                                    setrawMessage(e.target.value);
                                    setStateChange("text");
                                  }}
                                  placeholder="HTML Content"
                                />
                              ) : (
                                <AvField
                                  className="mb-3"
                                  type="textarea"
                                  // label="HTML Content "
                                  rows="16"
                                  name="pg_desc"
                                  id="page_description"
                                  value={rawMessage}
                                  onChange={(e) => {
                                    setrawMessage(e.target.value);
                                    setStateChange("text");
                                  }}
                                  placeholder="HTML Content"
                                />
                              )}
                            </Col>
                          ) : null}
                        </Row>
                        <p style={{ color: "green" }}>
                          {" "}
                          Use (mobile_read_more) for mobile readmore and
                          (desktop_read_more) for desktop and tab readmore
                        </p>
                        <p style={{ color: "green" }}>
                          {" "}
                          Use (dynamic_location) to show location and
                          (dynamic_category) to show category name
                        </p>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setmodal_fullscreen(false)}
                          >
                            Close
                          </button>
                          {submitting ? (
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light my-3"
                            >
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                              Submitting
                            </button>
                          ) : (
                            <Button color="primary my-3" type="submit">
                              Submit
                            </Button>
                          )}
                        </div>
                      </AvForm>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </Col>
      </div>
      {alert_confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDeleteContent(selected_id.id)}
          onCancel={() => {
            setAlert_confirm(false);
            setSelected_id(null);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {success_confirm ? (
        <SweetAlert
          success
          title={dynamic_title}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setSuccess_confirm(false);
            setAlert_confirm(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  );
};

export default ManageCities;
