import React, {useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { CKEditor  } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Date = `<table style="width:100%">
<tr>
  <th>Company</th>
  <th>Contact</th>
  <th>Country</th>
</tr>
<tr>
  <td>Alfreds Futterkiste</td>
  <td>Maria Anders</td>
  <td>Germany</td>
</tr>
<tr>
  <td>Centro comercial Moctezuma</td>
  <td>Francisco Chang</td>
  <td>Mexico</td>
</tr>
</table>`

const Editor = (props) => {


    const [data, setData] = useState('<p>Hello from CKEditor&nbsp;5!</p>')

    

    function handleEditorChange (event, editor) {
        const data = editor.getData();
        console.log('Raw HTML:', data);
        
        // You can store or further process the HTML data here
    };

    

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Categories" breadcrumbItem="Create Category" link="/categories" />
                <Row>
                <Col className="col-6">
                    <Card>
                    <CardBody>
                        <CardTitle>Category Detail</CardTitle>                  
                        
                       
                        <CKEditor
                            editor={ ClassicEditor }
                            data={Date}
                            
                            onReady={editor => {
                                editor.ui.view.editable.element.style.height = '300px'; // Set the height here
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={ handleEditorChange }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                                setData('')
                            } }
                        />
                  
                    {/* <CKEditorContext context={ Context }>
                        <h2>Using the CKEditor&nbsp;5 context feature in React</h2>
                        <CKEditor
                            editor={ ClassicEditor }
                            config={ {
                                plugins: [ Paragraph, Bold, Italic, Essentials ],
                                toolbar: [ 'bold', 'italic' ]
                            } }
                            data="<p>Hello from the first editor working with the context!</p>"
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor1 is ready to use!', editor );
                            } }
                        />

                     
                    </CKEditorContext> */}

                        

                           
               
                    
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </div>

        </React.Fragment>
        
    );  

}

export default Editor;