import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Spinner} from "reactstrap"
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert";
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"

import { get, del } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"




const Users = (props) => {
  ValidateUser(props)

  const [users, setUsers] = useState([]);
  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [loaddata, setLoadData] = useState(false)

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "username",
      text: "Login Name",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/edit-admin/${row.id}`} className="me-3 text-dark">{cellContent}</Link>     
      ),
      
    },    
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      formatter: (cellContent) => (
        cellContent    
      ),
      title: (cell) => `${cell}`
    },
    {
        dataField: "last_name",
        text: "Last Name",
        sort: true
    },
    {
        dataField: "email",
        text: "Email",
        sort: true
    },
    {
      dataField: "role",
      text: "Type",
      formatter: (cellContent) => (
        cellContent === 0?"Super Admin":"Sub Admin"
        )
    },
    {
        dataField: "date_joined",
        text: "Created",
        sort: true,
        formatter: (cellContent) => (
          `${new Date(cellContent).getDate()}-${new Date(cellContent).getMonth()+1}-${new Date(cellContent).getFullYear()}`
          )
        
    },
    {
      dataField: "last_login",
      text: "Last login",
      sort: true,
      formatter: (cellContent, row) => (
        cellContent?
        `${new Date(cellContent).getDate()}-${new Date(cellContent).getMonth()+1}-${new Date(cellContent).getFullYear()}`
        :
        `${new Date(row.date_joined).getDate()}-${new Date(row.date_joined).getMonth()+1}-${new Date(row.date_joined).getFullYear()}`
        )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={`/edit-admin/${row.id}`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
          <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row.id)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    loadData()
    
  }, []);


  function loadData(){
    get(`${BASE_URL}/api/accounts/users/`, { headers: { 'Content-Type': 'application/json', }} )
    .then(response => {
      setUsers(response.data.results)
      setLoadData(true)
    })
  }

  function handleUserClicks (id) { 
  
    del(`${BASE_URL}/api/accounts/users/${id}/`, 
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            loadData()
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Deleted!')
            setDynamic_description("User Has been deleted.") 
            setSelected_id(null)
          }
      }
    )
  }

  function NoDataIndication(){
    if (loaddata && users.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }

  const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];


    const { SearchBar } = Search

    //pagination customization
    const pageOptions = {
        sizePerPage: 20,
        totalSize: users.length, // replace later with size(Order),
        custom: true,
      }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Users' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={users}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={users}
                      columns={columns}
                      bootstrap5
                      search
                    >
                      
                      {toolkitProps => (
                        <React.Fragment>
                          
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                  
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded mb-2 me-2"
                                  onClick={()=> props.history.push('/new-user')}
                                >
                                  <i className="mdi mdi-plus me-1" />{" "}
                                  Add New User
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <div className="table-responsive">

                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              noDataIndication= { NoDataIndication }
                            />
                            
                          </div>
                          <Row>
                                <Col sm={6}>
                                  <div className="pagination  mb-2">
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                                <Col sm={6}>
                                  <div className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  
                                </Col>
                              </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
            {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleUserClicks(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
      </div>

    </React.Fragment>
  )
}

export default Users
