import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"
import { BASE_URL } from "../../helpers/url_helper";
import { get, post } from "../../helpers/api_helper";
import { Link } from "react-router-dom"
import Switch from "react-switch";
import { toast } from "react-toastify";
import { EditorState, convertToRaw, left, right, center, justify, undo, redo, color, bold, italic, underline,
    ordered,fontSize, indent, outdent, link, unlink, eraser, unordered } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { slugify } from "../../helpers/helper_methods"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';

const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };



const AddCampaign = (props) => {

    const statedata = props.history.location.state?.data
    const [selectedCategory, setselectedCategory] = useState({ label: "Select Category", value: null });
    const [selectedContractor, setselectedContractor] = useState({ label: "Select Contractor", value: null });
    const [selectedSubCats, setSelectedSubCats] = useState([]);
    const [childCategories, setChildCategories] = useState([])
    const [didMount, setDidMount] = useState(false);

    const [categories, setCategories] = useState([]); 
    const [contractors, setContractors] = useState([]); 
    const [editorState, setEditorState] = useState(EditorState.createEmpty()); 
    const [rawMessage, setrawMessage] = useState(''); 
    const [contractor, setContractor] = useState(null);
    const [status, setStatus] = useState(false);
    const [logo, setLogo] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    const [phone, setPhone] = useState("")

    const Categories = [
        {
          label: "Categories",
          options: categories,
        },
      ];

    const Contractors = [
        {
          label: "Contractors",
          options: contractors
        },
      ];

      const SubCategories = [
        {
            label: "Sub Categories",
            options: childCategories
        },
        ];
    

    useEffect(() => {
        setDidMount(true);
        if (statedata){
            loadContractor(statedata.id)
          }else{
            loadContractors(null)
          }
        loadCategories()
        
        return () => {setDidMount(false); props.history.replace({ state: {} })};
        
    },[]);

    if(!didMount) {
        return null;
      }

    function loadContractor(id){
        let data = get(`${BASE_URL}/api/tgcl/contractor/${id}/`, { 'Content-Type': 'application/json' } )
        data.then(response => 
            { 
                setContractor(response.data.contractor)
                setPhone(response.data.contractor.phone)
                setselectedContractor({ label: response.data.contractor.bus_name, value: response.data.contractor.id}) 
            },
        error => {
            props.history.push('/contractors')
          })
    }

    function handleSelectCategory(selectedCategory) {
        loadChildCategories(selectedCategory.value)
        setSelectedSubCats([])
        setselectedCategory(selectedCategory);
    }

    function handleSelectContractor(selectedContractor) {
        setselectedContractor(selectedContractor);
    }

    function changeLogo(e, setData) {
        setData(e.target.files[0]);
    }

    function loadCategories(){
        let data = get(`${BASE_URL}/api/tgcl/headings/?parent_id=1&active=1&page_size=200`)
        data.then(response => {
            
        let options = []
        let cat_data = response.data.results
        for(let i=0; i<cat_data.length; i++){
            options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
        }
        setCategories(options)
        },
        error => {
            props.history.push('/campaigns')

        })
    }

    function loadChildCategories(id){
        let data = get(`${BASE_URL}/api/tgcl/headings/?parent_id=${id}&page_size=50`,  {  validateStatus: false} )
        data.then(response => {
            
        let options = []
        let cat_data = response.data.results
        for(let i=0; i<cat_data.length; i++){
            options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
        }

        setChildCategories(options)
        setSelectedSubCats(options)
        
        },
        error => {
            props.history.push('/campaigns')
            toast.error("Somthing Went wrong")

        })

    }

    function loadContractors(search){
        let url = `${BASE_URL}/api/tgcl/all-contractors/?approved=true&page_size=200`
        if (search){
            url = `${BASE_URL}/api/tgcl/all-contractors/?approved=true&page_size=200&search=${search}`
        }
        let data = get(url)
        data.then(response => {
        let selected = { label: "Select Contractor", value: null }
        let options = []
        let cont_data = response.data.results
        for(let i=0; i<cont_data.length; i++){
            options.push({ label: cont_data[i].bus_name, value: cont_data[i].id })

        }
        setContractors(options)
        // setselectedContractor(selected)
        },
        error => {
            props.history.push('/campaigns')

        })
    }

    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    function addPhoneNumber(id){
        let area_code = prompt("Please enter a three-digit area code:");
        if (area_code !== '' && area_code !== undefined)
        {
            get(`${BASE_URL}/api/tgcl/dashboard/add-phone-number/?area_code=${area_code}`, { 'Content-Type': 'application/json' } )
                .then(response => {
                    if(response.status >= 400){
                        console.log(response)
                        toast.error("Somthing went Wrong")
                        
                    }else{
                        let num = response.data.friendly_name
                        num = num.replace("(", "")
                        num = num.replace(" ", "")
                        num = num.replace(")", "-")
                        setPhone(num)
                        
                    }
                
                })
        }
        else
        {
          alert("Acquire canceled.");
        }

    }

    function releasePhoneNumber(id){
        
        let number = phone
        if (number !== ""){
            number = number.replaceAll("-", "")
            number = number[0] === "+"? number : `+1${number}`
            if (window.confirm("Are you sure you want to decomission this number?  This cannot be undone."))
            {
                get(`${BASE_URL}/api/tgcl/dashboard/delete-phone-number/?phone_number=${number}`, { 'Content-Type': 'application/json' } )
                .then(response => {
                    if(response.status >= 400){
                        console.log(response)
                        toast.error("Somthing went Wrong")   
                    }
                })
                
                setPhone("")  
            }

        }  

    }

    function handleSubmit(e, values){
        if(!selectedCategory.value){
            toast.error("Select Category")
        }else if(!selectedContractor.value){
            toast.error("Select Contractor")
        }else{
            setSubmitting(true)
            const formData = new FormData();
            if (logo){
                formData.append('logo', logo, logo.name) 
            }
            for(let i=0; i< selectedSubCats.length; i++){
                formData.append('sub_cats', selectedSubCats[i].value)
            }
            let slug =  slugify(`${selectedContractor.label} ${selectedCategory.label} ${values.zip}`)
            formData.append('omit_add', document.getElementById('omit_add').checked?1:0)
            formData.append('active', document.getElementById('active').checked?1:2)
            formData.append('narrative', rawMessage)
            formData.append('contractor', selectedContractor.value)
            formData.append('slug', slug)
            formData.append('category', selectedCategory.value)
            formData.append('status', status)
            formData.append('page_title', values.city)
            formData.append('email', values.email)
            formData.append('contact_phone', values.contact_phone)
            formData.append('exp', values.exp)
            formData.append('keyword', values.keyword)
            // formData.append('membership_fee', values.membership_fee)
            // formData.append('monthly_payment', values.monthly_payment)
            formData.append('pg_desc', values.pg_desc)
            formData.append('pg_title', values.pg_title)
            formData.append('phone', values.phone)
            formData.append('start', values.start)
            formData.append('url', values.url)
            formData.append('zip', values.zip)
            formData.append('zone', values.zone)
            formData.append('email2', values.email2)
            formData.append('email3', values.email3)
            const options = {
                method: 'POST',
                body: formData,
                headers: { 'Authorization': getAccessToken()}
            };
            fetch(`${BASE_URL}/api/tgcl/dashboard/campaign/`, options)
            .then(response => 
                {   if(response.status >= 400){
                        response.json()
                        .then(
                            (data) =>{  
                            console.log(data, "---data")
                            toast.error("Somthing Went Wrong")
                            setSubmitting(false)
                            }    
                        )
                    }
                    else{
                        response.json()
                        .then(
                            (data) =>{
                                props.history.push(`/edit-campaign/${data.id}`)
                    })
                    } 
                   
                }
            )
        }
        
    }
    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title={contractor? `${contractor.bus_name} Details`:"Campaign"} breadcrumbItem={`Add Campaign`} link={contractor?`/contractor/${contractor.id_num}`:"/campaigns"} />
                {statedata && !contractor?
                    <div id="preloader">
                        <div id="status">
                            <div className="spinner-chase">
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                                <div className="chase-dot"></div>
                            </div>
                        </div>
                    </div>
                :
                    <Row>
                        <Col md="12">
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Campaign Detail </CardTitle>     
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label>Category</Label>
                                                            <Select
                                                            value={selectedCategory}
                                                            onChange={(e) => {
                                                                handleSelectCategory(e);
                                                            }}
                                                            options={Categories}
                                                            classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                    
                                                    
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label>Sub Categories</Label>
                                                            <Select
                                                            value={selectedSubCats}
                                                            onChange={(e) => {
                                                                setSelectedSubCats(e)
                                                            }}
                                                            options={SubCategories}
                                                            isMulti={true}
                                                            classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label>Contractor</Label>
                                                            {contractor?
                                                                <Select
                                                                onInputChange = {(e)=> loadContractors(e)}
                                                                value={selectedContractor}
                                                                onChange={(e) => {
                                                                    handleSelectContractor(e);
                                                                }}
                                                                options={Contractors}
                                                                classNamePrefix="select2-selection"
                                                                isDisabled = {true}
                                                                />
                                                            :
                                                                <Select
                                                                onInputChange = {(e)=> loadContractors(e)}
                                                                value={selectedContractor}
                                                                onChange={(e) => {
                                                                    handleSelectContractor(e);
                                                                    loadContractor(e.value)
                                                                }}
                                                                options={Contractors}
                                                                classNamePrefix="select2-selection"
                                                                />
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row> 
                                                <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <Label htmlFor="url">Site URL</Label>
                                                <AvField
                                                    name="url"
                                                    type="text"
                                                    errorMessage=" Please Enter Site URL."
                                                    className="form-control"
                                                    id="url"
                                                    value={contractor?contractor.url:""}
                                                />
                                                </div>
                                            </Col>
                                        </Row> 
                                                
                                                              
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="phone"
                                                                label="Tracking Phone"
                                                                placeholder="Tracking Phone"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9+-]+$",
                                                                        errorMessage: "Invalid Contact"
                                                                    },
                                                                }}
                                                                value={phone}
                                                                onChange={(e)=> setPhone(e.target.value)}
                                                            />
                                                            <Link to={'#'}  onClick={()=>addPhoneNumber('phone') } className="text-primary" style={{fontSize:'10px'}}>Acquire New Number</Link> | <Link to={'#'} onClick={()=> releasePhoneNumber('phone')} className="text-primary" style={{fontSize:'10px'}}>Release Number</Link>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="contact_phone"
                                                                label="Target Phone"
                                                                placeholder="Tracking Phone"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9+-]+$",
                                                                        errorMessage: "Invalid Contact"
                                                                    },
                                                                }}
                                                                value={contractor?contractor.contact_phone:""}
                                                                
                                                            />
                                                        </div>
                                                    </Col>
                                                    
                                                
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                        <Label htmlFor="validationCustom03">Contact Email</Label>
                                                        <AvField
                                                            name="email"
                                                            type="text"
                                                            errorMessage=" Please provide Email."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="email"
                                                        />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                        <Label htmlFor="email2">Additional Email 1</Label>
                                                        <AvField
                                                            name="email2"
                                                            type="text"
                                                            
                                                            className="form-control"
                                                            
                                                            id="email2"
                                                        />
                                                        </div>
                                                    </Col>
                                                    
                                                </Row>  

                                                <Row>
                                                <Col md="6">
                                                        <div className="mb-3">
                                                        <Label htmlFor="email3">Additional Email 2</Label>
                                                        <AvField
                                                            name="email3"
                                                            type="text"
                                                            
                                                            className="form-control"
                                                            
                                                            id="email3"
                                                        />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                        <Label htmlFor="validationCustom03">City</Label>
                                                        <AvField
                                                            name="city"
                                                            type="text"
                                                            errorMessage=" Please provide a valid city."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="validationCustom03"
                                                        />
                                                        </div>
                                                    </Col>
                                                    
                                                </Row>  
                                                <Row>
                                                    
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                        <Label htmlFor="validationCustom05">Zip</Label>
                                                        <AvField
                                                            name="zip"
                                                            placeholder="Zip Code"
                                                            type="text"
                                                            errorMessage=" Please provide a valid zip."
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="validationCustom05"
                                                            value={contractor?contractor.zip:""}
                                                        />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="zone"
                                                                label="Zone"
                                                                placeholder="Enter Zone"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9]+$",
                                                                        errorMessage: "Invalid Zone"
                                                                    },
                                                                }}
                                                                />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="start"
                                                                label="Start Date"
                                                                placeholder="Select Start Date"
                                                                type="date"
                                                                id="start_date"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="exp"
                                                                label="End Date"
                                                                placeholder="Select End Date"
                                                                type="date"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="membership_fee"
                                                                label="Membership Fee"
                                                                placeholder="Enter Membership Fee"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9]+$",
                                                                        errorMessage: "Invalid Membership Fee"
                                                                    },
                                                                }}
                                                                />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="monthly_payment"
                                                                label="Monthly Payment"
                                                                placeholder="Enter Monthly Payment"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9]+$",
                                                                        errorMessage: "Invalid Monthly Payment"
                                                                    },
                                                                }}
                                                                />
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                        
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="page_title">Page Title</Label>
                                                        <AvField
                                                            name="pg_title"
                                                            placeholder="Page Title"
                                                            type="text"
                                                            className="form-control"
                                                            id="page_title"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <AvField
                                                        className="mb-3"
                                                        type="textarea"
                                                        label="Page Description "
                                                        rows="3"
                                                        name="pg_desc"
                                                        id="page_description"
                                                        placeholder="Page Description"
                                                    />
                                                </Row>
                                                
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="keywords">Keywords & Phrases</Label>
                                                        <AvField
                                                            name="keyword"
                                                            placeholder="Keywords & Phrases"
                                                            type="text"
                                                            className="form-control"
                                                            id="keywords"
                                                        />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                    <Label >Narrative</Label>
                                                        <Editor       
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove', 'history'],
                                                            inline: {
                                                              inDropdown: false,
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                              options: ['bold', 'italic', 'underline'],
                                                              bold: { icon: bold, className: undefined },
                                                              italic: { icon: italic, className: undefined },
                                                              underline: { icon: underline, className: undefined },
                                                            },
                                                            blockType: {
                                                              inDropdown: true,
                                                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                            },
                                                        
                                                            list: {
                                                              inDropdown: false,
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                              options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                              unordered: { icon: unordered, className: undefined },
                                                              ordered: { icon: ordered, className: undefined },
                                                              indent: { icon: indent, className: undefined },
                                                              outdent: { icon: outdent, className: undefined },
                                                            },
                                                            textAlign: {
                                                              inDropdown: false,
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                              options: ['left', 'center', 'right', 'justify'],
                                                              left: { icon: left, className: undefined },
                                                              center: { icon: center, className: undefined },
                                                              right: { icon: right, className: undefined },
                                                              justify: { icon: justify, className: undefined },
                                                            },
                                                            
                                                            link: {
                                                              inDropdown: false,
                                                              className: undefined,
                                                              component: undefined,
                                                              popupClassName: undefined,
                                                              dropdownClassName: undefined,
                                                              showOpenOptionOnHover: true,
                                                              defaultTargetOption: '_self',
                                                              options: ['link', 'unlink'],
                                                              link: { icon: link, className: undefined },
                                                              unlink: { icon: unlink, className: undefined },
                                                              linkCallback: undefined
                                                            },
                                                            remove: { icon: eraser, className: undefined, component: undefined },
                                                            history: {
                                                              inDropdown: false,
                                                              className: undefined,
                                                              component: undefined,
                                                              dropdownClassName: undefined,
                                                              options: ['undo', 'redo'],
                                                              undo: { icon: undo, className: undefined },
                                                              redo: { icon: redo, className: undefined },
                                                            },
                                                          }}      
                                                        editorState={editorState}            
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={onEditorStateChange}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" id="omit_add"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="omit_add">
                                                            Omit Address</label>

                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={true} id="active"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="active">
                                                            Active</label>

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                
                                                <Col md={6}>
                                                <label className="form-check-label me-3">Is Approved Campaign</label>
                                                <Switch
                                                    uncheckedIcon={<Offsymbol />}
                                                    checkedIcon={<OnSymbol />}
                                                    onColor="#45cb85"
                                                    offColor="#ff715b"
                                                    className="form-label my-2"
                                                    onChange={() => {
                                                    setStatus(!status);
                                                    }}
                                                    checked={status}
                                                />
                                                </Col>
                                            </Row>
                                            <Row>
                                                        <Label htmlFor="icon">Logo</Label>
                                                        <Col md="4">
                                                        <input id="logo" className="" type="file" onChange={(e)=> changeLogo(e, setLogo)} />
                                                        </Col>
                                                        <Col md="8">
                                                        {logo?
                                                            <img src={URL.createObjectURL(logo)} style={{width:"50%"}} className="m-3"/>
                                                        :null}
                                                        </Col>
                                            </Row>
                                            {submitting?
                                                <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light my-3"
                                                >
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                                Updating
                                                </button>
                                                :
                                                <Button color="primary my-3" type="submit">
                                                    Submit
                                                </Button>
                                            }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                
                            </AvForm>
                        </Col>
                    </Row>
                }

            </div>

        </React.Fragment>
    )
}

export default AddCampaign
