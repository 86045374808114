import PropTypes from "prop-types"
import React, {useEffect} from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import image from '../../assets/images/logo-icon.png'

import {
  changeSidebarType,
} from "../../store/actions"

const Sidebar = props => {
  function HandleSidebar(){
    if(props.type === "icon"){
      props.changeSidebarType("default")
      localStorage.setItem("sidebar_type","default")
    }else{
      props.changeSidebarType("icon")
      localStorage.setItem("sidebar_type","icon")
    }    
  }

  useEffect(()=> {
    let type =localStorage.getItem('sidebar_type')
    props.changeSidebarType(type?type:'default')
  }, [])
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">
        <div className="text-center py-4">
          <Link to="#" style={{color: '#1e88e5'}} onClick={HandleSidebar}><i className="fas fa-grip-horizontal" style={{fontSize:"25px"}} /></Link>
        </div>
        
          <div className="user-wid text-center py-4">
            <div className="user-img">
              <img src={image} alt="" className="avatar-md mx-auto rounded-circle" />
            </div>
            <div className="mt-3">
              <Link to="#" className="text-dark fw-medium font-size-16"><span className="text-primary">Admin</span></Link>
              {/* <p className="text-body mt-1 mb-0 font-size-13"><span style={{color: '#a6b0cf'}}>UI/UX Designer</span></p> */}
            </div>
          </div>
          <div data-simplebar className="h-100">
            {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
          </div>
          </div>
        </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
        type: PropTypes.string,
        changeSidebarType: PropTypes.func,
        leftSideBarType: PropTypes.any,
}

const mapStatetoProps = state => {
  return {
        layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
      {changeSidebarType,}
)(withRouter(withTranslation()(Sidebar)))